import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import AuthenticateButton from 'components/global/AuthenticateButton';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      textAlign: 'center',
    },
  })
);

const LoginRequired = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <p>
          You must be logged in to access this feature.
          <br />
          Please log in or create an account.
        </p>

        <AuthenticateButton color="secondary" returnAfterLogin={false} variant="contained" />
      </div>
    </>
  );
};

export default LoginRequired;
