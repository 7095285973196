import { OrganizationFormData } from './OrganizationForm.interfaces';
import { OrganizationInput, ImageToUpload } from './OrganizationForm.interfaces';

export function transformFormToInputData(form: HTMLFormElement): OrganizationInput {
  const formData = new FormData(form);
  const fieldValues = Object.fromEntries(formData.entries());

  const { imageNameFile, imageNameFileCrop, imageNameFileAspectRatio } = fieldValues;

  const imagesToUpload: ImageToUpload[] = [];

  if (imageNameFile && typeof imageNameFile === 'object' && imageNameFile.size > 0) {
    const crop = !!imageNameFileCrop;
    imagesToUpload.push({
      key: 'imageName',
      file: imageNameFile as File,
      crop,
      aspectRatio: crop ? parseFloat(imageNameFileAspectRatio as string) : undefined,
    });
  }

  const input = Object.entries(blankState).reduce((acc: any, [key]) => {
    const value = fieldValues[key];

    switch (key) {
      case 'affiliation':
      case 'assetCd':
      case 'assets':
      case 'classification':
      case 'deductibility':
      case 'foundation':
      case 'group':
      case 'incomeCd':
      case 'revenue':
      case 'ruling':
      case 'subsection':
      case 'taxPeriod':
        acc[key] = getFormDataInt(value);
        break;
      case 'assetAmt':
      case 'incomeAmt':
      case 'revenueAmt':
        acc[key] = getFormDataFloat(value);
        break;
      case 'immediateImpact':
      case 'localImpact':
      case 'longTermImpact':
      case 'globalImpact':
        acc[key] = key in fieldValues;
        break;
      case 'deactivatedAt':
        acc['deactivatedAt'] = key in fieldValues ? new Date() : null;
        break;
      case 'annualReportUrl':
      case 'causeArea':
      case 'charityNavigatorUrl':
      case 'city':
      case 'context':
      case 'designation':
      case 'financialsUrl':
      case 'form990Url':
      case 'guidestarUrl':
      case 'ico':
      case 'id':
      case 'imageName':
      case 'impact':
      case 'mission':
      case 'name':
      case 'nteeCd':
      case 'orgSize':
      case 'programRestriction':
      case 'routingOrganizationName':
      case 'sortName':
      case 'state':
      case 'street':
      case 'websiteUrl':
      case 'whyYouShouldCare':
      case 'zip':
        acc[key] = value as string;
        break;
      case 'ein':
      case 'parentEin':
        acc[key] = value ? (value as string) : null;
        break;
      default:
        throw new Error(`${key} is not defined in OrganizationInput`);
    }

    return acc;
  }, {});

  return {
    input,
    imagesToUpload,
  };
}

function getFormDataInt(value?: FormDataEntryValue): number | null {
  if (!value || typeof value !== 'string') return null;

  const parsedValue = parseInt(value, 10);
  return isNaN(parsedValue) ? null : parsedValue;
}

function getFormDataFloat(value?: FormDataEntryValue): number | null {
  if (!value || typeof value !== 'string') return null;

  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? null : parsedValue;
}

const blankState: OrganizationFormData = {
  affiliation: null,
  annualReportUrl: null,
  assetAmt: null,
  assetCd: null,
  assets: null,
  causeArea: null,
  charityNavigatorUrl: null,
  city: null,
  classification: null,
  context: null,
  deductibility: null,
  deactivatedAt: null,
  designation: null,
  ein: null,
  financialsUrl: null,
  form990Url: null,
  foundation: null,
  globalImpact: false,
  group: null,
  guidestarUrl: null,
  ico: null,
  id: null,
  imageName: null,
  immediateImpact: false,
  impact: null,
  incomeAmt: null,
  incomeCd: null,
  localImpact: false,
  longTermImpact: false,
  mission: null,
  name: null,
  nteeCd: null,
  orgSize: null,
  parentEin: null,
  programRestriction: null,
  revenue: null,
  revenueAmt: null,
  routingOrganizationName: null,
  ruling: null,
  sortName: null,
  state: null,
  street: null,
  subsection: null,
  taxPeriod: null,
  websiteUrl: null,
  whyYouShouldCare: null,
  zip: null,
};

export const textareaFields: { name: keyof OrganizationFormData; label: string }[] = [
  { name: 'mission', label: 'Mission' },
  { name: 'impact', label: 'Impact' },
  { name: 'context', label: 'Context' },
  { name: 'whyYouShouldCare', label: 'Why You Should Care' },
];

export const urlFields: { name: keyof OrganizationFormData; label: string }[] = [
  { name: 'websiteUrl', label: 'Website URL' },
  { name: 'annualReportUrl', label: 'Annual Report Url' },
  { name: 'charityNavigatorUrl', label: 'Charity Navigator Url' },
  { name: 'financialsUrl', label: 'Financials Url' },
  { name: 'form990Url', label: 'Form 990 Url' },
  { name: 'guidestarUrl', label: 'Guidestar Url' },
];

export const addressFields: { name: keyof OrganizationFormData; label: string }[] = [
  { name: 'street', label: 'Street' },
  { name: 'city', label: 'City' },
  { name: 'state', label: 'State' },
  { name: 'zip', label: 'Zip' },
];

export const textFields: { name: keyof OrganizationFormData; label: string }[] = [
  { name: 'causeArea', label: 'Cause Area' },
  { name: 'designation', label: 'Designation' },
  { name: 'ico', label: 'ICO' },
  { name: 'nteeCd', label: 'NTEE CD' },
  { name: 'orgSize', label: 'Organization Size' },
  { name: 'programRestriction', label: 'Program Restriction' },
  { name: 'routingOrganizationName', label: 'Routing Organization Name' },
  { name: 'sortName', label: 'Sort Name' },
];

export const checkboxFields: { name: keyof OrganizationFormData; label: string }[] = [
  { name: 'globalImpact', label: 'Global Impact' },
  { name: 'immediateImpact', label: 'Immediate Impact' },
  { name: 'localImpact', label: 'Local Impact' },
  { name: 'longTermImpact', label: 'Long Term Impact' },
];

export const numberFields: { name: keyof OrganizationFormData; label: string }[] = [
  { name: 'affiliation', label: 'Affiliation' },
  { name: 'assetAmt', label: 'Asset Amount' },
  { name: 'assetCd', label: 'Asset CD' },
  { name: 'assets', label: 'Assets' },
  { name: 'classification', label: 'Classification' },
  { name: 'deductibility', label: 'Deductibility' },
  { name: 'foundation', label: 'Foundation' },
  { name: 'group', label: 'Group' },
  { name: 'incomeAmt', label: 'Income Amount' },
  { name: 'incomeCd', label: 'Income CD' },
  { name: 'revenue', label: 'Revenue' },
  { name: 'revenueAmt', label: 'Revenue Amount' },
  { name: 'ruling', label: 'Ruling' },
  { name: 'subsection', label: 'Subsection' },
  { name: 'taxPeriod', label: 'Tax Period' },
];
