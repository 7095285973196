import { useEffect, useState } from 'react';
import {
  getOrganizationsWithCampaignDonationMatchesGql,
  GetOrganizationsWithCampaignDonationMatchesResponse,
  GetOrganizationsWithCampaignDonationMatchesArgs,
} from 'gql/organizations/getOrganizations';
import { Grid, TextField, Typography } from '@mui/material';
import Organization from 'model/Organization.model';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';

interface OrganizationsAutocompleteInputProps {
  campaignId: string;
  onChange: (_: any, value: Organization | null) => void;
  value: Organization | null;
}

export default function DonationMatchOrganizationsAutocompleteInput({
  campaignId,
  onChange,
  value,
}: OrganizationsAutocompleteInputProps) {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>();
  const [search, setSearch] = useState('');

  useEffect(() => {
    return () => {
      if (timeoutRef) clearTimeout(timeoutRef);
    };
  }, [timeoutRef]);

  const { data } = useAuthenticatedHedadoQuery<
    GetOrganizationsWithCampaignDonationMatchesResponse,
    GetOrganizationsWithCampaignDonationMatchesArgs
  >(getOrganizationsWithCampaignDonationMatchesGql, {
    variables: { first: 25, search, campaignId },
  });

  function handleSearchChange(event: React.SyntheticEvent, newInputValue: string, reason: string) {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    setTimeoutRef(setTimeout(() => setSearch(newInputValue), 350));
  }

  const options = (data?.getOrganizationsWithCampaignDonationMatches.edges || []).map((edge) => edge.node);

  return (
    <Autocomplete
      id="search-organizations"
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(opt, v) => opt.id === v.id}
      value={value}
      onChange={onChange}
      onInputChange={handleSearchChange}
      renderInput={(params) => <TextField {...params} label="Organizations" fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {option.name}
                  <br />
                  <small>{option.ein}</small>
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

function getOptionLabel(option: Organization): string {
  if (typeof option === 'string') return option;
  if (typeof option === 'object') return option.name || '';
  return '';
}
