import { memo } from 'react';
import { Typography } from '@mui/material';
import CroppedImageInput from 'components/CroppedImageInput/CroppedImageInput';
import FormSection from './FormSection';
import FormGridItem from './FormGridItem';

interface ImagesSectionProps {
  bannerMobileSrc: string;
  bannerSrc: string;
  logoSrc: string;
  setBannerImage: (image: Blob | null) => void;
  setBannerMobileImage: (image: Blob | null) => void;
  setLogoImage: (image: Blob | null) => void;
}

function ImagesSection({
  bannerMobileSrc,
  bannerSrc,
  logoSrc,
  setBannerImage,
  setBannerMobileImage,
  setLogoImage,
}: ImagesSectionProps) {
  return (
    <FormSection heading="Images">
      <FormGridItem>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Logo
        </Typography>
        <CroppedImageInput
          aspectRatio={1}
          maxSize={500_000}
          onChanged={setLogoImage}
          previewImageSx={{ width: '100%', maxWidth: '250px', borderRadius: '8px' }}
          src={logoSrc}
        />
      </FormGridItem>
      <FormGridItem>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Banner Image (desktop)
        </Typography>
        <CroppedImageInput
          aspectRatio={6}
          maxSize={1_000_000}
          onChanged={setBannerImage}
          previewImageSx={{ width: '100%', borderRadius: '8px' }}
          src={bannerSrc}
        />
      </FormGridItem>
      <FormGridItem>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Banner Image (mobile)
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 1, fontSize: '0.75rem' }}>
          For a seamless mobile experience, we use a differently sized image for mobile devices.
          <br />
          For best results, use the same image provided above.
        </Typography>
        <CroppedImageInput
          aspectRatio={3}
          maxSize={400_000}
          onChanged={setBannerMobileImage}
          previewImageSx={{ width: '100%', borderRadius: '8px' }}
          src={bannerMobileSrc}
        />
      </FormGridItem>
    </FormSection>
  );
}

export default memo(ImagesSection);
