import React, { useState, useEffect, useRef } from 'react';
import { env } from '../../env';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { gql } from '@apollo/client';
import {
  Alert,
  Box,
  createStyles,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Snackbar,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivismOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useHedadoMutation from 'hooks/useHedadoMutation';
import useAnalytics, { events } from 'hooks/useAnalytics';
import YesNoDialog from '../global/YesNoDialog';
import {
  firstDonationDate,
  secondDonationDate,
  donationDateString,
  toCents,
  toDollars,
  toCurrencyString,
} from '../../helpers/donationProcessHelper';
import CurrentSubscription from '../../model/CurrentSubscription.model';
import { getLoggedInDonorProfileQuery_Name } from '../../gql/donorProfileQuery';
import { getMostRecentSubscriptionForDonor_Name } from '../../gql/giving/getMostRecentSubscriptionForDonor';
import { datesAreOnSameDay, formatDateToText } from '../../helpers/dateHelper';

import DonorProfile from '../../model/DonorProfile.model';
import DonationConfirmationModal from './DonationConfirmationModal';
import { sentenceCase } from '../../helpers/formatHelpers';
import Donation from '../../model/Donation.model';
import DisplayDonation from '../../model/DisplayDonation.model';
import LoadingButton from 'components/LoadingButton';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    portfolioLink: {
      fontWeight: 600,
      fontSize: 'inherit',
      color: '#072736',
    },
    datePicker: {
      minWidth: 120,
      width: 400,
    },
    snackbarLink: {
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
    donationMatchText: {
      color: '072736',
    },
  })
);

//TODO: Move these out to their own files
const cancelGivingPlanMutation = gql`
  mutation CancelCurrentSubscription {
    cancelCurrentSubscription {
      success
      errors
    }
  }
`;

const updateDonationPlanMutation = gql`
  mutation UpdateGivingPlan($donationFrequency: String!, $amountCents: Int!, $startAt: Date, $tipsCents: Int) {
    updateGivingPlan(
      input: {
        donationFrequency: $donationFrequency
        amountCents: $amountCents
        startAt: $startAt
        tipsCents: $tipsCents
      }
    ) {
      success
      errors
    }
  }
`;

const tipInfo = `
  Hedado believes in maximizing your donation to nonprofits, so we don't charge fees. As a Public Benefit Corporation, our purpose is to 
  help nonprofits and charities through sustained, predictable, and increased financial giving by creating donor-focused products and 
  innovations. We appreciate any support you're willing to give.
`;

interface DonationPlanProps {
  currentSubscription: CurrentSubscription | null;
  hasDonationPlan: boolean;
  displayPledgeAmount: boolean;
  donor?: DonorProfile;
  defaultTip: number;
}

const DonationPlan = ({
  currentSubscription,
  hasDonationPlan,
  displayPledgeAmount,
  donor,
  defaultTip,
}: DonationPlanProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [hasExistingDonation, setHasExistingDonation] = useState(hasDonationPlan);
  const [openDialog, setDialogOpen] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { trackEvent } = useAnalytics();

  // Holds form values
  const donationAmountRef = useRef<HTMLInputElement>();
  const tipCustomInputRef = useRef<HTMLInputElement>();

  const defaultDonation: Donation = {
    amountInCents: 0,
    tipInCents: 0,
    tipPercent: defaultTip / 100,
    tipCalculation: 'percent',
  };

  const [donation, _setDonation] = useState<Donation>(defaultDonation);

  const setDonation = (donationUpdates: Partial<Donation>) => {
    _setDonation({ ...donation, ...donationUpdates });
  };

  const [amountInputFocused, setAmountInputFocused] = useState(false);
  const [frequency, setFrequency] = useState('once');
  const [savedFrequency, setSavedFrequency] = useState('');
  const [startDateOption, setStartDateOption] = useState('today');

  const displayAmount = (hideSign = false) => {
    return {
      amountInDollars: toCurrencyString(toDollars(donation.amountInCents), { hideSign }),
      tipInDollars: toCurrencyString(toDollars(donation.tipInCents), { hideSign }),
      tipAsPercent: `${Math.round(donation.tipPercent * 100)}%`,
      totalInDollars: toCurrencyString(
        toDollars(
          donation.tipCalculation === 'percent'
            ? donation.amountInCents + donation.amountInCents * donation.tipPercent
            : donation.amountInCents + donation.tipInCents
        ),
        { hideSign }
      ),
    } as DisplayDonation;
  };

  // Holds form validation values
  const [donationAmountError, setDonationAmountError] = useState(false);
  const [donationAmountErrorHelperText, setDonationAmountErrorHelperText] = useState('');
  const [tipAmountError, setTipAmountError] = useState(false);
  const [frequencyError, setFrequencyError] = useState(false);
  const [frequencyErrorHelperText, setFrequencyErrorHelperText] = useState('');
  const [startDateOptionError, setStartDateOptionError] = useState(false);
  const [updateGivingPlanErrored, setUpdateGivingPlanErrored] = useState(false);
  const [cancelGivingPlanErrored, setCancelGivingPlanErrored] = useState(false);

  // Tip range
  const tipRange = [0, 2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25];
  const tipMarks = tipRange.map((tip) => {
    return { value: tip, label: Number.isInteger(tip) || tip === 0 ? `${tip}%` : '' };
  });

  const [updateGivingPlan, { loading: updateGivingPlanLoading }] = useHedadoMutation(updateDonationPlanMutation, {
    refetchQueries: [
      'GetLoggedInDonorActiveSubscription',
      'GetDonorPortfolioSummary',
      getLoggedInDonorProfileQuery_Name,
      getMostRecentSubscriptionForDonor_Name,
    ],
    onError: () => setUpdateGivingPlanErrored(true),
    onCompleted: () => {
      setShowConfirmation(true);
      setHasExistingDonation(frequency !== 'once');
    },
  });

  const [cancelGivingPlan, { loading: cancelGivingPlanLoading }] = useHedadoMutation(cancelGivingPlanMutation, {
    refetchQueries: [
      'GetLoggedInDonorActiveSubscription',
      'GetDonorPortfolioSummary',
      getLoggedInDonorProfileQuery_Name,
      getMostRecentSubscriptionForDonor_Name,
    ],
    onError: () => setCancelGivingPlanErrored(true),
    onCompleted: () => {
      setFrequency('monthly');
      clearForm();
      setHasExistingDonation(false);
      setShowConfirmation(false);
    },
  });

  const formInputStyle = { width: 'min(100%, 400px) !important' };
  const totalStyle = {
    fontSize: '1.1rem',
    display: 'inline',
    margin: '0 0.6rem 0 0',
  };

  useEffect(() => {
    setHasExistingDonation(currentSubscription ? true : false);

    if (!currentSubscription) return;

    // Set the frequency
    setFrequency(currentSubscription?.frequency || (displayPledgeAmount ? 'once' : 'monthly'));
    updateDonationFromSubscription(currentSubscription);

    // FIXME:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubscription]);

  const updateDonationFromSubscription = (currentSubscription: CurrentSubscription) => {
    const tipInCents = currentSubscription.tipsCents ?? 0;
    const tipPercentage = currentSubscription?.amountCents ? (tipInCents / currentSubscription?.amountCents) * 100 : 0;

    setDonation({
      tipCalculation: tipRange.includes(tipPercentage) ? 'percent' : 'cents',
      tipPercent: tipPercentage / 100,
      tipInCents: tipInCents,
      amountInCents: currentSubscription.amountCents ?? 0,
    });
  };

  // Event handlers for form controls
  const amountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Number(e.target.value.replace(/[^0-9./]/g, ''));
    const amountInCents = e.target.value.indexOf('.') !== -1 ? amount * 100 : toCents(amount);
    setDonation({ amountInCents: amountInCents });
    setDonationAmountError(false);
    setDonationAmountErrorHelperText('');
  };

  const amountBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setAmountInputFocused(false);
    e.target.value = displayAmount(true).amountInDollars;
  };

  const tipSliderChange = (event: Event, newValue: number | number[]) => {
    setDonation({ tipPercent: Number(newValue) / 100 });
  };

  const tipCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Number(e.target.value.replace(/[^0-9./]/g, ''));
    const amountInCents = e.target.value.indexOf('.') !== -1 ? amount * 100 : toCents(amount);
    setDonation({ tipInCents: amountInCents });
    setTipAmountError(false);
  };

  const tipCustomInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.value = displayAmount(true).tipInDollars;
  };

  const frequencyChange = (event: SelectChangeEvent) => {
    setFrequency(event.target.value as string);
    setFrequencyError(false);
    setFrequencyErrorHelperText('');
  };

  const startDateOptionChange = (event: SelectChangeEvent) => {
    setStartDateOption(event.target.value as string);
    setStartDateOptionError(false);
  };

  const startDateHelper = (frequency: string) => {
    if (hasExistingDonation) return '';
    const firstDonation = formatDateToText(firstDonationDate(frequency as any));
    const nextDonation = formatDateToText(secondDonationDate(frequency as any));
    switch (frequency) {
      case 'monthly':
      case 'quarterly':
        return startDateOption === 'later' ? `Next donation: ${nextDonation}` : `Next donation: ${firstDonation}`;
      case 'once':
        return 'Your donation will be processed today and will be allocated to the nonprofits in your portfolio.';
      case 'annually':
        return `First donation: today, next donation: ${nextDonation}`;
      default:
        return '';
    }
  };

  // Confirmation dialog event handler
  const handleDialogClose = (value: boolean) => {
    setDialogOpen(false);

    if (value) {
      trackEvent(events.cancelGivingPlan);
      cancelGivingPlan();
    }
  };

  // Run form validation
  const validateForm = (): boolean => {
    let isValid = true;
    clearErrors();

    if (donationAmountRef.current?.value === '') {
      setDonationAmountError(true);
      setDonationAmountErrorHelperText('You must choose a giving amount');
      isValid = false;
    } else if (donation.amountInCents <= 0) {
      setDonationAmountError(true);
      setDonationAmountErrorHelperText('Your donation amount must be greater than $0');
      isValid = false;
    }

    if (frequency === '') {
      setFrequencyError(true);
      setFrequencyErrorHelperText('You must choose a giving frequency');
      isValid = false;
    }

    return isValid;
  };

  const savePlan = async () => {
    if (!validateForm()) return;
    setSavedFrequency(frequency);

    // Set the start date based on selections
    let dateToStart = new Date();

    if (startDateOption === 'later') {
      dateToStart = firstDonationDate(frequency as any) || new Date();
    }

    const tipsCents = Math.floor(
      donation.tipCalculation === 'percent' ? donation.amountInCents * donation.tipPercent : donation.tipInCents
    );

    trackEvent(events.updateGivingPlan, {
      donationFrequency: frequency,
      amountCents: donation.amountInCents,
      startAt: dateToStart,
      tipsCents,
    });

    await updateGivingPlan({
      variables: {
        donationFrequency: frequency,
        amountCents: donation.amountInCents,
        startAt: dateToStart,
        tipsCents,
      },
    });
  };

  const clearErrors = () => {
    setDonationAmountError(false);
    setFrequencyError(false);
    setStartDateOptionError(false);
    setDonationAmountErrorHelperText('');
    setFrequencyErrorHelperText('');
  };

  const clearForm = () => {
    setDonation(defaultDonation);
    clearErrors();
  };

  const startDateString = (date: Date) => {
    return startDateOption === 'today' ? 'today' : formatDateToText(date);
  };

  const modalDonationSummary = () => {
    if (savedFrequency === 'once') {
      return `Your one-time donation of <strong>${displayAmount().totalInDollars}</strong> will process today`;
    } else if (currentSubscription?.frequency && startDateOption === 'later') {
      return `Your donation of <strong>${
        displayAmount().totalInDollars
      }</strong> is scheduled for <strong>${formatDateToText(firstDonationDate(frequency as any))}</strong>`;
    } else if (currentSubscription?.frequency && startDateOption !== 'later') {
      return `Your donation of <strong>${displayAmount().totalInDollars}</strong> will be processed today</strong>`;
    }
  };

  const totalStartDate = (frequency: string = '') => {
    const first = firstDonationDate(frequency as any);
    return `${sentenceCase(frequency)}, starting ${startDateString(first)}.`;
  };

  const planWillChange = () => {
    return hasExistingDonation && currentSubscription?.frequency && frequency !== currentSubscription?.frequency;
  };

  const showActivePlanDetails = () => {
    return hasExistingDonation && donor && savedFrequency !== 'once';
  };

  const showAdHocDonationDetails = () => {
    return showConfirmation && donor && savedFrequency === 'once';
  };

  const showStartDatePicker = () => {
    return (frequency === 'monthly' || frequency === 'quarterly') && (!hasExistingDonation || planWillChange());
  };

  const switchToCentsTipping = () => {
    setDonation({ tipCalculation: 'cents', tipInCents: donation.amountInCents * donation.tipPercent });
  };

  const switchToPercentTipping = () => {
    setDonation({ tipCalculation: 'percent', tipPercent: defaultTip / 100 });
  };

  const showTotal = () => {
    return donation.amountInCents > 0;
  };

  const percentTipAsDollars = (percent: number = 0) => {
    return toDollars(donation.amountInCents * (percent / 100));
  };

  const tipSliderLabelFormat = (value: number) => {
    const percent = value ?? defaultTip / 100;
    return percentTipAsDollars(percent) ? toCurrencyString(percentTipAsDollars(percent)) : '$0';
  };

  const frequencyHelperText = () => {
    switch (frequency) {
      case 'monthly':
      case 'quarterly':
        return frequencyErrorHelperText;
      case 'annually':
      case 'once':
        return frequencyError ? frequencyErrorHelperText : startDateHelper(frequency);
      default:
        return '';
    }
  };

  const startDateHelperText = () => {
    switch (frequency) {
      case 'monthly':
      case 'quarterly':
        return startDateHelper(frequency);
      default:
        return '';
    }
  };

  const closestDonationDateInfo = () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const closestPaymentDate = new Date(currentSubscription?.startAt || today);

    if (datesAreOnSameDay(closestPaymentDate, today)) {
      return ` Your donation will be processed today, ${formatDateToText(today)}.`;
    } else {
      return ` Your next donation will be processed on ${formatDateToText(firstDonationDate(frequency as any))}.`;
    }
  };

  const matchedAmountToShow = (): string => {
    const donationAmount = donation.amountInCents;
    const matchAmountToDisplay = donationAmount >= 2000 ? 2000 : donationAmount;

    return toCurrencyString(toDollars(matchAmountToDisplay), { hideSign: true });
  };

  const showDonationMatchText = env.REACT_APP_FEATURE_FLAG_ENABLE_MATCH && frequency === 'monthly';

  return (
    <>
      {showActivePlanDetails() && (
        <Alert severity="success" sx={{ marginBottom: 3 }} icon={<VolunteerActivismIcon />}>
          <Typography variant="body1">
            Thanks {donor?.firstName ? ` ${donor?.firstName}!` : '!'} Your donation will be allocated to the nonprofits
            in your{' '}
            <RouterLink className={classes.portfolioLink} to="/portfolio">
              Portfolio.
            </RouterLink>
            {closestDonationDateInfo()}
          </Typography>
        </Alert>
      )}

      {showAdHocDonationDetails() && (
        <Alert severity="success" sx={{ marginBottom: 3 }} icon={<VolunteerActivismIcon />}>
          <Typography variant="body1">
            Thanks{donor?.firstName ? ` ${donor?.firstName}!` : '!'} You've given to your{' '}
            <RouterLink className={classes.portfolioLink} to="/portfolio">
              Portfolio.
            </RouterLink>
            <br />
            Your donation of {displayAmount().totalInDollars} will be allocated to the nonprofits in your portfolio.
          </Typography>
        </Alert>
      )}

      {!hasExistingDonation && (
        <Alert severity="warning" sx={{ marginBottom: 3 }} icon={false}>
          Set a plan below to donate to your{' '}
          <RouterLink className={classes.portfolioLink} to="/portfolio">
            portfolio
          </RouterLink>
          . The amount you choose will be allocated to the nonprofits in your portfolio.
          {env.REACT_APP_FEATURE_FLAG_ENABLE_MATCH === true && (
            <strong>
              <span className={classes.donationMatchText}>
                &nbsp; Choose a Monthly frequency and get a $60 match over 3 months!
              </span>
              <Tooltip
                enterTouchDelay={0}
                title="For donations starting after 1/15/2022, Hedado will match $20/month for 3 months when you give to organizations in your portfolio. The funds will be split with the same weights as your portfolio."
              >
                <InfoOutlinedIcon sx={{ marginLeft: 1, mb: '2px', fontSize: '16px', color: 'primary.main' }} />
              </Tooltip>
            </strong>
          )}
        </Alert>
      )}

      <FormControl error={donationAmountError} sx={{ mb: 3, ...formInputStyle }}>
        <TextField
          id="donation-amount"
          label="Donation Amount"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          sx={formInputStyle}
          value={amountInputFocused ? donationAmountRef.current?.value : displayAmount(true).amountInDollars}
          onChange={amountChange}
          onFocus={() => {
            setAmountInputFocused(true);
          }}
          onBlur={amountBlur}
          inputRef={donationAmountRef}
          error={donationAmountError}
        />
        <FormHelperText>{donationAmountErrorHelperText}</FormHelperText>
      </FormControl>

      <Box sx={{ mb: 3, ...formInputStyle }}>
        <FormControl fullWidth error={frequencyError}>
          <InputLabel id="select-frequency-label">Frequency</InputLabel>
          <Select
            labelId="select-frequency-label"
            id="select-frequency"
            value={frequency}
            label="Frequency"
            onChange={frequencyChange}
          >
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="quarterly">Quarterly</MenuItem>
            <MenuItem value="annually">Annually</MenuItem>
            {!hasExistingDonation && <MenuItem value="once">One Time - Now</MenuItem>}
          </Select>
          <FormHelperText>{frequencyHelperText()}</FormHelperText>
        </FormControl>
      </Box>

      {showStartDatePicker() && (
        <Box sx={{ mb: 3, ...formInputStyle }}>
          <FormControl fullWidth error={startDateOptionError}>
            <InputLabel id="select-start-date-label">
              {planWillChange() ? 'Next Donation' : 'First Donation'}
            </InputLabel>
            <Select
              labelId="select-start-date-label"
              id="select-start-date-option"
              value={startDateOption}
              label={planWillChange() ? 'Next Donation' : 'First Donation'}
              onChange={startDateOptionChange}
            >
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="later">{formatDateToText(firstDonationDate(frequency as any))}</MenuItem>
            </Select>
            <FormHelperText>{startDateHelperText()}</FormHelperText>
          </FormControl>
        </Box>
      )}

      <Box>
        <Alert severity="success" sx={{ marginBottom: donation.tipCalculation === 'percent' ? 3 : 1 }} icon={false}>
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            {donation.tipInCents > 0 ? 'Thanks for your tip!' : 'Add a tip to support Hedado'}
            <Tooltip enterTouchDelay={0} title={tipInfo}>
              <InfoOutlinedIcon sx={{ marginLeft: 1, mb: '2px', fontSize: '16px', color: 'primary.main' }} />
            </Tooltip>
          </Typography>
          Hedado does not charge any platform fees, and relies on the generosity of donors like you to support our
          service. 💚
        </Alert>
        <Fade in={donation.tipCalculation === 'percent'} appear={false}>
          <Box
            sx={{
              px: 3,
              height: donation.tipCalculation === 'percent' ? 'auto' : 0,
              transition: 'height 0.3s ease-in-out',
            }}
          >
            <Typography id="tip-slider" gutterBottom>
              Tip amount
            </Typography>
            {/* TODO:// Should be refactored out into a separate control/file */}
            <Slider
              aria-label="Add a tip to your donation to support Hedado"
              defaultValue={defaultTip}
              getAriaValueText={() => displayAmount().tipAsPercent}
              aria-labelledby="tip-slider"
              valueLabelDisplay="auto"
              valueLabelFormat={tipSliderLabelFormat}
              value={Number(donation.tipPercent * 100)}
              onChange={tipSliderChange}
              step={2.5}
              marks={tipMarks}
              min={0}
              max={25}
              color="secondary"
              sx={{
                '.MuiSlider-thumb': {
                  border: '3px solid #395884',
                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                    boxShadow: 'inherit',
                  },
                  height: 24,
                  width: 24,
                },
                '.MuiSlider-mark': {
                  height: '7px',
                  width: '3px',
                },
                '.MuiSlider-markLabel': {
                  fontWeight: 600,
                },
                '& .MuiSlider-valueLabel': {
                  lineHeight: 1.2,
                  fontSize: 14,
                  fontWeight: 500,
                  background: 'unset',
                  width: 'fit-content',
                  height: 32,
                  borderRadius: 6,
                  backgroundColor: 'primary.main',
                },
              }}
            />
          </Box>
        </Fade>
        {donation.tipCalculation === 'cents' && (
          <FormControl error={tipAmountError} sx={{ mt: 3, ...formInputStyle }}>
            <TextField
              id="custom-tip-amount"
              label="Custom Tip"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={formInputStyle}
              defaultValue={displayAmount(true).tipInDollars}
              onChange={tipCustomInputChange}
              onBlur={tipCustomInputBlur}
              inputRef={tipCustomInputRef}
              error={tipAmountError}
            />
            <FormHelperText>{donationAmountErrorHelperText}</FormHelperText>
          </FormControl>
        )}

        <Typography
          sx={{ fontSize: '0.8rem', textDecoration: 'underline', cursor: 'pointer', mb: 3, ml: 0 }}
          onClick={donation.tipCalculation === 'percent' ? switchToCentsTipping : switchToPercentTipping}
        >
          {donation.tipCalculation === 'percent' ? 'Custom tip' : 'Percent tip'}
        </Typography>
      </Box>

      <Box sx={{ minWidth: 120 }}>
        {showTotal() && (
          <Alert severity="warning" icon={false} sx={{ display: 'flex', mb: 3 }}>
            <Box>
              <Typography sx={{ ...totalStyle, fontWeight: 500 }}>
                {planWillChange() ? 'New total' : 'Donation total'}
              </Typography>
              <Typography
                sx={{
                  ...totalStyle,
                  fontWeight: 600,
                  textDecoration: 'underline',
                  textDecorationColor: '#86d966',
                  textDecorationThickness: '3px',
                  textUnderlineOffset: '3px',
                }}
              >
                {displayAmount().totalInDollars}
              </Typography>
            </Box>

            {showDonationMatchText && (
              <Typography sx={{ ...totalStyle, fontWeight: 500 }}>
                <span className={classes.donationMatchText}>
                  Plus a <strong>{`$${matchedAmountToShow()}`}</strong> match for 3 months
                </span>
              </Typography>
            )}

            <Typography sx={{ fontSize: '0.9rem' }}>
              {!hasExistingDonation || planWillChange()
                ? totalStartDate(frequency)
                : `${sentenceCase(frequency)}, on the ${donationDateString(currentSubscription?.frequency)}.`}
            </Typography>
          </Alert>
        )}

        <Grid
          container
          gap={2}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <Grid item xs={12} sm="auto">
            <LoadingButton
              color="primary"
              disabled={updateGivingPlanLoading}
              loading={updateGivingPlanLoading}
              onClick={savePlan}
              size="large"
              variant="contained"
            >
              {hasExistingDonation ? 'Update Plan' : 'Complete Donation'}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm="auto">
            {hasExistingDonation && (
              <>
                <LoadingButton
                  color="error"
                  disabled={cancelGivingPlanLoading}
                  loading={cancelGivingPlanLoading}
                  onClick={() => setDialogOpen(true)}
                  size="large"
                  variant="contained"
                >
                  Cancel Recurrence
                </LoadingButton>
                <YesNoDialog
                  title="Cancel Recurring Donation"
                  declineText="No"
                  confirmText="Yes"
                  text="Are you sure you want to cancel your recurring donation to your portfolio?"
                  open={openDialog}
                  onClose={handleDialogClose}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* FIXME: Should conditionally say "today, ___" */}
      <DonationConfirmationModal
        open={showConfirmation}
        handleClose={() => history.push('/summary')}
        donor={donor}
        donationSummary={modalDonationSummary()}
      />

      <Snackbar
        open={cancelGivingPlanErrored || updateGivingPlanErrored}
        autoHideDuration={8000}
        onClose={() => {
          setCancelGivingPlanErrored(false);
          setUpdateGivingPlanErrored(false);
        }}
      >
        <Alert elevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>
          {cancelGivingPlanErrored
            ? 'Sorry, there was an error cancelling your giving plan'
            : updateGivingPlanErrored
            ? 'Sorry, there was an error saving your giving plan'
            : null}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DonationPlan;
