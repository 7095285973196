import type { DonationMatchesTableState } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import { useState } from 'react';
import {
  updateDonationMatchesGql,
  UpdateDonationMatchesMutationVariables,
  UpdateDonationMatchesMutationResponse,
} from 'gql/donationMatches/updateCampaignDonationMatches';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { getCampaignDonationMatchesQuery } from 'gql/donationMatches/getCampaignDonationMatches';
import useHedadoMutation from 'hooks/useHedadoMutation';

export function useUpdateDonationMatches(campaignId: string) {
  const [hasErrored, setHasErrored] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [updateDonationMatchesMutation] = useHedadoMutation<
    UpdateDonationMatchesMutationResponse,
    UpdateDonationMatchesMutationVariables
  >(updateDonationMatchesGql, {
    onError: () => setHasErrored(true),
    onCompleted: () => setHasUpdated(true),
  });

  async function updateDonationMatches(
    donationMatchIds: string[],
    status: DonationMatchStatus,
    refetchQueryVariables: DonationMatchesTableState['queryVariables']
  ) {
    const updateDonationMatchesResult = await updateDonationMatchesMutation({
      variables: {
        campaignId,
        donationMatchIds,
        input: {
          status,
        },
      },
      refetchQueries: [
        'GetCampaignAdminStatistics',
        'GetCampaignDonationMatchTotals',
        {
          query: getCampaignDonationMatchesQuery,
          variables: { ...refetchQueryVariables, statuses: [DonationMatchStatus.pending] },
        },
        {
          query: getCampaignDonationMatchesQuery,
          variables: { ...refetchQueryVariables, statuses: [DonationMatchStatus.deferred] },
        },
        {
          query: getCampaignDonationMatchesQuery,
          variables: { ...refetchQueryVariables, statuses: [DonationMatchStatus.excluded] },
        },
      ],
    });

    return updateDonationMatchesResult;
  }

  return { updateDonationMatches, hasErrored, hasUpdated };
}
