import type { Donation } from 'components/DonorHistoryPage/hooks/useDonorDonationsQuery';
import { Dialog, DialogContent, DialogTitle, List, ListItem } from '@mui/material';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import SettledMatchListItem from './components/SettledMatchListItem';
import PendingMatchListItem from './components/PendingMatchListItem';

type DonationMatch = Donation['donationMatches'][number];

interface DonationMatchesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  donationMatches: DonationMatch[];
}

export default function DonationMatchesDialog({ isOpen, onClose, donationMatches }: DonationMatchesDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Donation Matches</DialogTitle>
      <DialogContent>
        <List>
          {donationMatches.map((donationMatch) => {
            switch (donationMatch.status) {
              case DonationMatchStatus.pending:
              case DonationMatchStatus.deferred:
              case DonationMatchStatus.processing:
                return (
                  <ListItem
                    sx={{
                      '&:not(:last-of-type)': {
                        borderBottom: '1px solid #D9D9D9',
                      },
                      mb: 1.5,
                      pb: 2,
                      display: 'inline-block',
                    }}
                  >
                    <PendingMatchListItem donationMatch={donationMatch} />
                  </ListItem>
                );
              case DonationMatchStatus.settled:
                return (
                  <ListItem
                    sx={{
                      '&:not(:last-of-type)': {
                        borderBottom: '1px solid #D9D9D9',
                      },
                      mb: 1.5,
                      pb: 2,
                      display: 'inline-block',
                    }}
                  >
                    <SettledMatchListItem donationMatch={donationMatch} />
                  </ListItem>
                );
              default:
                return null;
            }
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}
