import { gql } from '@apollo/client';

export interface UpdatePersonalDetailsMutationVariables {
  donorId: string;
  input: {
    firstName: string;
    lastName: string;
  };
}

export interface UpdatePersonalDetailsMutationResponse {
  updateDonorPersonalDetails: { id: string };
}

export const updatePersonalDetailsGql = gql`
  mutation UpdatePersonalDetails($donorId: ID!, $input: UpdateDonorPersonalDetailsInput!) {
    updateDonorPersonalDetails(donorId: $donorId, donor: $input) {
      id
    }
  }
`;
