import type { PageSize } from 'components/DonorHistoryPage/hooks/useDonationsTableState';
import { Box, Table, TableRow, TableBody, TablePagination } from '@mui/material';

interface DonationsTableFooterProps {
  page: number;
  rowsPerPage: number;
  setPage: (value: number) => void;
  setRowsPerPage: (value: PageSize) => void;
  total: number;
}

const ROW_PER_PAGE_OPTIONS = [25, 50, 100];

export default function DonationsTableFooter({
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  total,
}: DonationsTableFooterProps) {
  function handlePageChange(_: any, value: number) {
    setPage(value);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newSize = parseInt(event.target.value);

    if (ROW_PER_PAGE_OPTIONS.includes(newSize)) {
      setRowsPerPage(newSize as PageSize);
    }
  }

  return (
    <Box sx={{ position: 'sticky', bottom: '0px', background: '#fff', zIndex: 2 }}>
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              count={total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
