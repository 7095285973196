import AdminPage from 'components/admin/AdminPage';
import CampaignForm from 'components/CampaignForm/CampaignForm';

export default function CreateCampaign() {
  const pageTitle = 'New Campaign';

  return (
    <AdminPage title={pageTitle}>
      <CampaignForm />
    </AdminPage>
  );
}
