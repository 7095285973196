import { ApolloError } from '@apollo/client';
import DonorProfile from 'model/DonorProfile.model';
import { getLoggedInDonorProfileQuery_Gql } from 'gql/donorProfileQuery';
import { useAuthenticatedHedadoQuery } from './useHedadoQuery';

export default function useDonorProfile(): [DonorProfile | null, { loading: boolean; error: ApolloError | undefined }] {
  console.info('here');
  const { loading, data, error } = useAuthenticatedHedadoQuery(getLoggedInDonorProfileQuery_Gql);

  const donorProfile = data?.getLoggedInDonorProfile;

  return [donorProfile ? (donorProfile as DonorProfile) : null, { loading, error }];
}
