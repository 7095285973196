// import type { Theme } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Drawer, Box, Card, CardActions, CardContent, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { env } from 'env';
import useAddOrganizationToPortfolio from 'hooks/useAddOrganizationToPortfolio';
import { useOrganizationAddedAfterRedirect } from 'components/global/RedirectProvider';
import CauseAreaChip from '../CauseAreaChip';
import MoreInfo from '../MoreInfo/MoreInfo';
import OrganizationAddedSnackbar from '../OrganizationAddedSnackbar';
import Organization from 'model/Organization.model';
import AddMenu from './components/AddMenu';
import InPortfolioFlag from './components/InPortfolioFlag';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    iconAction: {
      cursor: 'pointer',
    },
    learnMoreButton: {
      width: 'auto',
    },
    addButton: {
      flexGrow: 1,
      whiteSpace: 'wrap',
    },
    cardBase: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignContent: 'stretch',
      alignItems: 'stretch',
      minWidth: '180px',
      maxWidth: '325px',
    },
    cardDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      // flexGrow: 0,
      flexDirection: 'column',
      flexBasis: 'fit-content',
    },
    cardImage: {
      aspectRatio: '34/23',
      objectFit: 'cover',
    },
    moreInfoDrawer: {
      [theme.breakpoints.only('xs')]: {
        maxWidth: `min(calc(100vw - ${theme.spacing(7)}, 500px))`,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500,
      },
      height: '100%',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(10),
    },
  })
);

interface OrganizationCardProps {
  organization: Organization;
  showAdd?: boolean;
  currentlyInPortfolio?: boolean;
}

const OrganizationCard = ({ organization, showAdd = true, currentlyInPortfolio = false }: OrganizationCardProps) => {
  const classes = useStyles();
  const { added: addedAfterRedirect } = useOrganizationAddedAfterRedirect(organization.id);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [orgDrawerOpen, setOrgDrawerOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { addOrganizationToPortfolio } = useAddOrganizationToPortfolio();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOrgDrawerOpen(open);
  };

  const addOrganization = () => {
    if (isAuthenticated) {
      addOrganizationToPortfolio({ variables: { organizationId: organization.id } });
      setShowConfirmation(true);
    } else {
      loginWithStateToAddOrganization({ organizationId: organization.id });
    }
  };

  async function loginWithStateToAddOrganization({ organizationId }: { organizationId: string }) {
    loginWithRedirect({
      appState: {
        organizationIdToAdd: organizationId,
        returnTo: window.location.pathname,
      },
    });
  }

  useEffect(() => {
    setShowConfirmation(addedAfterRedirect);
  }, [addedAfterRedirect]);

  const imageUrl = !organization.imageName
    ? env.REACT_APP_DEFAULT_ORG_IMAGE_URL
    : `${env.REACT_APP_ORGANIZATION_IMAGE_PATH}${organization.imageName}`;

  function handleAddToPortfolioClick() {
    addOrganization();
  }

  return (
    <>
      <Card className={classes.cardBase} sx={{ position: 'relative' }}>
        <CardMedia component="img" image={imageUrl} alt="" className={classes.cardImage} />
        {currentlyInPortfolio && <InPortfolioFlag />}
        <CardContent className={classes.cardDetails}>
          <Typography gutterBottom variant="h6" component="div">
            {organization.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            EIN: {organization.ein}
            <Box sx={{ mt: 1 }}>
              {organization.causeArea && (
                <CauseAreaChip key={organization.causeArea} causeArea={organization.causeArea} colorSet={0} />
              )}
              {organization.campaignSections &&
                organization.campaignSections.length > 0 &&
                organization.campaignSections.map((section, index) => (
                  <CauseAreaChip key={section} causeArea={section} colorSet={Math.min(2, index + 1)} />
                ))}
            </Box>
          </Typography>
        </CardContent>
        <CardActions sx={{ flexGrow: 1, alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <Button
            className={classes.learnMoreButton}
            sx={{ flexGrow: showAdd ? 0 : 1 }}
            variant="outlined"
            size="large"
            onClick={toggleDrawer(true)}
          >
            About
          </Button>

          {showAdd && (
            <AddMenu
              id={`AddMenu-${organization.ein}`}
              organization={organization}
              isInPortfolio={currentlyInPortfolio}
              onAddToPortfolioClick={handleAddToPortfolioClick}
            />
          )}
        </CardActions>
      </Card>

      <Drawer
        variant="temporary"
        anchor="right"
        open={orgDrawerOpen}
        onClose={toggleDrawer(false)}
        hideBackdrop={false}
      >
        <Box className={classes.moreInfoDrawer}>
          <CloseIcon onClick={toggleDrawer(false)} className={classes.iconAction} />

          <MoreInfo
            organization={organization}
            currentlyInPortfolio={!showAdd || currentlyInPortfolio}
            showButton={true}
          />
        </Box>
      </Drawer>
      <OrganizationAddedSnackbar organization={organization} showSnackbar={showConfirmation} />
    </>
  );
};

export default OrganizationCard;
