import { useState } from 'react';
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
} from '@mui/material';
import FormGridItem from '../../FormGridItem';

interface StringsTableProps {
  onAdd: (email: string) => void;
  onRemove: (email: string) => void;
  tableCellLabel: string;
  tableHeading: string;
  textFieldProps?: TextFieldProps;
  values: string[];
  Row?: (props: { value: string; onRemove: (email: string) => void }) => React.ReactElement;
  showAddRow: boolean;
}

export default function StringsTable({
  onAdd,
  onRemove,
  tableCellLabel,
  tableHeading,
  textFieldProps,
  values,
  Row = DefaultRow,
  showAddRow,
}: StringsTableProps) {
  const [newEmail, setNewEmail] = useState('');

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewEmail(event.target.value);
  }

  function handleAddClick() {
    onAdd(newEmail);
    setNewEmail('');
  }

  return (
    <FormGridItem>
      <Typography variant="overline">{tableHeading}</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>{tableCellLabel}</b>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((value) => (
              <Row value={value} key={`row_${value}`} onRemove={onRemove} />
            ))}
            {showAddRow && (
              <TableRow sx={{ '& td': { border: 0 } }}>
                <TableCell>
                  <TextField
                    {...textFieldProps}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={newEmail}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Button variant="outlined" onClick={handleAddClick} disabled={!newEmail}>
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FormGridItem>
  );
}

function DefaultRow({ value, onRemove }: { value: string; onRemove: (email: string) => void }) {
  return (
    <TableRow key={`row_${value}`}>
      <TableCell>{value}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <Button color="error" variant="outlined" onClick={() => onRemove(value)}>
          Remove
        </Button>
      </TableCell>
    </TableRow>
  );
}
