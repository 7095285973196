import type Organization from 'model/Organization.model';
import { useState, useRef } from 'react';
import { Button, Grid, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';
import DateRangePicker from 'components/DateRangePicker';
import DonationMatchOrganizationsAutocompleteInput from './components/DonationMatchOrganizationsAutocompleteInput';

interface DonationMatchFiltersProps {
  campaignId: string;
  clearFilters: () => void;
  endDate?: Date;
  setEndDate: (value?: Date) => void;
  setOrganizationId: (value: string) => void;
  setSearch: (value: string) => void;
  setStartDate: (value?: Date) => void;
  startDate?: Date;
}

export default function DonationMatchFilters({
  campaignId,
  clearFilters,
  endDate,
  setEndDate,
  setOrganizationId,
  setSearch,
  setStartDate,
  startDate,
}: DonationMatchFiltersProps) {
  const searchInput = useRef<HTMLInputElement>(null);
  const debounce = useDebounce();
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    debounce(() => {
      setSearch(event.target.value);
    }, 350);
  }

  function handleDateRangeChange({ startDate, endDate }: { startDate?: Date; endDate?: Date }) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  function handleOrganizationAutocompleteChange(_: any, organization: Organization | null) {
    setSelectedOrganization(organization);
    setOrganizationId(organization?.id || '');
  }

  function handleClearFiltersClick() {
    clearFilters();
    setSelectedOrganization(null);
    if (searchInput.current) {
      searchInput.current.value = '';
    }
  }

  return (
    <Grid container sx={{ mt: 3, display: 'flex', alignItems: 'flex-end' }} spacing={2}>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          label="Search"
          placeholder="Search by name or email address"
          fullWidth
          onChange={handleSearchChange}
          inputProps={{ ref: searchInput }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <DateRangePicker startDate={startDate} endDate={endDate} onDateRangeChange={handleDateRangeChange} />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <DonationMatchOrganizationsAutocompleteInput
          campaignId={campaignId}
          onChange={handleOrganizationAutocompleteChange}
          value={selectedOrganization}
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12} sx={{ textAlign: { lg: 'right', md: 'left', sm: 'right', xs: 'left' } }}>
        <Button onClick={handleClearFiltersClick}>clear filters</Button>
      </Grid>
    </Grid>
  );
}
