import { useState } from 'react';
import { Divider, Snackbar, Alert } from '@mui/material';
import { useDownloadDonorHistory } from './hooks/useDownloadDonorHistory';
import ContributionsTable from './components/ContributionsTable/ContributionsTable';
import DonationsTable from './components/DonationsTable/DonationsTable';
import DonationStatistics from './components/DonationStatistics';
import DonorHistoryHeader from './components/DonorHistoryHeader';
import DonorHistoryTabs, { HistoryTab } from './components/DonorHistoryTabs';
import DonorPaymentMethodDrawer from './components/DonorPaymentMethodDrawer';

export default function DonorHistoryPage() {
  const [isPaymentMethodDrawerOpen, setIsPaymentMethodDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(HistoryTab.donations);
  const { downloadDonorHistoryExport, hasErrored, setHasErrored } = useDownloadDonorHistory();

  async function handleExportDataClick() {
    downloadDonorHistoryExport();
  }

  function handleManagePaymentsClick() {
    setIsPaymentMethodDrawerOpen(true);
  }

  function handleTabChange(_: any, value: HistoryTab) {
    setActiveTab(value);
  }

  function handleCloseDrawer() {
    setIsPaymentMethodDrawerOpen(false);
  }

  return (
    <>
      <DonorHistoryHeader onExportDataClick={handleExportDataClick} onManagePaymentClick={handleManagePaymentsClick} />
      <DonationStatistics />
      <Divider sx={{ mt: 5, border: 'none' }} />
      <DonorHistoryTabs activeTab={activeTab} onTabChange={handleTabChange} />
      {activeTab === HistoryTab.donations && <DonationsTable />}
      {activeTab === HistoryTab.contributions && <ContributionsTable />}
      <DonorPaymentMethodDrawer isOpen={isPaymentMethodDrawerOpen} onClose={handleCloseDrawer} />
      <Snackbar open={hasErrored} autoHideDuration={3500} onClose={() => setHasErrored(false)}>
        <Alert elevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>
          Oops, it looks like an error occurred
        </Alert>
      </Snackbar>
    </>
  );
}
