import { useState } from 'react';
import { Divider, Snackbar, Alert } from '@mui/material';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { GetCampaignsWhereDonorIsAdminQueryResponse } from 'gql/campaigns/getCampaignsWhereDonorIsAdmin';
import { useDownloadCampaignExport } from './hooks/useDownloadCampaignExport';
import { useDonationMatchesTableState } from './hooks/useDonationMatchesTableState';
import CampaignDetailsDrawer from './components/CampaignDetailsDrawer';
import CampaignDonations from './components/CampaignDonations/CampaignDonations';
import CampaignHeader from './components/CampaignHeader';
import CampaignPaymentMethodDrawer from './components/CampaignPaymentMethodDrawer/CampaignPaymentMethodDrawer';
import CampaignStatistics from './components/CampaignStatistics/CampaignStatistics';

interface CampaignAdminPageProps {
  campaign: GetCampaignsWhereDonorIsAdminQueryResponse['getCampaignsWhereDonorIsAdmin'][number];
}

enum DrawerState {
  none = 'none',
  viewDetails = 'viewDetails',
  paymentMethod = 'paymentMethod',
}

export default function CampaignAdminPage({ campaign }: CampaignAdminPageProps) {
  const [activeDrawer, setActiveDrawer] = useState<DrawerState>(DrawerState.none);
  const [activeStatus, setActiveStatus] = useState<DonationMatchStatus>(DonationMatchStatus.pending);
  const { downloadCampaignExport, hasErrored, setHasErrored } = useDownloadCampaignExport();

  const { queryVariables, ...restDonationMatchesTableState } = useDonationMatchesTableState(campaign.id, activeStatus);

  function handleViewCampaignDetailsClick() {
    setActiveDrawer(DrawerState.viewDetails);
  }

  async function handleManageExportClick() {
    downloadCampaignExport(campaign.id, campaign.slug);
  }

  function handleManagePaymentsClick() {
    setActiveDrawer(DrawerState.paymentMethod);
  }

  function handleCloseDrawer() {
    setActiveDrawer(DrawerState.none);
  }

  return (
    <>
      <CampaignHeader
        logoImage={campaign.displayData.logoImage}
        onManageExportClick={handleManageExportClick}
        onManagePaymentsClick={handleManagePaymentsClick}
        onViewCampaignDetailsClick={handleViewCampaignDetailsClick}
        slug={campaign.slug}
        title={campaign.title}
        tier={campaign.tier}
      />
      <CampaignStatistics campaignId={campaign.id} queryVariables={queryVariables} />
      <Divider sx={{ mt: 5, border: 'none' }} />
      <CampaignDonations
        campaignId={campaign.id}
        {...restDonationMatchesTableState}
        queryVariables={queryVariables}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
      />
      <CampaignDetailsDrawer
        campaignId={campaign.id}
        isOpen={activeDrawer === DrawerState.viewDetails}
        onClose={handleCloseDrawer}
      />
      <CampaignPaymentMethodDrawer
        campaignId={campaign.id}
        isOpen={activeDrawer === DrawerState.paymentMethod}
        onClose={handleCloseDrawer}
      />
      <Snackbar open={hasErrored} autoHideDuration={3500} onClose={() => setHasErrored(false)}>
        <Alert elevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>
          Oops, it looks like an error occurred
        </Alert>
      </Snackbar>
    </>
  );
}
