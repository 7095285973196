import { Helmet } from 'react-helmet';
import { useMetaTagData } from './Head.hooks';

export default function Head(overrides: {
  title?: string;
  image?: string;
  imageAlt?: string;
  url?: string;
  description?: string;
}) {
  const { title, image, url, description, imageAlt } = useMetaTagData(overrides);

  // Source for necessary meta tags: https://css-tricks.com/essential-meta-tags-social-media/
  return (
    <Helmet>
      <title>{title}</title>
      {/* Essential META Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Non-Essential, But Recommended */}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:image:alt" content={imageAlt} />
    </Helmet>
  );
}
