export const STORAGE: Storage = sessionStorage;
export const USER_AUTH_KEY = 'userAuth';
export const USER_ROLES_KEY = 'userRoles';
export const AUTH0_TOKEN_CLAIM_KEY = 'https://app.hedado.com/roles';

export function getAccessTokenFromStorage(): string | null {
  return STORAGE.getItem(USER_AUTH_KEY);
}

export function getRolesFromStorage(): string[] | null {
  const storedValue = STORAGE.getItem(USER_ROLES_KEY);

  if (!storedValue) return null;

  return JSON.parse(storedValue) as string[];
}

export function setAccessTokenInStorage(token: string) {
  STORAGE.setItem(USER_AUTH_KEY, token);
}

export function setRolesInStorage(roles: string[]) {
  STORAGE.setItem(USER_ROLES_KEY, JSON.stringify(roles));
}

export function removeAccessTokenFromStorage() {
  STORAGE.removeItem(USER_AUTH_KEY);
}

export function removeRolesFromStorage() {
  STORAGE.removeItem(USER_ROLES_KEY);
}
