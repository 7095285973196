import { Box, Typography, IconButton, Grid, Link, Tooltip } from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';

// icons

import ChevronRight from '@mui/icons-material/ChevronRight';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';

import useOnboarding from '../../../hooks/useOnboarding';

interface GettingStartedChecklistProps {
  onDismiss?: () => void;
}

interface GettingStartedTask {
  preCondition: boolean;
  trueCondition: boolean;
  text: string;
  link: string;
  tooltip?: string;
}

const DoTaskButton = (props: { task: GettingStartedTask }) => {
  return (
    <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'flex' }}>
      {/* <Tooltip title={`Help me ${props.task.text.toLowerCase()}`}> */}
      <IconButton
        component={RouterLink}
        to={props.task.link}
        disabled={!(props.task.preCondition === true || props.task.trueCondition === true)}
      >
        <ChevronRight />
      </IconButton>
      {/* </Tooltip> */}
    </Box>
  );
};

const GettingStartedChecklist = ({ onDismiss }: GettingStartedChecklistProps) => {
  const [onboarding] = useOnboarding();

  const history = useHistory();

  const tasks: Array<GettingStartedTask> = [
    {
      preCondition: true,
      trueCondition: onboarding.portfolioTodo?.didAddToPortfolio ?? false,
      text: 'Add a nonprofit to your portfolio',
      link: '/browse-nonprofits',
    },
    {
      preCondition: (onboarding.portfolioTodo?.allocations ?? 0) > 0,
      trueCondition: onboarding.portfolioTodo?.didSetAllocations ?? false,
      text: (onboarding.portfolioTodo?.allocations ?? 0) > 1 ? 'Set your allocations' : 'Review your allocations',
      link: '/portfolio',
      tooltip:
        (onboarding.portfolioTodo?.allocations ?? 0) > 1
          ? 'Add another nonprofit to your portfolio to manage your % allocations'
          : '',
    },
    {
      preCondition: true,
      trueCondition: onboarding.accountTodo?.hasPaymentMethod ?? false,
      text: 'Add a payment method',
      link: '/manage-giving',
      tooltip: 'Add a payment method to set up recurring donations',
    },
    {
      preCondition: onboarding.accountTodo?.hasPaymentMethod ?? false,
      trueCondition: onboarding.accountTodo?.hasRecurringDonation ?? false,
      text: 'Set up recurring donations',
      link: '/manage-giving',
      tooltip: 'Set up monthly, quarterly, or annual recurring giving',
    },
    {
      preCondition: true,
      trueCondition: onboarding.receiptTrackingTodo?.hasForwardedDonation ?? false,
      text: 'Forward past donation receipts',
      link: '/history',
    },
    {
      preCondition: true,
      trueCondition: onboarding.profileTodo.didCreatePublicProfile ?? false,
      text: 'Create a public profile',
      link: '/my-account/public-profile',
    },
  ];

  return (
    <Box sx={{ color: 'primary.main', display: 'flex', flexGrow: 1 }}>
      <Grid container sx={{ flexGrow: 1, textAlign: 'left' }} spacing={2} direction="column">
        {tasks.map((task, index) => {
          return (
            <Grid item gap={1} key={index} display="flex" sx={{ alignItems: 'center' }}>
              <Box sx={{ '> svg': { fontSize: '2rem' }, display: 'flex', alignItems: 'center' }}>
                {task.trueCondition ? (
                  <CheckCircle sx={{ color: 'secondary.main' }} />
                ) : (
                  <CircleOutlined sx={{ opacity: task.preCondition ? 0.2 : 0.1 }} />
                )}
              </Box>
              {task.trueCondition || task.preCondition ? (
                <Typography
                  onClick={() => {
                    history.push(task.link);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {task.text}
                </Typography>
              ) : (task.tooltip?.length ?? 0) > 0 ? (
                <Tooltip title={task.tooltip ?? ''}>
                  <Typography sx={{ opacity: 0.4 }}>{task.text}</Typography>
                </Tooltip>
              ) : (
                <Typography sx={{ opacity: 0.4 }}>{task.text}</Typography>
              )}
              <DoTaskButton task={task} />
            </Grid>
          );
        })}
        <Box sx={{ flexGrow: 1, alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
          <Link
            sx={{
              display: 'block',
              mt: 2,
              fontSize: '0.8rem',
              opacity: 0.5,
              color: 'primary.main',
              textDecorationColor: 'primary.main',
              textAlign: 'right',
              cursor: 'pointer',
            }}
            onClick={onDismiss}
          >
            Dismiss getting started steps.
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};

export default GettingStartedChecklist;
