import type { CampaignDonationMatchesOrderByAttribute } from 'gql/donationMatches/getCampaignDonationMatches';
import type { DonationMatch } from 'components/CampaignAdminPage/hooks/useCampaignDonationMatchesQuery';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Checkbox, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import { convertCentsToDollars } from 'helpers/currencyHelper';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { tableColumns } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';

interface DonationMatchTableRowProps {
  donationMatch: DonationMatch;
  isSelected: boolean;
  onSelectedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatusChange: (id: string, status: DonationMatchStatus) => void;
}

export default function DonationMatchTableRow({
  donationMatch,
  isSelected,
  onSelectedChange,
  onStatusChange,
}: DonationMatchTableRowProps) {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuAnchor);

  function openMenu(event: React.SyntheticEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  function onMoveToExcludedClick() {
    onStatusChange(donationMatch.id, DonationMatchStatus.excluded);
  }

  function onMoveToDeferredClick() {
    onStatusChange(donationMatch.id, DonationMatchStatus.deferred);
  }

  function onMoveToPendingClick() {
    onStatusChange(donationMatch.id, DonationMatchStatus.pending);
  }

  const showActions = [
    DonationMatchStatus.pending,
    DonationMatchStatus.deferred,
    DonationMatchStatus.excluded,
  ].includes(donationMatch.status);

  return (
    <>
      <TableRow>
        {showActions && (
          <TableCell width={75}>
            <Checkbox value={donationMatch.id} onChange={onSelectedChange} checked={isSelected} sx={{ p: 0 }} />
          </TableCell>
        )}
        {tableColumns.map((tableColumn) => (
          <TableCell
            key={`${donationMatch.id}_${tableColumn.id}`}
            align={tableColumn.numeric ? 'right' : 'left'}
            width={tableColumn.width}
            sx={{
              maxWidth: `${tableColumn.width}px`,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {getCellValue(donationMatch, tableColumn.id)}
          </TableCell>
        ))}
        {showActions && (
          <TableCell width={120}>
            <Button onClick={openMenu}>Move to</Button>
          </TableCell>
        )}
      </TableRow>
      <Menu
        open={isMenuOpen}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {donationMatch.status !== DonationMatchStatus.pending && (
          <MenuItem onClick={onMoveToPendingClick}>Inbox</MenuItem>
        )}
        {donationMatch.status !== DonationMatchStatus.deferred && (
          <MenuItem onClick={onMoveToDeferredClick}>Deferred</MenuItem>
        )}
        {donationMatch.status !== DonationMatchStatus.excluded && (
          <MenuItem onClick={onMoveToExcludedClick}>Excluded</MenuItem>
        )}
      </Menu>
    </>
  );
}

function getCellValue(donationMatch: DonationMatch, key: CampaignDonationMatchesOrderByAttribute): string | number {
  switch (key) {
    case 'createdAt':
      return dayjs(donationMatch.createdAt).format('MM-DD-YYYY');
    case 'donorName':
      return [donationMatch.donor?.firstName, donationMatch.donor?.lastName].filter(Boolean).join(' ');
    case 'donorEmail':
      return donationMatch.donor?.email || '';
    case 'organizationEin':
      return donationMatch.donation?.organization?.ein || '';
    case 'organizationName':
      return donationMatch.donation?.organization?.name || '';
    case 'organizationTaxDeductibility':
      return donationMatch.donation?.organization?.deductibility === 1 ? 'True' : 'False';
    case 'donationAmountInCents':
      return convertCentsToDollars(donationMatch.donationAmountInCents);
    case 'matchedAmountInCents':
      return convertCentsToDollars(donationMatch.matchedAmountInCents);
  }
}
