import { memo, forwardRef, useState } from 'react';
import { Box, Checkbox, FormControlLabel, InputAdornment, TextField, Alert, Link } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  setStateFromInputChange,
  setStateFromInputBlur,
  setStateFromInputCheck,
  getVisibleError,
} from 'helpers/formHelpers';
import { MatchingFormState, MatchingFormStateKey, MatchingFormStateErrors } from '../hooks/useMatchingFormState';
import MaskedCurrencyInput from 'components/MaskedCurrencyInput';
import FormSection from './FormSection';
import FormGridItem from './FormGridItem';
import { CampaignTier } from 'model/CampaignTier.model';
import { useCampaignPermissions } from '../../CampaignAdminPage/hooks/useCampaignPermissions';
import { env } from '../../../env';

interface MatchingSectionProps {
  errors: MatchingFormStateErrors;
  formState: MatchingFormState;
  tier: CampaignTier;
  setFormState: React.Dispatch<React.SetStateAction<MatchingFormState>>;
}

const MatchingSection = forwardRef<HTMLDivElement, MatchingSectionProps>(
  ({ errors, formState, setFormState, tier }, ref) => {
    const { exceedsMatchLimit } = useCampaignPermissions();
    const [showMatchLimitError, setShowMatchLimitError] = useState(false);

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      setStateFromInputChange(event, setFormState);
    }

    function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
      setStateFromInputBlur(event, setFormState);
    }

    function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
      setStateFromInputCheck(event, setFormState);
    }

    function handleCurrencyValueChange(value: number, name: string) {
      if (!(name in MatchingFormStateKey)) return;

      if (name === MatchingFormStateKey.overallMatchLimitCents) {
        setShowMatchLimitError(false);

        if (exceedsMatchLimit(tier, value)) {
          setShowMatchLimitError(true);
          return;
        } else {
          setFormState((prev) => ({ ...prev, [name]: { ...prev[name as MatchingFormStateKey], value } }));
          return;
        }
      }

      setFormState((prev) => ({ ...prev, [name]: { ...prev[name as MatchingFormStateKey], value } }));
    }

    const hasMatching = formState.hasMatching.value;
    const visibleErrors = {
      individualMatchLimitCents: getVisibleError(formState.individualMatchLimitCents, errors.individualMatchLimitCents),
      matchRatio: getVisibleError(formState.matchRatio, errors.matchRatio),
      overallMatchLimitCents: getVisibleError(formState.overallMatchLimitCents, errors.overallMatchLimitCents),
    };

    return (
      <FormSection heading="Matching" ref={ref}>
        <FormGridItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.hasMatching.value}
                inputProps={{ onBlur: handleInputBlur }}
                name={MatchingFormStateKey.hasMatching}
                onChange={handleCheckboxChange}
              />
            }
            label="Does this campaign have a match?"
          />
        </FormGridItem>
        {hasMatching && (
          <>
            <FormGridItem>
              <TextField
                error={!!visibleErrors.matchRatio}
                fullWidth
                helperText={visibleErrors.matchRatio}
                inputProps={{ min: 0, step: 0.1 }}
                label="Match Percentage"
                name={MatchingFormStateKey.matchRatio}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                required
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                value={formState.matchRatio.value}
              />
            </FormGridItem>
            <FormGridItem>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.hasIndividualMatchLimit.value}
                      inputProps={{ onBlur: handleInputBlur }}
                      name={MatchingFormStateKey.hasIndividualMatchLimit}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Individual Limit?"
                  sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 }, flexShrink: 0 }}
                />
                <MaskedCurrencyInput
                  disabled={!formState.hasIndividualMatchLimit.value}
                  error={!!visibleErrors.individualMatchLimitCents}
                  fullWidth
                  helperText={visibleErrors.individualMatchLimitCents}
                  inputProps={{ min: 1 }}
                  label="Individual Match Limit"
                  name={MatchingFormStateKey.individualMatchLimitCents}
                  onBlur={handleInputBlur}
                  onValueChange={handleCurrencyValueChange}
                  type="tel"
                  value={formState.individualMatchLimitCents.value}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </FormGridItem>
            <FormGridItem>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.hasOverallMatchLimit.value}
                      inputProps={{ onBlur: handleInputBlur }}
                      name={MatchingFormStateKey.hasOverallMatchLimit}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Overall Limit?"
                  sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 }, flexShrink: 0 }}
                />
                <MaskedCurrencyInput
                  disabled={!formState.hasOverallMatchLimit.value}
                  error={!!visibleErrors.overallMatchLimitCents}
                  fullWidth
                  helperText={visibleErrors.overallMatchLimitCents}
                  inputProps={{ min: 1 }}
                  label="Overall Match Limit"
                  name={MatchingFormStateKey.overallMatchLimitCents}
                  onBlur={handleInputBlur}
                  onValueChange={handleCurrencyValueChange}
                  type="tel"
                  value={formState.overallMatchLimitCents.value}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </FormGridItem>
            {showMatchLimitError && (
              <FormGridItem>
                <Alert severity="error">
                  With your current plan, your match limit can't exceed $1,000. See{' '}
                  <Link href={`${env.REACT_APP_NEXTJS_URL}/pricing/match`} target="_blank">
                    pricing page
                  </Link>{' '}
                  for details.
                </Alert>
              </FormGridItem>
            )}
            <FormGridItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.includeDonationsExceedingMatchLimits.value}
                    inputProps={{ onBlur: handleInputBlur }}
                    name={MatchingFormStateKey.includeDonationsExceedingMatchLimits}
                    onChange={handleCheckboxChange}
                    disabled={!formState.hasOverallMatchLimit.value}
                  />
                }
                label="Continue tracking donations when overall limit is reached?"
              />
            </FormGridItem>
          </>
        )}
      </FormSection>
    );
  }
);

export default memo(MatchingSection);
