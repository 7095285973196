import React from 'react';
import { Box, Typography, Container } from '@mui/material';

interface EmptyStateProps {
  title?: string;
  subtitle?: string;
  style?: 'fullPage' | 'simple';
  body: string;
  color?: string;
  backgroundColor?: string;
  image?: React.ReactNode;
  ctaButton?: React.ReactNode;
}

const images = {
  getStarted: require('../../../assets/img/hedado-get-started.svg').default,
  getStartedXs: require('../../../assets/img/hedado-get-started-xs.svg').default,
};

const EmptyState = (props: EmptyStateProps) => {
  return (
    <Container
      maxWidth="md"
      sx={{
        marginY: { xs: 4, lg: 6 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 0,
          width: 'min(400px, 70vw)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {props.style !== 'simple' && (
          <img
            alt=""
            src={images.getStarted}
            style={{ width: 'clamp(400px, 80vw, 100%)', height: 'auto', maxWidth: '100%' }}
          />
        )}
      </Box>
      <Box
        sx={{
          mt: -8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center',
          bgcolor: props.style !== 'simple' ? props.backgroundColor ?? '#ffffff' : 'transparent',
          borderRadius: 16,
          textAlign: 'center',
          color: props.color ?? 'primary.main',
          p: props.style !== 'simple' ? { xs: 4, sm: 6 } : 0,
          zIndex: 10,
        }}
        gap={2}
      >
        {props.style !== 'simple' && (
          <Box
            sx={{
              border: '4px solid #ffffff',
              borderRadius: '100%',
              overflow: 'hidden',
              height: { xs: 100, md: 120, xl: 160 },
              width: { xs: 100, md: 120, xl: 160 },
              bgcolor: 'rgba(255, 255, 255, .3)',
            }}
          >
            {props.image}
          </Box>
        )}
        <Typography
          variant="h3"
          component="h1"
          sx={{ fontSize: { xs: '2.0rem !important', sm: '2.4rem !important' }, mt: { xs: 2, sm: 4 } }}
        >
          {props.title}
        </Typography>
        <Typography variant="h5" component="h2">
          {props.subtitle}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: props.body }}
          sx={{
            opacity: props.style !== 'simple' ? 1 : 0.6,
            fontSize: props.style !== 'simple' ? '1rem' : '0.9rem',
            margin: 'revert',
            ' a': {
              color: 'inherit',
            },
          }}
        ></Typography>
        {props.ctaButton}
      </Box>
    </Container>
  );
};

export default EmptyState;
