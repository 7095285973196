import { useState } from 'react';
import { env } from 'env';
import { Box, Button, IconButton, Typography, Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LaunchIcon from '@mui/icons-material/Launch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useCampaignPermissions } from '../hooks/useCampaignPermissions';
import { CampaignTier } from 'model/CampaignTier.model';

interface CampaignHeaderProps {
  logoImage?: string;
  onManageExportClick: () => void;
  onManagePaymentsClick: () => void;
  onViewCampaignDetailsClick: () => void;
  slug?: string;
  title: string;
  tier: CampaignTier;
}

export default function CampaignHeader({
  onViewCampaignDetailsClick,
  onManageExportClick,
  onManagePaymentsClick,
  slug,
  title,
  logoImage,
  tier,
}: CampaignHeaderProps) {
  const { dataExportEnabled } = useCampaignPermissions();
  const [openPricingDialog, setOpenPricingDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenPricingDialog(true);
  };

  const handleClose = () => {
    setOpenPricingDialog(false);
  };

  const handleExportClick = () => {
    if (dataExportEnabled(tier)) {
      onManageExportClick();
    } else {
      handleClickOpen();
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: { md: 'center', xs: 'flex-start' },
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          flexWrap: 'wrap',
          justifyContent: { md: 'space-between', xs: 'flex-start' },
          mt: 4,
        }}
      >
        <Box>
          <Typography variant="h4">Manage Campaign</Typography>
        </Box>
        <Box
          sx={{
            mt: { xs: 2, md: 0 },
            display: 'flex',
            gap: 2,
          }}
        >
          <Button variant="outlined" size="large" onClick={onViewCampaignDetailsClick} startIcon={<InfoOutlinedIcon />}>
            View Details
          </Button>
          <Button variant="outlined" size="large" onClick={onManagePaymentsClick} startIcon={<CreditCardIcon />}>
            Manage Payment
          </Button>

          <Button
            sx={{ display: { xs: 'none', sm: 'flex' } }}
            variant="outlined"
            size="large"
            onClick={handleExportClick}
            startIcon={<FileDownloadIcon />}
          >
            Export Data
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'secondary.light',
          borderRadius: 4,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mt: 4,
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}>
          {!!logoImage && (
            <Box
              sx={{
                background: 'white',
                border: '4px solid white',
                borderRadius: '12px',
                maxHeight: 70,
                maxWidth: 70,
                overflow: 'hidden',
                display: 'flex',
                zIndex: 1,
                mr: 2,
              }}
            >
              <Box
                component="img"
                sx={{
                  maxWidth: '100%',
                  borderRadius: {
                    xs: '10px',
                  },
                }}
                src={`${env.REACT_APP_S3_PATH}/campaigns/${logoImage}`}
                alt={`${title} logo`}
              />
            </Box>
          )}
          <Typography variant="h3">{title}</Typography>
          {slug && (
            <IconButton
              href={`${env.REACT_APP_NEXTJS_URL}/c/${slug}`}
              LinkComponent={'a'}
              size="large"
              sx={{ ml: 2 }}
              target="_blank"
            >
              <LaunchIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Dialog open={openPricingDialog} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            To export your campaign data, please{' '}
            <Link
              href={`${env.REACT_APP_NEXTJS_URL}/pricing/match`}
              sx={{ color: '#8784E6', fontWeight: 'bold' }}
              target="_blank"
            >
              upgrade your account
            </Link>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
