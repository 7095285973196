import { gql } from '@apollo/client';

export const updateAllocationAnonymousMutation_Name = 'UpdateAllocationDesignation';
export const updateAllocationAnonymousMutation_Gql = gql`
  mutation UpdateAllocationAnonymous($allocation: UpdateAllocationAnonymousInput!) {
    updateAllocationAnonymous(allocation: $allocation) {
      id
    }
  }
`;
