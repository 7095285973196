import { useState } from 'react';
import { Box, Typography, Grid, InputAdornment, InputLabel } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MaskedCurrencyInput from 'components/MaskedCurrencyInput';
import PercentageSlider from 'components/PercentageSlider';
import TipDisclaimer from './TipDisclaimer';
import { setStateFromInputBlur } from 'helpers/formHelpers';

const SLIDER_STEP = 2.5;
const SLIDER_MAX = 30;

export default function AmountAndTip({ formState, setFormState, errors }: AmountAndTipProps) {
  const [isPercentageTip, setIsPercentageTip] = useState(() => getInitialIsPercentageTipStateFromFormState(formState));
  const [tipsPercentage, setTipsPercentage] = useState(() => getInitialTipsPercentageFromFormState(formState));

  function handleAmountCentsChange(amount: number) {
    setFormState((curr) => ({
      ...curr,
      amountCents: {
        ...curr.amountCents,
        value: amount,
      },
    }));

    if (isPercentageTip) {
      handleTipsCentsChange(Math.round(amount * (tipsPercentage / 100)));
    }
  }

  function handleTipTypeToggleClick() {
    setIsPercentageTip((prev) => !prev);
  }

  function handleTipsCentsChange(amount: number) {
    setFormState((curr) => ({
      ...curr,
      tipsCents: {
        ...curr.tipsCents,
        value: amount,
      },
    }));
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    setStateFromInputBlur(event, setFormState);
  }

  function handleTipsPercentageChange(amount: number) {
    setTipsPercentage(amount);
    if (formState.amountCents) {
      handleTipsCentsChange(Math.round((formState.amountCents.value || 0) * (amount / 100)));
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <MaskedCurrencyInput
          error={formState.amountCents.hasBlurredAtLeastOnce && !!errors.amountCents}
          fullWidth={true}
          helperText={formState.amountCents.hasBlurredAtLeastOnce && errors.amountCents}
          label="Donation Amount"
          name="amountCents"
          onBlur={handleInputBlur}
          onValueChange={handleAmountCentsChange}
          type="tel"
          required
          value={formState.amountCents.value || 0}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 3 }}
        />

        <Box>
          <TipDisclaimer sx={{ mb: 3 }} />
          {isPercentageTip ? (
            <Box sx={{ mb: 1 }}>
              <InputLabel shrink={true} sx={{ pl: 2.2 }}>
                Tip Amount
              </InputLabel>
              <PercentageSlider
                min={0}
                max={SLIDER_MAX}
                step={SLIDER_STEP}
                value={tipsPercentage}
                onChange={handleTipsPercentageChange}
                fromTotal={formState.amountCents.value || 0}
              />
            </Box>
          ) : (
            <MaskedCurrencyInput
              fullWidth={true}
              label="Tip Amount"
              name="tipsCents"
              onBlur={handleInputBlur}
              onValueChange={handleTipsCentsChange}
              sx={{ mb: 3, mt: 1 }}
              type="tel"
              value={formState.tipsCents.value || 0}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Typography
            sx={{
              cursor: 'pointer',
              fontSize: '0.8rem',
              ml: 0,
              textDecoration: 'underline',
              userSelect: 'none',
            }}
            onClick={handleTipTypeToggleClick}
          >
            {isPercentageTip ? 'Custom tip' : 'Percent tip'}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">
            After completing a transaction you will receive a email confirmation. We partner with Every.org, a nonprofit
            who disburses funds to the organizations in your portfolio.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

enum AmountAndTipFormStateKey {
  amountCents = 'amountCents',
  tipsCents = 'tipsCents',
}

type AmountAndTipErrors = {
  [key in AmountAndTipFormStateKey]?: string | null;
};

interface AmountAndTipProps {
  formState: AmountAndTipFormState;
  setFormState: React.Dispatch<React.SetStateAction<AmountAndTipFormState>>;
  errors: AmountAndTipErrors;
}

export type AmountAndTipFormState = {
  [key in AmountAndTipFormStateKey]: {
    value?: number;
    hasBlurredAtLeastOnce: boolean;
  };
};

export const defaultAmountAndTipFromState: AmountAndTipFormState = {
  amountCents: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
  tipsCents: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
};

function getInitialIsPercentageTipStateFromFormState(formState: AmountAndTipFormState): boolean {
  const percentage = getInitialTipsPercentageFromFormState(formState);

  return percentage <= SLIDER_MAX && percentage % SLIDER_STEP === 0;
}

function getInitialTipsPercentageFromFormState(formState: AmountAndTipFormState): number {
  const {
    tipsCents: { value: tipsCentsValue },
    amountCents: { value: amountCentsValue },
  } = formState;

  if (tipsCentsValue === undefined) return 15;
  if (!amountCentsValue) return 15;

  return Math.round((tipsCentsValue / amountCentsValue) * 1000) / 10;
}
