import type Organization from 'model/Organization.model';
import { useState } from 'react';
import { Button, Grid, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from '@mui/material';
import { DonationsQueryMatchStatus } from 'gql/donations/getDonorDonations';
import DateRangePicker from 'components/DateRangePicker';
import DonorOrganizationAutocomplete from './components/DonationOrganizationAutocomplete';

interface DonationFiltersProps {
  clearFilters: () => void;
  matchStatus: DonationsQueryMatchStatus | undefined;
  endDate: Date | undefined;
  setMatchStatus: (value: DonationsQueryMatchStatus) => void;
  setEndDate: (value?: Date | undefined) => void;
  setOrganizationId: (value: string) => void;
  setStartDate: (value?: Date | undefined) => void;
  startDate: Date | undefined;
}

export default function DonationFilters({
  clearFilters,
  matchStatus,
  endDate,
  setMatchStatus,
  setEndDate,
  setOrganizationId,
  setStartDate,
  startDate,
}: DonationFiltersProps) {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);

  function handleDateRangeChange({ startDate, endDate }: { startDate?: Date; endDate?: Date }) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  function handleOrganizationAutocompleteChange(_: any, organization: Organization | null) {
    setSelectedOrganization(organization);
    setOrganizationId(organization?.id || '');
  }

  function handleClearFiltersClick() {
    clearFilters();
    setSelectedOrganization(null);
  }

  function handleSelectChange(event: SelectChangeEvent) {
    setMatchStatus(event.target.value as DonationsQueryMatchStatus);
  }

  return (
    <Grid container sx={{ mt: 3, display: 'flex', alignItems: 'flex-end' }} spacing={2}>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <DonorOrganizationAutocomplete onChange={handleOrganizationAutocompleteChange} value={selectedOrganization} />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <DateRangePicker startDate={startDate} endDate={endDate} onDateRangeChange={handleDateRangeChange} />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="donation-match-status-select-label">Donation Match Status</InputLabel>
          <Select
            labelId="donation-match-status-select-label"
            id="eligible-members-select"
            value={matchStatus || ''}
            label="Donation Match Status"
            onChange={handleSelectChange}
          >
            <MenuItem sx={{ fontSize: { xs: '0.75em', sm: '1rem' } }} value={DonationsQueryMatchStatus.noMatch}>
              No Matches
            </MenuItem>
            <MenuItem sx={{ fontSize: { xs: '0.75em', sm: '1em' } }} value={DonationsQueryMatchStatus.pending}>
              Pending Matches
            </MenuItem>
            <MenuItem sx={{ fontSize: { xs: '0.75em', sm: '1em' } }} value={DonationsQueryMatchStatus.settled}>
              Completed Matches
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12} sx={{ textAlign: { lg: 'right', md: 'left', sm: 'right', xs: 'left' } }}>
        <Button onClick={handleClearFiltersClick}>clear filters</Button>
      </Grid>
    </Grid>
  );
}
