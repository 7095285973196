import { useState } from 'react';
import useHedadoMutation from 'hooks/useHedadoMutation';
import { STORAGE } from 'utils/storage';
import {
  requestOrganizationChangesGql,
  RequestOrganizationChangesMutationResult,
  RequestOrganizationChangesMutationVariables,
} from 'gql/organizations/requestOrganizationChanges';

export function useRequestOrganizationChanges(organizationId: string) {
  const STORAGE_KEY = `${organizationId}_change_requested`;
  const [hasRequestedOrganizationChanges, setHasRequestedOrganizationChanges] = useState<boolean>(
    !!STORAGE.getItem(STORAGE_KEY)
  );

  const [requestOrganizationChangesMutation, { loading: isRequestingOrganizationChanges }] = useHedadoMutation<
    RequestOrganizationChangesMutationResult,
    RequestOrganizationChangesMutationVariables
  >(requestOrganizationChangesGql);

  async function requestOrganizationChanges(changeRequests?: string) {
    if (hasRequestedOrganizationChanges) return;
    await requestOrganizationChangesMutation({
      variables: {
        organizationId,
        changeRequests,
      },
    });
    setHasRequestedOrganizationChanges(true);
    STORAGE.setItem(STORAGE_KEY, 'true');
  }

  return { requestOrganizationChanges, hasRequestedOrganizationChanges, isRequestingOrganizationChanges };
}
