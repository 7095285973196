import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { Button, Grid, Typography, Card, CardContent, Theme, createStyles, GridSize } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FlexBox } from 'components/global/Flex';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    centerContent: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignContent: 'space-between',
    },
    fillContainer: {
      display: 'flex',
      flexGrow: 1,
    },
  })
);

interface SummaryCardProps {
  icon: ReactNode;
  cardTitle: string;
  amount?: string;
  date?: string;
  buttonText?: string;
  route: string;
  text?: string;
  gridSize?: boolean | GridSize | undefined;
}

const SummaryCard = (props: SummaryCardProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={props.gridSize || 6} className={classes.fillContainer}>
        <Card className={classes.fillContainer}>
          <CardContent component={FlexBox} flexDirection="column" gap={1} flexGrow={1}>
            <FlexBox flexDirection="row" gap={1}>
              {props.icon}
              <Typography variant="h6">{props.cardTitle}</Typography>
            </FlexBox>
            <FlexBox flexDirection="column" gap={1}>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {props.amount && props.amount !== '' && props.amount}
              </Typography>
              {props.date && (
                <Typography variant="subtitle1" display="inline" sx={{ fontWeight: 'bold' }}>
                  for {props.date}
                </Typography>
              )}
              {props.text && (
                <Typography variant="subtitle1" display="inline" sx={{ fontWeight: 'bold' }}>
                  {props.text}
                </Typography>
              )}
            </FlexBox>
            <FlexBox flexDirection="column" alignItems="flex-end" justifyContent="flex-end" flexGrow={1}>
              {props.buttonText && (
                <Button
                  component={Link}
                  to={props.route}
                  size="large"
                  variant="contained"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {props.buttonText}
                </Button>
              )}
            </FlexBox>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default SummaryCard;
