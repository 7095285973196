import type { CampaignFormProps } from '../CampaignForm';
import { useState, useMemo, useCallback } from 'react';

export function useSelectedCampaignRelationsState({ admin, donors, domains, campaign }: CampaignFormProps) {
  const existingAdminEmails = useMemo(() => (admin || []).map(({ donor }) => donor.email), [admin]);
  const existingDonorEmails = useMemo(() => (donors || []).map(({ donor }) => donor.email), [donors]);
  const existingDomains = useMemo(() => (domains || []).map((domain) => domain.domainName), [domains]);
  const existingOrganizationIds = useMemo(
    () => (campaign?.categoryOrganizations?.edges || []).map((edge) => edge.node.organization.id),
    [campaign]
  );

  const [selectedAdminEmails, setSelectedAdminEmails] = useState(existingAdminEmails);
  const [selectedDonorEmails, setSelectedDonorEmails] = useState(existingDonorEmails);
  const [selectedDomains, setSelectedDomains] = useState(existingDomains);
  const [selectedOrganizations, setSelectedOrganizations] = useState(
    (campaign?.categoryOrganizations?.edges || []).map((edge) => edge.node.organization)
  );

  const selectedOrganizationIds = useMemo(
    () => selectedOrganizations.map((organization) => organization.id),
    [selectedOrganizations]
  );

  const getMutationVariablesFromRelationshipStates = useCallback(() => {
    return {
      adminEmailsToAdd: getArrayDifference(selectedAdminEmails, existingAdminEmails),
      adminEmailsToRemove: getArrayDifference(existingAdminEmails, selectedAdminEmails),
      domainsToAdd: getArrayDifference(selectedDomains, existingDomains),
      domainsToRemove: getArrayDifference(existingDomains, selectedDomains),
      donorEmailsToAdd: getArrayDifference(selectedDonorEmails, existingDonorEmails),
      donorEmailsToRemove: getArrayDifference(existingDonorEmails, selectedDonorEmails),
      organizationIdsToAdd: getArrayDifference(selectedOrganizationIds, existingOrganizationIds),
      organizationIdsToRemove: getArrayDifference(existingOrganizationIds, selectedOrganizationIds),
    };
  }, [
    existingAdminEmails,
    existingDomains,
    existingDonorEmails,
    existingOrganizationIds,
    selectedAdminEmails,
    selectedDomains,
    selectedDonorEmails,
    selectedOrganizationIds,
  ]);

  return useMemo(
    () => ({
      getMutationVariablesFromRelationshipStates,
      relationshipStates: {
        admin: { selectedAdminEmails, setSelectedAdminEmails },
        domains: { selectedDomains, setSelectedDomains },
        donors: { selectedDonorEmails, setSelectedDonorEmails },
        organizations: { selectedOrganizations, setSelectedOrganizations },
      },
    }),
    [
      getMutationVariablesFromRelationshipStates,
      selectedAdminEmails,
      selectedDomains,
      selectedDonorEmails,
      selectedOrganizations,
    ]
  );
}

function getArrayDifference(source: string[], comparison: string[]) {
  return source.filter((value) => !comparison.includes(value));
}
