import type Campaign from 'model/Campaign.model';
import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useCampaignFormImages } from './hooks/useCampaignFormImages';
import { useIsCampaignSlugTaken } from './hooks/useIsCampaignSlugTaken';
import { useCampaignFormErrors } from './hooks/useCampaignFormErrors';
import { useSaveCampaign } from './hooks/useSaveCampaign';
import { useSelectedCampaignRelationsState } from './hooks/useSelectedCampaignRelationsState';
import { useCampaignFormSectionStates } from './hooks/useCampaignFormSectionStates';
import { useCampaignFormHasUnsavedChanges } from './hooks/useCampaignFormHasUnsavedChanges';
import ColorPageContainer from 'components/global/ColorPageContainer';
import LeavePageBlocker from 'components/LeavePageBlocker';
import DetailsSection from './components/DetailsSection';
import DonationsSection from './components/DonationsSection';
import ImagesSection from './components/ImagesSection';
import MatchingSection from './components/MatchingSection';
import NonProfitsSection from './components/NonProfitsSection';
import MembersSection from './components/MembersSection/MembersSection';
import FormActions from './components/FormActions';
import StatusSnackbar from './components/StatusSnackbar';
import FormGridItem from './components/FormGridItem';
import { CampaignTier } from 'model/CampaignTier.model';
// import PreviewDialog from './components/PreviewDialog';

export interface CampaignFormProps {
  admin?: {
    id: string;
    donor: { id: string; email: string };
  }[];
  campaign?: Campaign & {
    categoryOrganizations: {
      pageInfo: {
        hasNextPage: boolean;
        endCursor?: string;
      };
      edges: {
        cursor: string;
        node: {
          organization: {
            id: string;
            name: string;
            ein: string;
          };
        };
      }[];
    };
  };
  domains?: {
    id: string;
    domainName: string;
  }[];
  donors?: {
    id: string;
    donor: { id: string; email: string };
  }[];
  onSaved?: () => void;
  onUnsavedChangesChanged?: (hasUnsavedChanges: boolean) => void;
  qualification?: {
    id: string;
    campaignId: string;
    endDate: Date | null;
    startDate: Date | null;
    includeDonationsExceedingMatchLimits: boolean | null;
    individualMatchLimitCents: number | null;
    limitOrganizations: boolean;
    limitToDomains: boolean;
    limitToPeople: boolean;
    matchRatio: number | null;
    openToAll: boolean;
    overallMatchLimitCents: number | null;
    hasMatching: boolean | null;
    universal: boolean | null;
  };
  simplifiedView?: boolean;
}

export default function CampaignForm({
  admin = [],
  campaign,
  domains = [],
  donors = [],
  onSaved,
  onUnsavedChangesChanged,
  qualification,
  simplifiedView = false,
}: CampaignFormProps) {
  const detailsRef = useRef<HTMLDivElement>(null);
  const matchingRef = useRef<HTMLDivElement>(null);
  const nonProfitsRef = useRef<HTMLDivElement>(null);
  const donationsRef = useRef<HTMLDivElement>(null);
  const membersRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [activeCampaignTier, setActiveCampaignTier] = useState<CampaignTier>(campaign?.tier ?? CampaignTier.basic);

  const { updateCampaign, createCampaign, hasErrored, hasUpdated, clearStatus } = useSaveCampaign();
  const { isSlugTaken, checkIfSlugIsTaken } = useIsCampaignSlugTaken(campaign?.id);

  const { relationshipStates, getMutationVariablesFromRelationshipStates } = useSelectedCampaignRelationsState({
    admin,
    campaign,
    domains,
    donors,
  });
  const { sectionFormStates, blurAllSectionFormStates, getMutationVariablesFromSectionFormStates } =
    useCampaignFormSectionStates({ campaign, qualification });
  const errors = useCampaignFormErrors({ isSlugTaken, ...sectionFormStates });
  const { resetImages, uploadImages, ...formImages } = useCampaignFormImages(campaign);

  const hasMatching = sectionFormStates.matching.formState.hasMatching.value;
  const isFormDisabled = errors.doesCampaignFormHaveAnyVisibleError;
  const { hasUnsavedChanges } = useCampaignFormHasUnsavedChanges({
    admin,
    campaign,
    domains,
    donors,
    bannerMobileSrc: formImages.bannerMobileSrc,
    bannerSrc: formImages.bannerSrc,
    details: sectionFormStates.details.formState,
    donations: sectionFormStates.donations.formState,
    logoSrc: formImages.logoSrc,
    matching: sectionFormStates.matching.formState,
    members: sectionFormStates.members.formState,
    nonProfits: sectionFormStates.nonProfits.formState,
    selectedAdminEmails: relationshipStates.admin.selectedAdminEmails,
    selectedDomains: relationshipStates.domains.selectedDomains,
    selectedDonorEmails: relationshipStates.donors.selectedDonorEmails,
    selectedOrganizations: relationshipStates.organizations.selectedOrganizations,
  });

  useEffect(() => {
    if (!onUnsavedChangesChanged) return;

    onUnsavedChangesChanged(hasUnsavedChanges);
  }, [hasUnsavedChanges, onUnsavedChangesChanged]);

  async function handleSubmit() {
    if (errors.doesCampaignFormHaveAnyError) {
      blurAllSectionFormStates();
      return;
    }

    setIsSaving(true);

    const uploadedImages = await uploadImages();
    const mutationVariables = {
      relationships: getMutationVariablesFromRelationshipStates(),
      ...getMutationVariablesFromSectionFormStates(uploadedImages),
    };

    if (campaign) {
      const updatedCampaign = await updateCampaign(campaign.id, mutationVariables);
      if (!updatedCampaign) {
        setIsSaving(false);
        return;
      }

      if (updatedCampaign) {
        resetImages(updatedCampaign);
      }
    } else {
      const createdCampaign = await createCampaign(mutationVariables);
      if (createdCampaign) {
        history.push(`/admin/campaigns/${createdCampaign.id}/edit?created=true`);
      }
    }

    setIsSaving(false);

    if (onSaved) onSaved();
  }

  const handleCampaignTierChange = (tier: CampaignTier) => {
    setActiveCampaignTier(tier);
  };

  // function scrollToFirstError() {
  //   switch (errors.sectionWithFirstError) {
  //     case 'details':
  //       return scrollToSection(detailsRef);
  //     case 'matching':
  //       return scrollToSection(matchingRef);
  //     case 'nonProfits':
  //       return scrollToSection(nonProfitsRef);
  //     case 'donations':
  //       return scrollToSection(donationsRef);
  //     case 'members':
  //       return scrollToSection(membersRef);
  //   }
  // }

  // function scrollToSection(sectionRef: React.RefObject<HTMLDivElement>) {
  //   if (!sectionRef.current) return;

  //   window.scroll(0, window.scrollY + sectionRef.current.getBoundingClientRect().top - 40);
  // }

  function handlePreviewClick() {
    // TODO:
    // Previewing this component is tricky because we need to run a query for the currently selected non profits,
    // as we don't have everything necessary to render from the autocomplete input,
    // also we don't have an easy path toward showing what popular donor nonprofits would look like relative
    // to the currently selected list of donors / domains
  }

  return (
    <>
      <LeavePageBlocker message="Discard unsaved changes?" shouldBlock={hasUnsavedChanges && !isSaving} />
      <ColorPageContainer sx={{ position: 'relative' }}>
        <Grid sx={{ mb: 2 }} container spacing={4}>
          <FormGridItem>
            <strong>Number of Campaign Sessions:</strong> {campaign?.numberOfCampaignSessions}
            <br />
            <strong>Number of Campaign Sessions With Donor:</strong> {campaign?.numberOfCampaignSessionsWithDonor}
          </FormGridItem>
        </Grid>
        <Grid container spacing={4}>
          <DetailsSection
            ref={detailsRef}
            errors={errors.details}
            onSlugChange={checkIfSlugIsTaken}
            isSlugTaken={isSlugTaken}
            onPricingTierChange={handleCampaignTierChange}
            {...sectionFormStates.details}
          />
          <ImagesSection {...formImages} />
          <DonationsSection
            ref={donationsRef}
            errors={errors.donations}
            hasMatching={hasMatching}
            {...sectionFormStates.donations}
          />
          <MatchingSection
            ref={matchingRef}
            errors={errors.matching}
            tier={activeCampaignTier}
            {...sectionFormStates.matching}
          />
          <NonProfitsSection
            ref={nonProfitsRef}
            errors={errors.nonProfits}
            simplifiedView={simplifiedView}
            tier={activeCampaignTier}
            {...sectionFormStates.nonProfits}
            {...relationshipStates.organizations}
          />
          <MembersSection
            tier={activeCampaignTier}
            ref={membersRef}
            errors={errors.members}
            {...sectionFormStates.members}
            {...relationshipStates.admin}
            {...relationshipStates.domains}
            {...relationshipStates.donors}
          />
          <FormActions
            isSaving={isSaving}
            onSubmit={handleSubmit}
            onPreviewClick={handlePreviewClick}
            isDisabled={isFormDisabled}
          />
        </Grid>
      </ColorPageContainer>
      <StatusSnackbar hasErrored={hasErrored} hasUpdated={hasUpdated} clearStatus={clearStatus} slug={campaign?.slug} />
    </>
  );
}
