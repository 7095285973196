import type { DialogTitleProps, DialogContentProps, DialogActionsProps, ButtonProps } from '@mui/material';
import { DialogTitle, Container, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import LoadingButton from 'components/LoadingButton';

export default function FormStep({
  backButtonProps,
  children,
  dialogActionsProps,
  dialogContentProps,
  dialogTitleProps,
  loading,
  nextButtonProps,
  onBackClick,
  onNextClick,
  title,
  errored,
}: FormStepProps) {
  return (
    <>
      {title && (
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }} {...dialogTitleProps}>
          {title}
        </DialogTitle>
      )}
      <DialogContent {...dialogContentProps} sx={{ px: { md: 3, sm: 2, xs: 1 } }}>
        <Container maxWidth="lg">{children}</Container>
      </DialogContent>
      <DialogActions {...dialogActionsProps}>
        {errored && (
          <Typography variant="caption" sx={{ color: '#d32f2f' }}>
            Oops, it looks like an error occurred.
          </Typography>
        )}
        {onBackClick && (
          <Button {...backButtonProps} disabled={loading} onClick={onBackClick}>
            Back
          </Button>
        )}
        <LoadingButton onClick={onNextClick} variant="contained" color="primary" loading={loading} {...nextButtonProps}>
          {nextButtonProps?.children || 'Next'}
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export interface FormStepProps {
  backButtonProps?: ButtonProps;
  children: React.ReactNode;
  dialogActionsProps?: DialogActionsProps;
  dialogContentProps?: DialogContentProps;
  dialogTitleProps?: DialogTitleProps;
  errored?: boolean;
  loading?: boolean;
  nextButtonProps?: ButtonProps;
  onBackClick?: () => void;
  onNextClick: () => void;
  title?: string;
}
