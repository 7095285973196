import { FC, ComponentType, ReactNode } from 'react';
import { Route } from 'react-router-dom';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface ProtectedRouteProps {
  component: ComponentType<any>;
  path: string;
  exact?: boolean;
  adminOnly?: boolean;
  args?: ReactNode;
}

const ProtectedRoute = ({ adminOnly, component, path, exact = true, ...args }: ProtectedRouteProps) => (
  <Route
    component={withAuthenticationRequired(adminOnly ? withAdminOnly(component) : component, {
      returnTo: () => window.location.pathname,
    })}
    path={path}
    exact
    {...args}
  />
);

const withAdminOnly = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithAdminOnly(props: P): JSX.Element {
    const { isAdmin } = useStoredAuth0Values();

    if (isAdmin === false) {
      window.location.pathname = '/';
      return <></>;
    }

    if (isAdmin === undefined) {
      return <></>;
    }

    return <Component {...props} />;
  };
};

export default ProtectedRoute;
