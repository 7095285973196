export const GA_TRACKING_ID = 'G-NR9YML9XCK';

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = (
  eventName: Gtag.EventNames | string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
) => {
  gtag('event', eventName, eventParams);
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#set_user_id
export const gtagIdentify = (donorId: string) => {
  gtag('config', GA_TRACKING_ID, {
    user_id: donorId,
  });
};
