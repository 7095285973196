import type { DonationMatchesTableState } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import { Box, Divider, Paper, Skeleton, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { useCampaignDonationMatchesQuery } from 'components/CampaignAdminPage/hooks/useCampaignDonationMatchesQuery';
import { useUpdateDonationMatches } from 'components/CampaignAdminPage/hooks/useUpdateDonationMatches';
import DonationMatchesTableFooter from './components/DonationMatchesTableFooter';
import DonationMatchesTableHeader from './components/DonationMatchesTableHeader/DonationMatchesTableHeader';
import DonationMatchFilters from './components/DonationMatchFilters/DonationMatchFilters';
import DonationMatchActions from './components/DonationMatchActions';
import DonationMatchTableRow from './components/DonationMatchTableRow';
import DonationMatchTabs from './components/DonationMatchTabs';

interface CampaignDonationsProps {
  activeStatus: DonationMatchStatus;
  campaignId: string;
  filters: DonationMatchesTableState['filters'];
  headerCheckSelected: boolean;
  ordering: DonationMatchesTableState['ordering'];
  pagination: DonationMatchesTableState['pagination'];
  queryVariables: DonationMatchesTableState['queryVariables'];
  selectedRows: string[];
  setActiveStatus: React.Dispatch<React.SetStateAction<DonationMatchStatus>>;
  setHeaderCheckSelected: (value: boolean) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function CampaignDonations({
  activeStatus,
  campaignId,
  filters,
  headerCheckSelected,
  ordering,
  pagination,
  queryVariables,
  selectedRows,
  setActiveStatus,
  setHeaderCheckSelected,
  setSelectedRows,
}: CampaignDonationsProps) {
  const { updateDonationMatches } = useUpdateDonationMatches(campaignId);

  const { donationMatches, isLoading } = useCampaignDonationMatchesQuery(queryVariables);

  function handleTabChange(_: any, value: DonationMatchStatus) {
    setActiveStatus(value);
    if (activeStatus !== value) {
      pagination.setPage(0);
    }
  }

  function handleHeaderCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (headerCheckSelected) {
      setHeaderCheckSelected(false);
      setSelectedRows([]);
    } else {
      setHeaderCheckSelected(true);
      setSelectedRows((donationMatches?.donationMatches || []).map((donationMatch) => donationMatch.id));
    }
  }

  function handleRowCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const donationMatchId = event.target.value;
    setSelectedRows((prev) =>
      prev.includes(donationMatchId) ? prev.filter((id) => id !== donationMatchId) : prev.concat([donationMatchId])
    );
  }

  async function handleRowStatusChange(id: string, status: DonationMatchStatus) {
    await updateDonationMatches([id], status, queryVariables);
  }

  async function handleSelectedStatusChange(status: DonationMatchStatus) {
    await updateDonationMatches(selectedRows, status, queryVariables);
    setHeaderCheckSelected(false);
    setSelectedRows([]);
  }

  const showEmptyState = !isLoading && (donationMatches?.donationMatches || []).length === 0;

  return (
    <>
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
        Campaign Donations
      </Typography>
      <Divider />
      <DonationMatchTabs campaignId={campaignId} status={activeStatus} onTabChange={handleTabChange} />

      <DonationMatchFilters campaignId={campaignId} {...filters} />

      <Paper elevation={2} sx={{ mt: 5, position: 'relative' }}>
        <TableContainer>
          <Table stickyHeader>
            <DonationMatchesTableHeader
              {...ordering}
              isSettled={activeStatus === DonationMatchStatus.settled}
              checkboxSelected={headerCheckSelected}
              onCheckboxChange={handleHeaderCheckboxChange}
            />
            <TableBody
              sx={{
                '& tr:nth-of-type(2n)': { backgroundColor: '#f9f9f9' },
                '& tr:hover': { backgroundColor: '#f2f2f2' },
              }}
            >
              {(donationMatches?.donationMatches || []).map((donationMatch) => (
                <DonationMatchTableRow
                  donationMatch={donationMatch}
                  isSelected={selectedRows.includes(donationMatch.id)}
                  onSelectedChange={handleRowCheckboxChange}
                  onStatusChange={handleRowStatusChange}
                  key={donationMatch.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading ? (
          <Box sx={{ p: 3 }}>
            <Skeleton width={'100%'} height={220} sx={{ borderRadius: 4, transform: 'scale(1, 1)' }} />
          </Box>
        ) : showEmptyState ? (
          <Box
            sx={{
              px: 5,
              py: 3,
            }}
          >
            <Box
              sx={{
                height: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6" component="p">
                No results found.
              </Typography>
              <Typography>Try broadening your search or use different filter criteria.</Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ position: 'sticky', bottom: '0px', background: '#fff', zIndex: 2 }}>
            <Table>
              <DonationMatchesTableFooter total={donationMatches?.total || 0} {...pagination} />
            </Table>
            <DonationMatchActions
              totalSelected={selectedRows.length}
              status={activeStatus}
              onSelectedStatusChange={handleSelectedStatusChange}
            />
          </Box>
        )}
      </Paper>
    </>
  );
}
