import { useMemo } from 'react';
import { Slider } from '@mui/material';
import { toDollars, toCurrencyString } from 'helpers/donationProcessHelper';

interface PercentageSliderProps {
  value: number;
  onChange: (value: number) => void;
  step: number;
  min: number;
  max: number;
  fromTotal: number;
}

export default function PercentageSlider({ value, onChange, step, min, max, fromTotal }: PercentageSliderProps) {
  if (max <= min) throw new Error('Prop `max` must be greater than prop `min`');
  if (step >= max) throw new Error('Prop `step` must be less than prop `max`');

  const marks = useMemo(() => getSliderTipMarks({ max, min, step }), [max, min, step]);

  function handleSliderChange(_: Event, eventValue: number | number[]) {
    if (Array.isArray(eventValue)) {
      onChange(eventValue[0]);
    } else {
      onChange(eventValue);
    }
  }

  function valueLabelFormat(value: number): string {
    const tipsCentsFromTotal = Math.round(fromTotal * (value / 100));
    if (tipsCentsFromTotal === 0) return '$0.00';

    return toCurrencyString(toDollars(tipsCentsFromTotal));
  }

  function getAriaValueText(value: number): string {
    return `${value}%`;
  }

  return (
    <Slider
      aria-label="Add a tip to your donation to support Hedado"
      aria-labelledby="tip-slider"
      color="secondary"
      getAriaValueText={getAriaValueText}
      marks={marks}
      max={max}
      min={min}
      onChange={handleSliderChange}
      step={step}
      value={value}
      valueLabelDisplay="auto"
      valueLabelFormat={valueLabelFormat}
      sx={{
        '.MuiSlider-thumb': {
          border: '3px solid #395884',
          height: 24,
          width: 24,
        },
        '.MuiSlider-mark': {
          height: '7px',
          width: '3px',
        },
        '.MuiSlider-markLabel': {
          fontWeight: 600,
        },
        '& .MuiSlider-valueLabel': {
          lineHeight: 1.2,
          fontSize: 14,
          fontWeight: 500,
          background: 'unset',
          width: 'fit-content',
          height: 32,
          borderRadius: 6,
          backgroundColor: 'primary.main',
        },
      }}
    />
  );
}

function getSliderTipMarks({
  max,
  min,
  step,
}: {
  max: number;
  min: number;
  step: number;
}): { value: number; label: string }[] {
  Array(Math.round((max - min) / step));
  const totalSteps = Math.round((max - min) / step) + 1;

  return Array(totalSteps)
    .fill(null)
    .map((_, i) => {
      const value = i * step;

      return {
        value,
        label: value % 1 === 0 ? `${i * step}%` : '',
      };
    });
}
