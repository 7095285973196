import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { Integrations } from '@sentry/tracing';
import { loadEnv, env } from './env';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.css';
import App from './App';

LogRocket.init(process.env.REACT_APP_LOGROCKET_ID ?? '');
setupLogRocketReact(LogRocket);

loadEnv();

if (env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://98f5823c5e7e4b4aa97bef9e3cfc67e3@o880558.ingest.sentry.io/5985396',
    integrations: [new Integrations.BrowserTracing()],
    environment: env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
