import LoadingButton from 'components/LoadingButton';
import FormGridItem from './FormGridItem';

interface FormActionsProps {
  isDisabled: boolean;
  isSaving: boolean;
  onPreviewClick: () => void;
  onSubmit: () => void;
}

export default function FormActions({ isDisabled, isSaving, onPreviewClick, onSubmit }: FormActionsProps) {
  return (
    <FormGridItem sx={{ textAlign: 'right', position: 'sticky', bottom: '12px', maxWidth: '602px' }}>
      {/* <Button
        variant="outlined"
        sx={{
          backgroundColor: 'white',
          mr: 2,
          boxShadow:
            '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);',
        }}
        onClick={onPreviewClick}
      >
        Preview
      </Button> */}
      <LoadingButton
        sx={{
          width: { xs: '100%', sm: 'auto' },
          boxShadow:
            '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);',
        }}
        size="large"
        variant="contained"
        disabled={isDisabled}
        loading={isSaving}
        onClick={onSubmit}
      >
        Save Campaign
      </LoadingButton>
    </FormGridItem>
  );
}
