import { gql } from '@apollo/client';

export const addOrUpdatePaymentMethod_Name = 'AddOrUpdatePaymentMethod';
export const addOrUpdatePaymentMethod_Gql = gql`
  mutation AddOrUpdatePaymentMethod($paymentMethodId: String!, $campaignId: String) {
    addOrUpdatePaymentMethod(paymentMethodId: $paymentMethodId, campaignId: $campaignId) {
      id
    }
  }
`;
