import { useParams, useHistory } from 'react-router-dom';
import { uploadPresignedImage } from 'helpers/s3';
import {
  getOrganizationByIdGql,
  GetOrganizationByIdQueryVariables,
  GetOrganizationByIdQueryResponse,
} from 'gql/organizations/getOrganizationById';
import { getOrganizationsGql } from 'gql/organizations/getOrganizations';
import { updateOrganizationGql } from 'gql/organizations/updateOrganization';
import useHedadoQuery from 'hooks/useHedadoQuery';
import useHedadoMutation from 'hooks/useHedadoMutation';
import AdminPage from 'components/admin/AdminPage';
import OrganizationForm from 'components/admin/OrganizationForm/OrganizationForm';
import { OrganizationInput } from 'components/admin/OrganizationForm/OrganizationForm.interfaces';

export default function EditOrganization() {
  const history = useHistory();
  const { id: rawId } = useParams<{ id: string }>();
  const id = decodeURIComponent(rawId);

  const { loading, data } = useHedadoQuery<GetOrganizationByIdQueryResponse, GetOrganizationByIdQueryVariables>(
    getOrganizationByIdGql,
    {
      variables: {
        id,
      },
    }
  );

  const [updateOrganization, { loading: isSaving }] = useHedadoMutation(updateOrganizationGql, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getOrganizationByIdGql,
        variables: { id },
      },
      {
        query: getOrganizationsGql,
        variables: { first: 25, search: '', missingImagesOnly: false, sortedByPopularity: true },
      },
    ],
  });

  const pageTitle = loading ? 'Edit Organization' : `Edit Organization: ${data?.getOrganizationById?.name}`;
  const notFound = !loading && data?.getOrganizationById === null;

  async function handleSubmit({ imagesToUpload, input }: OrganizationInput) {
    await Promise.all(
      imagesToUpload.map(async ({ key, file, crop, aspectRatio }) => {
        const uploadResult = await uploadPresignedImage({
          file,
          fileName: `${id}_${key}`,
          folderName: 'organizations',
          crop,
          aspectRatio,
        });

        input[key] = uploadResult;
      })
    );

    await updateOrganization({
      variables: { input: input },
    });
    history.push({
      pathname: '/admin/organizations',
      state: { snackbarMessage: 'Successfully Updated Organization' },
    });
  }

  if (notFound) throw new Error(`Organization with id ${id} could not be found`);

  return (
    <AdminPage title={pageTitle}>
      {data?.getOrganizationById && (
        <OrganizationForm initialState={data.getOrganizationById} handleSubmit={handleSubmit} isSaving={isSaving} />
      )}
    </AdminPage>
  );
}
