import { useState, useEffect } from 'react';
import { Dialog, Divider } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Organization from 'model/Organization.model';
import { blurState } from 'helpers/formHelpers';
import { toDollars, toCurrencyString } from 'helpers/donationProcessHelper';
import useAnalytics, { events } from 'hooks/useAnalytics';
import { useProcessOneTimeOrganizationContribution } from './hooks/useProcessOneTimeOrganizationContribution';
import { useFormStepErrors } from './hooks/useFormStepErrors';
import FormStep from './components/FormStep';
import AmountAndTip, { defaultAmountAndTipFromState } from './components/AmountAndTip';
import PaymentInfo, { defaultPaymentInfoFormState } from './components/PaymentInfo';
import Confirmation from './components/Confirmation';
import OrganizationPreview from './components/OrganizationPreview';
import AmountAndTipSummary from './components/AmountAndTipSummary';

interface GiveNowDialogProps {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
}

enum FORM_STEPS {
  amountAndTip = 'amountAndTip',
  paymentInfo = 'paymentInfo',
  confirmation = 'confirmation',
}

export default function GiveNowDialog({ isOpen, onClose, organization }: GiveNowDialogProps) {
  const { trackEvent } = useAnalytics();
  const [formStep, setFormStep] = useState(FORM_STEPS.confirmation);
  const [updateGivingPlanErrored, setUpdateGivingPlanErrored] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripeError, setStripeError] = useState<string | null>(null);
  const [amountAndTipFormState, setAmountAndTipFormState] = useState(defaultAmountAndTipFromState);
  const [paymentInfoFormState, setPaymentInfoFormState] = useState(defaultPaymentInfoFormState);
  const stripe = useStripe();
  const elements = useElements();
  const errors = useFormStepErrors(amountAndTipFormState, paymentInfoFormState, stripeError);
  const totalCents = (amountAndTipFormState.amountCents?.value || 0) + (amountAndTipFormState.tipsCents?.value || 0);
  const totalDollars = toCurrencyString(toDollars(totalCents));

  const { processOneTimeOrganizationContribution, loading: isMutationLoading } =
    useProcessOneTimeOrganizationContribution({
      onCompleted: () => {
        setIsSubmitting(false);
        setFormStep(FORM_STEPS.confirmation);
      },
      onError: () => {
        setIsSubmitting(false);
        setUpdateGivingPlanErrored(true);
      },
    });

  useEffect(() => {
    if (isOpen) return;

    const timeout = setTimeout(() => {
      setAmountAndTipFormState(defaultAmountAndTipFromState);
      setPaymentInfoFormState(defaultPaymentInfoFormState);
      setFormStep(FORM_STEPS.amountAndTip);
      setIsSubmitting(false);
      setUpdateGivingPlanErrored(false);
    }, 150);

    return () => clearTimeout(timeout);
  }, [isOpen]);

  useEffect(() => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    cardElement.on('change', function (event) {
      if (event.error) {
        setStripeError(event.error.message);
      } else {
        setStripeError(null);
      }
    });
  }, [stripe, elements, formStep]);

  async function handleNextClick() {
    switch (formStep) {
      case FORM_STEPS.amountAndTip:
        return handleSubmitAmountAndTip();
      case FORM_STEPS.paymentInfo:
        return handleSubmitPaymentInfo();
      case FORM_STEPS.confirmation:
        onClose();
        return;
      default:
        throw new Error(
          `Nowhere to go! Attempted to navigate forward in the give now dialog form steps from ${formStep}`
        );
    }
  }

  function handleBackClick() {
    switch (formStep) {
      case FORM_STEPS.paymentInfo:
        setFormStep(FORM_STEPS.amountAndTip);
        return;
      default:
        throw new Error(
          `Nowhere to go!  Attempted to navigate back in the give now dialog form steps from ${formStep}`
        );
    }
  }

  function handleClose() {
    if (formStep === FORM_STEPS.paymentInfo && !updateGivingPlanErrored) {
      if (window.confirm('Are you sure you want to cancel your donation?')) {
        onClose();
      }
    } else {
      onClose();
    }
  }

  async function handleSubmitAmountAndTip() {
    if (errors.amountAndTip.hasAnyError) {
      blurState(setAmountAndTipFormState);
      return;
    }

    setFormStep(FORM_STEPS.paymentInfo);
  }

  async function handleSubmitPaymentInfo() {
    if (errors.paymentInfo.hasAnyError) {
      blurState(setPaymentInfoFormState);
      return;
    }

    setIsSubmitting(true);
    const paymentMethodResult = await createPaymentMethod();

    if (paymentMethodResult?.error || !paymentMethodResult?.paymentMethod) {
      setIsSubmitting(false);
      return;
    }

    await processOneTimeOrganizationContribution({
      variables: {
        amountCents: amountAndTipFormState.amountCents.value,
        tipsCents: amountAndTipFormState.tipsCents.value,
        firstName: paymentInfoFormState.firstName.value,
        lastName: paymentInfoFormState.lastName.value,
        email: paymentInfoFormState.email.value,
        paymentMethodId: paymentMethodResult.paymentMethod.id,
        organizationId: organization.id,
        anonymous: paymentInfoFormState.anonymous.value,
        designation: paymentInfoFormState.designation.value,
      },
    });

    trackEvent(events.giveNow, {
      ein: organization.ein,
      amountCents: amountAndTipFormState.amountCents.value || 0,
      tipsCents: amountAndTipFormState.tipsCents.value || 0,
    });

    setIsSubmitting(false);
  }

  async function createPaymentMethod() {
    if (!stripe || !elements) return;
    const element = elements.getElement(CardElement);
    if (!element) return;

    const { firstName, lastName } = paymentInfoFormState;

    return await stripe.createPaymentMethod({
      type: 'card',
      card: element,
      billing_details: {
        name: `${firstName.value} ${lastName.value}`,
      },
    });
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} sx={{ zIndex: '1401 !important' }}>
      {formStep === FORM_STEPS.amountAndTip ? (
        <FormStep
          nextButtonProps={{ disabled: errors.amountAndTip.hasAnyVisibleError }}
          onNextClick={handleNextClick}
          title="Let's Donate!"
        >
          <OrganizationPreview organization={organization} />
          <Divider sx={{ my: 3 }} />
          <AmountAndTip
            formState={amountAndTipFormState}
            setFormState={setAmountAndTipFormState}
            errors={errors.amountAndTip.errors}
          />
        </FormStep>
      ) : formStep === FORM_STEPS.paymentInfo ? (
        <FormStep
          loading={isSubmitting || isMutationLoading}
          onBackClick={handleBackClick}
          onNextClick={handleNextClick}
          title="Enter your payment info"
          nextButtonProps={{
            size: 'large',
            children: `Donate ${totalDollars}`,
            disabled: errors.paymentInfo.hasAnyVisibleError,
          }}
          errored={updateGivingPlanErrored}
        >
          <OrganizationPreview organization={organization} />
          <Divider sx={{ my: 3 }} />

          <AmountAndTipSummary
            amountCents={amountAndTipFormState.amountCents.value ?? 0}
            onChangeAmountClick={handleBackClick}
            tipsCents={amountAndTipFormState.tipsCents.value ?? 0}
            totalDollars={totalDollars}
          />
          <Divider sx={{ my: 3 }} />
          <PaymentInfo
            formState={paymentInfoFormState}
            setFormState={setPaymentInfoFormState}
            errors={errors.paymentInfo.errors}
          />
        </FormStep>
      ) : formStep === FORM_STEPS.confirmation ? (
        <FormStep
          dialogActionsProps={{ sx: { display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 3 } }}
          nextButtonProps={{ variant: 'outlined', size: 'small', children: 'Continue' }}
          onNextClick={handleNextClick}
        >
          <Confirmation organization={organization} />
        </FormStep>
      ) : null}
    </Dialog>
  );
}
