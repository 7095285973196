import { Typography, Box, Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getLoggedInDonorProfileQuery_Gql } from 'gql/donorProfileQuery';
import {
  getPublicProfileGql,
  GetPublicProfileQueryResponse,
  GetPublicProfileQueryVariables,
} from 'gql/donor/getPublicProfile';
import {
  updatePersonalDetailsGql,
  UpdatePersonalDetailsMutationResponse,
  UpdatePersonalDetailsMutationVariables,
} from 'gql/donor/updatePersonalDetails';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useHedadoMutation from 'hooks/useHedadoMutation';
import PageHeader from 'components/global/PageHeader';
import PageContainer from 'components/global/ColorPageContainer';
import PersonalDetailsForm, { PersonalDetailsInput } from 'components/donor/PersonalDetailsForm/PersonalDetailsForm';

const MyAccount = () => {
  const { waiting, loading, data } = useAuthenticatedHedadoQuery<
    GetPublicProfileQueryResponse,
    GetPublicProfileQueryVariables
  >(getPublicProfileGql);

  const [updatePersonalDetails] = useHedadoMutation<
    UpdatePersonalDetailsMutationResponse,
    UpdatePersonalDetailsMutationVariables
  >(updatePersonalDetailsGql, {
    refetchQueries: [getPublicProfileGql, getLoggedInDonorProfileQuery_Gql],
  });

  if (loading || waiting) return <></>;
  if (!data || !data.getDonor) throw new Error('error fetching profile');
  const donor = data.getDonor;

  async function handlePersonalDetailsFormSubmit(input: PersonalDetailsInput) {
    await updatePersonalDetails({
      variables: { input, donorId: donor.id },
    });
  }

  return (
    <>
      <PageHeader icon={<AccountCircleIcon />} title="My Account" subTitle="Settings > My Account" />

      <PageContainer sx={{ mb: 3 }}>
        <Typography variant="h6" id="my-account">
          Personal Details
        </Typography>
      </PageContainer>

      <Grid container rowSpacing={3}>
        <Grid item xl={4} lg={6} md={12} sm={12}>
          <Box sx={{ maxWidth: 500 }}>
            <PersonalDetailsForm initialState={donor} handleSubmit={handlePersonalDetailsFormSubmit} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MyAccount;
