import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

interface LeavePageBlockerProps {
  shouldBlock: boolean;
  message: string;
}

export default function LeavePageBlocker({ shouldBlock, message }: LeavePageBlockerProps) {
  useEffect(() => {
    if (!shouldBlock) return () => {};

    function beforeUnloadCallback(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = message;
      return message;
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, [shouldBlock, message]);

  return <Prompt when={shouldBlock} message={message} />;
}
