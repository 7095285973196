import Stripe from '@stripe/stripe-js';
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Button, Typography } from '@mui/material';
import useDonorProfile from 'hooks/useDonorProfile';
import LoadingButton from 'components/LoadingButton';

interface ConnectBankAccountProps {
  clientSecret: string;
  onCancel: () => void;
  onPaymentMethodConnected: (paymentMethod: Stripe.PaymentMethod | { id: string } | null) => void;
}

const defaultErrorMessage = 'Oops!  It looks like there was an error adding your bank account';

export default function ConnectBankAccount({
  clientSecret,
  onCancel,
  onPaymentMethodConnected,
}: ConnectBankAccountProps) {
  const stripe = useStripe();
  const [donorProfile] = useDonorProfile();
  const [stripeError, setStripeError] = useState<string | null>(null);
  const [isCreateUnconfirmedPaymentMethodLoading, setIsCreateUnconfirmedPaymentMethodLoading] = useState(false);

  async function createStripePaymentMethod() {
    if (!stripe) {
      setStripeError(defaultErrorMessage);
      return null;
    }

    setIsCreateUnconfirmedPaymentMethodLoading(true);

    const name = [donorProfile?.firstName, donorProfile?.lastName].filter(Boolean).join(' ');
    const email = donorProfile?.email || '';

    try {
      const unconfirmedPaymentMethod = await getPaymentMethodFromBank(stripe, { clientSecret, name, email });

      if (unconfirmedPaymentMethod === null) return null;
      if ('error' in unconfirmedPaymentMethod) {
        Sentry.captureException(unconfirmedPaymentMethod.error);
        setStripeError(defaultErrorMessage);
        return null;
      }

      return unconfirmedPaymentMethod;
    } catch (error: any) {
      setStripeError(defaultErrorMessage);
      return null;
    } finally {
      setIsCreateUnconfirmedPaymentMethodLoading(false);
    }
  }

  async function handleConnectAccountClick() {
    setStripeError('');

    const paymentMethod = await createStripePaymentMethod();
    if (!paymentMethod) return;

    onPaymentMethodConnected(paymentMethod);
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingButton
          onClick={handleConnectAccountClick}
          variant="contained"
          color="secondary"
          loading={isCreateUnconfirmedPaymentMethodLoading}
          size="small"
        >
          Connect Bank Account
        </LoadingButton>
        <Button onClick={onCancel} variant="outlined" sx={{ ml: 2 }} size="small">
          Cancel
        </Button>
      </Box>
      {stripeError && (
        <Typography variant="caption" sx={{ color: '#d32f2f', mt: 2 }} component="p">
          Oops, it looks like there was an error occurred.
        </Typography>
      )}
    </>
  );
}

async function getPaymentMethodFromBank(
  stripe: Stripe.Stripe,
  { clientSecret, name, email }: { clientSecret?: string | null; name: string; email: string }
): Promise<Stripe.PaymentMethod | { id: string } | { error: Stripe.StripeError } | null> {
  if (!clientSecret) return null;

  const { error, setupIntent } = await stripe.collectBankAccountForSetup({
    clientSecret,
    params: {
      payment_method_type: 'us_bank_account',
      payment_method_data: {
        billing_details: { name, email },
      },
    },
    expand: ['payment_method'],
  });

  if (error) return { error };
  if (!setupIntent?.payment_method) return null;

  if (typeof setupIntent.payment_method === 'string') return { id: setupIntent.payment_method };

  return setupIntent.payment_method;
}
