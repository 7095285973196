import { gql } from '@apollo/client';

export interface AddCampaignSessionMutationVariables {
  campaignSessionId: string;
  campaignId: string;
}

export interface AddCampaignSessionMutationResponse {
  addCampaignSession: { id: string };
}

export const addCampaignSessionGql = gql`
  mutation AddCampaignSession($campaignSessionId: ID!, $campaignId: ID!) {
    addCampaignSession(campaignSessionId: $campaignSessionId, campaignId: $campaignId) {
      id
    }
  }
`;
