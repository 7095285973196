import type Campaign from 'model/Campaign.model';
import { env } from 'env';
import { useState, useCallback, useMemo } from 'react';
import { uploadPresignedImage } from 'helpers/s3';

export interface CampaignFormImages {
  logoImage: string | null;
  bannerImage: string | null;
  bannerImageMobile: string | null;
}

export interface UploadedImagesResult {
  bannerImageMobilePath: string;
  bannerImagePath: string;
  logoImagePath: string;
}

export function useCampaignFormImages(campaign?: Campaign) {
  const [images, setImages] = useState({
    logoImage: campaign?.displayData?.logoImage,
    bannerImage: campaign?.displayData?.bannerImage,
    bannerImageMobile: campaign?.displayData?.bannerImageMobile,
  });

  const {
    src: logoSrc,
    setImage: setLogoImage,
    upload: uploadProfileImage,
    resetImage: resetLogoImage,
  } = useFormImage(images.logoImage, 'campaigns');
  const {
    src: bannerSrc,
    setImage: setBannerImage,
    upload: uploadBannerImage,
    resetImage: resetBannerImage,
  } = useFormImage(images.bannerImage, 'campaigns');
  const {
    src: bannerMobileSrc,
    setImage: setBannerMobileImage,
    upload: uploadBannerMobileImage,
    resetImage: resetBannerMobileImage,
  } = useFormImage(images.bannerImageMobile, 'campaigns');

  const uploadImages = useCallback(async (): Promise<UploadedImagesResult> => {
    const [logoImagePath, bannerImagePath, bannerImageMobilePath] = await Promise.all([
      uploadProfileImage(),
      uploadBannerImage(),
      uploadBannerMobileImage(),
    ]);

    return { logoImagePath, bannerImagePath, bannerImageMobilePath };
  }, [uploadBannerImage, uploadBannerMobileImage, uploadProfileImage]);

  const resetImages = useCallback(
    ({ displayData: { logoImage, bannerImage, bannerImageMobile } }: Campaign) => {
      setImages({
        logoImage,
        bannerImage,
        bannerImageMobile,
      });

      resetLogoImage(logoImage);
      resetBannerImage(bannerImage);
      resetBannerMobileImage(bannerImageMobile);
    },
    [resetBannerImage, resetBannerMobileImage, resetLogoImage]
  );

  return {
    bannerMobileSrc,
    bannerSrc,
    logoSrc,
    resetImages,
    setBannerImage,
    setBannerMobileImage,
    setLogoImage,
    uploadImages,
  };
}

export function useFormImage(value: string | null, folderName: string) {
  const [image, setImage] = useState<Blob | null>(null);
  const [initialValue, setInitialValue] = useState(value);

  const src = useMemo(() => {
    if (image) return URL.createObjectURL(image);

    if (initialValue) return `${env.REACT_APP_S3_PATH}/${folderName}/${initialValue}`;

    return '';
  }, [image, initialValue, folderName]);

  const resetImage = useCallback((value: string | null) => {
    setImage(null);
    setInitialValue(value);
  }, []);

  const handleSetImage = useCallback((img: Blob | null) => {
    setInitialValue(null);
    setImage(img);
  }, []);

  const upload = useCallback(async () => {
    let imagePath = initialValue || '';

    if (image) {
      imagePath = await uploadPresignedImage({
        file: image as File,
        fileName: '',
        folderName,
        crop: false,
        extension: 'png',
      });
    }

    return imagePath;
  }, [folderName, image, initialValue]);

  return { image, setImage: handleSetImage, src, upload, resetImage };
}
