import type PaymentMethodType from 'model/PaymentMethod.model';
import { Box, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { sentenceCase } from 'helpers/formatHelpers';

interface BankAccountProps {
  paymentMethod: PaymentMethodType;
}

export default function BankAccount({ paymentMethod }: BankAccountProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AccountBalanceIcon sx={{ mr: 1 }} />
      <Typography>
        <b>{sentenceCase(paymentMethod.institution ?? '')}</b> account ending in <b>{paymentMethod.last4}</b>
      </Typography>
    </Box>
  );
}
