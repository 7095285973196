import { MouseEvent, ChangeEvent, useState, useRef } from 'react';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { env } from 'env';
import cropImage from 'utils/crop';

interface ImageInputProps {
  defaultValue: string;
  name: string;
  s3Directory: string;
  crop?: boolean;
  aspectRatio?: number;
  previewWidth?: number;
  previewHeight?: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  fileInput: {
    display: 'none',
  },
  image: ({
    aspectRatio,
    previewWidth,
    previewHeight,
  }: {
    aspectRatio?: number;
    previewWidth?: number;
    previewHeight?: number;
  }) => {
    let imgAspectRatio: string | string[] = 'auto';
    let imgWidth: number | string = '100%';
    let imgHeight: number | string = 'auto';

    if (aspectRatio) {
      imgWidth = (previewWidth || 120) * aspectRatio;
      imgHeight = previewHeight || 'auto';
      imgAspectRatio = String(aspectRatio);
    } else if (previewWidth || previewHeight) {
      imgWidth = previewWidth || 120;
      imgHeight = previewHeight || 'auto';
    }

    return {
      borderRadius: '5px',
      border: `1px solid ${theme.palette.primary.main}`,
      height: imgHeight,
      width: imgWidth,
      maxWidth: '100%',
      aspectRatio: imgAspectRatio,
    };
  },
}));

export default function ImageInput({
  aspectRatio,
  crop,
  defaultValue,
  name,
  s3Directory,
  onChange,
  previewWidth,
  previewHeight,
  disabled = false,
}: ImageInputProps) {
  const classes = useStyles({ aspectRatio, previewWidth, previewHeight });
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState<string>();
  const fileInputName = `${name}File`;
  const src = fileUrl
    ? fileUrl
    : currentValue
    ? s3Directory
      ? `${env.REACT_APP_S3_PATH}/${s3Directory}/${currentValue}`
      : `${env.REACT_APP_S3_PATH}/s3Directory`
    : '';

  function handleChangeImageClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    inputRef?.current && inputRef.current.click();
  }

  function handleRemoveImageClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setFileUrl(undefined);
    setCurrentValue('');
    if (inputRef.current) inputRef.current.value = '';
    const manualEvent = new Event('input', { bubbles: true });
    onChange(manualEvent as unknown as ChangeEvent<HTMLInputElement>);
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const file = (event.currentTarget.files || [])[0];

    if (!file) {
      setFileUrl(undefined);
    } else {
      if (crop && aspectRatio) {
        setFileUrlFromCroppedImage(file, aspectRatio);
      } else {
        setFileUrl(URL.createObjectURL(file));
      }
    }

    onChange(event);
  }

  async function setFileUrlFromCroppedImage(file: File, aspectRatio: number) {
    const croppedImage = await cropImage(URL.createObjectURL(file), aspectRatio);
    setFileUrl(URL.createObjectURL(croppedImage as File));
  }

  return (
    <>
      <input type="hidden" value={currentValue} name={name} />
      <input
        onChange={onInputChange}
        className={classes.fileInput}
        type="file"
        ref={inputRef}
        name={fileInputName}
        accept="image/*"
      />
      {crop && aspectRatio && (
        <>
          <input type="hidden" name={`${fileInputName}Crop`} value={1} />
          <input type="hidden" name={`${fileInputName}AspectRatio`} value={aspectRatio} />
        </>
      )}
      {src && (
        <div>
          <img className={classes.image} alt="Upload preview" src={src} />
        </div>
      )}
      <div>
        <Button variant="contained" component="span" onClick={handleChangeImageClick} disabled={disabled}>
          {src ? 'Change Image' : 'Add Image'}
        </Button>
        {src && (
          <IconButton aria-label="Remove Image" onClick={handleRemoveImageClick} sx={{ ml: 1 }}>
            <DeleteIcon color="primary" />
          </IconButton>
        )}
      </div>
    </>
  );
}
