import type { DonorPublicProfile } from 'model/Donor.model';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Box, Grid, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import Organization from 'model/Organization.model';
import { env } from 'env';
import { getState } from 'staticData/states';
import { getProfileColor } from 'staticData/profileColors';
import isDarkColor from 'helpers/isDarkColor';
import useAnalytics, { events } from 'hooks/useAnalytics';
import PageContainer from 'components/global/ColorPageContainer';
import UnauthCTABanner from 'components/global/UnauthCTABanner';
import PublicProfileOrganizationCard from 'components/organization/PublicProfileOrganizationCard';
import ShareButton from 'components/ShareButton';
import SocialMediaLink from './components/SocialMediaLink';

interface PublicProfilePageProps {
  donorId: string;
  publicProfile: Omit<DonorPublicProfile, 'profileImage' | 'bannerImage' | 'bannerImageMobile'>;
  organizations: Organization[];
  images: {
    profileSrc?: string;
    bannerSrc?: string;
    bannerMobileSrc?: string;
  };
}

function textColorForBgColor(hex: string): string {
  return isDarkColor(hex) ? '#FFF' : 'text.primary';
}

export default function PublicProfilePage({
  donorId,
  publicProfile,
  organizations,
  images: { profileSrc, bannerSrc, bannerMobileSrc },
}: PublicProfilePageProps) {
  const { trackEvent } = useAnalytics();
  const { isAuthenticated } = useAuth0();
  const profileColor = getProfileColor(publicProfile.colorTheme);
  const state = getState(publicProfile.state);
  const location = [publicProfile.city, state?.fullName].filter(Boolean).join(', ');
  const hasAnySocialMediaLinks = !!(
    publicProfile.instagramHandle ||
    publicProfile.linkedInHandle ||
    publicProfile.tikTokHandle ||
    publicProfile.twitterHandle
  );

  return (
    <Container sx={{ pt: 4 }} maxWidth={false} disableGutters>
      <PageContainer bgColor={profileColor.hex}>
        <Box
          sx={{
            '&::before': {
              content: '""',
              float: 'left',
              paddingTop: {
                md: '16.6666666%',
                xs: '33.333333%',
              },
            },
            '&::after': {
              content: '""',
              display: 'block',
              clear: 'both',
            },
            zIndex: 0,
            mx: -2,
            mt: -2,
            aspectRatio: {
              xs: '3 / 1',
              md: '6 / 1',
            },
            height: 'auto',
            borderRadius: '10px 10px 0 0',
            backgroundImage: {
              xs: `url(${bannerMobileSrc})`,
              md: `url(${bannerSrc})`,
            },
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <Grid
          container
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            position: 'relative',
            zIndex: 100,
            mt: { xs: '-120px', sm: '-180px' },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: { md: 0, xs: 0 },
              top: { md: 180, sm: 190, xs: 130 },
              pt: { md: 2, xs: 0 },
            }}
          >
            <ShareButton
              color={textColorForBgColor(profileColor.hex)}
              shareData={{ url: `${env.REACT_APP_NEXTJS_URL}/d/${publicProfile.slug}`, title: document.title }}
              onShared={() => {
                trackEvent(events.shareProfile, { donorId });
              }}
            />
          </Box>
          <Box
            sx={{
              p: 1.5,
              borderRadius: 4,
              mt: 8,
              mb: 2,
              bgcolor: 'white',
              width: '220px',
              height: '220px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {profileSrc ? (
              <img
                src={profileSrc}
                alt={publicProfile.displayName}
                style={{ aspectRatio: '1', width: '100%', borderRadius: 6 }}
              />
            ) : (
              <PersonIcon sx={{ fontSize: 300, color: 'primary.main' }} />
            )}
          </Box>

          {publicProfile.displayName && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                letterSpacing: 0.2,
                color: textColorForBgColor(profileColor.hex),
                textAlign: 'center',
              }}
            >
              {publicProfile.displayName}
            </Typography>
          )}

          {location && (
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, letterSpacing: 0.2, color: textColorForBgColor(profileColor.hex) }}
            >
              {location}
            </Typography>
          )}

          {hasAnySocialMediaLinks && (
            <Box>
              {publicProfile.linkedInHandle && <SocialMediaLink value={publicProfile.linkedInHandle} type="linkedIn" />}
              {publicProfile.twitterHandle && <SocialMediaLink value={publicProfile.twitterHandle} type="twitter" />}
              {publicProfile.tikTokHandle && <SocialMediaLink value={publicProfile.tikTokHandle} type="tikTok" />}
              {publicProfile.instagramHandle && (
                <SocialMediaLink value={publicProfile.instagramHandle} type="instagram" />
              )}
            </Box>
          )}

          {publicProfile.description && (
            <Box
              sx={{
                borderRadius: 4,
                p: 2,
                mt: 2,
                maxWidth: 640,
                bgcolor: isDarkColor(profileColor.hex) ? alpha('#FFF', 0.05) : '#FFF',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: isDarkColor(profileColor.hex) ? alpha('#FFF', 0.1) : '#fff',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  letterSpacing: 0.2,
                  color: textColorForBgColor(profileColor.hex),
                  whiteSpace: 'pre-wrap',
                }}
                gutterBottom
              >
                {publicProfile.description}
              </Typography>
            </Box>
          )}

          {organizations.length > 0 && (
            <>
              <Typography
                variant="h5"
                sx={{ pt: 6, fontWeight: 500, letterSpacing: 0.2, color: textColorForBgColor(profileColor.hex) }}
                component="p"
              >
                Where I Give
              </Typography>
              <Grid container spacing={2} sx={{ py: { lg: 8, xs: 4 }, display: 'flex', justifyContent: 'center' }}>
                {organizations.map((organization) => (
                  <Grid item key={organization.ein} lg={12} md={6} sm={12}>
                    <PublicProfileOrganizationCard
                      organization={organization}
                      hexColor={profileColor.hex}
                      donorDescription={
                        publicProfile.organizations?.find((org) => org.id === organization.id)?.description
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </PageContainer>
      <UnauthCTABanner open={!isAuthenticated} />
    </Container>
  );
}
