import { useEffect, useState } from 'react';
import {
  getOrganizationsGql,
  GetOrganizationsArgs,
  GetOrganizationsQueryResponse,
} from 'gql/organizations/getOrganizations';
import { Grid, TextField, Typography } from '@mui/material';
import Organization from 'model/Organization.model';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';

interface OrganizationsAutocompleteInputProps {
  initialState: Organization[];
  label?: string;
  onChange: (value: Organization[]) => void;
}

export default function OrganizationsAutocompleteInput({
  initialState,
  onChange,
  label = 'Highlighted Nonprofits',
}: OrganizationsAutocompleteInputProps) {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>();
  const [search, setSearch] = useState('');
  const [value, setValue] = useState<Organization[]>(initialState);

  useEffect(() => {
    return () => {
      if (timeoutRef) clearTimeout(timeoutRef);
    };
  }, [timeoutRef]);

  const { data } = useAuthenticatedHedadoQuery<GetOrganizationsQueryResponse, GetOrganizationsArgs>(
    getOrganizationsGql,
    {
      variables: { first: 25, search, missingImagesOnly: false, sortedByPopularity: true },
    }
  );

  function onAutocompleteChange(_: any, newValue: Organization[]) {
    // TODO: need to ensure unique items here!
    setValue(newValue);
    onChange(newValue);
  }

  function handleSearchChange(event: React.SyntheticEvent, newInputValue: string, reason: string) {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    setTimeoutRef(setTimeout(() => setSearch(newInputValue), 350));
  }

  const options = (data?.getOrganizations.edges || []).map((edge) => edge.node);

  return (
    <Autocomplete
      id="search-organizations"
      multiple
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(opt, v) => opt.id === v.id}
      value={value}
      onChange={onAutocompleteChange}
      onInputChange={handleSearchChange}
      renderInput={(params) => <TextField {...params} label={label} fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {option.name}
                  <br />
                  <small>{option.ein}</small>
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

function getOptionLabel(option: Organization): string {
  if (typeof option === 'string') return option;
  if (typeof option === 'object') return option.name || '';
  return '';
}
