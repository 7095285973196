import { Box, Paper } from '@mui/material';

export default function StatisticBox({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        flexBasis: { xs: '100%', sm: '50%', md: '33.333%', lg: '25%', xl: '20%' },
        flexGrow: 0,
        flexShrink: 1,
        minHeight: { xs: '', sm: '114px' },
        p: { xs: 1, sm: 2 },
        transform: 'scale(1,1)',
      }}
    >
      <Box
        sx={{ border: '1px solid #999', textAlign: 'center', p: 2, borderRadius: 2 }}
        elevation={2}
        component={Paper}
      >
        {children}
      </Box>
    </Box>
  );
}
