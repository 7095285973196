import type PaymentMethodType from 'model/PaymentMethod.model';
import { useState } from 'react';
import { Box, Divider, Typography, Button } from '@mui/material';
import BankAccount from './components/BankAccount';
import ChangePaymentMethod from './components/ChangePaymentMethod/ChangePaymentMethod';
import CreditCard from './components/CreditCard';
import RemovePaymentMethod from './components/RemovePaymentMethod';

interface PaymentMethodProps {
  campaignId?: string;
  isRemovingCardBlockedByCurrentDonationPlan?: boolean;
  hideTitle?: boolean;
  paymentMethod: PaymentMethodType | null;
}

export default function PaymentMethod({
  campaignId,
  hideTitle,
  isRemovingCardBlockedByCurrentDonationPlan,
  paymentMethod,
}: PaymentMethodProps) {
  const [isChangingPaymentMethod, setIsChangingPaymentMethod] = useState(false);

  function handleChangePaymentMethodClick() {
    setIsChangingPaymentMethod(true);
  }

  const title = hideTitle
    ? null
    : isChangingPaymentMethod
    ? !!paymentMethod
      ? 'Change Payment Method'
      : 'Add Payment Method'
    : !!paymentMethod
    ? 'Payment Method'
    : null;

  return (
    <Box>
      {!!title && (
        <>
          <Typography variant="h6">{title}</Typography>
          <Divider sx={{ mt: 1, mb: 3 }} />
        </>
      )}

      {isChangingPaymentMethod ? (
        <ChangePaymentMethod
          campaignId={campaignId}
          onCancel={() => setIsChangingPaymentMethod(false)}
          onSubmitted={() => setIsChangingPaymentMethod(false)}
        />
      ) : !!paymentMethod ? (
        <>
          {paymentMethod.type === 'PaymentMethods::Card' ? (
            <CreditCard paymentMethod={paymentMethod} />
          ) : paymentMethod.type === 'PaymentMethods::BankAccount' ? (
            <BankAccount paymentMethod={paymentMethod} />
          ) : null}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3 }}>
            <Button size="small" variant="contained" onClick={handleChangePaymentMethodClick} sx={{ mr: 2 }}>
              Change
            </Button>
            {paymentMethod && (
              <RemovePaymentMethod campaignId={campaignId} disabled={isRemovingCardBlockedByCurrentDonationPlan} />
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleChangePaymentMethodClick} size="large">
            Add Payment Method
          </Button>
        </Box>
      )}
    </Box>
  );
}
