import { Link } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

export default function InPortfolioFlag() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    >
      <Box
        sx={{
          height: '100px',
          width: '100px',
          backgroundColor: 'secondary.main',
          transform: 'rotate(45deg) translate(-0%, -70%)',
          zIndex: 1,
        }}
      />
      <Link to="/portfolio">
        <Tooltip title="In my portfolio" placement="top">
          <BusinessCenterIcon color="info" sx={{ position: 'absolute', top: '8px', right: '10px' }} />
        </Tooltip>
      </Link>
    </Box>
  );
}
