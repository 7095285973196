import { ChangeEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import { Grid, Stack, Box, Button, TextField, FormControlLabel, Checkbox } from '@mui/material';
import {
  getOrganizationsGql,
  GetOrganizationsArgs,
  GetOrganizationsQueryResponse,
} from 'gql/organizations/getOrganizations';
import useHedadoQuery from 'hooks/useHedadoQuery';
import useDebounce from 'hooks/useDebounce';
import AdminPage from 'components/admin/AdminPage';

const PER_PAGE = 25;

export default function OrganizationList() {
  const debounce = useDebounce();
  const history = useHistory();
  const pageTitle = 'Organizations';
  const [missingImagesOnly, setMissingImagesOnly] = useState(false);
  const [search, setSearch] = useState('');

  const { data, fetchMore } = useHedadoQuery<GetOrganizationsQueryResponse, GetOrganizationsArgs>(getOrganizationsGql, {
    variables: { first: PER_PAGE, search, missingImagesOnly, sortedByPopularity: true },
  });
  const pageInfo = data?.getOrganizations.pageInfo;
  const edges = data?.getOrganizations.edges || [];

  function handleFetchMore() {
    if (!pageInfo) return;

    fetchMore({
      variables: {
        first: PER_PAGE,
        after: pageInfo.endCursor,
        search,
        missingImagesOnly,
      },
    });
  }

  function handleCreateNewClick() {
    history.push('/admin/organizations/new');
  }

  function handleSearchChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = event.currentTarget.value;
    debounce(() => setSearch(value), 350);
  }

  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.currentTarget;

    setMissingImagesOnly(checked);
  }

  return (
    <>
      <AdminPage title={pageTitle}>
        <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 2, mb: 3 }}>
          <Grid item lg={8} md={6} xs={12}>
            <Stack>
              <TextField
                type="text"
                variant="standard"
                onChange={handleSearchChange}
                placeholder="Search by name or ein"
              />
            </Stack>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={missingImagesOnly} name="missingImagesOnly" onChange={handleCheckboxChange} />
              }
              label={'Filter by missing images'}
            />
          </Grid>
        </Grid>
        <Button variant="contained" onClick={handleCreateNewClick} sx={{ mb: 4 }}>
          Create Organization
        </Button>

        {edges.map(({ node: { ein, name, imageName, id } }) => (
          <Box key={`organization-${id}`} sx={{ mb: 1 }}>
            <Link
              to={{ pathname: `/admin/organizations/${encodeURIComponent(id)}/edit` }}
              style={{ display: 'inline-flex' }}
            >
              <span>
                {name || ein}
                <br />
                <small>{ein}</small>
              </span>{' '}
              {!imageName && <ImageNotSupportedIcon sx={{ ml: 1 }} color="error" />}
            </Link>
          </Box>
        ))}
        {pageInfo?.hasNextPage && (
          <Button sx={{ mt: 4 }} variant="outlined" onClick={handleFetchMore}>
            Load More
          </Button>
        )}
      </AdminPage>
    </>
  );
}
