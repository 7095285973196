import { gql } from '@apollo/client';

export const getDistinctDonorOrganizationsForCampaign_Name = 'GetDistinctDonorOrganizationsForCampaign';
export const getDistinctDonorOrganizationsForCampaign_Gql = gql`
  query GetDistinctDonorOrganizationsForCampaign($campaignId: String!) {
    getDistinctDonorOrganizationsForCampaign(campaignId: $campaignId) {
      id
      ein
      name
      causeArea
      mission
      context
      impact
      whyYouShouldCare
      websiteUrl
      annualReportUrl
      financialsUrl
      form990Url
      orgSize
      imageName
      revenue
      assets
      street
      city
      state
      zip
      classification
      deductibility
    }
  }
`;
