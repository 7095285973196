import type { DonationMatchesTableState } from './useDonationMatchesTableState';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import {
  getCampaignDonationMatchesQuery,
  GetCampaignDonationMatchesQueryResponse,
  GetCampaignDonationMatchesQueryVariables,
} from 'gql/donationMatches/getCampaignDonationMatches';

export function useCampaignDonationMatchesQuery(variables: DonationMatchesTableState['queryVariables']) {
  const { data, loading, waiting } = useAuthenticatedHedadoQuery<
    GetCampaignDonationMatchesQueryResponse,
    GetCampaignDonationMatchesQueryVariables
  >(getCampaignDonationMatchesQuery, {
    variables,
  });

  return {
    isLoading: loading || waiting,
    donationMatches: data?.getCampaignDonationMatches,
  };
}

export type DonationMatchResponse = ReturnType<typeof useCampaignDonationMatchesQuery>['donationMatches'];
export type DonationMatch = NonNullable<DonationMatchResponse>['donationMatches'][number];
