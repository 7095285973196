import { memo, forwardRef } from 'react';
import dayjs from 'dayjs';
import { Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { setStateFromInputBlur, setStateFromInputChange, setStateFromInputCheck } from 'helpers/formHelpers';
import { DonationsFormState, DonationsFormStateErrors, DonationsFormStateKey } from '../hooks/useDonationsFormState';
import MaskedCurrencyInput from 'components/MaskedCurrencyInput';
import FormGridItem from './FormGridItem';
import FormSection from './FormSection';

interface DonationsSectionFormProps {
  errors: DonationsFormStateErrors;
  formState: DonationsFormState;
  hasMatching: boolean;
  setFormState: React.Dispatch<React.SetStateAction<DonationsFormState>>;
}

const DonationsSection = forwardRef<HTMLDivElement, DonationsSectionFormProps>(
  ({ errors, formState, hasMatching, setFormState }, ref) => {
    const showCampaignGoal = formState.showDonationGoal.value;

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      setStateFromInputChange(event, setFormState);
    }

    function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
      setStateFromInputCheck(event, setFormState);
    }

    function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
      setStateFromInputBlur(event, setFormState);
    }

    function handleCurrencyValueChange(value: number, name: string) {
      if (!(name in DonationsFormStateKey)) return;

      setFormState((prev) => ({ ...prev, [name]: { ...prev[name as DonationsFormStateKey], value } }));
    }

    const visibleErrors = {
      donationGoalCents: formState.donationGoalCents.hasBlurredAtLeastOnce ? errors.donationGoalCents : '',
      endDate: formState.endDate.hasBlurredAtLeastOnce ? errors.endDate : '',
      startDate: formState.startDate.hasBlurredAtLeastOnce ? errors.startDate : '',
    };

    return (
      <FormSection heading="Donation Tracker" ref={ref}>
        <FormGridItem>
          <TextField
            error={!!visibleErrors.startDate}
            required
            fullWidth
            helperText={
              visibleErrors.startDate || 'Donations made to this campaign before this date will not be eligible.'
            }
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: dayjs().format('YYYY-MM-DD') }}
            label="Start Date"
            name={DonationsFormStateKey.startDate}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            type="date"
            value={formState.startDate.value}
          />
        </FormGridItem>
        <FormGridItem>
          <TextField
            error={!!visibleErrors.endDate}
            required
            fullWidth
            helperText={
              visibleErrors.endDate || 'Donations made to this campaign on or after this date will not be eligible.'
            }
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: dayjs().format('YYYY-MM-DD') }}
            label="End Date"
            name={DonationsFormStateKey.endDate}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            type="date"
            value={formState.endDate.value}
          />
        </FormGridItem>
        <FormGridItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.showDonationTotal.value}
                inputProps={{ onBlur: handleInputBlur }}
                name={DonationsFormStateKey.showDonationTotal}
                onChange={handleCheckboxChange}
              />
            }
            label="Display total amount donated?"
          />
        </FormGridItem>
        {hasMatching && (
          <FormGridItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.showDonationTotalMatched.value}
                  inputProps={{ onBlur: handleInputBlur }}
                  name={DonationsFormStateKey.showDonationTotalMatched}
                  onChange={handleCheckboxChange}
                />
              }
              label="Include total amount matched?"
            />
          </FormGridItem>
        )}
        <FormGridItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.showDonationGoal.value}
                inputProps={{ onBlur: handleInputBlur }}
                name={DonationsFormStateKey.showDonationGoal}
                onChange={handleCheckboxChange}
              />
            }
            label="Include campaign goal?"
          />
        </FormGridItem>
        {showCampaignGoal && (
          <FormGridItem>
            <MaskedCurrencyInput
              error={!!visibleErrors.donationGoalCents}
              fullWidth
              helperText={visibleErrors.donationGoalCents}
              inputProps={{ min: 0 }}
              label="Goal"
              name={DonationsFormStateKey.donationGoalCents}
              onBlur={handleInputBlur}
              onValueChange={handleCurrencyValueChange}
              type="tel"
              value={formState.donationGoalCents.value}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormGridItem>
        )}
      </FormSection>
    );
  }
);

export default memo(DonationsSection);
