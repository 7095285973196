import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';
import type { PixelCrop } from 'react-image-crop';
import { ChangeEvent, useState, useRef } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CropDialog from './components/CropDialog';

interface CroppedImageInputProps {
  src: string;
  aspectRatio: number;
  isDisabled?: boolean;
  previewImageSx?: SxProps<Theme> | undefined;
  maxSize: number;
  onChanged: (image: Blob | null) => void;
}

export default function CroppedImageInput({
  src,
  aspectRatio,
  isDisabled,
  previewImageSx,
  maxSize,
  onChanged,
}: CroppedImageInputProps) {
  if (maxSize > 16777216) throw new Error(`Max size for Cropped Image can't exceed 16,777,216.  Provided; ${maxSize}`);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [rawFile, setRawFile] = useState<File>();
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);

  function handleImageChangeClick() {
    fileInputRef?.current && fileInputRef.current.click();
  }

  function handleHiddenFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    const file = (event.currentTarget.files || [])[0];
    if (!file) return;

    setIsCropDialogOpen(true);
    setRawFile(file);
  }

  function closeCropDialog() {
    cleanup();
  }

  async function handleCrop(croppedImageData: PixelCrop, scale: number, image: HTMLImageElement) {
    const canvas = canvasPreview(image, croppedImageData, { scale, rotation: 0, maxSize, aspectRatio });

    canvas.toBlob(toBlobCallback, 'image/png', 1);
  }

  function toBlobCallback(blob: Blob | null) {
    if (!blob) return;

    onChanged(blob);
    cleanup();
  }

  function handleRemove() {
    onChanged(null);
    cleanup();
  }

  function cleanup() {
    setRawFile(undefined);
    setIsCropDialogOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  return (
    <>
      <Box>
        {src && <Box component="img" src={src} sx={previewImageSx} />}
        <input
          accept="image/*"
          onChange={handleHiddenFileInputChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
        />
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Button
            startIcon={<UploadFileIcon />}
            size="small"
            onClick={handleImageChangeClick}
            disabled={isDisabled}
            variant="outlined"
          >
            {src ? 'Change' : 'Add'} Image
          </Button>
          {src && (
            <IconButton aria-label="Remove Image" sx={{ ml: 1 }} disabled={isDisabled} onClick={handleRemove}>
              <DeleteIcon color="primary" sx={{ opacity: isDisabled ? '0.2' : 1 }} />
            </IconButton>
          )}
        </Box>
      </Box>
      <CropDialog
        aspectRatio={aspectRatio}
        file={rawFile}
        isOpen={isCropDialogOpen}
        onClose={closeCropDialog}
        onSubmit={handleCrop}
      />
    </>
  );
}

function canvasPreview(
  image: HTMLImageElement,
  crop: PixelCrop,
  { scale = 1, maxSize }: { scale: number; rotation: number; aspectRatio: number; maxSize: number }
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }
  canvas.style.height = `${crop.height}px`;
  canvas.style.width = `${crop.width}px`;

  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  const { scaleAdjustX, scaleAdjustY, scaleX, scaleY } = getMaximumValidScaleFactors({
    crop,
    maxSize,
    pixelRatio,
    natural: {
      height: image.naturalHeight,
      width: image.naturalWidth,
    },
    rendered: {
      height: image.height,
      width: image.width,
    },
  });

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 4) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 3) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth * scaleAdjustX,
    image.naturalHeight * scaleAdjustY,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();

  return canvas;
}

interface GetMaximumValidScaleFactorsArgs {
  pixelRatio: number;
  maxSize: number;
  crop: { height: number; width: number };
  natural: { height: number; width: number };
  rendered: { height: number; width: number };
}

function getMaximumValidScaleFactors({
  crop,
  maxSize,
  natural,
  pixelRatio,
  rendered,
}: GetMaximumValidScaleFactorsArgs): {
  scaleAdjustX: number;
  scaleAdjustY: number;
  scaleX: number;
  scaleY: number;
} {
  const initialScaleX = natural.width / rendered.width;
  const initialScaleY = natural.height / rendered.height;
  const initialWidth = Math.floor(crop.width * initialScaleX * pixelRatio);
  const initialHeight = Math.floor(crop.height * initialScaleY * pixelRatio);

  if (initialWidth * initialHeight < maxSize)
    return { scaleX: initialScaleX, scaleAdjustX: 1, scaleAdjustY: 1, scaleY: initialScaleY };

  const aspectRatio = crop.width / crop.height;
  const adjustedHeight = Math.sqrt(maxSize / aspectRatio);
  const adjustedWidth = maxSize / adjustedHeight;

  const scaleAdjustX = initialWidth / adjustedWidth;
  const scaleAdjustY = initialHeight / adjustedHeight;

  return {
    scaleAdjustX,
    scaleAdjustY,
    scaleX: initialScaleX / scaleAdjustX,
    scaleY: initialScaleY / scaleAdjustY,
  };
}
