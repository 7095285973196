import { gql } from '@apollo/client';
import Campaign from '../../model/Campaign.model';
import Organization from '../../model/Organization.model';
import { CampaignTier } from 'model/CampaignTier.model';

export interface GetCampaignQueryVariables {
  id: string;
}

export interface GetCampaignQueryResponse {
  getCampaign: {
    __typename: 'Campaign';
    id: string;
    slug: string;
    title: string;
    nameOnReceipt: string;
    tier: CampaignTier;
    displayData: { logoImage?: string };
  };
}

export const getCampaignName = 'GetCampaign';
export const getCampaignGql = gql`
  query GetCampaign($id: ID, $slug: String) {
    getCampaign(id: $id, slug: $slug) {
      id
      slug
      title
      nameOnReceipt
      tier
      displayData {
        logoImage
      }
    }
  }
`;

export interface GetCampaignWithOrganizationsQueryVariables {
  id?: string;
  slug?: string;
  firstOrganizations?: number;
  afterOrganizations?: string;
}

export type GetCampaignWithOrganizationQueryResponseCampaign = Campaign & {
  __typename: 'Campaign';
  categoryId: string;
  categoryOrganizations: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor?: string;
    };
    edges: {
      cursor: string;
      node: {
        organization: Organization;
      };
    }[];
  };
};

export interface GetCampaignWithOrganizationQueryResponse {
  getCampaign: GetCampaignWithOrganizationQueryResponseCampaign;
}

export const getCampaignWithOrganizationsGql = gql`
  query GetCampaignWithOrganizations($id: ID, $slug: String, $firstOrganizations: Int, $afterOrganizations: String) {
    getCampaign(id: $id, slug: $slug) {
      categoryId
      description
      id
      slug
      title
      tier
      nameOnReceipt
      donationGoalCents
      donationTotalCents
      donationTotalMatchedCents
      displayData {
        bannerImage
        bannerImageMobile
        logoImage
        showCampaignOrganizations
        showDonationGoal
        showDonationTotal
        showDonationTotalMatched
        showDonorOrganizations
      }
      categoryOrganizations(first: $firstOrganizations, after: $afterOrganizations) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            organization {
              annualReportUrl
              assets
              causeArea
              city
              classification
              context
              deductibility
              ein
              financialsUrl
              form990Url
              id
              imageName
              impact
              mission
              name
              orgSize
              revenue
              state
              street
              websiteUrl
              whyYouShouldCare
              zip
            }
          }
        }
      }
    }
  }
`;

export interface GetCampaignForModificationQueryVariables {
  id: string;
}

export interface GetCampaignForModificationQueryResponse {
  getCampaign: Campaign & {
    __typename: 'Campaign';
    categoryOrganizations: {
      pageInfo: {
        hasNextPage: boolean;
        endCursor?: string;
      };
      edges: {
        cursor: string;
        node: {
          organization: {
            id: string;
            ein: string;
            name: string;
          };
        };
      }[];
    };
    domains: {
      id: string;
      domainName: string;
    }[];
    donors: {
      id: string;
      donor: {
        id: string;
        email: string;
      };
    }[];
    admin: {
      id: string;
      donor: {
        id: string;
        email: string;
      };
    }[];
    qualification: {
      campaignId: string;
      endDate: Date | null;
      id: string;
      includeDonationsExceedingMatchLimits: boolean | null;
      individualMatchLimitCents: number | null;
      limitOrganizations: boolean;
      limitToDomains: boolean;
      limitToPeople: boolean;
      matchRatio: number | null;
      openToAll: boolean;
      overallMatchLimitCents: number | null;
      startDate: Date | null;
      hasMatching: boolean | null;
      universal: boolean;
    };
  };
}

export const getCampaignForModificationGql = gql`
  query GetCampaignForModificationGql($id: ID!) {
    getCampaign(id: $id) {
      categoryId
      description
      id
      slug
      tier
      title
      nameOnReceipt
      donationGoalCents
      donationTotalCents
      donationTotalMatchedCents
      numberOfCampaignSessions
      numberOfCampaignSessionsWithDonor
      displayData {
        bannerImage
        bannerImageMobile
        logoImage
        showCampaignOrganizations
        showDonationGoal
        showDonationTotal
        showDonationTotalMatched
        showDonorOrganizations
      }
      admin {
        id
        donor {
          id
          email
        }
      }
      donors {
        id
        donor {
          id
          email
        }
      }
      domains {
        id
        domainName
      }
      qualification {
        endDate
        id
        includeDonationsExceedingMatchLimits
        individualMatchLimitCents
        limitOrganizations
        limitToDomains
        limitToPeople
        matchRatio
        openToAll
        overallMatchLimitCents
        startDate
        hasMatching
        universal
      }
      categoryOrganizations(first: 100) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            organization {
              ein
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface CheckIfCampaignExistsBySlugQueryVariables {
  slug: string;
}

export interface CheckIfCampaignExistsBySlugQueryResponse {
  getCampaign: {
    id: string;
  };
}

export const checkIfCampaignExistsBySlug = gql`
  query CheckIfCampaignExistsBySlug($slug: String!) {
    getCampaign(slug: $slug) {
      id
    }
  }
`;
