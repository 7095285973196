import { createStyles, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Grid, Container, Box } from '@mui/material';
import { Link as RouteLink } from 'react-router-dom';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    footerLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    parent: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row !important',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column !important',
      },
      alignItems: 'flex-start',
      justifyContent: 'center',
      alignContent: 'space-around',
      marginTop: 50,
      flexBasis: 'fit-content',
      gap: theme.spacing(8),
    },
    child: {
      flexDirection: 'column',
      verticalAlign: 'top',
      textAlign: 'left',
    },
  })
);

interface FooterHeaderProps {
  title: string;
}

const FooterHeader = ({ title }: FooterHeaderProps) => {
  return <Typography variant="h5">{title}</Typography>;
};

interface InternalFooterLinkProps {
  route: string;
  text: string;
}

const InternalFooterLink = ({ route, text }: InternalFooterLinkProps) => {
  const classes = useStyles();

  return (
    <p>
      <RouteLink className={classes.footerLink} to={route}>
        {text}
      </RouteLink>
    </p>
  );
};

interface ExternalFooterLinkProps {
  url: string;
  text: string;
}

const ExternalFooterLink = ({ url, text }: ExternalFooterLinkProps) => {
  const classes = useStyles();

  return (
    <p>
      <a className={classes.footerLink} href={url} target="_blank" rel="noreferrer">
        {text}
      </a>
    </p>
  );
};

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="md">
        <Grid container className={classes.parent}>
          <Box className={classes.child}>
            <FooterHeader title="Offerings" />

            <InternalFooterLink route="/summary" text="My Overview" />
            <ExternalFooterLink url="https://hedado.com/offerings/portfolio" text="Portfolio" />
            <InternalFooterLink route="/browse-nonprofits" text="Curated Charities" />
            <ExternalFooterLink url="https://hedado.com/offerings/track-receipts" text="Track Receipts" />
          </Box>
          <Box className={classes.child}>
            <FooterHeader title="Details" />

            <ExternalFooterLink url="https://hedado.com/details/faq" text="FAQ" />
            <ExternalFooterLink url="https://hedado.com/details/terms-of-use" text="Terms of Use" />
            <ExternalFooterLink url="https://hedado.com/details/privacy-policy" text="Privacy Policy" />
          </Box>
          <Box className={classes.child}>
            <FooterHeader title="Who We Are" />

            <ExternalFooterLink url="https://airtable.com/shrbwSQ28V9HmHCuB" text="Give Us Feedback" />
            <ExternalFooterLink url="https://www.linkedin.com/company/hedado" text="Team" />
          </Box>
        </Grid>
        <Grid container className={classes.parent} sx={{ paddingBottom: 4 }}>
          <Typography>{`© ${new Date().getFullYear()} Hedado Inc. All rights reserved.`}</Typography>
        </Grid>
      </Container>
    </>
  );
};

export default Footer;
