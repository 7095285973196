import { Drawer, Box, Grid, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCampaignPaymentMethod } from 'components/CampaignAdminPage/hooks/useCampaignPaymentMethod';
import PaymentMethod from 'components/PaymentMethod/PaymentMethod';

interface CampaignPaymentMethodDrawerProps {
  campaignId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function CampaignPaymentMethodDrawer({ campaignId, isOpen, onClose }: CampaignPaymentMethodDrawerProps) {
  const { paymentMethod } = useCampaignPaymentMethod(campaignId);

  return (
    <Drawer anchor="right" onClose={onClose} open={isOpen} variant="temporary">
      <Box
        sx={{
          pb: 2,
          height: 'calc(100vh - 64px)',
          mt: '64px',
          overflow: 'scroll',
          position: 'relative',
          width: {
            sm: '500px',
            xs: '100vw',
          },
        }}
      >
        <Box sx={{ position: 'sticky', top: 0, background: '#fff', px: 2, pb: 1.5, boxShadow: '0px 0px 3px #000' }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h5" sx={{ pt: 1 }}>
                Add / Edit Payment Method
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ px: 2, mt: 4 }}>
          <PaymentMethod campaignId={campaignId} paymentMethod={paymentMethod} hideTitle={true} />
        </Box>
      </Box>
    </Drawer>
  );
}
