import { useState } from 'react';
import { checkPublicProfileExistsGql } from 'gql/donor/getPublicProfile';
import useHedadoQuery from 'hooks/useHedadoQuery';
import useDebounce from 'hooks/useDebounce';

export function useIsDonorProfileSlugTaken(donorId: string) {
  const debounce = useDebounce();
  const [searchSlug, setSearchSlug] = useState('');
  const { data: donorDataForSlug } = useHedadoQuery(checkPublicProfileExistsGql, {
    variables: {
      slug: searchSlug,
    },
    suppressErrors: true,
    fetchPolicy: 'network-only',
  });

  function checkIfSlugIsTaken(slug: string) {
    debounce(() => {
      setSearchSlug(slug);
    }, 350);
  }

  const isSlugTaken = !!searchSlug && !!donorDataForSlug?.getDonor?.id && donorDataForSlug.getDonor.id !== donorId;

  return {
    isSlugTaken,
    checkIfSlugIsTaken,
  };
}
