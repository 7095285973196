import { SxProps } from '@mui/system';
import type { GridSize, Theme } from '@mui/material';
import { Grid } from '@mui/material';

interface FormGridItemProps {
  children?: React.ReactNode;
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
  xl?: boolean | GridSize;
  sx?: SxProps<Theme>;
}

export default function FormGridItem({ children, sm, md, lg, xl, sx }: FormGridItemProps) {
  return (
    <Grid item xs={12} sm={sm} md={md} lg={lg} xl={xl} sx={sx}>
      {children}
    </Grid>
  );
}
