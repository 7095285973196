import React from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import PageTitle from '../components/global/PageTitle';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    itemSpacing: {
      marginTop: 20,
    },
  })
);

interface AboutItemProps {
  title: string;
  children?: React.ReactNode;
}

const AboutItem = ({ title, children }: AboutItemProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.itemSpacing}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body1">{children}</Typography>
      </CardContent>
    </Card>
  );
};

const About = () => {
  return (
    <>
      <PageTitle title="About Hedado" />
      <Typography variant="h6">
        We help people reach their philanthropic goals and exclaim "I've given!" → or in spanish{' '}
        <strong>'He dado!'</strong>
      </Typography>

      <AboutItem title="Product">
        We’re building the future of personal philanthropy. We accomplish this by providing tools for individuals to
        plan, make, manage, and track their charitable giving. Having a single place to view all your giving is core to
        our mission, so we offer a receipt tracker for all your donations outside of Hedado.
      </AboutItem>

      <AboutItem title="For Nonprofits">
        Our product is free and does not require a signup. When individuals choose to make a donation to your
        organization, they will receive that money through the Donor Advised Fund we partner with.
      </AboutItem>

      <AboutItem title="Pricing">
        Hedado is free to donors and free to nonprofits. Hedado does not take a cut of the donation to nonprofits. In
        the future, Hedado will explore a gratuity based model for the service it provides.
      </AboutItem>

      <AboutItem title="Governance">
        Hedado is a Public Benefit Corporation incorporated in Delaware. The specific public benefit purpose of the
        corporation is to help nonprofits and charities through sustained, predictable, and increased financial giving
        by creating donor-focused products and innovations.
      </AboutItem>
    </>
  );
};

export default About;
