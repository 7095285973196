import { createStyles, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Container, Button } from '@mui/material';
import { PAGE_ACTION_BLOCKS_CTA, PAGE_ACTION_BLOCKS_BUTTONTEXT, PAGE_ACTION_BLOCKS_ROUTE } from '../../utils/constants';
import { Box } from '@mui/system';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#395884',
      display: 'flex !important',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(3),
      justifyContent: 'center',
      color: theme.palette.primary.light,
      height: 'calc(max, 9rem, fit-content)',
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(8),
      paddingLeft: `${theme.spacing(8)} !important`,
      paddingRight: `${theme.spacing(8)} !important`,
      textAlign: 'center',
    },
    arc: {
      height: '5rem',
      maxHeight: '10vw',
      width: '100%',
      padding: '0 !important',
      overflow: 'clip',
    },
  })
);

interface FooterActionBlockProps {
  cta?: string;
  buttonText?: string;
  route?: string;
}

const curve = require('../../assets/img/footer-cta-curve.svg').default;
const curveXs = require('../../assets/img/footer-cta-curve-xs.svg').default;

const FooterActionBlockHeader = ({ cta = PAGE_ACTION_BLOCKS_CTA }: FooterActionBlockProps) => {
  return (
    <Typography component="h3" variant="h5" sx={{ fontWeight: 600 }}>
      {cta}
    </Typography>
  );
};

const FooterActionButton = ({
  buttonText = PAGE_ACTION_BLOCKS_BUTTONTEXT,
  route = PAGE_ACTION_BLOCKS_ROUTE,
}: FooterActionBlockProps) => {
  return buttonText && buttonText !== '' ? (
    <Button variant="light" href={route} target="_blank">
      {buttonText}
    </Button>
  ) : null;
};

const FooterActionBlock = ({ cta, buttonText, route }: FooterActionBlockProps) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root} maxWidth={false}>
        <FooterActionBlockHeader cta={cta}></FooterActionBlockHeader>
        <FooterActionButton buttonText={buttonText} route={route}></FooterActionButton>
      </Container>
      <Container className={classes.arc} maxWidth={false}>
        {/* <img src={curve} alt="" style={{objectFit: 'fill', padding: '0 !important', border: '1px solid red'}} /> */}
        <Box
          sx={{
            backgroundImage: { xs: `url(${curveXs})`, lg: `url(${curve})` },
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
            padding: '0 !important',
            marginTop: '-1px',
          }}
        ></Box>
      </Container>
    </>
  );
};

export default FooterActionBlock;
