import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Snackbar, Alert, Link } from '@mui/material';

interface StatusSnackbarProps {
  hasErrored: boolean;
  hasUpdated: boolean;
  clearStatus: () => void;
  slug?: string;
}

export default function StatusSnackbar({ hasErrored, hasUpdated, clearStatus, slug }: StatusSnackbarProps) {
  const [hasCreated, setHasCreated] = useState(window.location.search.indexOf('?created') === 0);

  function clearSnackbarState() {
    setHasCreated(false);
    clearStatus();
  }

  if (hasErrored) {
    return (
      <Snackbar open={true} autoHideDuration={3500} onClose={clearSnackbarState}>
        <Alert elevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>
          Oops, it looks like an error occurred
        </Alert>
      </Snackbar>
    );
  }

  if (hasUpdated || hasCreated) {
    if (slug) {
      return (
        <Snackbar open={true} autoHideDuration={10000} onClose={clearSnackbarState}>
          <Alert elevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>
            Campaign saved!{' '}
            <Link component={RouterLink} to={`/c/${slug}`} target="_blank">
              View Campaign
            </Link>
          </Alert>
        </Snackbar>
      );
    } else {
      return (
        <Snackbar open={true} autoHideDuration={10000} onClose={clearSnackbarState}>
          <Alert elevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>
            Campaign Saved
          </Alert>
        </Snackbar>
      );
    }
  }

  return null;
}
