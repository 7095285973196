import { gql } from '@apollo/client';

export const getLoggedInDonorProfileQuery_Name = 'GetLoggedInDonorProfile';
export const getLoggedInDonorProfileQuery_Gql = gql`
  query ${getLoggedInDonorProfileQuery_Name} {
    getLoggedInDonorProfile {
      id
      donorMetadata
      hasPaymentMethod
      hasRecurringDonation
      recurringFrequency
      hasForwardedDonation
      totalContributions
      nextDonationDate
      nextDonationAmount
      firstName
      lastName
      email
      pledgeAmount
      recentContributionDate
      recentContributionAmount
      recentContributionStatus
      campaignAdmin {
        id
        title
      }
      allocations {
        percentage
        organization {
          id
          ein
          name
          causeArea
          mission
          context
          impact
          whyYouShouldCare
          websiteUrl
          annualReportUrl
          financialsUrl
          form990Url
          orgSize
          imageName
          revenue
          assets
          street
          city
          state
          zip
          classification
          deductibility
        }
      }
    }
  }
`;
