import { useParams, Redirect } from 'react-router-dom';
import { env } from 'env';
import {
  getPublicProfileGql,
  GetPublicProfileQueryResponse,
  GetPublicProfileQueryVariables,
} from 'gql/donor/getPublicProfile';
import {
  getPublicDonorOrganizationsGql,
  GetPublicDonorOrganizationsQueryResponse,
  GetPublicDonorOrganizationsQueryVariables,
} from 'gql/donor/getPublicOrganizations';
import useHedadoQuery from 'hooks/useHedadoQuery';
import PublicProfilePage from 'components/donor/PublicProfilePage/PublicProfilePage';

export default function PublicProfile() {
  const { slug } = useParams<{ slug: string }>();

  const { data: donorData, loading: donorLoading } = useHedadoQuery<
    GetPublicProfileQueryResponse,
    GetPublicProfileQueryVariables
  >(getPublicProfileGql, {
    variables: {
      slug,
    },
    suppressErrors: true,
  });

  const { data: donorOrganizationsData, loading: donorOrganizationsLoading } = useHedadoQuery<
    GetPublicDonorOrganizationsQueryResponse,
    GetPublicDonorOrganizationsQueryVariables
  >(getPublicDonorOrganizationsGql, {
    variables: {
      slug,
    },
    suppressErrors: true,
  });

  if (donorLoading || donorOrganizationsLoading) return <></>;

  if (
    !donorData ||
    !donorData.getDonor ||
    !donorOrganizationsData ||
    !donorOrganizationsData.getPublicDonorOrganizations
  ) {
    return <Redirect to="/" />;
  }

  const donor = donorData.getDonor;

  const organizations = donorOrganizationsData.getPublicDonorOrganizations.edges.map((edge) => edge.node);

  const bannerSrc = donor.publicProfile.bannerImage
    ? `${env.REACT_APP_S3_PATH}/donors/${donor.publicProfile.bannerImage}`
    : '';
  const bannerMobileSrc = donor.publicProfile.bannerImageMobile
    ? `${env.REACT_APP_S3_PATH}/donors/${donor.publicProfile.bannerImageMobile}`
    : bannerSrc;
  const profileSrc = donor.publicProfile.profileImage
    ? `${env.REACT_APP_S3_PATH}/donors/${donor.publicProfile.profileImage}`
    : '';

  return (
    <PublicProfilePage
      donorId={donor.id}
      publicProfile={donor.publicProfile}
      organizations={organizations}
      images={{
        profileSrc,
        bannerSrc,
        bannerMobileSrc,
      }}
    />
  );
}
