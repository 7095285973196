import { ChangeEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Stack, Button, TextField } from '@mui/material';
import { env } from 'env';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useDebounce from 'hooks/useDebounce';
import { getCampaignsGql, GetCampaignsArgs, GetCampaignsQueryResponse } from 'gql/campaigns/getCampaigns';
import AdminPage from 'components/admin/AdminPage';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PER_PAGE = 25;

export default function CampaignList() {
  const debounce = useDebounce();
  const history = useHistory();
  const pageTitle = 'Campaigns';
  const [search, setSearch] = useState('');

  const { data, fetchMore } = useAuthenticatedHedadoQuery<GetCampaignsQueryResponse, GetCampaignsArgs>(
    getCampaignsGql,
    {
      variables: { first: PER_PAGE, search },
    }
  );
  const pageInfo = data?.getCampaigns.pageInfo;
  const edges = data?.getCampaigns.edges || [];

  function handleFetchMore() {
    if (!pageInfo) return;

    fetchMore({
      variables: {
        first: PER_PAGE,
        after: pageInfo.endCursor,
        search,
      },
    });
  }

  async function handleCreateNewClick() {
    history.push(`/admin/campaigns/new`);
  }

  function handleSearchChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = event.currentTarget.value;
    debounce(() => setSearch(value), 350);
  }

  return (
    <>
      <AdminPage title={pageTitle}>
        <Stack sx={{ mt: 2, mb: 3 }}>
          <TextField
            type="text"
            variant="standard"
            onChange={handleSearchChange}
            placeholder="Search by title or description"
          />
        </Stack>
        <Button variant="contained" onClick={handleCreateNewClick} sx={{ mb: 4 }}>
          Create Campaign
        </Button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Campaign</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {edges.map(({ node: { id, title, slug } }) => (
                <TableRow key={`campaign-${id}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {title}
                  </TableCell>
                  <TableCell>
                    {slug && (
                      <>
                        <Link to={{ pathname: `/c/${slug}` }} target="_blank">
                          View in App
                        </Link>
                        &nbsp;|&nbsp;
                        <a href={`${env.REACT_APP_NEXTJS_URL}/c/${slug}`} target="_blank" rel="noreferrer">
                          View on Hedado.com
                        </a>
                        &nbsp;|&nbsp;
                      </>
                    )}
                    <Link
                      to={{ pathname: `/admin/campaigns/${encodeURIComponent(id)}/edit` }}
                      style={{ display: 'inline-flex' }}
                    >
                      Edit
                    </Link>
                    &nbsp;|&nbsp;
                    <Link to={{ pathname: `/campaign/${encodeURIComponent(id)}` }} style={{ display: 'inline-flex' }}>
                      User-Facing Campaign Admin
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {pageInfo?.hasNextPage && (
          <Button sx={{ mt: 4 }} variant="outlined" onClick={handleFetchMore}>
            Load More
          </Button>
        )}
      </AdminPage>
    </>
  );
}
