import { gql } from '@apollo/client';

export const getLoggedInDonorActiveSubscription_Name = 'GetLoggedInDonorActiveSubscription';
export const getLoggedInDonorActiveSubscription_Gql = gql`
  query GetLoggedInDonorActiveSubscription {
    getLoggedInDonorActiveSubscription {
      startAt
      deactivatedAt
      frequency
      amountCents
      tipsCents
    }
  }
`;
