import Stripe from '@stripe/stripe-js';
import { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';
import useAnalytics, { events } from 'hooks/useAnalytics';
import { useUpdatePaymentMethod } from 'components/PaymentMethod/hooks/useUpdatePaymentMethod';
import LoadingButton from 'components/LoadingButton';
import BankAccountPreview from './BankAccountPreview';

interface ConfirmBankAccountProps {
  campaignId?: string;
  clientSecret: string;
  onCancel: () => void;
  onPaymentMethodAdded: () => void;
  paymentMethod?: Stripe.PaymentMethod | { id: string } | null;
}

const defaultErrorMessage = 'Oops!  It looks like there was an error adding your bank account';

export default function ConfirmBankAccount({
  campaignId,
  clientSecret,
  onCancel,
  onPaymentMethodAdded,
  paymentMethod,
}: ConfirmBankAccountProps) {
  const stripe = useStripe();
  const [updatePaymentMethodErrored, setUpdatePaymentMethodErrored] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [stripeError, setStripeError] = useState<string | null>(null);
  const [isConfirmPaymentMethodLoading, setIsConfirmPaymentMethodLoading] = useState(false);
  const { trackEvent } = useAnalytics();
  const { updatePaymentMethod, loading: updatePaymentMethodLoading } = useUpdatePaymentMethod({
    onCompleted: onPaymentMethodAdded,
    onError: () => {
      setUpdatePaymentMethodErrored(true);
    },
  });

  if (!paymentMethod) return null;

  async function handleConfirmAccountClick() {
    const confirmedPaymentMethod = await confirmStripePaymentMethod();

    if (confirmedPaymentMethod) {
      trackEvent(events.addPaymentMethod);
      await updatePaymentMethod({
        variables: { paymentMethodId: confirmedPaymentMethod.id, campaignId },
      });
    }
  }

  async function confirmStripePaymentMethod() {
    if (!stripe || !paymentMethod) {
      setStripeError(defaultErrorMessage);

      return null;
    }

    setIsConfirmPaymentMethodLoading(true);

    try {
      const confirmedPaymentMethod = await confirmBankPaymentMethod(stripe, {
        clientSecret,
        paymentMethod: paymentMethod.id,
      });

      if (confirmedPaymentMethod === null) return null;
      if ('error' in confirmedPaymentMethod) {
        setStripeError(confirmedPaymentMethod.error?.message || defaultErrorMessage);
        return null;
      }

      return confirmedPaymentMethod;
    } catch (error: any) {
      setStripeError(defaultErrorMessage);
      return null;
    } finally {
      setIsConfirmPaymentMethodLoading(false);
    }
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsConfirmed(event.currentTarget.checked);
  }

  const last4 = paymentMethod && ((paymentMethod as any)?.us_bank_account?.last4 as string);
  const bank_name = paymentMethod && ((paymentMethod as any)?.us_bank_account?.bank_name as string);
  const showPaymentMethodDetails = !!last4 && !!bank_name;
  const isSaveAccountLoading = isConfirmPaymentMethodLoading || updatePaymentMethodLoading;

  return (
    <Box sx={{ px: 2 }}>
      {showPaymentMethodDetails && <BankAccountPreview last4={last4} bankName={bank_name} />}
      <Typography sx={{ my: 2 }} variant="body2">
        By clicking [confirm], you authorize Hedado to debit the bank account specified above for any amounts arising
        one-time or recurring donations, pursuant to Hedado's website and terms, until this authorization is revoked.
        You may amend or cancel this authorization by changing this payment method.
      </Typography>
      <FormControlLabel
        control={<Checkbox name="confirmBankAccount" checked={isConfirmed} onChange={handleCheckboxChange} />}
        label="Confirm"
      />
      <Box sx={{ mt: 2 }}>
        <LoadingButton
          onClick={handleConfirmAccountClick}
          variant="contained"
          loading={isSaveAccountLoading}
          disabled={!isConfirmed}
          size="small"
        >
          Save
        </LoadingButton>
        <Button onClick={onCancel} variant="outlined" sx={{ ml: 2 }} size="small">
          Cancel
        </Button>
      </Box>
      {updatePaymentMethodErrored && (
        <Typography variant="caption" sx={{ color: '#d32f2f', mt: 2 }}>
          {defaultErrorMessage}
        </Typography>
      )}
      {stripeError && stripeError !== defaultErrorMessage && (
        <Typography variant="caption" sx={{ color: '#d32f2f', mt: 2 }}>
          {stripeError}
        </Typography>
      )}
    </Box>
  );
}

async function confirmBankPaymentMethod(
  stripe: Stripe.Stripe,
  { clientSecret, paymentMethod }: { clientSecret?: string | null; paymentMethod: string }
) {
  if (!clientSecret) return null;

  const { error, setupIntent } = await stripe.confirmUsBankAccountSetup(clientSecret, {
    payment_method: paymentMethod,
  });

  if (setupIntent && setupIntent.status !== 'succeeded') return null;

  if (error) return { error };
  if (!setupIntent?.payment_method) return null;

  if (typeof setupIntent.payment_method === 'string') return { id: setupIntent.payment_method };

  return setupIntent.payment_method;
}
