import { CampaignTier } from 'model/CampaignTier.model';
import { EligibilityType } from 'components/CampaignForm/hooks/useMembersFormState';

interface SelectMenuList {
  value?: string | number | readonly string[] | undefined;
  text: string;
}

export function useCampaignPermissions() {
  function dataExportEnabled(tier: CampaignTier) {
    return tier !== CampaignTier.basic;
  }

  function getTrimmedMemberOptions(tier: CampaignTier): SelectMenuList[] {
    const selectList: SelectMenuList[] = [];

    if (tier === CampaignTier.business) {
      selectList.push({ value: EligibilityType.limitToPeople, text: 'People specific (email addresses)' });
      selectList.push({ value: EligibilityType.limitToDomains, text: 'Company specific (domain names)' });
      selectList.push({ value: EligibilityType.limitToPeopleAndDomains, text: 'People & company specific' });
    }

    selectList.push({ value: EligibilityType.openToAll, text: 'Campaign specific (donors who visit campaign page)' });

    if (tier === CampaignTier.business) {
      selectList.push({ value: EligibilityType.universal, text: 'Universal (anyone meeting other campaign criteria)' });
    }

    return selectList;
  }

  function canAddAdminUsers(tier: CampaignTier, currentNumOfAdmins: number) {
    const benefactorAdminCap = 3;
    const basicAdminCap = 1;

    if (tier === CampaignTier.business) return true;

    if (tier === CampaignTier.benefactor) {
      return currentNumOfAdmins < benefactorAdminCap;
    }

    if (tier === CampaignTier.basic) {
      return currentNumOfAdmins < basicAdminCap;
    }

    return false;
  }

  function exceedsMatchLimit(tier: CampaignTier, matchLimitCents: number) {
    if (tier !== CampaignTier.basic) return false;

    const basicMatchLimitCents: number = 100000;

    return matchLimitCents > basicMatchLimitCents;
  }

  function displayDonorOrganizations(tier: CampaignTier) {
    return tier !== CampaignTier.basic;
  }

  return { dataExportEnabled, getTrimmedMemberOptions, canAddAdminUsers, exceedsMatchLimit, displayDonorOrganizations };
}
