import { hasAnyError, hasAnyVisibleError } from 'helpers/formHelpers';
import type { AmountAndTipFormState } from '../components/AmountAndTip';
import type { PaymentInfoFormState } from '../components/PaymentInfo';
import { useMemo } from 'react';

export function useFormStepErrors(
  amountAndTipFormState: AmountAndTipFormState,
  paymentInfoFormState: PaymentInfoFormState,
  stripeError: string | null
) {
  const amountAndTipErrors = useMemo(() => computeAmountAndTipErrors(amountAndTipFormState), [amountAndTipFormState]);
  const paymentInfoErrors = useMemo(
    () => computePaymentInfoErrors(paymentInfoFormState, stripeError),
    [paymentInfoFormState, stripeError]
  );

  return useMemo(
    () => ({
      amountAndTip: {
        errors: amountAndTipErrors,
        hasAnyError: hasAnyError(amountAndTipErrors),
        hasAnyVisibleError: hasAnyVisibleError(amountAndTipErrors, amountAndTipFormState),
      },
      paymentInfo: {
        errors: paymentInfoErrors,
        hasAnyError: hasAnyError(paymentInfoErrors),
        hasAnyVisibleError: hasAnyVisibleError(paymentInfoErrors, paymentInfoFormState),
      },
    }),
    [amountAndTipErrors, amountAndTipFormState, paymentInfoErrors, paymentInfoFormState]
  );
}

function computeAmountAndTipErrors(formState: AmountAndTipFormState) {
  return {
    amountCents:
      !formState.amountCents.value || formState.amountCents.value < 0 || formState.amountCents.value % 1 !== 0
        ? 'Please provide a valid amount'
        : '',
  };
}

function computePaymentInfoErrors(formState: PaymentInfoFormState, stripeError: string | null) {
  return {
    firstName: !formState.firstName.value ? 'This field is required' : '',
    lastName: !formState.lastName.value ? 'This field is required' : '',
    email: !formState.email.value
      ? 'This field is required'
      : formState.email.hasBlurredAtLeastOnce &&
        formState.email.value &&
        !/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          formState.email?.value
        )
      ? 'Please enter a valid email address'
      : '',
    paymentMethod: stripeError || '',
  };
}
