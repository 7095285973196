import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Authenticate = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const appState = getRedirectAppStateFromSearch(location.search);

  loginWithRedirect(appState);
  return <></>;
};

function getRedirectAppStateFromSearch(search: string) {
  const appState: { [key: string]: string } = {};

  const authRedirectParams = Object.fromEntries(new URLSearchParams(search));
  if ('organizationIdToAddToPortfolio' in authRedirectParams) {
    appState.organizationIdToAdd = decodeURIComponent(authRedirectParams.organizationIdToAddToPortfolio);
  }

  if ('redirectTo' in authRedirectParams) {
    appState.returnTo = decodeURIComponent(authRedirectParams.redirectTo);
  }

  return { appState };
}

export default Authenticate;
