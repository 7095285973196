import React from 'react';

import { Box, Grid } from '@mui/material';
import { ResponsiveStyleValue, SystemProps } from '@mui/system';
import { BoxProps } from '@mui/material/Box';
import { GridProps } from '@mui/material/Grid';

type Axis = 'center' | 'left' | 'right' | 'top' | 'bottom';

interface FlexProps {
  x?: Axis;
  y?: Axis;
  children?: React.ReactNode;
}

interface FlexBoxProps extends FlexProps, BoxProps {}
interface FlexGridProps extends FlexProps, GridProps {}

function flexMap(props: FlexBoxProps | FlexGridProps): SystemProps {
  let { x, y, flexDirection } = props;
  if (flexDirection?.toString().startsWith('column')) {
    [x, y] = [y, x];
  }

  const positionMap = (noun?: string): ResponsiveStyleValue<any> => {
    switch (noun) {
      case 'center':
        return 'center';
      case 'top':
      case 'left':
        return 'flex-start';
      case 'right':
      case 'bottom':
        return 'flex-end';
      default:
        return 'normal';
    }
  };

  return {
    display: 'flex',
    justifyContent: props.justifyContent || positionMap(x),
    alignItems: props.alignItems || positionMap(y),
  };
}

const FlexBox = React.forwardRef<React.RefObject<any>, FlexBoxProps>((props, ref) => {
  const { x, y, children, ...other } = props;
  return (
    <Box {...other} {...flexMap(props)} ref={ref}>
      {children}
    </Box>
  );
});
FlexBox.displayName = 'FlexBox';

const FlexGrid = (props: FlexGridProps) => {
  const { x, y, children, ...other } = props;
  return (
    <Grid {...other} {...flexMap(props)}>
      {children}
    </Grid>
  );
};

export { FlexBox, FlexGrid };

export type { Axis, FlexProps };
