import type { DonationMatchesTableState } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { numberExists, getDollarString, getCommaSeparatedString } from 'helpers/numberHelpers';
import { useCampaignStatistics } from 'components/CampaignAdminPage/hooks/useCampaignStatistics';
import StatisticBox from 'components/StatisticBox';
import StatisticBoxSkeleton from 'components/StatisticBoxSkeleton';
import CampaignDonationMatchDialog from './components/CampaignDonationMatchDialog';

interface CampaignStatisticsProps {
  campaignId: string;
  queryVariables: DonationMatchesTableState['queryVariables'];
}

export default function CampaignStatistics({ campaignId, queryVariables }: CampaignStatisticsProps) {
  const [isCampaignMatchDialogOpen, setIsCampaignMatchDialogOpen] = useState(false);
  const { statistics } = useCampaignStatistics(campaignId);

  const donationTotalDollars = getDollarString(statistics?.donationsTotalCents);
  const settledMatchesTotalDollars = getDollarString(statistics?.settledMatchesTotalCents);
  const pendingMatchesTotalDollarsWithoutCents = getDollarString(statistics?.pendingMatchesTotalCents);
  const pendingMatchesTotalDollars = getDollarString(statistics?.pendingMatchesTotalCents, { hideCents: false });
  const totalDonorsString = getCommaSeparatedString(statistics?.donorsTotal);
  const totalNonProfitsString = getCommaSeparatedString(statistics?.organizationsTotal);

  function handleMatchDonationsClick() {
    setIsCampaignMatchDialogOpen(true);
  }

  function handleCloseCampaignDonationMatchDialog() {
    setIsCampaignMatchDialogOpen(false);
  }

  return (
    <>
      <Box
        sx={{
          alignItems: 'stretch',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          m: { xs: -1, sm: -2 },
          mt: { xs: 3, sm: 2 },
        }}
      >
        {numberExists(statistics?.donationsTotalCents) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {donationTotalDollars}
            </Typography>
            <Typography>Total Amount Donated</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}

        {numberExists(statistics?.settledMatchesTotalCents) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {settledMatchesTotalDollars}
            </Typography>
            <Typography>Total Amount Matched</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}

        {numberExists(statistics?.pendingMatchesTotalCents) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {pendingMatchesTotalDollarsWithoutCents}
            </Typography>
            <Typography>Pending Match Amount</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}

        {numberExists(statistics?.donorsTotal) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {totalDonorsString}
            </Typography>
            <Typography>No. of Donors</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}

        {numberExists(statistics?.organizationsTotal) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {totalNonProfitsString}
            </Typography>
            <Typography>No. of Nonprofits</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}
      </Box>
      {(statistics?.pendingMatchesTotalCents || 0) > 0 && (
        <Button variant="contained" sx={{ mt: 4 }} size="large" onClick={handleMatchDonationsClick}>
          Match {pendingMatchesTotalDollars} of Pending Donations
        </Button>
      )}
      <CampaignDonationMatchDialog
        campaignId={campaignId}
        isOpen={isCampaignMatchDialogOpen}
        onClose={handleCloseCampaignDonationMatchDialog}
        currentQueryVariables={queryVariables}
      />
    </>
  );
}
