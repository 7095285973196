import { FocusEvent, ChangeEvent, SyntheticEvent, useState } from 'react';
import { Snackbar, Alert, Button, Stack, Grid, TextField } from '@mui/material';
import { PersonalDetailsFormState, PersonalDetailsFormProps } from './PersonalDetailsForm.interfaces';
export type {
  PersonalDetailsFormProps,
  PersonalDetailsFormState,
  PersonalDetailsInput,
} from './PersonalDetailsForm.interfaces';

export default function PersonalDetailsForm({ initialState, handleSubmit }: PersonalDetailsFormProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [formState, setFormState] = useState<PersonalDetailsFormState>({
    firstName: {
      value: initialState?.firstName || '',
      hasBlurredAtLeastOnce: false,
    },
    lastName: {
      value: initialState?.lastName || '',
      hasBlurredAtLeastOnce: false,
    },
  });

  const hasFirstNameError = !formState.firstName.value && formState.firstName.hasBlurredAtLeastOnce;
  const hasLastNameError = !formState.lastName.value && formState.lastName.hasBlurredAtLeastOnce;
  const hasInputError = hasFirstNameError || hasLastNameError;
  const shouldShowSnackbar = !!errorMessage || !!successMessage;
  const isError = !!errorMessage;
  const snackMessage = errorMessage || successMessage;
  const isFormDisabled = hasInputError || isSubmitting || !formValuesChanged;
  const NAME_REQUIRED_ERROR_MESSAGE = 'Please provide your first & last name for your donation receipt.';

  function handleInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setFormValuesChanged(true);
    const { name, value } = event.currentTarget;

    if (!['firstName', 'lastName'].includes(name)) return;
    const key = name as keyof typeof formState;

    setFormState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value,
      },
    }));
  }

  function handleInputBlur(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name } = event.currentTarget;

    if (!['firstName', 'lastName'].includes(name)) return;
    const key = name as keyof typeof formState;

    setFormState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        hasBlurredAtLeastOnce: true,
      },
    }));
  }

  function handleSnackbarClose() {
    setErrorMessage('');
    setSuccessMessage('');
  }

  async function onSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await handleSubmit({
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
      });
      setFormValuesChanged(false);
      setSuccessMessage('Personal details updated!');
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2}>
        <TextField variant="outlined" label="Email" name="email" disabled value={initialState?.email || ''} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              error={hasFirstNameError}
              helperText={hasInputError ? NAME_REQUIRED_ERROR_MESSAGE : ''}
              sx={{
                display: 'flex',
                flexGrow: 1,
                '& .MuiFormHelperText-root': {
                  whiteSpace: { xs: 'normal', sm: 'nowrap' },
                },
              }}
              variant="outlined"
              label="First Name"
              name="firstName"
              required
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              value={formState.firstName.value}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={hasLastNameError}
              sx={{ display: 'flex', flexGrow: 1 }}
              variant="outlined"
              label="Last Name"
              name="lastName"
              required
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              value={formState.lastName.value}
            />
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" type="submit" disabled={isFormDisabled}>
          Save
        </Button>
      </Stack>
      <Snackbar open={shouldShowSnackbar} autoHideDuration={3500} onClose={handleSnackbarClose}>
        <Alert elevation={6} severity={isError ? 'error' : 'success'} variant="filled" sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}
