import { useParams, Redirect } from 'react-router-dom';
import { useAdminCampaign } from 'hooks/useAdminCampaign';
import Head from 'components/Head/Head';
import CampaignAdminPage from 'components/CampaignAdminPage/CampaignAdminPage';

export default function CampaignAdmin() {
  const { id: rawId } = useParams<{ id: string }>();
  const campaignId = decodeURIComponent(rawId);
  const { isCampaignAdmin, campaign } = useAdminCampaign(campaignId);

  if (isCampaignAdmin === undefined) return <></>;
  if (isCampaignAdmin === false) return <Redirect to="/" />;
  if (!campaign) return <></>;

  return (
    <>
      <Head title={`${campaign.title} on Hedado`} />
      <CampaignAdminPage campaign={campaign} />
    </>
  );
}
