import type { Donation } from 'components/DonorHistoryPage/hooks/useDonorDonationsQuery';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { convertCentsToDollars } from 'helpers/currencyHelper';

type DonationMatch = Donation['donationMatches'][number];

export default function SettledMatchListItem({ donationMatch }: { donationMatch: DonationMatch }) {
  const { slug, title } = donationMatch.campaign;
  const matchedAmount = convertCentsToDollars(donationMatch.matchedAmountInCents);

  if (donationMatch.matchedAmountInCents === 0) {
    return (
      <>
        Donation match was skipped by{' '}
        <Link component={RouterLink} to={`/c/${slug}`}>
          {title}
        </Link>{' '}
        - campaign match limits have been met
      </>
    );
  }

  return (
    <>
      {matchedAmount} has been matched by{' '}
      <Link component={RouterLink} to={`/c/${slug}`}>
        {title}
      </Link>
    </>
  );
}
