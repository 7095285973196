import { Tabs, Tab, Tooltip, Skeleton } from '@mui/material';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { useCampaignDonationMatchTotals } from 'components/CampaignAdminPage/hooks/useCampaignDonationMatchTotals';

interface DonationMatchTabsProps {
  onTabChange: (_: any, value: DonationMatchStatus) => void;
  status: DonationMatchStatus;
  campaignId: string;
}

export default function DonationMatchTabs({ onTabChange, status, campaignId }: DonationMatchTabsProps) {
  const { totals } = useCampaignDonationMatchTotals(campaignId);

  if (!totals) {
    return (
      <Tabs sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} value={status} variant="fullWidth">
        <Tab label={<Skeleton variant="text" width={200} height={24} sx={{ maxWidth: '100%' }} />} />
        <Tab label={<Skeleton variant="text" width={200} height={24} sx={{ maxWidth: '100%' }} />} />
        <Tab label={<Skeleton variant="text" width={200} height={24} sx={{ maxWidth: '100%' }} />} />
        <Tab label={<Skeleton variant="text" width={200} height={24} sx={{ maxWidth: '100%' }} />} />
      </Tabs>
    );
  }

  return (
    <Tabs
      onChange={onTabChange}
      sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      value={status}
      variant="fullWidth"
    >
      <Tab
        label={<span>Inbox [{totals.pending}]</span>}
        sx={{ textTransform: 'capitalize', fontSize: '1.125em' }}
        value={DonationMatchStatus.pending}
      />
      <Tab
        label={
          <Tooltip arrow placement="top" title="Donations not ready to be matched, but are still part of the campaign.">
            <span>Deferred [{totals.deferred}]</span>
          </Tooltip>
        }
        sx={{ textTransform: 'capitalize', fontSize: '1.125em' }}
        value={DonationMatchStatus.deferred}
      />

      <Tab
        label={
          <Tooltip
            arrow
            placement="top"
            title="Donations that should be removed from the campaign regardless of reason. Donations will not be included in campaign totals."
          >
            <span>Excluded [{totals.excluded}]</span>
          </Tooltip>
        }
        sx={{ textTransform: 'capitalize', fontSize: '1.125em' }}
        value={DonationMatchStatus.excluded}
      />
      <Tab
        label={
          <Tooltip
            arrow
            placement="top"
            title="Donations that were matched or donations made after matching funds expired."
          >
            <span>Settled [{totals.processing + totals.settled}]</span>
          </Tooltip>
        }
        sx={{ textTransform: 'capitalize', fontSize: '1.125em' }}
        value={DonationMatchStatus.settled}
      />
    </Tabs>
  );
}
