import { forwardRef } from 'react';
import { Paper, Typography, Grid } from '@mui/material';

interface FormSectionProps {
  heading?: string;
  children?: React.ReactNode;
}

const FormSection = forwardRef<HTMLDivElement, FormSectionProps>(({ heading, children }, ref) => {
  return (
    <Grid item xs={12} ref={ref}>
      <Paper sx={{ p: 3, pb: 4, borderRadius: 3, width: '570px', maxWidth: '100%' }} elevation={4}>
        {heading && (
          <Typography variant="h5" sx={{ mb: 3 }}>
            {heading}
          </Typography>
        )}
        <Grid container spacing={2}>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
});

export default FormSection;
