import { useState, useCallback, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getAccessTokenFromStorage,
  getRolesFromStorage,
  setRolesInStorage,
  setAccessTokenInStorage,
  removeAccessTokenFromStorage,
  removeRolesFromStorage,
  AUTH0_TOKEN_CLAIM_KEY,
} from 'utils/storage';
import { getLoggedInDonorProfileQuery_Gql } from 'gql/donorProfileQuery';

export default function useStoredAuth0Values() {
  const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const accessToken = getAccessTokenFromStorage();
  const roles = getRolesFromStorage();
  const [isReady, setIsReady] = useState(false);
  const [isAdmin, setIsAdmin] = useState(!!roles && roles.includes('Admin'));

  // NOTE: this query is a stopgap to prevent a race condition in the API would otherwise potentially result
  // in duplicate donor records.  Essentially, we need to ensure that the donor is queried exactly ONCE and
  // successfully returned before we allow any other authenticated query to hit the api.

  // NOTE: this query does NOT use useHedadoQuery, because that hook relies on this one in order to wait for
  // authentication before making any graphql requests.
  const { data: donorData } = useQuery(getLoggedInDonorProfileQuery_Gql, {
    skip: !(isAuthenticated && isReady),
  });

  const isDonorProfileReady = !!donorData?.getLoggedInDonorProfile;
  const isAuthenticatedAndReady = isReady && isAuthenticated && isDonorProfileReady;

  const handleIsAuthenticated = useCallback(async () => {
    async function setRolesFromAuth0Claims() {
      const claims = await getIdTokenClaims();
      const roles = claims[AUTH0_TOKEN_CLAIM_KEY] || [];

      setRolesInStorage(roles as string[]);
      setIsAdmin(roles.includes('Admin'));
    }

    async function setAuth0AccessToken() {
      const accessToken = await getAccessTokenSilently();

      setAccessTokenInStorage(accessToken);
    }

    if (!accessToken) await setAuth0AccessToken();
    if (!roles) await setRolesFromAuth0Claims();

    setIsReady(true);
  }, [accessToken, getAccessTokenSilently, getIdTokenClaims, roles]);

  const handleNotAuthenticated = useCallback(() => {
    removeAccessTokenFromStorage();
    removeRolesFromStorage();

    setIsReady(true);
    setIsAdmin(false);
  }, []);

  useLayoutEffect(() => {
    if (isLoading) return setIsReady(false);

    if (isAuthenticated) {
      handleIsAuthenticated();
    } else {
      handleNotAuthenticated();
    }
  }, [isLoading, isAuthenticated, handleIsAuthenticated, handleNotAuthenticated]);

  return {
    isAdmin,
    isReady,
    isAuthenticatedAndReady,
  };
}
