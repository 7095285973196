import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Divider, Grid, Button, Alert } from '@mui/material';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useDonorProfile from 'hooks/useDonorProfile';
import Head from 'components/Head/Head';
import PageHeader from 'components/global/PageHeader';
import PageContainer from 'components/global/ColorPageContainer';
import ManageContributions from 'components/organization/ManageContributions';
import OrgListItem from 'components/portfolio/OrgListItem';
import EmptyState from 'components/global/onboarding/EmptyState';

import Allocation from 'model/Allocation';
import { getActiveAllocationsForDonorQuery_Gql } from 'gql/allocation/getActiveAllocationsForDonorQuery';
import { PAGE_TITLE_PORTFOLIO, APP_TITLE } from 'utils/constants';

import { ChevronRight } from '@mui/icons-material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HedadoPortfolioImage from 'assets/img/hedado-autopilot.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    circle: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      padding: '10px',
      marginLeft: '5px',
      background: theme.palette.secondary.main,
      border: '1px solid #86D966',
      color: '#FFF',
      textAlign: 'center',
      font: '18px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
    },
  })
);

const MyPortfolio = () => {
  const classes = useStyles();
  const [allocations, setAllocations] = useState<Allocation[]>([]);
  const [contributionManagerOpen, setContributionManagerOpen] = useState(false); // FIXME: this should be a hook not passed state props
  const [donorProfile] = useDonorProfile();
  const { isAuthenticatedAndReady } = useStoredAuth0Values();

  const { loading, data } = useAuthenticatedHedadoQuery(getActiveAllocationsForDonorQuery_Gql);

  useEffect(() => {
    if (loading || !data || !isAuthenticatedAndReady) return;
    setAllocations([...data.getActiveAllocationsForDonor].sort((a, b) => b.percentage - a.percentage));
  }, [loading, data, isAuthenticatedAndReady]);

  function openContributionManager() {
    setContributionManagerOpen(true);
  }

  return (
    <>
      {allocations.length > 0 ? (
        <>
          <Head title={`${PAGE_TITLE_PORTFOLIO} | ${APP_TITLE}`} />
          <PageHeader
            icon={<BusinessCenterIcon />}
            title="My Portfolio"
            subTitle="Review the organizations you support and allocate your support across them."
          />

          <PageContainer>
            Your donations are automatically distributed to the organizations you choose through a single transaction
            based on your allocations
          </PageContainer>

          {!donorProfile?.hasPaymentMethod ? (
            <Alert
              severity="warning"
              sx={{
                display: 'flex',
                my: 4,
                alignItems: { xs: 'flex-start', sm: 'center' },
                flexGrow: 1,
                '.MuiAlert-icon': {
                  mt: { xs: 1, sm: 'unset' },
                },
                '> div[class$="message"]': {
                  display: 'flex',
                  flexGrow: 1,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'space-between',
                  flexGrow: 1,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="body1" sx={{ flexWrap: 'wrap', flexBasis: 'content' }}>
                  You will need to add a payment method to begin contributing to your portfolio.
                </Typography>
                <Button
                  component={RouterLink}
                  to="/manage-giving"
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={{
                    flexWrap: 'nowrap',
                    flexBasis: 'max-content',
                    m: 2,
                    p: 1,
                  }}
                  endIcon={<ChevronRight />}
                >
                  Add a payment method
                </Button>
              </Box>
            </Alert>
          ) : (
            <Button variant="contained" size="large" sx={{ my: 4 }} component={RouterLink} to="/manage-giving">
              Donate to Portfolio
            </Button>
          )}

          <Grid container>
            <Grid item xs={12} sm={7} sx={{ display: 'flex', verticalAlign: 'middle' }}>
              <Typography variant="h5" component="div">
                My Nonprofits
              </Typography>
              <div className={classes.circle}>{allocations && allocations.length}</div>
            </Grid>
            <Grid item xs={12} sm={5} sx={{ textAlign: 'right' }}>
              <Typography variant="h6">
                <ManageContributions openState={[contributionManagerOpen, setContributionManagerOpen]} />
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <br />
          <Grid container gap={2}>
            {allocations &&
              allocations.map((allocation) => (
                <Grid key={allocation.organizationId} sx={{ flexGrow: 1 }}>
                  <OrgListItem allocation={allocation} onDeleteClicked={openContributionManager} />
                </Grid>
              ))}
          </Grid>

          <Button variant="contained" size="large" sx={{ mt: 4 }} component={RouterLink} to="/manage-giving">
            Donate to Portfolio
          </Button>

          <Button
            size="large"
            color="primary"
            component={RouterLink}
            to="/browse-nonprofits"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              gap: 1,
              flexGrow: 1,
              alignItems: { xs: 'flex-start', md: 'center' },
              width: '100%',
              backgroundColor: 'secondary.light',
              marginTop: 5,
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            + Add Nonprofits
          </Button>
        </>
      ) : (
        !loading && (
          <EmptyState
            title="My Portfolio"
            subtitle="Add nonprofits to your portfolio, then allocate your support across them."
            body={
              'Donate to all your nonprofits with a single transaction. You choose which % is allocated to each, and 100% of your transaction is tax-deductible.'
            }
            image={<img src={HedadoPortfolioImage} alt="add nonprofits to your portfolio" />}
            color="primary.main"
            backgroundColor="#afe69c"
            ctaButton={
              <Button
                component={RouterLink}
                color="primary"
                variant="light"
                size="large"
                to="/browse-nonprofits"
                endIcon={<ChevronRightIcon />}
              >
                Discover over 1.5M nonprofits
              </Button>
            }
          />
        )
      )}
    </>
  );
};

export default MyPortfolio;
