import React, { useState } from 'react';
import { Switch, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import useHedadoMutation from 'hooks/useHedadoMutation';
import { updateAllocationAnonymousMutation_Gql } from '../../gql/allocation/updateAllocationAnonymousMutation';
import { getActiveAllocationsForDonorQuery_Name } from '../../gql/allocation/getActiveAllocationsForDonorQuery';

interface AnonymousAllocationToggleProps {
  compact: boolean;
  allocationId: string;
  anonymous: boolean | null;
}

const AnonymousAllocationToggle = ({ compact, allocationId, anonymous }: AnonymousAllocationToggleProps) => {
  const initallyAnonymous = anonymous === null ? true : anonymous;

  const [donateAnonymously, setDonateAnonymously] = useState(initallyAnonymous);

  const [updateAnonymousAllocation] = useHedadoMutation(updateAllocationAnonymousMutation_Gql, {
    refetchQueries: [getActiveAllocationsForDonorQuery_Name],
  });

  const handleAnonymousChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAnonymous = !event.target.checked;

    setDonateAnonymously(isAnonymous);

    updateAnonymousAllocation({
      variables: {
        allocation: {
          id: allocationId,
          anonymous: isAnonymous,
        },
      },
    });
  };

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={!donateAnonymously} onChange={handleAnonymousChange} color="secondary" />}
          label={!compact ? 'Share my name & email with this nonprofit' : 'Share my name & email'}
          sx={{
            '.MuiFormControlLabel-label': {
              fontSize: !compact ? '0.9rem' : '0.8rem',
              opacity: !donateAnonymously ? 1 : 0.5,
            },
          }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default AnonymousAllocationToggle;
