import { useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import useHedadoMutation from 'hooks/useHedadoMutation';
import TextInputDialog from '../global/TextInputDialog';
import { updateAllocationDesignationMutation_Gql } from '../../gql/allocation/updateAllocationDesignationMutation';
import { getActiveAllocationsForDonorQuery_Name } from '../../gql/allocation/getActiveAllocationsForDonorQuery';

interface AllocationDesignationProps {
  compact: boolean;
  allocationId: string;
  organizationName: string;
  designation: string | null;
}

const AllocationDesignation = ({
  compact,
  allocationId,
  organizationName,
  designation,
}: AllocationDesignationProps) => {
  const initialDesignation = designation === null ? '' : designation;

  const [donationDesignation, setDonationDesignation] = useState(initialDesignation);
  const [editDesignationDialogOpen, setEditDesignationDialogOpen] = useState(false);

  const [updateAllocationDesignation] = useHedadoMutation(updateAllocationDesignationMutation_Gql, {
    refetchQueries: [getActiveAllocationsForDonorQuery_Name],
  });

  const handleEditDesignation = () => {
    setEditDesignationDialogOpen(true);
  };

  const handleDesignationDialogClosed = (shouldSave: boolean, value: string) => {
    setEditDesignationDialogOpen(false);

    if (shouldSave) {
      setDonationDesignation(value);

      updateAllocationDesignation({
        variables: {
          allocation: {
            id: allocationId,
            designation: value,
          },
        },
      });
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ cursor: 'pointer', display: 'flex', alignItems: 'flex-start' }}
        onClick={handleEditDesignation}
      >
        {donationDesignation !== '' ? (
          <Typography sx={{ display: 'inline-flex', fontSize: !compact ? '0.9rem' : '0.8rem', mt: 1 }}>
            {donationDesignation}
          </Typography>
        ) : (
          <Typography
            variant="caption"
            sx={{
              display: 'inline-flex',
              fontStyle: 'oblique',
              opacity: 0.5,
              fontSize: !compact ? '0.9rem' : '0.8rem',
              mt: 1,
            }}
          >
            Add instructions for this nonprofit
          </Typography>
        )}

        <IconButton
          color="secondary"
          aria-label="Add instructions"
          component="span"
          sx={{ '.MuiSvgIcon-root': { width: '1.2rem', height: '1.2rem' } }}
        >
          <EditIcon />
        </IconButton>
      </Grid>
      <TextInputDialog
        open={editDesignationDialogOpen}
        title={`Instructions for '${organizationName}'`}
        helpText={`This will be sent to the nonprofit along with your donation.`}
        // This expression must always begin with ^ and end
        // with +$ in order to negate the character range.
        allowedChars={/^[a-zA-Z0-9\s.,?!:;()-_+=@$%^&*#[\]{}|\\/]+$/}
        maxChars={128}
        text={donationDesignation || ''}
        onClose={handleDesignationDialogClosed}
      />
    </>
  );
};

export default AllocationDesignation;
