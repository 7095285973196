import { Tab, Tabs } from '@mui/material';

export enum HistoryTab {
  donations = 'donations',
  contributions = 'contributions',
}

interface DonorHistoryTabsProps {
  activeTab: HistoryTab;
  onTabChange: (_: any, value: HistoryTab) => void;
}

export default function DonorHistoryTabs({ onTabChange, activeTab }: DonorHistoryTabsProps) {
  return (
    <Tabs
      onChange={onTabChange}
      sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      value={activeTab}
      variant="fullWidth"
    >
      <Tab
        label={<span>Donation History</span>}
        sx={{ textTransform: 'capitalize', fontSize: '1.125em' }}
        value={HistoryTab.donations}
      />
      <Tab
        label={<span>Contribution History</span>}
        sx={{ textTransform: 'capitalize', fontSize: '1.125em' }}
        value={HistoryTab.contributions}
      />
    </Tabs>
  );
}
