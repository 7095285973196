import DonorProfile from '../../model/DonorProfile.model';
import { Backdrop, Box, Modal, Fade, Button, Typography, Grid, useMediaQuery, createStyles } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { Link as RouterLink } from 'react-router-dom';

const images = {
  hero: require('../../assets/img/hedado-hero-image-xs.svg').default,
};

interface DonationConfirmationProps {
  title?: string;
  donor?: DonorProfile;
  donationSummary?: string;
  open: boolean;
  handleClose: () => void;
}

const useStyles: any = makeStyles(() =>
  createStyles({
    link: {
      color: 'black !important',
    },
  })
);

export default function DonationConfirmationModal(props: DonationConfirmationProps) {
  const isMinHeight = useMediaQuery('(min-height:900px)');

  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box
            sx={{
              position: 'relative',
              top: { xs: 0, md: isMinHeight ? '50%' : 0 },
              left: { xs: 0, md: isMinHeight ? '50%' : 0 },
              transform: { md: isMinHeight ? 'translate(-50%, -50%)' : 'none' },
              backgroundColor: 'secondary.light',
              width: { xs: '100%', md: isMinHeight ? '90vw' : '100%', lg: isMinHeight ? '80vw' : '100%' },
              borderRadius: { xs: 0, md: isMinHeight ? 8 : 0 },
              boxShadow: 24,
              minHeight: 'min(600px, 100%)',
              height: { xs: '100%', md: isMinHeight ? 'auto' : '100%' },
              p: { xs: 4, md: 12 },
              maxWidth: '900px',
              outline: 'none !important',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              '&::before': {
                overflowX: 'hidden',
                position: 'absolute',
                content: {
                  xs: `url(${images.hero})`,
                },
                width: {
                  xs: '160%',
                  md: '120%',
                },
                top: {
                  xs: 'inherit',
                  md: 0,
                },
                right: {
                  xs: 'inherit',
                },
                zIndex: 0,
              },
            }}
          >
            <Grid
              container
              gap={2}
              sx={{
                maxWidth: { xs: '60%', sm: '50%' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
              }}
            >
              <Grid item xs={12}>
                <Typography
                  id="transition-modal-title"
                  variant="h4"
                  component="h2"
                  sx={{ fontWeight: 'bold', zIndex: 1000 }}
                >
                  Thank you{props.donor?.firstName ? `, ${props.donor?.firstName}` : ''}!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  id="transition-modal-description"
                  variant="h6"
                  component="h3"
                  sx={{ zIndex: 1000, color: 'primary.main' }}
                >
                  You're making a difference.{' '}
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        `${props.donationSummary} and will be allocated to the organizations in your <strong>portfolio</strong>.` ||
                        '',
                    }}
                  ></p>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" size="large" onClick={props.handleClose} sx={{ zIndex: 1000 }}>
                  Done
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="body2" component="p" sx={{ zIndex: 1000 }}>
                  Go to your{' '}
                  <RouterLink className={classes.link} to="/summary">
                    summary
                  </RouterLink>{' '}
                  or{' '}
                  <RouterLink className={classes.link} to="/portfolio">
                    view your portfolio
                  </RouterLink>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
