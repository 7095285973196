import {
  getCampaignDonationMatchTotalsQuery,
  GetCampaignDonationMatchTotalsQueryResponse,
  GetCampaignDonationMatchTotalsQueryVariables,
} from 'gql/donationMatches/getCampaignDonationMatchTotals';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';

export function useCampaignDonationMatchTotals(campaignId: string) {
  const { data, error } = useAuthenticatedHedadoQuery<
    GetCampaignDonationMatchTotalsQueryResponse,
    GetCampaignDonationMatchTotalsQueryVariables
  >(getCampaignDonationMatchTotalsQuery, { variables: { campaignId } });

  if (!data)
    return {
      loading: true,
      error,
    };

  return { totals: data?.getCampaignDonationMatchTotals, loading: false, error };
}
