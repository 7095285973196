import type PaymentMethod from 'model/PaymentMethod.model';
import { gql } from '@apollo/client';

export interface GetCampaignPaymentMethodQueryVariables {
  campaignId: string;
}

export interface GetCampaignPaymentMethodQueryResult {
  getCampaignPaymentMethod?: PaymentMethod;
}

export const getCampaignPaymentMethod_Name = 'GetCampaignPaymentMethod';
export const getCampaignPaymentMethod_Gql = gql`
  query GetCampaignPaymentMethod($campaignId: String!) {
    getCampaignPaymentMethod(campaignId: $campaignId) {
      id
      donorId
      paymentProcessorCustomerId
      name
      last4
      deactivatedAt
      addressZipCode
      retryCount
      type
      institution
      paymentProcessorSourceId
    }
  }
`;
