import { Typography, Grid, Divider } from '@mui/material';
import PageTitle from '../components/global/PageTitle';

import { HEDADO_EMAIL } from '../utils/constants';

const ReceiptTracking = () => {

  return (
    <>
      <PageTitle title="Let Hedado organize your donation receipts" />

      <p>
        <Typography variant="body1">
          You find it difficult to remember where and how much you've given to charity. Hedado helps you sort through
          all your donation receipts. Our free service will show you how much you gave, what services you used, and
          benchmark your giving against others on the platform.
        </Typography>
      </p>

      <Typography variant="h5">How It Works</Typography>
      <br />
      <Grid container>
        <Grid item xs={4}>
          <img
            alt="sending donation"
            src="https://img1.wsimg.com/isteam/ip/65a8fc63-e0d6-48d6-b31d-709cd93c4751/undraw_Personal_email_re_4lx7.svg/:/cr=t:0%25,l:3.28%25,w:82.02%25,h:100%25/rs=w:300,h:300,cg:true"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6">Forward your donation receipts to {HEDADO_EMAIL}</Typography>
          <Typography variant="body1">Any receipt can be included, no matter the time period</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <img
            alt="start account and get report"
            src="https://img1.wsimg.com/isteam/ip/65a8fc63-e0d6-48d6-b31d-709cd93c4751/blob.png/:/cr=t:5.36%25,l:19.91%25,w:63.1%25,h:89.29%25/rs=w:300,h:300,cg:true,m"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6">We'll start your account after we receive your first receipt.</Typography>
          <Typography variant="body1">Your report shows you more than your donations</Typography>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Typography variant="h5">Your report shows you more than your donations</Typography>
      <br />
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h6">
            <p>See how your charities are rated.</p>
            <p>Benchmark your giving to activity on the Hedado platform.</p>
            <p>Understand which companies are intermediaries between you and your favorite nonprofits.</p>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <img
            alt="get your report"
            src="https://img1.wsimg.com/isteam/ip/65a8fc63-e0d6-48d6-b31d-709cd93c4751/undraw_reviews_lp8w.svg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:600,cg:true"
          />
        </Grid>
      </Grid>

      <Divider />
      <br />

      <Typography variant="h5">Get back $300 in tax savings</Typography>
      <br />
      <Grid container>
        <Grid item xs={4}>
          <img
            alt="tax savings"
            src="https://img1.wsimg.com/isteam/ip/65a8fc63-e0d6-48d6-b31d-709cd93c4751/undraw_pay_online_b1hk.svg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:600,cg:true"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6">
            <p>For the 2020 and 2021 tax years, you can get $300 back even if you don't itemize your deductions.</p>
            <p>
              Hedado will tell you which receipts qualify and how much your deduction could be. Your report is optimized
              for filing taxes.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Divider />
      <br />

      {/* <div className={classes.callToAction}> */}
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h5">More than $40K of receipts tracked to date. Start today!</Typography>
        <br />
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              <p>It's free!</p>
              <p>No credit card required</p>
              <p>Forward your first receipt to {HEDADO_EMAIL}</p>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ReceiptTracking;
