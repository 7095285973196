import { gql } from '@apollo/client';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';

export type DonationsOrderByAttribute =
  | 'amountCents'
  | 'createdAt'
  | 'organizationEin'
  | 'organizationName'
  | 'organizationTaxDeductibility';

export enum DonationsQueryMatchStatus {
  noMatch = 'noMatch',
  pending = 'pending',
  settled = 'settled',
}

export interface GetDonorDonationsQueryVariables {
  status?: DonationsQueryMatchStatus;
  endDate?: Date;
  orderByAttribute: DonationsOrderByAttribute;
  orderDirection: 'asc' | 'desc';
  organizationId?: string;
  page: number;
  rowsPerPage: number;
  startDate?: Date;
}

export interface GetDonorDonationsQueryResponse {
  getDonorDonations: {
    total: number;
    donations: {
      id: string;
      createdAt: Date;
      amountCents: number | null;
      organization?: {
        id: string;
        ein: string;
        name: string;
        deductibility?: number;
      };
      donationMatches: {
        id: string;
        status: DonationMatchStatus;
        matchedAmountInCents: number;
        campaign: {
          id: string;
          title: string;
          slug: string;
        };
      }[];
    }[];
  };
}

export const getDonorDonationsGql = gql`
  query GetDonorDonations(
    $matchStatus: MatchStatus
    $endDate: Date
    $orderByAttribute: DonationOrderByAttribute!
    $orderDirection: DonationOrderDirection!
    $organizationId: ID
    $page: Int!
    $rowsPerPage: Int!
    $startDate: Date
  ) {
    getDonorDonations(
      matchStatus: $matchStatus
      endDate: $endDate
      orderByAttribute: $orderByAttribute
      orderDirection: $orderDirection
      organizationId: $organizationId
      page: $page
      rowsPerPage: $rowsPerPage
      startDate: $startDate
    ) {
      total
      donations {
        id
        createdAt
        amountCents
        organization {
          id
          ein
          name
          deductibility
        }
        donationMatches {
          id
          status
          matchedAmountInCents
          campaign {
            id
            title
            slug
          }
        }
      }
    }
  }
`;
