import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useHedadoQuery from 'hooks/useHedadoQuery';
import Head from 'components/Head/Head';
import PageHeader from '../components/global/PageHeader';
import OrganizationList from '../components/organization/OrganizationList';
import Organization from '../model/Organization.model';
import { getOrganizationsByCategory_Gql } from '../gql/organizationQueries';
import { PAGE_TITLE_CAMPAIGNS, APP_TITLE } from '../utils/constants';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    routerLink: {
      color: theme.palette.text.primary,
    },
  })
);

const pageResults = () => {
  console.log('page results');
};

export default function BainLaCampaign() {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  // const { categoryId } = useParams<CampaignParams>();
  const categoryId = '5b0536c0-53b7-11ec-afd9-1f7c1246255e';

  const { loading, data } = useHedadoQuery(getOrganizationsByCategory_Gql, {
    variables: {
      categoryId: categoryId,
    },
  });

  useEffect(() => {
    if (loading || !data) return;

    setOrganizations(data.getOrganizationsByCategory);
  }, [data, loading]);

  return (
    <>
      <Head title={`${PAGE_TITLE_CAMPAIGNS} | ${APP_TITLE}`} />
      <Box
        sx={{
          ml: -3,
          mr: -3,
          backgroundImage:
            'url(https://www.bain.com/contentassets/51cb6e30db184db8a0afbea3b961c68d/local-office-images-la-1950x650.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '400px',
          position: 'relative',
        }}
      ></Box>
      <Box sx={{ position: 'relative', height: `80px` }}>
        <Box
          sx={{
            position: 'absolute',
            left: 36,
            bottom: 0,
            maxWidth: `180px`,
            border: '4px solid white',
            borderRadius: 8,
          }}
          component="img"
          src="https://hedado.s3.us-east-2.amazonaws.com/campaigns/bain_la/bain_la.png"
          alt="Bain Los Angeles logo"
        />
      </Box>
      <PageHeader icon={undefined} sx={{ ml: 2 }} title="Bain Los Angeles" subTitle="" />

      <Box sx={{ marginLeft: 6, marginBottom: 1, maxWidth: '800px' }}>
        <Typography variant="body1">
          Thank you for your pledge related to this year’s Holiday Auction! We have highlighted several charities below
          - please feel free to direct your pledge to one, multiple, or use the{' '}
          <RouterLink className={classes.routerLink} to="/browse-nonprofits">
            search function
          </RouterLink>{' '}
          to find any other charity you’d like to support. Please reach out to Andrew or Randy if you have any
          questions.
        </Typography>
      </Box>

      <OrganizationList organizations={organizations} onPageChange={pageResults} title="" subTitle="" />
    </>
  );
}
