import type { DonorPublicProfile } from 'model/Donor.model';
import { env } from 'env';
import { useState, useCallback, useMemo } from 'react';
import { uploadPresignedImage } from 'helpers/s3';

export interface PublicProfileFormImages {
  profileImage: string | null;
  bannerImage: string | null;
  bannerImageMobile: string | null;
}

export function usePublicProfileFormImages(publicProfile: DonorPublicProfile) {
  const [images, setImages] = useState({
    profileImage: publicProfile.profileImage,
    bannerImage: publicProfile.bannerImage,
    bannerImageMobile: publicProfile.bannerImageMobile,
  });

  const {
    src: profileSrc,
    setImage: setProfileImage,
    upload: uploadProfileImage,
    resetImage: resetProfileImage,
  } = useFormImage(images.profileImage, 'donors');
  const {
    src: bannerSrc,
    setImage: setBannerImage,
    upload: uploadBannerImage,
    resetImage: resetBannerImage,
  } = useFormImage(images.bannerImage, 'donors');
  const {
    src: bannerMobileSrc,
    setImage: setBannerImageMobile,
    upload: uploadBannerImageMobile,
    resetImage: resetBannerImageMobile,
  } = useFormImage(images.bannerImageMobile, 'donors');

  async function uploadImages() {
    const [profileImagePath, bannerImagePath, bannerImageMobilePath] = await Promise.all([
      uploadProfileImage(),
      uploadBannerImage(),
      uploadBannerImageMobile(),
    ]);

    return { profileImagePath, bannerImagePath, bannerImageMobilePath };
  }

  function resetImages({ profileImage, bannerImage, bannerImageMobile }: DonorPublicProfile) {
    setImages({
      profileImage: profileImage,
      bannerImage: bannerImage,
      bannerImageMobile: bannerImageMobile,
    });

    resetProfileImage(profileImage);
    resetBannerImage(bannerImage);
    resetBannerImageMobile(bannerImageMobile);
  }

  return {
    bannerMobileSrc,
    bannerSrc,
    profileSrc,
    resetImages,
    setBannerImage,
    setBannerImageMobile,
    setProfileImage,
    uploadImages,
  };
}

export function useFormImage(value: string | null, folderName: string) {
  const [image, setImage] = useState<Blob | null>(null);
  const [initialValue, setInitialValue] = useState(value);

  const src = useMemo(() => {
    if (image) return URL.createObjectURL(image);

    if (initialValue) return `${env.REACT_APP_S3_PATH}/${folderName}/${initialValue}`;

    return '';
  }, [image, initialValue, folderName]);

  const resetImage = useCallback((value: string | null) => {
    setImage(null);
    setInitialValue(value);
  }, []);

  const handleSetImage = useCallback((img: Blob | null) => {
    setInitialValue(null);
    setImage(img);
  }, []);

  async function upload() {
    let imagePath = initialValue || '';

    if (image) {
      imagePath = await uploadPresignedImage({
        file: image as File,
        fileName: '',
        folderName,
        crop: false,
        extension: 'png',
      });
    }

    return imagePath;
  }

  return { image, setImage: handleSetImage, src, upload, resetImage };
}
