import { IconButton } from '@mui/material';
type Social = 'linkedIn' | 'instagram' | 'tikTok' | 'twitter';

interface SocialMediaLinkProps {
  value: string;
  type: Social;
}

export default function SocialMediaLink({ value, type }: SocialMediaLinkProps) {
  const { link, imageSrc } = generateSocialFromType(value, type);

  return (
    <IconButton
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      sx={{
        display: 'inlineBlock',
        transition: '0.5s ease filter',
        filter: 'grayscale(100%)',
        '&:hover': { filter: 'grayscale(0%)' },
      }}
    >
      <img src={imageSrc} height="30" width="30" alt={`Link to donor ${type} account`} />
    </IconButton>
  );
}

function generateSocialFromType(value: string, type: Social) {
  switch (type) {
    case 'linkedIn':
      return { link: `https://www.linkedin.com/in/${value}`, imageSrc: '/linkedin.svg' };
    case 'instagram':
      return { link: `https://instagram.com/${value}/`, imageSrc: '/instagram.svg' };
    case 'tikTok':
      return { link: `https://www.tiktok.com/@${value}`, imageSrc: '/tiktok.svg' };
    case 'twitter':
      return { link: `https://twitter.com/${value}`, imageSrc: '/twitter.svg' };
  }
}
