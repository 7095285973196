import { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useRequestOrganizationChanges } from '../../hooks/useRequestOrganizationChanges';
import LoadingButton from 'components/LoadingButton';

interface RequestChangesProps {
  organizationId: string;
}

export default function RequestChanges({ organizationId }: RequestChangesProps) {
  const { hasRequestedOrganizationChanges, requestOrganizationChanges, isRequestingOrganizationChanges } =
    useRequestOrganizationChanges(organizationId);
  const [isRequestChangesTextareaOpen, setIsRequestChangesTextareaOpen] = useState(false);
  const [changeRequests, setChangeRequests] = useState('');

  function handleRequestChangesClick() {
    setIsRequestChangesTextareaOpen(true);
  }

  function handleCancelRequestChangesClick() {
    setIsRequestChangesTextareaOpen(false);
    setChangeRequests('');
  }

  function handleChangeRequestsChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setChangeRequests(event.target.value);
  }

  async function handleSubmitOrganizationChangeRequest() {
    await requestOrganizationChanges(changeRequests);
    setIsRequestChangesTextareaOpen(false);
    setChangeRequests('');
  }

  return hasRequestedOrganizationChanges ? (
    <small>
      Thank you! Our team has been notified of your request. Your support helps us to continually improve and ensures we
      present these nonprofits more accurately.
    </small>
  ) : isRequestChangesTextareaOpen ? (
    <Box sx={{ width: '100%' }}>
      <TextField
        value={changeRequests}
        onChange={handleChangeRequestsChanged}
        multiline
        size="small"
        minRows={3}
        fullWidth
        placeholder="(Optional) Let us know what needs to be updated on this nonprofit"
      />
      <Box sx={{ display: 'flex', gap: 1, mt: 1, justifyContent: 'flex-end' }}>
        <Button onClick={handleCancelRequestChangesClick} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          size="small"
          loading={isRequestingOrganizationChanges}
          onClick={handleSubmitOrganizationChangeRequest}
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  ) : (
    <>
      <small>Is this information and image accurate?</small>
      <br />
      <Button
        sx={{ mt: 1 }}
        onClick={handleRequestChangesClick}
        size="small"
        startIcon={<EditIcon />}
        variant="outlined"
      >
        Suggest an edit
      </Button>
    </>
  );
}
