import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import useDonorProfile from 'hooks/useDonorProfile';

export default function AuthRedirect() {
  const history = useHistory();
  const location = useLocation();
  const { state } = location as any;

  const [donorProfile, { error }] = useDonorProfile();

  if (error) history.push('/');

  if (donorProfile) {
    const pathname = state?.returnTo ?? '/';
    return <Redirect to={{ pathname, state }} />;
  }

  return <Skeleton height={400} sx={{ borderRadius: 5, bgcolor: 'secondary.light' }} />;
}
