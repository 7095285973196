import { env } from 'env';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import useAnalytics, { events } from 'hooks/useAnalytics';
import CauseAreaChip from '../CauseAreaChip';
import Organization from 'model/Organization.model';
import OrganizationAddedSnackbar from '../OrganizationAddedSnackbar';

import { deformatEin } from 'helpers/einHelper';
import { gql } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { getActiveAllocationsForDonorQuery_Name } from 'gql/allocation/getActiveAllocationsForDonorQuery';
import useHedadoMutation from 'hooks/useHedadoMutation';
import RequestChanges from './components/RequestChanges';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    drawerImage: {
      width: '100%',
      borderRadius: 6,
    },
  })
);

const addToPortfolioMutation = gql`
  mutation AddOrgToPortfolio($organizationId: ID!) {
    addOrgToPortfolio(input: { organizationId: $organizationId }) {
      success
      errors
    }
  }
`;

interface MoreInfoListItemProps {
  href?: string;
  text: string;
}

const MoreInfoListItem = ({ href, text }: MoreInfoListItemProps) => {
  return (
    <>
      {href && (
        <Button
          size="small"
          href={href}
          sx={{
            textTransform: 'none',
            textDecoration: 'none',
            ':hover': {
              textDecoration: 'underline',
            },
          }}
          target="_blank"
          startIcon={<ArrowForwardIcon />}
        >
          {text}
        </Button>
      )}
    </>
  );
};

interface MoreInfoProps {
  organization: Organization;
  currentlyInPortfolio: boolean;
  showButton: boolean;
}

const MoreInfo = ({ organization, currentlyInPortfolio = false, showButton = true }: MoreInfoProps) => {
  const classes = useStyles();
  const { trackEvent } = useAnalytics();

  // TODO: This is a copy of what's on OrganizationCard.tsx, this and all the gql code
  // should be refactored into a shared util
  const history = useHistory();
  const { isAuthenticated } = useAuth0();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [addToPortfolio] = useHedadoMutation(addToPortfolioMutation, {
    refetchQueries: [getActiveAllocationsForDonorQuery_Name],
  });

  const addOrganization = () => {
    if (isAuthenticated) {
      trackEvent(events.addOrganizationToPortfolio, { organizationId: organization.id });
      addToPortfolio({ variables: { organizationId: organization.id } });
      setShowConfirmation(true);
    } else {
      history.push('/login-required');
    }
  };

  return (
    <>
      <Typography variant="h5">{organization.name}</Typography>
      <p>
        <CauseAreaChip causeArea={organization.causeArea} />
      </p>
      <Divider />

      <p>
        <Grid container>
          <Grid item xs={1}>
            <LocationOnIcon />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              {organization.city && <span>{organization.city}</span>}
              {organization.city && organization.state && <span> | </span>}
              {organization.state && <span>{organization.state}</span>}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              textAlign: 'right',
            }}
          >
            <Typography variant="subtitle2">EIN: {organization.ein}</Typography>
          </Grid>
        </Grid>
      </p>

      {organization.imageName && (
        <p>
          <img
            src={`${env.REACT_APP_ORGANIZATION_IMAGE_PATH}${organization.imageName}`}
            alt={organization.name}
            className={classes.drawerImage}
          />
        </p>
      )}

      {(organization.irsClassification || organization.taxDeductible) && (
        <p>
          <Grid container>
            <Grid item xs={6}>
              {organization.irsClassification && <Typography variant="subtitle1">Education | Activity</Typography>}
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                textAlign: 'right',
              }}
            >
              <Typography variant="subtitle2">
                {organization.taxDeductible && <Chip label="Deductible" color="warning" />}
              </Typography>
            </Grid>
          </Grid>
        </p>
      )}

      <Divider />

      {organization.mission && (
        <>
          <p>
            <Typography variant="h5">Mission</Typography>
          </p>
          <p>
            <Typography variant="body1">{organization.mission}</Typography>
          </p>
        </>
      )}

      {organization.whyYouShouldCare && (
        <>
          <Typography variant="h5">Why you should care</Typography>
          <p>
            <Typography variant="body1">{organization.whyYouShouldCare}</Typography>
          </p>
        </>
      )}

      {organization.context && (
        <>
          <Typography variant="h5">Context</Typography>
          <p>
            <Typography variant="body1">{organization.context}</Typography>
          </p>
        </>
      )}

      {organization.impact && (
        <>
          <Typography variant="h5">Impact</Typography>
          <p>
            <Typography variant="body1">{organization.impact}</Typography>
          </p>
        </>
      )}

      <p>
        <Typography variant="h5" gutterBottom>
          More Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <MoreInfoListItem
              href={`${env.REACT_APP_CHARITY_NAVIGATOR_URL}${deformatEin(organization.ein)}`}
              text="Charity Navigator Profile"
            />
            <MoreInfoListItem href={organization.websiteUrl} text="Visit website" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MoreInfoListItem href={organization.annualReportUrl} text="Annual Report" />
            <MoreInfoListItem href={organization.financialsUrl} text="Financials" />
            <MoreInfoListItem href={organization.form990Url} text="Form 990 Tax Filing" />
          </Grid>
        </Grid>
      </p>

      {(organization.revenue || organization.assets) && (
        <>
          <p>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">Income</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">Assets</Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">$ {organization.revenue}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">$ {organization.assets}</Typography>
              </Grid>
            </Grid>
          </p>
        </>
      )}

      <Box sx={{ my: 3 }}>
        <RequestChanges organizationId={organization.id} />
      </Box>

      {isAuthenticated && showButton && (
        <Button
          size="large"
          variant="contained"
          onClick={() => (!currentlyInPortfolio ? addOrganization() : history.push('/portfolio'))}
          sx={{
            width: '100%',
            marginBottom: 2,
          }}
        >
          {!currentlyInPortfolio ? 'Add to Portfolio' : 'Manage in My Portfolio'}
        </Button>
      )}

      <OrganizationAddedSnackbar organization={organization} showSnackbar={showConfirmation} />
    </>
  );
};

export default MoreInfo;
