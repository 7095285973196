import { Box, Typography } from '@mui/material';
import { numberExists, getDollarString, getCommaSeparatedString } from 'helpers/numberHelpers';
import { useDonorDonationStatisticsQuery } from 'hooks/useDonorDonationStatisticsQuery';
import StatisticBox from 'components/StatisticBox';
import StatisticBoxSkeleton from 'components/StatisticBoxSkeleton';

export default function DonationStatistics() {
  const { statistics } = useDonorDonationStatisticsQuery();

  const donationTotalDollars = getDollarString(statistics?.donationsTotalCents);
  const settledMatchesTotalDollars = getDollarString(statistics?.settledMatchesTotalCents);
  const totalDonationsString = getCommaSeparatedString(statistics?.donationsTotal);
  const totalNonProfitsString = getCommaSeparatedString(statistics?.organizationsTotal);
  return (
    <>
      <Box
        sx={{
          alignItems: 'stretch',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          m: { xs: -1, sm: -2 },
          mt: { xs: 3, sm: 2 },
        }}
      >
        {numberExists(statistics?.donationsTotalCents) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {donationTotalDollars}
            </Typography>
            <Typography>Total Amount Donated</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}

        {numberExists(statistics?.settledMatchesTotalCents) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {settledMatchesTotalDollars}
            </Typography>
            <Typography>Total Amount Matched</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}

        {numberExists(statistics?.donationsTotal) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {totalDonationsString}
            </Typography>
            <Typography>No. of Donations</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}

        {numberExists(statistics?.organizationsTotal) ? (
          <StatisticBox>
            <Typography variant="h3" component="span">
              {totalNonProfitsString}
            </Typography>
            <Typography>No. of Nonprofits</Typography>
          </StatisticBox>
        ) : (
          <StatisticBoxSkeleton />
        )}
      </Box>
    </>
  );
}
