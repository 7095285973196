import { Box, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { sentenceCase } from 'helpers/formatHelpers';

interface BankAccountPreviewProps {
  bankName: string;
  last4: string;
}

export default function BankAccountPreview({ bankName, last4 }: BankAccountPreviewProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AccountBalanceIcon sx={{ mr: 1 }} />
      <Typography>
        <b>{sentenceCase(bankName)}</b> account ending in <b>{last4}</b>
      </Typography>
    </Box>
  );
}
