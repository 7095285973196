import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import useAddOrganizationToPortfolio from 'hooks/useAddOrganizationToPortfolio';

interface ContextStateType {
  organizationIdAddedAfterRedirect: string | null;
}

const defaultContextState: ContextStateType = {
  organizationIdAddedAfterRedirect: null,
};

const RedirectContext = createContext(defaultContextState);

export const RedirectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const redirectState = useRedirectContextState();
  return <RedirectContext.Provider value={redirectState}>{children}</RedirectContext.Provider>;
};

function useRedirectContextState(): ContextStateType {
  const { isAuthenticatedAndReady } = useStoredAuth0Values();
  const { addOrganizationToPortfolio } = useAddOrganizationToPortfolio();
  const history = useHistory();
  const location = useLocation();
  const [organizationIdAddedAfterRedirect, setOrganizationIdAddedAfterRedirect] = useState<string | null>(null);
  const { state } = location as any;

  const organizationIdToAdd = state?.organizationIdToAdd;
  const pathname = location.pathname;
  const shouldAdd = isAuthenticatedAndReady && !!organizationIdToAdd && !organizationIdAddedAfterRedirect;

  useEffect(() => {
    if (!shouldAdd) return;

    async function processOrganizationToAdd() {
      await addOrganizationToPortfolio({ variables: { organizationId: organizationIdToAdd } });
      setOrganizationIdAddedAfterRedirect(organizationIdToAdd);
    }
    delete state.organizationIdToAdd;
    history.replace(pathname, state);
    processOrganizationToAdd();
  }, [addOrganizationToPortfolio, history, organizationIdToAdd, pathname, shouldAdd, state]);

  return { organizationIdAddedAfterRedirect };
}

export function useRedirectContext() {
  return useContext(RedirectContext);
}

export function useOrganizationAddedAfterRedirect(id: string) {
  const { organizationIdAddedAfterRedirect } = useRedirectContext();

  return { added: organizationIdAddedAfterRedirect === id };
}
