import type { DonationsOrderByAttribute, DonationsQueryMatchStatus } from 'gql/donations/getDonorDonations';
import { useState, useMemo, useCallback } from 'react';

export const DEFAULT_SORT_ORDERS: { [key in DonationsOrderByAttribute]: 'asc' | 'desc' } = {
  createdAt: 'desc',
  amountCents: 'asc',
  organizationEin: 'asc',
  organizationName: 'asc',
  organizationTaxDeductibility: 'asc',
};

export type PageSize = 25 | 50 | 100;

export function useDonationsTableState() {
  // When specific attributes of the table state are mutated, a few things must happen in order to present a predictable and intuitive table UI.
  // 1. When filter criteria change, we need to go back to the first page of the results
  // This is why most of the state setters in this hook are preceded by an underscore.  The exported setters include these necessary side effects in addition to setting the specific state

  const [endDate, _setEndDate] = useState<Date>();
  const [orderByAttribute, setOrderByAttribute] = useState<DonationsOrderByAttribute>('createdAt');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('desc');
  const [organizationId, _setOrganizationId] = useState('');
  const [page, _setPage] = useState(0);
  const [rowsPerPage, _setRowsPerPage] = useState<PageSize>(25);
  const [startDate, _setStartDate] = useState<Date>();
  const [matchStatus, _setMatchStatus] = useState<DonationsQueryMatchStatus>();

  const setPage = useCallback((value: number) => {
    _setPage(value);
  }, []);

  const setStartDate = useCallback(
    (value?: Date) => {
      _setStartDate(value);
      setPage(0);
    },
    [setPage]
  );

  const setEndDate = useCallback(
    (value?: Date) => {
      _setEndDate(value);
      setPage(0);
    },
    [setPage]
  );

  const setOrganizationId = useCallback(
    (value: string) => {
      _setOrganizationId(value);
      setPage(0);
    },
    [setPage]
  );

  const setMatchStatus = useCallback(
    (value: DonationsQueryMatchStatus) => {
      _setMatchStatus(value);
      setPage(0);
    },
    [setPage]
  );

  const setRowsPerPage = useCallback(
    (value: PageSize) => {
      _setRowsPerPage(value);
      setPage(0);
    },
    [setPage]
  );

  const setOrderBy = useCallback(
    (key: DonationsOrderByAttribute) => {
      if (key === orderByAttribute) {
        setOrderDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
      } else {
        setOrderByAttribute(key);
        setOrderDirection(DEFAULT_SORT_ORDERS[key]);
      }
      setPage(0);
    },
    [orderByAttribute, setPage]
  );

  const clearFilters = useCallback(() => {
    _setEndDate(undefined);
    _setOrganizationId('');
    _setStartDate(undefined);
    _setMatchStatus(undefined);
    _setPage(0);
  }, []);

  const filters = useMemo(
    () => ({
      clearFilters,
      endDate,
      setEndDate,
      setOrganizationId,
      setStartDate,
      startDate,
      setMatchStatus,
      matchStatus,
    }),
    [clearFilters, endDate, setEndDate, setOrganizationId, setStartDate, setMatchStatus, startDate, matchStatus]
  );

  const pagination = useMemo(
    () => ({
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
    }),
    [page, rowsPerPage, setPage, setRowsPerPage]
  );

  const ordering = useMemo(
    () => ({
      orderByAttribute,
      orderDirection,
      setOrderBy,
    }),
    [orderByAttribute, orderDirection, setOrderBy]
  );

  const queryVariables = useMemo(() => {
    return {
      first: rowsPerPage,
      orderByAttribute,
      orderDirection,
      organizationId,
      page,
      rowsPerPage,
      matchStatus,
      ...(startDate ? { startDate } : null),
      ...(endDate ? { endDate } : null),
    };
  }, [endDate, orderByAttribute, orderDirection, organizationId, page, rowsPerPage, startDate, matchStatus]);

  return useMemo(
    () => ({
      filters,
      ordering,
      pagination,
      queryVariables,
    }),
    [filters, ordering, pagination, queryVariables]
  );
}

export type DonationsTableState = ReturnType<typeof useDonationsTableState>;

export interface TableColumn {
  disablePadding: boolean;
  label: string;
  id: DonationsOrderByAttribute | 'donationMatches';
  numeric: boolean;
  width: number;
}

export const tableColumns: TableColumn[] = [
  {
    disablePadding: true,
    label: 'Date',
    id: 'createdAt',
    numeric: false,
    width: 110,
  },
  {
    disablePadding: false,
    label: 'EIN',
    id: 'organizationEin',
    numeric: false,
    width: 125,
  },
  {
    disablePadding: false,
    label: 'Organization',
    id: 'organizationName',
    numeric: false,
    width: 210,
  },
  {
    disablePadding: false,
    label: 'Tax Deductibility',
    id: 'organizationTaxDeductibility',
    numeric: false,
    width: 175,
  },
  { disablePadding: false, label: 'Donation Amount', id: 'amountCents', numeric: true, width: 185 },
  { disablePadding: false, label: 'Match Amount', id: 'donationMatches', numeric: true, width: 175 },
];
