export function computeEmptyStates({
  showDonorOrganizations,
  showCampaignOrganizations,
  campaignOrganizations,
  donorOrganizations,
}: {
  showDonorOrganizations: boolean;
  showCampaignOrganizations: boolean;
  campaignOrganizations?: any[];
  donorOrganizations?: any[];
}): { isEmptyState: boolean; hasBothTypesToDisplay: boolean } {
  const isCampaignOrganizationsEmpty = !!campaignOrganizations && campaignOrganizations.length === 0;
  const isEmployeePicksEmpty = !!donorOrganizations && donorOrganizations.length === 0;

  const isEmptyState =
    (showCampaignOrganizations || showDonorOrganizations) &&
    ((showCampaignOrganizations && isCampaignOrganizationsEmpty && (!showDonorOrganizations || isEmployeePicksEmpty)) ||
      (showDonorOrganizations && isEmployeePicksEmpty && (!showCampaignOrganizations || isCampaignOrganizationsEmpty)));

  const hasBothTypesToDisplay =
    showCampaignOrganizations && showDonorOrganizations && !isCampaignOrganizationsEmpty && !isEmployeePicksEmpty;

  return {
    isEmptyState,
    hasBothTypesToDisplay,
  };
}
