import { useState, useEffect, useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

interface CropDialogProps {
  aspectRatio: number;
  file: File | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (imageData: PixelCrop, scale: number, image: HTMLImageElement) => void;
}

export default function CropDialog({ aspectRatio, file, isOpen, onClose, onSubmit }: CropDialogProps) {
  const [imgSrc, setImgSrc] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
    reader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    if (isOpen) return;

    const timeout = setTimeout(() => {
      setCompletedCrop(undefined);
      setCrop(undefined);
      setImgSrc('');
      setScale(1);
    }, 150);

    return () => clearTimeout(timeout);
  }, [isOpen]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspectRatio));
  }

  function handleZoomInClick() {
    setScale((prev) => prev + 0.1);
  }

  function handleZoomOutClick() {
    setScale((prev) => (prev === 0.1 ? prev : prev - 0.1));
  }

  if (!file) return <></>;

  function onSubmitClick() {
    if (!completedCrop || !imgRef.current) return;

    onSubmit(completedCrop, scale, imgRef.current);
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Photo</DialogTitle>
      <DialogContent>
        {Boolean(imgSrc) && (
          <ReactCrop
            aspect={aspectRatio}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img
              alt="Crop me"
              onLoad={onImageLoad}
              ref={imgRef}
              src={imgSrc}
              style={{ transform: `scale(${scale})` }}
            />
          </ReactCrop>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <IconButton onClick={handleZoomInClick}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={handleZoomOutClick} disabled={scale === 0.1}>
            <RemoveIcon />
          </IconButton>
        </Box>
        <Button onClick={onSubmitClick} variant="outlined">
          Done Cropping
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
