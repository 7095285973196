import type { PublicProfileFormState } from './usePublicProfileFormState';
import type { PublicProfileFormImages } from './usePublicProfileFormImages';
import { useState } from 'react';
import {
  updatePublicProfileGql,
  UpdatePublicProfileMutationResponse,
  UpdatePublicProfileMutationVariables,
} from 'gql/donor/updatePublicProfile';
import useHedadoMutation from 'hooks/useHedadoMutation';
import { getLoggedInDonorProfileQuery_Gql } from 'gql/donorProfileQuery';
import { getPublicProfileGql } from 'gql/donor/getPublicProfile';

export function useUpdatePublicProfile(donorId: string) {
  const [hasErrored, setHasErrored] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [updatePublicProfileMutation] = useHedadoMutation<
    UpdatePublicProfileMutationResponse,
    UpdatePublicProfileMutationVariables
  >(updatePublicProfileGql, {
    awaitRefetchQueries: true,
    refetchQueries: [getPublicProfileGql, getLoggedInDonorProfileQuery_Gql],
    onError: () => {
      setHasErrored(true);
    },
    onCompleted: () => {
      setHasUpdated(true);
    },
  });

  function clearUpdateStatus() {
    setHasErrored(false);
    setHasUpdated(false);
  }

  async function updatePublicProfile(formState: PublicProfileFormState, images: PublicProfileFormImages) {
    clearUpdateStatus();

    const input = {
      bannerImage: images.bannerImage || '',
      bannerImageMobile: images.bannerImageMobile || '',
      city: formState.city.value || '',
      colorTheme: formState.colorTheme.value || '',
      description: formState.description.value || '',
      displayName: formState.displayName.value || '',
      instagramHandle: formState.instagramHandle.value || '',
      isEnabled: formState.isEnabled.value,
      linkedInHandle: formState.linkedInHandle.value || '',
      organizations: formState.organizations.map((org) => ({ id: org.id, description: org.value })),
      profileImage: images.profileImage || '',
      slug: formState.slug.value || '',
      state: formState.state.value || '',
      tikTokHandle: formState.tikTokHandle.value || '',
      twitterHandle: formState.twitterHandle.value || '',
    };

    const mutationResult = await updatePublicProfileMutation({
      variables: {
        donorId,
        input,
      },
    });

    if (!mutationResult.data?.updateDonorPublicProfile?.publicProfile) {
      setHasErrored(true);
      return;
    }

    return mutationResult.data?.updateDonorPublicProfile?.publicProfile;
  }

  return { clearUpdateStatus, hasErrored, hasUpdated, updatePublicProfile };
}
