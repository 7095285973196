import { useState } from 'react';
import {
  checkIfCampaignExistsBySlug,
  CheckIfCampaignExistsBySlugQueryVariables,
  CheckIfCampaignExistsBySlugQueryResponse,
} from 'gql/campaigns/getCampaign';
import useHedadoQuery from 'hooks/useHedadoQuery';
import useDebounce from 'hooks/useDebounce';

export function useIsCampaignSlugTaken(campaignId?: string) {
  const debounce = useDebounce();
  const [searchSlug, setSearchSlug] = useState('');
  const { data: campaignDataForSlug } = useHedadoQuery<
    CheckIfCampaignExistsBySlugQueryResponse,
    CheckIfCampaignExistsBySlugQueryVariables
  >(checkIfCampaignExistsBySlug, {
    variables: {
      slug: searchSlug,
    },
    suppressErrors: true,
    fetchPolicy: 'network-only',
  });

  function checkIfSlugIsTaken(slug: string) {
    debounce(() => {
      setSearchSlug(slug);
    }, 350);
  }

  const isSlugTaken =
    !!searchSlug &&
    !!campaignDataForSlug?.getCampaign?.id &&
    (!campaignId || campaignDataForSlug.getCampaign.id !== campaignId);

  return {
    isSlugTaken,
    checkIfSlugIsTaken,
  };
}
