import { gql } from '@apollo/client';

export interface ProcessCampaignMatchContributionMutationArgs {
  campaignId: string;
}

export interface ProcessCampaignMatchContributionMutationResult {
  processCampaignMatchContribution: {
    id: string;
  };
}

export const processCampaignMatchContribution_Gql = gql`
  mutation ProcessCampaignMatchContribution($campaignId: String!) {
    processCampaignMatchContribution(campaignId: $campaignId) {
      id
    }
  }
`;
