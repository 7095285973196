import type Organization from 'model/Organization.model';
import type { DonorPublicProfile } from 'model/Donor.model';
import type DonorProfile from 'model/DonorProfile.model';
import { useState, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Checkbox,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { STATES } from 'staticData/states';
import { hasAnyVisibleError, hasAnyError } from 'helpers/formHelpers';
import { PROFILE_COLORS, getProfileColor } from 'staticData/profileColors';
import { usePublicProfileFormImages } from './hooks/usePublicProfileFormImages';
import { useUpdatePublicProfile } from './hooks/useUpdatePublicProfile';
import { usePublicProfileHasUnsavedChanges } from './hooks/usePublicProfileHasUnsavedChanges';
import {
  usePublicProfileFormState,
  PublicProfileFormState,
  PublicProfileFormStateKey,
} from './hooks/usePublicProfileFormState';
import CroppedImageInput from 'components/CroppedImageInput/CroppedImageInput';
import LeavePageBlocker from 'components/LeavePageBlocker';
import LoadingButton from 'components/LoadingButton';
import PreviewDialog from './components/PreviewDialog';
import StatusSnackbar from './components/StatusSnackbar';

export interface PublicProfileFormProps {
  donor: DonorProfile;
  publicProfile: DonorPublicProfile;
  organizations: Organization[];
}

export default function PublicProfileForm({ donor, publicProfile, organizations }: PublicProfileFormProps) {
  const detailsRef = useRef<HTMLDivElement>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const { clearUpdateStatus, hasErrored, hasUpdated, updatePublicProfile } = useUpdatePublicProfile(donor.id);
  const {
    profileSrc,
    bannerSrc,
    bannerMobileSrc,
    uploadImages,
    resetImages,
    setBannerImage,
    setBannerImageMobile,
    setProfileImage,
  } = usePublicProfileFormImages(publicProfile);
  const {
    blurFormState,
    formState,
    handleInputBlur,
    handleInputChange,
    handleIsEnabledChange,
    handleOrganizationDescriptionInputChange,
    handleSelectChange,
    handleSlugInputChange,
    isSlugTaken,
    resetFormState,
  } = usePublicProfileFormState(donor, publicProfile);
  const { hasUnsavedChanges } = usePublicProfileHasUnsavedChanges({
    publicProfile,
    formState,
    bannerSrc,
    bannerMobileSrc,
    profileSrc,
  });

  function handlePreviewChangesClick() {
    setIsPreviewDialogOpen(true);
  }

  function closePreviewDialog() {
    setIsPreviewDialogOpen(false);
  }

  async function handleSaveClick() {
    const errors = computePublicProfileFormErrors(formState, isSlugTaken);

    if (hasAnyError(errors)) {
      blurFormState();
      scrollToSection(detailsRef);
      return;
    }

    setIsUpdating(true);

    const { profileImagePath, bannerImagePath, bannerImageMobilePath } = await uploadImages();

    const updatedPublicProfile = await updatePublicProfile(formState, {
      profileImage: profileImagePath,
      bannerImage: bannerImagePath,
      bannerImageMobile: bannerImageMobilePath,
    });

    setIsUpdating(false);

    if (updatedPublicProfile) {
      resetFormState(updatedPublicProfile);
      resetImages(updatedPublicProfile);
    }
  }

  function scrollToSection(sectionRef: React.RefObject<HTMLDivElement>) {
    if (!sectionRef.current) return;

    window.scroll(0, window.scrollY + sectionRef.current.getBoundingClientRect().top - 40);
  }

  const errors = computePublicProfileFormErrors(formState, isSlugTaken);
  const isFormDisabled = !formState.isEnabled.value;
  const isSubmitDisabled = hasAnyVisibleError(errors, formState);

  return (
    <>
      <LeavePageBlocker shouldBlock={hasUnsavedChanges} message={'Discard unsaved changes?'} />
      <Grid container spacing={3} sx={{ position: 'relative' }}>
        <Grid item xs={12} ref={detailsRef}>
          <Typography variant="h5">Your Details</Typography>
          <Typography variant="subtitle1">Tell the world about who you are and why you give</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={formState.isEnabled.value} name="isEnabled" onChange={handleIsEnabledChange} />}
            label="Share a public profile of my giving?"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={formState.slug.hasBlurredAtLeastOnce && !!errors.slug}
            name={PublicProfileFormStateKey.slug}
            required
            helperText={(formState.slug.hasBlurredAtLeastOnce && errors.slug) || 'Your unique giving URL'}
            onChange={handleSlugInputChange}
            onBlur={handleInputBlur}
            fullWidth
            label="URL"
            disabled={isFormDisabled}
            value={formState.slug.value}
            InputProps={{
              startAdornment: <InputAdornment position="start">https://hedado.com/d/</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={formState.displayName.hasBlurredAtLeastOnce && !!errors.displayName}
            name={PublicProfileFormStateKey.displayName}
            label="Display Name"
            value={formState.displayName.value}
            helperText={
              (formState.displayName.hasBlurredAtLeastOnce && errors.displayName) ||
              'How your name will appear in your public profile'
            }
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            error={formState.tikTokHandle.hasBlurredAtLeastOnce && !!errors.tikTokHandle}
            name={PublicProfileFormStateKey.tikTokHandle}
            label="TikTok"
            value={formState.tikTokHandle.value}
            helperText={formState.tikTokHandle.hasBlurredAtLeastOnce && errors.tikTokHandle}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">@</InputAdornment>,
            }}
            disabled={isFormDisabled}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            error={formState.instagramHandle.hasBlurredAtLeastOnce && !!errors.instagramHandle}
            name={PublicProfileFormStateKey.instagramHandle}
            label="Instagram"
            value={formState.instagramHandle.value}
            helperText={formState.instagramHandle.hasBlurredAtLeastOnce && errors.instagramHandle}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">@</InputAdornment>,
            }}
            disabled={isFormDisabled}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            error={formState.twitterHandle.hasBlurredAtLeastOnce && !!errors.twitterHandle}
            name={PublicProfileFormStateKey.twitterHandle}
            label="Twitter"
            value={formState.twitterHandle.value}
            helperText={formState.twitterHandle.hasBlurredAtLeastOnce && errors.twitterHandle}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">@</InputAdornment>,
            }}
            disabled={isFormDisabled}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={formState.linkedInHandle.hasBlurredAtLeastOnce && !!errors.linkedInHandle}
            name={PublicProfileFormStateKey.linkedInHandle}
            helperText={formState.linkedInHandle.hasBlurredAtLeastOnce && errors.linkedInHandle}
            onChange={handleSlugInputChange}
            onBlur={handleInputBlur}
            fullWidth
            label="LinkedIn"
            disabled={isFormDisabled}
            value={formState.linkedInHandle.value}
            InputProps={{
              startAdornment: <InputAdornment position="start">linkedin.com/in/</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isFormDisabled}
            error={formState.city.hasBlurredAtLeastOnce && !!errors.city}
            fullWidth
            helperText={formState.city.hasBlurredAtLeastOnce && errors.city}
            label="City"
            name={PublicProfileFormStateKey.city}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            value={formState.city.value}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="state-select-label" htmlFor="state">
              State
            </InputLabel>
            <Select
              disabled={isFormDisabled}
              fullWidth
              id="state-select"
              label="State"
              labelId="state-select-label"
              name="state"
              onBlur={handleInputBlur}
              onChange={handleSelectChange}
              value={formState.state.value}
            >
              <MenuItem key="--" value="" sx={{ opacity: 0.5 }}>
                Prefer not to say
              </MenuItem>
              <Divider />
              {STATES.map((state) => (
                <MenuItem key={state.abbreviation} value={state.fullName}>
                  {state.fullName}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem key="XX" value="Outside the U.S.">
                Outside the U.S.
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            error={formState.city.hasBlurredAtLeastOnce && !!errors.description}
            name={PublicProfileFormStateKey.description}
            label="About You"
            minRows={3}
            fullWidth
            value={formState.description.value}
            helperText={formState.description.hasBlurredAtLeastOnce && errors.description}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            disabled={isFormDisabled}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Profile Picture
          </Typography>
          <CroppedImageInput
            src={profileSrc}
            aspectRatio={1}
            maxSize={500_000}
            isDisabled={isFormDisabled}
            previewImageSx={{ width: '100%', maxWidth: '250px', borderRadius: '8px' }}
            onChanged={setProfileImage}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Background Image (desktop)
          </Typography>
          <CroppedImageInput
            src={bannerSrc}
            aspectRatio={6}
            maxSize={1_000_000}
            isDisabled={isFormDisabled}
            previewImageSx={{ width: '100%', borderRadius: '8px' }}
            onChanged={setBannerImage}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Background Image (mobile)
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 1, fontSize: '0.75rem' }}>
            For a seamless mobile experience, we use a differently sized image for mobile devices.
            <br />
            For best results, use the same image provided above.
          </Typography>
          <CroppedImageInput
            src={bannerMobileSrc}
            aspectRatio={3}
            maxSize={400_000}
            isDisabled={isFormDisabled}
            previewImageSx={{ width: '100%', borderRadius: '8px' }}
            onChanged={setBannerImageMobile}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="color-theme-select-label">Color theme</InputLabel>
            <Select
              labelId="color-theme-select-label"
              id="color-theme-select"
              value={getProfileColor(formState.colorTheme.value || '').hex}
              label="Color theme"
              name="colorTheme"
              onChange={handleSelectChange}
              disabled={isFormDisabled}
            >
              {PROFILE_COLORS.map((color) => (
                <MenuItem key={color.hex} value={color.hex} sx={{ display: 'flex', justifyContent: 'min-content' }}>
                  <Box
                    sx={{
                      width: '1.4rem',
                      height: '1.4rem',
                      borderRadius: '4px',
                      backgroundColor: color?.hex,
                      mr: 1.4,
                      float: 'left',
                    }}
                  />
                  {color.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Your Nonprofits</Typography>
          <Typography variant="subtitle1">
            Tell the world about the nonprofits in{' '}
            <Link component={RouterLink} to="/portfolio">
              your portfolio
            </Link>{' '}
            and why you chose them specifically
          </Typography>
        </Grid>

        {(donor.allocations || []).map(({ organization }) => (
          <Grid item xs={12} key={`organization_${organization.id}`}>
            <TextField
              multiline
              label={organization.name}
              fullWidth
              disabled={isFormDisabled}
              minRows={3}
              name={organization.id}
              onChange={handleOrganizationDescriptionInputChange}
              value={(formState.organizations || []).find((org) => org.id === organization.id)?.value || ''}
            />
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          sx={{ textAlign: 'right', position: 'sticky', bottom: '12px', maxWidth: '602px', zIndex: 5 }}
        >
          <Button
            size="large"
            variant="outlined"
            onClick={handlePreviewChangesClick}
            sx={{
              width: { xs: '100%', sm: 'auto' },
              backgroundColor: 'white',
              boxShadow:
                '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);',
            }}
            disabled={!formState.isEnabled.value}
          >
            Preview Changes
          </Button>

          <LoadingButton
            size="large"
            variant="contained"
            sx={{
              ml: { xs: 0, sm: 2 },
              mt: { xs: 2, sm: 0 },
              width: { xs: '100%', sm: 'auto' },
              boxShadow:
                '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);',
            }}
            disabled={isSubmitDisabled}
            onClick={handleSaveClick}
            loading={isUpdating}
          >
            Save Changes
          </LoadingButton>
        </Grid>
      </Grid>
      <PreviewDialog
        formState={formState}
        isOpen={isPreviewDialogOpen}
        onClose={closePreviewDialog}
        organizations={organizations}
        images={{
          bannerMobileSrc,
          bannerSrc,
          profileSrc,
        }}
      />
      <StatusSnackbar
        hasErrored={hasErrored}
        hasUpdated={hasUpdated}
        onClose={clearUpdateStatus}
        slug={publicProfile.slug || formState.slug.value}
      />
    </>
  );
}

type PublicProfileErrors = {
  [key in PublicProfileFormStateKey]?: string | null;
};

function computePublicProfileFormErrors(formState: PublicProfileFormState, isSlugTaken: boolean): PublicProfileErrors {
  if (!formState.isEnabled.value) return {};

  return {
    slug: !formState.slug.value ? 'This field is required' : isSlugTaken ? 'This username is already taken' : '',
    linkedInHandle:
      formState.isEnabled.value &&
      formState.linkedInHandle.value &&
      !/^[\w\-.]{2,100}$/.test(formState.linkedInHandle.value)
        ? 'Please enter a valid LinkedIn handle (max 100 characters)'
        : '',
    twitterHandle:
      formState.isEnabled.value &&
      formState.twitterHandle.value &&
      !/^[\w\-.]{1,15}$/.test(formState.twitterHandle.value)
        ? 'Please enter a valid Twitter handle (max 15 characters)'
        : '',
    instagramHandle:
      formState.isEnabled.value &&
      formState.instagramHandle.value &&
      !/^[\w\-.]{1,30}$/.test(formState.instagramHandle.value)
        ? 'Please enter a valid Instagram handle (max 30 characters)'
        : '',
    tikTokHandle:
      formState.isEnabled.value && formState.tikTokHandle.value && !/^[\w\-.]{1,24}$/.test(formState.tikTokHandle.value)
        ? 'Please enter a valid TikTok handle (max 24 characters)'
        : '',
  };
}
