import { useState, useEffect } from 'react';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { env } from '../../env';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import { getActiveAllocationsForDonorQuery_Gql } from '../../gql/allocation/getActiveAllocationsForDonorQuery';
import { OrganizationSearchResult } from '../../model/OrganizationSearchResult';
import SearchResultCard from './SearchResultCard';
import Allocation from '../../model/Allocation';
import { Grid } from '@mui/material';
import { DEFAULT_ORG_IMAGES } from '../../utils/constants';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';

const useStyles: any = makeStyles(() =>
  createStyles({
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: 'max-content',
    },
  })
);

interface OrganizationSearchResultsProps {
  searchResults: OrganizationSearchResult[] | undefined;
}

const OrganizationSearchResults = ({ searchResults }: OrganizationSearchResultsProps) => {
  const classes = useStyles();
  const { isAuthenticatedAndReady } = useStoredAuth0Values();

  const [results, setResults] = useState<OrganizationSearchResult[] | undefined>();
  const [currentOrganizations, setCurrentOrganizations] = useState<Allocation[]>([]);

  const { loading, data } = useAuthenticatedHedadoQuery(getActiveAllocationsForDonorQuery_Gql);

  useEffect(() => {
    if (loading || !data || !isAuthenticatedAndReady) return;

    setCurrentOrganizations(data.getActiveAllocationsForDonor);
  }, [currentOrganizations, data, isAuthenticatedAndReady, loading]);

  useEffect(() => {
    setResults(searchResults);
  }, [searchResults]);

  const orgIsInPortfolio = (organizationId: string): boolean => {
    if (!currentOrganizations) return false;

    const org = currentOrganizations.find((x) => x.organizationId === organizationId);
    return org !== undefined;
  };

  const getDefaultImage = (index: number): string => {
    return `${env.REACT_APP_ORGANIZATION_DEFAULT_IMAGE_PATH}${DEFAULT_ORG_IMAGES[index]}`;
  };

  return (
    <Grid container className={classes.flexContainer} spacing={2}>
      {results !== undefined &&
        results.length > 0 &&
        results.map((organization: OrganizationSearchResult, index) => (
          <SearchResultCard
            key={organization.ein}
            organization={organization}
            currentlyInPortfolio={orgIsInPortfolio(organization.ein)}
            defaultImage={getDefaultImage(index)}
          />
        ))}
    </Grid>
  );
};

export default OrganizationSearchResults;
