import { gql } from '@apollo/client';

export const updateAllocationDesignationMutation_Name = 'UpdateAllocationDesignation';
export const updateAllocationDesignationMutation_Gql = gql`
  mutation UpdateAllocationDesignation($allocation: UpdateAllocationDesignationInput!) {
    updateAllocationDesignation(allocation: $allocation) {
      id
    }
  }
`;
