export const formatEin = (ein: string): string => {
  if (ein === undefined || ein === null) return '';

  if (ein.indexOf('-') >= 0) return ein;

  const prefix = ein.substring(0, 2);
  const suffix = ein.substring(2);

  return `${prefix}-${suffix}`;
};

export const deformatEin = (ein: string): string => {
  if (ein === undefined || ein === null) return '';

  return ein.replace('-', '');
};
