import { gql } from '@apollo/client';

export interface GetPublicDonorOrganizationsQueryResponse {
  getPublicDonorOrganizations: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor?: string;
    };
    edges: {
      cursor: string;
      node: any;
    }[];
  };
}

export interface GetPublicDonorOrganizationsQueryVariables {
  slug: string;
  first?: number;
  after?: string;
}

export const getPublicDonorOrganizationsGql = gql`
  query GetPublicDonorOrganizations($slug: String!, $first: Int, $after: String) {
    getPublicDonorOrganizations(slug: $slug, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          ein
          name
          causeArea
          mission
          context
          impact
          whyYouShouldCare
          websiteUrl
          annualReportUrl
          financialsUrl
          form990Url
          orgSize
          imageName
          revenue
          assets
          street
          city
          state
          zip
          classification
          deductibility
        }
      }
    }
  }
`;
