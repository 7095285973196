import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Alert, AlertTitle, Link, Typography, Grid } from '@mui/material';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import Organization from '../../model/Organization.model';
import { getActiveAllocationsForDonorQuery_Gql } from '../../gql/allocation/getActiveAllocationsForDonorQuery';
import Allocation from '../../model/Allocation';
import OrganizationGrid from '../global/OrganizationGrid';
import useOnboarding from '../../hooks/useOnboarding';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import UnauthCTABanner from '../global/UnauthCTABanner';

const useStyles: any = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly !important',
    },
    displayOrgs: {
      display: 'inline-flex',
      flexBasis: 'fit-content !important',
      marginRight: 10,
      paddingBottom: 35,
    },
  })
);

interface OrganizationListProps {
  organizations: Organization[];
  onPageChange?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  subTitle?: string;
  noOrgsTitle?: string;
  noOrgsText?: string;
}

const OrganizationList = ({
  organizations,
  onPageChange,
  title,
  subTitle,
  noOrgsTitle = '',
  noOrgsText = '',
}: OrganizationListProps) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const { isAuthenticatedAndReady } = useStoredAuth0Values();
  const [onboarding, setOnboarding] = useOnboarding();
  const { loading, data } = useAuthenticatedHedadoQuery(getActiveAllocationsForDonorQuery_Gql);

  const ctaBannerOpen = !isAuthenticated;
  const currentDonorAllocations: Allocation[] = data?.getActiveAllocationsForDonor ?? [];

  useEffect(() => {
    if (!isAuthenticatedAndReady) return;

    onboarding.portfolioTodo = onboarding.portfolioTodo || {};
    onboarding.portfolioTodo.didBrowseNonProfits = true;
    setOnboarding(onboarding);
    // FIXME:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isAuthenticatedAndReady, loading]);

  if (!organizations || organizations.length === 0) {
    return (
      <Alert severity="warning">
        <AlertTitle>{noOrgsTitle.length === 0 ? 'No organizations found' : noOrgsTitle}</AlertTitle>

        {noOrgsText.length > 0 && noOrgsText}

        {noOrgsText.length === 0 && (
          <>
            Please refine your search and try again. If an organization is missing, please contact{' '}
            <Link href="mailto:support@hedado.com?subject=Missing Organization">support@hedado.com</Link>.
          </>
        )}
      </Alert>
    );
  }

  return (
    <>
      <Grid container className={classes.container} gap={2}>
        <Grid item xs={12}>
          {title && <Typography variant="h6">{title}</Typography>}
          {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <OrganizationGrid
            organizationList={organizations}
            currentOrganizations={currentDonorAllocations}
            showAdd={true}
          />
        </Grid>
      </Grid>
      <UnauthCTABanner open={ctaBannerOpen} />
    </>
  );
};

export default OrganizationList;
