import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';

export type MaskedCurrencyInputProps = TextFieldProps & {
  value: number;
  onValueChange: (value: number, name: string) => void;
};

export default function MaskedCurrencyInput({ value, onValueChange, ...restMuiProps }: MaskedCurrencyInputProps) {
  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const strippedValue = event.currentTarget.value.replace(/[^0-9/]/g, '');
    onValueChange(parseInt(strippedValue) || 0, restMuiProps.name || '');
  }

  const displayValue = (value / 100).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return <TextField {...restMuiProps} value={displayValue} onChange={onInputChange} />;
}
