import slugify from 'slugify';
import Organization from 'model/Organization.model';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OrganizationList from 'components/organization/OrganizationList';

interface CampaignOrganizationsProps {
  title: string;
  organizations: Organization[];
  inAccordion: boolean;
}

export default function CampaignOrganizations({ title, organizations, inAccordion }: CampaignOrganizationsProps) {
  if (organizations.length === 0) return <></>;

  if (!inAccordion) {
    return (
      <Box sx={{ mx: { sx: 0, sm: 4 } }}>
        <OrganizationList organizations={organizations} title="" subTitle="" />
      </Box>
    );
  }

  return (
    <Accordion elevation={0} defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={slugify(title, { lower: true })}
        id={slugify(title, { lower: true })}
        sx={{ mx: { xs: 4, sm: 0 } }}
      >
        <Typography variant="h6" component="h3">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <OrganizationList organizations={organizations} title="" subTitle="" />
      </AccordionDetails>
    </Accordion>
  );
}
