import type { DonationsTableState } from './useDonationsTableState';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import {
  getDonorDonationsGql,
  GetDonorDonationsQueryVariables,
  GetDonorDonationsQueryResponse,
} from 'gql/donations/getDonorDonations';

export function useDonorDonationsQuery(variables: DonationsTableState['queryVariables']) {
  const { data, loading, waiting } = useAuthenticatedHedadoQuery<
    GetDonorDonationsQueryResponse,
    GetDonorDonationsQueryVariables
  >(getDonorDonationsGql, { variables });

  return {
    isLoading: loading || waiting,
    donations: data?.getDonorDonations,
  };
}

export type DonationResponse = ReturnType<typeof useDonorDonationsQuery>['donations'];
export type Donation = NonNullable<DonationResponse>['donations'][number];
