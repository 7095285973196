import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import {
  getCampaignsWhereDonorIsAdmin_Gql,
  GetCampaignsWhereDonorIsAdminQueryResponse,
} from 'gql/campaigns/getCampaignsWhereDonorIsAdmin';
import { getCampaignGql, GetCampaignQueryResponse, GetCampaignQueryVariables } from 'gql/campaigns/getCampaign';

export function useAdminCampaign(campaignId: string): {
  isCampaignAdmin: boolean | undefined;
  campaign: GetCampaignsWhereDonorIsAdminQueryResponse['getCampaignsWhereDonorIsAdmin'][number] | undefined;
} {
  const { isReady, isAdmin } = useStoredAuth0Values();

  const {
    data: getCampaignsWhereDonorIsAdminResponse,
    loading: isCampaignAdminsQueryLoading,
    waiting: isCampaignAdminsQueryWaiting,
  } = useAuthenticatedHedadoQuery<GetCampaignsWhereDonorIsAdminQueryResponse>(getCampaignsWhereDonorIsAdmin_Gql, {
    // We don't need to query the campaigns where the current donor is an admin if the stored auth0 values indicate the current user is a SUPER admin
    // In this case, we can just fetch the campaign data directly
    skip: isAdmin,
  });

  const { data: getCampaignResponse } = useAuthenticatedHedadoQuery<
    GetCampaignQueryResponse,
    GetCampaignQueryVariables
  >(getCampaignGql, {
    variables: { id: campaignId },
    // We don't want to query for the campaign directly if the stored auth0 values indicate the current user is NOT a SUPER admin
    // In this case, we rely on the result of `getCampaignsWhereDonorIsAdmin`
    skip: !isAdmin,
  });

  if (isAdmin)
    return {
      isCampaignAdmin: true,
      campaign: getCampaignResponse?.getCampaign,
    };

  const campaign = getCampaignFromResponse(campaignId, getCampaignsWhereDonorIsAdminResponse);

  if (!isReady || isCampaignAdminsQueryLoading || isCampaignAdminsQueryWaiting)
    return { isCampaignAdmin: undefined, campaign };

  const isCampaignAdmin = !!campaign;
  return { isCampaignAdmin, campaign };
}

function getCampaignFromResponse(campaignId: string, response: GetCampaignsWhereDonorIsAdminQueryResponse | undefined) {
  if (!response) return undefined;
  return response.getCampaignsWhereDonorIsAdmin.find((c) => c.id === campaignId);
}
