import { Chip } from '@mui/material';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatCauseArea } from '../../helpers/causeAreaHelpers';

interface CauseAreaChipProps {
  causeArea?: string | undefined;
  [x: string]: any;
  colorSet?: number
}

const CauseAreaChip = ({ causeArea, colorSet, ...props }: CauseAreaChipProps) => {
  const formattedCauseArea: string | undefined = formatCauseArea(causeArea);

  const theme = useTheme();

  const colorSets = [
    {
      backgroundColor: alpha(theme.palette.secondary.main, 0.4),
      borderColor: 'secondary.main',
    },
    {
      backgroundColor: alpha('#4FAFF8', 0.4),
      borderColor: '#4FAFF8',
    },
    {
      backgroundColor: alpha('#F84FB4', 0.4),
      borderColor: '#F84FB4',
    }
  ];

  const colorSetIndex = !colorSet || colorSet > colorSets.length ? 0 : colorSet;
  
  return (
    <>
      {formattedCauseArea && (
        <Chip
          sx={{
            borderStyle: 'solid',
            borderWidth: '2px',
            mb: 1,
            mr: 1,
            maxWidth: '100%',
            ...colorSets[colorSetIndex],
          }}
          label={formattedCauseArea}
          {...props}
        />
      )}
    </>
  );
};

export default CauseAreaChip;
