import React from 'react';
import { Box, Typography, Checkbox, FormControlLabel, Button, Link, useMediaQuery, useTheme,} from '@mui/material';

import ChevronRight from '@mui/icons-material/ChevronRight';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';

const checkboxContainer: any = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 1,
  flexBasis: 0,
  borderRadius: 6,
  ' > span': {
    fontSize: '1.1rem !important',
    fontWeight: 500,
    marginBottom: 1,
  },
  p: 3,
};

interface OnboardingGuideCardActionProps {
  label?: string;
  onClick?: () => void;
}

interface OnboardingGuideCardProps {
  icon: React.ReactNode;
  title: string;
  label: string | React.ReactNode;
  visible?: boolean;
  completed?: boolean;
  backgroundColor: string;
  textColor: string;
  checkboxName?: string;
  state?: any;
  onChangeEvent?: (e: React.BaseSyntheticEvent) => void;
  checkboxStyle?: 'interactive' | 'visual';
  actionButton?: OnboardingGuideCardActionProps;
  doneButton?: OnboardingGuideCardActionProps;
  dismissable?: boolean;
  onDismiss?: () => void;
}

const OnboardingGuideCard = (props: OnboardingGuideCardProps) => {

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box sx={{ ...checkboxContainer, backgroundColor: props.backgroundColor, color: props.textColor }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: props.checkboxStyle === 'interactive' ? 'space-between' : 'flex-start',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box gap={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', mb: 2 }}>
          <Box sx={{ mixBlendMode: 'soft-light' }}>{props.icon}</Box>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 500,
              fontSize: {
                xs: '1.1rem',
                sm: '1.2rem',
              },
            }}
          >
            {props.title}
          </Typography>
          {props.checkboxStyle === 'visual' ? (
            <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
              {props.completed === true ? (
                <CheckCircle
                  sx={{
                    color: props.textColor,
                    fontSize: '2rem',
                  }}
                />
              ) : (
                <CircleOutlined sx={{ mixBlendMode: 'hard-light', fontSize: '2rem' }} />
              )}
            </Box>
          ) : null}
        </Box>
        {props.checkboxStyle === 'interactive' ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={props.state || false}
                sx={{
                  color: props.textColor,
                  '&.Mui-checked': {
                    color: props.textColor,
                  },
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                }}
              />
            }
            label={props.label}
            name={props.checkboxName}
            labelPlacement={isXsScreen ? 'top' : 'end'}
            onChange={props.onChangeEvent}
            sx={{ m: 0 }}
          />
        ) : typeof props.label === 'string' ? (
          <Typography sx={{ fontSize: { xs: '0.9rem', sm: 'unset' } }}>{props.label}</Typography>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'flex-start',
              '> *': { fontSize: { xs: '0.9rem !important', sm: 'unset' } },
            }}
          >
            {props.label}
          </Box>
        )}
        {props.actionButton !== undefined ? (
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end' }}>
            {props.completed !== true ? (
              <Button
                sx={{ mt: 2 }}
                onClick={props.actionButton?.onClick}
                variant="light"
                size="small"
                endIcon={<ChevronRight sx={{ mb: 0 }} />}
              >
                {props.actionButton?.label}
              </Button>
            ) : (
              <Button
                sx={{ mt: 2,  }}
                onClick={props.doneButton?.onClick}
                variant="light"
                size="small"
                endIcon={<ChevronRight sx={{ mb: 0 }} />}
              >
                {props.doneButton?.label}
              </Button>
            )}
          </Box>
        ) : null}

        {props.dismissable === true && props.completed !== true ? (
          <Box>
            <Link
              sx={{
                display: 'block',
                mt: 2,
                fontSize: '0.8rem',
                opacity: 0.5,
                color: props.textColor,
                textDecorationColor: props.textColor,
                textAlign: 'right',
                cursor: 'pointer',
              }}
              onClick={props.onDismiss}
            >
              Don't remind me about this.
            </Link>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default OnboardingGuideCard;
