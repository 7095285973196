import { gql } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Card, Grid, CardActions, CardContent, Typography, Button, Chip } from '@mui/material';
import useHedadoMutation from 'hooks/useHedadoMutation';
import useAnalytics, { events } from 'hooks/useAnalytics';
import { OrganizationSearchResult } from '../../model/OrganizationSearchResult';
import { formatEin } from '../../helpers/einHelper';
import { getActiveAllocationsForDonorQuery_Name } from '../../gql/allocation/getActiveAllocationsForDonorQuery';

const useStyles: any = makeStyles(() =>
  createStyles({
    title: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    img: {
      maxWidth: '100%',
    },
  })
);

const addSearchedOrganizationToPortfolioMutation = gql`
  mutation AddSearchOrganizationToPortfolio($ein: String) {
    addOrgToPortfolio(input: { ein: $ein }) {
      success
      errors
    }
  }
`;

interface SearchResultCardProps {
  organization: OrganizationSearchResult;
  currentlyInPortfolio?: boolean;
  defaultImage: string;
}

const SearchResultCard = ({ organization, defaultImage, currentlyInPortfolio = false }: SearchResultCardProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const { isAuthenticated } = useAuth0();

  const [addSearchOrganizationToPortfolio, { client }] = useHedadoMutation(addSearchedOrganizationToPortfolioMutation, {
    refetchQueries: [getActiveAllocationsForDonorQuery_Name],
  });

  const addSearchedOrganizationToPortfolio = async (ein: string) => {
    const formattedEin = formatEin(ein);
    trackEvent(events.addOrganizationToPortfolioFromSearch, {
      ein: formattedEin,
    });
    await addSearchOrganizationToPortfolio({ variables: { ein: formattedEin } });
    client.resetStore();
    history.push('/portfolio');
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} sx={{ display: 'flex' }}>
      <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <CardContent>
          <div>
            <div className={classes.title}>
              <img className={classes.img} alt="logo" src={organization.coverImageUrl || defaultImage} />
            </div>
            <div className={classes.title}>
              <div>
                <Typography variant="h6">{organization.name}</Typography>
              </div>
            </div>
          </div>

          <Typography variant="body2" color="text.secondary">
            EIN: {formatEin(organization.ein)}
            <p>{organization.description}</p>
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexGrow: 1 }}>
          {currentlyInPortfolio && <Chip label="Currently in Portfolio" sx={{ marginLeft: 2 }} color="secondary" />}
          {isAuthenticated && !currentlyInPortfolio && (
            <Button
              onClick={() => addSearchedOrganizationToPortfolio(organization.ein)}
              sx={{ width: '100%' }}
              size="large"
              variant="contained"
            >
              Add to Portfolio
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SearchResultCard;
