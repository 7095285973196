import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { env } from 'env';
import { getAccessTokenFromStorage } from 'utils/storage';

export function useDownloadCampaignExport() {
  const [hasErrored, setHasErrored] = useState(false);

  const downloadCampaignExport = useCallback(async (campaignId: string, campaignSlug?: string) => {
    try {
      const exportData = await getCampaignExportData(campaignId);
      const fileName = `${campaignSlug || campaignId}-${dayjs().format('YY-MM-DD')}.xlsx`;
      fileDownload(exportData, fileName);
    } catch (error) {
      setHasErrored(true);
    }
  }, []);

  return { downloadCampaignExport, hasErrored, setHasErrored };
}

type ExportResponseData = string | ArrayBuffer | ArrayBufferView | Blob;

async function getCampaignExportData(campaignId: string) {
  const token = getAccessTokenFromStorage();
  const authorizationHeader = token ? `Bearer ${token}` : '';
  const response = await axios.get<ExportResponseData>(
    `${env.REACT_APP_NODE_URL}/campaignDonationExport?campaignId=${campaignId}`,
    {
      responseType: 'blob',
      headers: {
        Authorization: authorizationHeader,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    }
  );

  return response.data;
}
