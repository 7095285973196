import { useState } from 'react';
import { Tooltip, Button, Snackbar, Alert } from '@mui/material';
import { getPaymentMethodQuery_Name } from 'gql/profileQueries';
import { getLoggedInDonorProfileQuery_Name } from 'gql/donorProfileQuery';
import { getCampaignPaymentMethod_Name } from 'gql/paymentMethod/getCampaignPaymentMethod';
import { deactivatePaymentMethod_Gql } from 'gql/paymentMethod/deactivatePaymentMethod';
import useHedadoMutation from 'hooks/useHedadoMutation';
import useAnalytics, { events } from 'hooks/useAnalytics';
import LoadingButton from 'components/LoadingButton';

export default function RemovePaymentMethod({ campaignId, disabled }: { campaignId?: string; disabled?: boolean }) {
  const { trackEvent } = useAnalytics();
  const [deactivatePaymentMethodErrored, setDeactivatePaymentMethodErrored] = useState(false);
  const [deactivatePaymentMethod, { loading: deactivatePaymentMethodLoading }] = useHedadoMutation(
    deactivatePaymentMethod_Gql,
    {
      variables: {
        campaignId,
      },
      refetchQueries: [getPaymentMethodQuery_Name, getLoggedInDonorProfileQuery_Name, getCampaignPaymentMethod_Name],
      onError: () => setDeactivatePaymentMethodErrored(true),
    }
  );

  async function handleRemovePaymentMethodClick() {
    trackEvent(events.removePaymentMethod);
    await deactivatePaymentMethod();
  }

  if (disabled) return <DisabledRemoveButton />;

  return (
    <>
      <LoadingButton
        color="error"
        loading={deactivatePaymentMethodLoading}
        onClick={handleRemovePaymentMethodClick}
        variant="outlined"
      >
        Remove
      </LoadingButton>

      <Snackbar
        autoHideDuration={8000}
        onClose={() => setDeactivatePaymentMethodErrored(false)}
        open={deactivatePaymentMethodErrored}
      >
        <Alert elevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>
          Sorry, there was an error removing your card
        </Alert>
      </Snackbar>
    </>
  );
}

function DisabledRemoveButton() {
  return (
    <Tooltip title="Before removing your card, please cancel your current donation plan below first. If you just want to change your card, please add a new card and your current card will be removed.">
      <Button variant="outlined" color="inherit" sx={{ opacity: 0.2, cursor: 'default' }}>
        Remove
      </Button>
    </Tooltip>
  );
}
