import { createStyles, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles(() =>
  createStyles({
    title: {
      textTransform: 'uppercase',
      textAlign: 'center',
      paddingBottom: 30,
    },
  })
);

interface PageTitleProps {
  title: string;
}

const PageTitle = ({ title }: PageTitleProps) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.title} color="textSecondary">
      {title}
    </Typography>
  );
};

export default PageTitle;
