import type { PageSize } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import { TableRow, TableBody, TablePagination } from '@mui/material';

interface DonationMatchesTableFooterProps {
  page: number;
  rowsPerPage: number;
  setPage: (value: number) => void;
  setRowsPerPage: (value: PageSize) => void;
  total: number;
}

const ROW_PER_PAGE_OPTIONS = [25, 50, 100];

export default function DonationMatchesTableFooter({
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  total,
}: DonationMatchesTableFooterProps) {
  function handlePageChange(_: any, value: number) {
    setPage(value);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newSize = parseInt(event.target.value);

    if (ROW_PER_PAGE_OPTIONS.includes(newSize)) {
      setRowsPerPage(newSize as PageSize);
    }
  }

  return (
    <TableBody>
      <TableRow>
        <TablePagination
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          page={page}
        />
      </TableRow>
    </TableBody>
  );
}
