import type { DonationMatchesTableState } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import { useState, useCallback } from 'react';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import {
  processCampaignMatchContribution_Gql,
  ProcessCampaignMatchContributionMutationArgs,
  ProcessCampaignMatchContributionMutationResult,
} from 'gql/contributions/processCampaignMatchContribution';
import { getCampaignDonationMatchesQuery } from 'gql/donationMatches/getCampaignDonationMatches';
import useHedadoMutation from 'hooks/useHedadoMutation';

export function useProcessCampaignMatch(campaignId: string) {
  const [hasErrored, setHasErrored] = useState(false);
  const [hasMatchedSuccessfully, setHasMatchedSuccessfully] = useState(false);

  const [processCampaignMatchMutation] = useHedadoMutation<
    ProcessCampaignMatchContributionMutationResult,
    ProcessCampaignMatchContributionMutationArgs
  >(processCampaignMatchContribution_Gql, {
    onError: () => setHasErrored(true),
    onCompleted: () => setHasMatchedSuccessfully(true),
  });

  async function processCampaignMatch(refetchQueryVariables: DonationMatchesTableState['queryVariables']) {
    const updateDonationMatchesResult = await processCampaignMatchMutation({
      variables: {
        campaignId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        'GetCampaignAdminStatistics',
        'GetCampaignDonationMatchTotals',
        {
          query: getCampaignDonationMatchesQuery,
          variables: { ...refetchQueryVariables, statuses: [DonationMatchStatus.pending] },
        },
        {
          query: getCampaignDonationMatchesQuery,
          variables: { ...refetchQueryVariables, statuses: [DonationMatchStatus.deferred] },
        },
        {
          query: getCampaignDonationMatchesQuery,
          variables: { ...refetchQueryVariables, statuses: [DonationMatchStatus.excluded] },
        },
        {
          query: getCampaignDonationMatchesQuery,
          variables: {
            ...refetchQueryVariables,
            statuses: [DonationMatchStatus.settled, DonationMatchStatus.processing],
          },
        },
      ],
    });

    return updateDonationMatchesResult;
  }

  const clearStatus = useCallback(() => {
    setHasMatchedSuccessfully(false);
    setHasErrored(false);
  }, []);

  return { processCampaignMatch, hasErrored, hasMatchedSuccessfully, clearStatus };
}
