import { useParams, Redirect } from 'react-router-dom';
import {
  getCampaignWithOrganizationsGql,
  GetCampaignWithOrganizationsQueryVariables,
  GetCampaignWithOrganizationQueryResponse,
} from 'gql/campaigns/getCampaign';
import useHedadoQuery from 'hooks/useHedadoQuery';
import CampaignLandingPage from 'components/campaign/CampaignLandingPage/CampaignLandingPage';

export default function PublicCampaign() {
  const { slug } = useParams<{ slug: string }>();
  const { loading, data } = useHedadoQuery<
    GetCampaignWithOrganizationQueryResponse,
    GetCampaignWithOrganizationsQueryVariables
  >(getCampaignWithOrganizationsGql, {
    variables: {
      slug,
      firstOrganizations: 50,
    },
    suppressErrors: true,
  });

  if (loading) return <></>;

  if (!data || !data.getCampaign) {
    return <Redirect to="/" />;
  }

  const campaign = data.getCampaign;

  return <CampaignLandingPage campaign={campaign} />;
}
