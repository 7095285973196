// Monthly - will start on the 15th and process monthly on the 15th
// Quarterly - will start on the 15th and process every three months on the 15th
// Annual - will start on the 15th and process every year
// One Time - will process today

const next15th = (currentDate: Date): Date => {
  // prevent mutation of original date
  let copy = new Date(currentDate.getTime());
  if (copy.getDate() >= 15) {
    // not in the same month
    copy.setMonth(copy.getMonth() + 1);
  }
  copy.setDate(15);
  // Set to 12pm to combat most timezone issues
  copy.setHours(12, 0, 0);
  return copy;
};

export const firstDonationDate = (frequency: 'monthly' | 'quarterly' | 'annually' | 'once'): Date => {
  const currentDate = new Date();

  switch (frequency) {
    case 'monthly':
      return next15th(currentDate);
    case 'quarterly':
      return getNextQuarter(currentDate);
    case 'annually':
      return new Date();
    default:
      return new Date();
  }
};

export const secondDonationDate = (frequency: 'monthly' | 'quarterly' | 'annually' | 'once'): Date => {
  const first = firstDonationDate(frequency);

  switch (frequency) {
    case 'monthly':
      return next15th(first);
    case 'quarterly':
      return getNextQuarter(first);
    case 'annually':
      return new Date(new Date().setFullYear(first.getFullYear() + 1));
    default:
      return new Date();
  }
};

export const donationDateString = (frequency: string = '') => {
  switch (frequency) {
    case 'monthly':
      return '15th of each month';
    case 'quarterly':
      return '1st of each quarter (i.e. Jan 1st, Apr 1st, Jul 1st, and Oct 1st)';
    case 'annually':
      return 'anniversary of your last scheduled donation';
    default:
      return 'next business day';
  }
};

export const getScheduledDonationText = (frequency: string): string => {
  const dateToProcess = 15;

  if (frequency === 'monthly') return getNextMonthlyProcessDate(dateToProcess);
  if (frequency === 'once') return getOneTimeProcessDate();

  return '';
};

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

const getNextMonthlyProcessDate = (dateToProcess: number): string => {
  const date = new Date();
  const currentDay = date.getDate();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();

  let nextMonthToProcess = currentDay >= dateToProcess ? currentMonth + 1 : currentMonth;
  if (nextMonthToProcess === 13) nextMonthToProcess = 1;

  let nextYearToProcess = currentMonth < 12 ? currentYear : currentYear + 1;
  let nextTransactionMonthName = getMonthName(nextMonthToProcess);

  return finalDateFormat(nextTransactionMonthName, dateToProcess, nextYearToProcess);
};

const getOneTimeProcessDate = () => {
  return 'today';
};

const getMonthName = (month: number): string => {
  if (month === 1) return 'January';
  if (month === 2) return 'February';
  if (month === 3) return 'March';
  if (month === 4) return 'April';
  if (month === 5) return 'May';
  if (month === 6) return 'June';
  if (month === 7) return 'July';
  if (month === 8) return 'August';
  if (month === 9) return 'September';
  if (month === 10) return 'October';
  if (month === 11) return 'November';
  if (month === 12) return 'December';

  return '';
};

const finalDateFormat = (monthName: string, day: number, year: number): string => {
  return `${monthName} ${day}, ${year}`;
};

const getNextQuarter = (comparisonDate: Date): Date => {
  const nextProcessDate = new Date();
  const quarterOfComparisonDate = Math.floor((comparisonDate.getMonth() + 3) / 3);
  const nextQuarter = quarterOfComparisonDate === 4 ? 1 : quarterOfComparisonDate + 1;

  let nextMonthToProcess = 0;
  let nextYearToProcess = comparisonDate.getFullYear();

  if (nextQuarter === 2) nextMonthToProcess = 3;
  if (nextQuarter === 3) nextMonthToProcess = 6;
  if (nextQuarter === 4) nextMonthToProcess = 9;
  if (nextQuarter === 1) nextMonthToProcess = 0;

  if (nextMonthToProcess === 0) nextYearToProcess += 1;

  nextProcessDate.setMonth(nextMonthToProcess);
  nextProcessDate.setDate(1);
  nextProcessDate.setFullYear(nextYearToProcess);

  return nextProcessDate;
};

// Calculators, should probably move to a separate helper file
export const toDollars = (amountCents?: number): number => {
  if (!amountCents || amountCents < 0) return 0;
  return amountCents / 100;
};

export const toCents = (amountDollars?: number): number => {
  if (!amountDollars || amountDollars < 0) return 0;
  return amountDollars * 100;
};

interface ToCurrencyStringOptions {
  hideSign?: boolean;
  hideCents?: boolean;
}

export const toCurrencyString = (
  amount: number | undefined,
  { hideSign, hideCents }: ToCurrencyStringOptions = { hideSign: false, hideCents: false }
): string => {
  if (typeof amount === 'undefined') return '';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hideCents ? 0 : 2,
    maximumFractionDigits: hideCents ? 0 : 2,
  });

  const formatted = formatter.format(amount);

  return hideSign ? formatted.replace('$', '') : formatted;
};

export const tipCentsFromPercent = (donationAmount: number, tipDisplayAmount: number) => {
  return (donationAmount * Number(tipDisplayAmount ?? 0)) / 100;
};
