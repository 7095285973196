import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import {
  getCampaignAdminStatisticsGql,
  GetCampaignAdminStatisticsQueryResponse,
  GetCampaignAdminStatisticsQueryVariables,
} from 'gql/campaigns/getCampaignStatistics';

export function useCampaignStatistics(campaignId: string) {
  const { data, loading, waiting } = useAuthenticatedHedadoQuery<
    GetCampaignAdminStatisticsQueryResponse,
    GetCampaignAdminStatisticsQueryVariables
  >(getCampaignAdminStatisticsGql, { variables: { id: campaignId }, skip: false });

  return { statistics: data?.getCampaignAdminStatistics || null, loading, waiting };
}
