import type { CampaignFormProps } from '../CampaignForm';
import { useMemo } from 'react';
import { env } from 'env';
import { hasBlurredAnyInput } from 'helpers/formHelpers';
import { DetailsFormState } from './useDetailsFormState';
import { DonationsFormState } from './useDonationsFormState';
import { MatchingFormState } from './useMatchingFormState';
import { MembersFormState } from './useMembersFormState';
import { NonProfitsFormState } from './useNonProfitsFormState';

interface UseCampaignFormHasUnsavedChangesArgs {
  admin: CampaignFormProps['admin'];
  bannerMobileSrc: string;
  bannerSrc: string;
  campaign: CampaignFormProps['campaign'];
  details: DetailsFormState;
  domains: CampaignFormProps['domains'];
  donations: DonationsFormState;
  donors: CampaignFormProps['donors'];
  logoSrc: string;
  matching: MatchingFormState;
  members: MembersFormState;
  nonProfits: NonProfitsFormState;
  selectedAdminEmails: string[];
  selectedDomains: string[];
  selectedDonorEmails: string[];
  selectedOrganizations: { id: string }[];
}

export function useCampaignFormHasUnsavedChanges({
  admin,
  bannerMobileSrc,
  bannerSrc,
  campaign,
  details,
  domains,
  donations,
  donors,
  logoSrc,
  matching,
  members,
  nonProfits,
  selectedAdminEmails,
  selectedDomains,
  selectedDonorEmails,
  selectedOrganizations,
}: UseCampaignFormHasUnsavedChangesArgs) {
  const hasUnsavedChanges = useMemo(
    () =>
      computeHasUnsavedChanges({
        admin,
        campaign,
        bannerMobileSrc,
        bannerSrc,
        domains,
        donors,
        formStates: {
          ...details,
          ...donations,
          ...members,
          ...matching,
          ...nonProfits,
        },
        logoSrc,
        selectedAdminEmails,
        selectedDomains,
        selectedDonorEmails,
        selectedOrganizations,
      }),
    [
      admin,
      bannerMobileSrc,
      bannerSrc,
      campaign,
      details,
      domains,
      donations,
      donors,
      logoSrc,
      matching,
      members,
      nonProfits,
      selectedAdminEmails,
      selectedDomains,
      selectedDonorEmails,
      selectedOrganizations,
    ]
  );

  return { hasUnsavedChanges };
}

interface ComputeHasUnsavedChangesArgs {
  admin: CampaignFormProps['admin'];
  bannerMobileSrc: string;
  bannerSrc: string;
  campaign: CampaignFormProps['campaign'];
  formStates: DetailsFormState & DonationsFormState & MatchingFormState & MembersFormState & NonProfitsFormState;
  domains: CampaignFormProps['domains'];
  donors: CampaignFormProps['donors'];
  logoSrc: string;
  selectedAdminEmails: string[];
  selectedDomains: string[];
  selectedDonorEmails: string[];
  selectedOrganizations: { id: string }[];
}

function computeHasUnsavedChanges({
  admin,
  bannerMobileSrc,
  bannerSrc,
  campaign,
  domains,
  donors,
  formStates,
  logoSrc,
  selectedAdminEmails,
  selectedDomains,
  selectedDonorEmails,
  selectedOrganizations,
}: ComputeHasUnsavedChangesArgs) {
  return (
    hasBlurredAnyInput(formStates) ||
    haveSelectedAdminChanged(admin, selectedAdminEmails) ||
    haveSelectedDomainsChanged(domains, selectedDomains) ||
    haveSelectedDonorsChanged(donors, selectedDonorEmails) ||
    haveSelectedOrganizationsChanged(campaign, selectedOrganizations) ||
    hasImageChanged(bannerMobileSrc, campaign?.displayData?.bannerImageMobile) ||
    hasImageChanged(bannerSrc, campaign?.displayData?.bannerImage) ||
    hasImageChanged(logoSrc, campaign?.displayData?.logoImage)
  );
}

function hasImageChanged(formImage: string, imgPath: string) {
  if (!formImage && !imgPath) return false;

  return formImage === `${env.REACT_APP_S3_PATH}/campaigns/${imgPath}`;
}

function haveSelectedAdminChanged(admin: CampaignFormProps['admin'], selectedAdminEmails: string[]) {
  return !(
    (admin || []).length === selectedAdminEmails.length &&
    (admin || []).every((admin) => selectedAdminEmails.includes(admin.donor.email))
  );
}

function haveSelectedDomainsChanged(domains: CampaignFormProps['domains'], selectedDomains: string[]) {
  return !(
    (domains || []).length === selectedDomains.length &&
    (domains || []).every((domain) => selectedDomains.includes(domain.domainName))
  );
}

function haveSelectedDonorsChanged(donors: CampaignFormProps['donors'], selectedDonorEmails: string[]) {
  return !(
    (donors || []).length === selectedDonorEmails.length &&
    (donors || []).every((donor) => selectedDonorEmails.includes(donor.donor.email))
  );
}

function haveSelectedOrganizationsChanged(
  campaign: CampaignFormProps['campaign'],
  selectedOrganizations: { id: string }[]
) {
  const organizationsIds = (campaign?.categoryOrganizations.edges || []).map((edge) => edge.node.organization.id);
  return !(
    organizationsIds.length === selectedOrganizations.length &&
    selectedOrganizations.every((selectedOrg) => organizationsIds.includes(selectedOrg.id))
  );
}
