import { useState, useEffect } from 'react';
import { getLoggedInDonorProfileQuery_Name } from '../gql/donorProfileQuery';
import { UpdateDonorMetadataMutation_Gql } from '../gql/updateUserMetadataMutation';
import useHedadoMutation from 'hooks/useHedadoMutation';

const useUpdateDonorMetadata = () => {
  const [donor, setDonor] = useState();

  const [updateDonorMetadata, { loading, data }] = useHedadoMutation(UpdateDonorMetadataMutation_Gql, {
    refetchQueries: [getLoggedInDonorProfileQuery_Name],
  });

  useEffect(() => {
    if (loading || !data) return;

    setDonor(data.updateDonorMetadata);
  }, [loading, data]);

  const handlers = {
    updateDonorMetadata: async (metadata: string) => {
      await updateDonorMetadata({
        variables: {
          metadata: metadata,
        },
      });
    },
    error: () => {
      setDonor(undefined);
    },
    //TODO: Create a return object to hold error and data, use it instead of donor
  };

  return [donor, handlers] as const;
};

export default useUpdateDonorMetadata;
