import { gql } from '@apollo/client';

export interface GetActiveAllocationsForDonorQueryResponse {
  getActiveAllocationsForDonor: {
    anonymous: boolean;
    createdAt: Date;
    deactivatedAt: Date;
    designation: string;
    id: string;
    percentage: number;
    portfolioId: string;
    updatedAt: Date;
    organizationId: string;
    organization: {
      affiliation?: number;
      annualReportUrl?: string;
      assetAmt?: number;
      assetCd?: number;
      assets?: number;
      causeArea?: string;
      charityNavigatorUrl?: string;
      city?: string;
      classification?: number;
      context?: string;
      deactivatedAt: Date;
      deductibility?: number;
      ein: string;
      financialsUrl?: string;
      form990Url?: string;
      foundation?: number;
      globalImpact: boolean;
      group?: number;
      guidestarUrl?: string;
      id: string;
      ico?: string;
      imageName?: string;
      immediateImpact: boolean;
      impact?: string;
      incomeAmt?: number;
      incomeCd?: number;
      localImpact: boolean;
      longTermImpact: boolean;
      mission?: string;
      name: string;
      nteeCd?: string;
      orgSize?: string;
      programRestriction?: string;
      revenue?: number;
      revenueAmt?: number;
      routingOrganizationName?: string;
      ruling?: number;
      sortName?: string;
      state?: string;
      street?: string;
      subsection?: number;
      suggestedByDonorId: string;
      taxPeriod?: number;
      websiteUrl?: string;
      whyYouShouldCare?: string;
      zip?: string;
    };
  }[];
}

export const getActiveAllocationsForDonorQuery_Name = 'GetActiveAllocationsForDonor';
export const getActiveAllocationsForDonorQuery_Gql = gql`
  query ${getActiveAllocationsForDonorQuery_Name} {
    getActiveAllocationsForDonor {
      id
      organizationId
      percentage
      portfolioId
      designation
		  anonymous
      organization {
        id
        ein
        name
        causeArea
        mission
        context
        impact
        whyYouShouldCare
        websiteUrl
        annualReportUrl
        financialsUrl
        form990Url
        orgSize
        imageName
        revenue
        assets
        street
        city
        state
        zip
        classification
        deductibility
      }
    }
  }
`;
