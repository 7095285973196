import { ChangeEvent, SyntheticEvent, useState } from 'react';
import {
  FormLabel,
  Snackbar,
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Stack,
  Grid,
  Typography,
} from '@mui/material';
import ImageInput from 'components/ImageInput';
import useStyles from './OrganizationForm.styles';
import { OrganizationFormProps } from './OrganizationForm.interfaces';
import {
  transformFormToInputData,
  textareaFields,
  urlFields,
  addressFields,
  textFields,
  checkboxFields,
  numberFields,
} from './OrganizationForm.helpers';

export default function OrganizationForm({ handleSubmit, isSaving, initialState }: OrganizationFormProps) {
  const [isEinRequired, setIsEinRequired] = useState(!initialState);
  const [isParentEinRequired, setIsParentEinRequired] = useState(!initialState);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const classNames = useStyles();
  const [isProcessingInput, setIsProcessingInput] = useState(false);
  const [hasSizeError, setHasSizeError] = useState(false);
  const isFormDisabled = hasSizeError || isSaving || isProcessingInput;

  function onEinInputChange(event: ChangeEvent<HTMLInputElement>) {
    setIsParentEinRequired(!event.currentTarget.value);
  }

  function onParentEinChange(event: ChangeEvent<HTMLInputElement>) {
    setIsEinRequired(!event.currentTarget.value);
  }

  function onImageChange(event: ChangeEvent<HTMLInputElement>) {
    if (
      event.currentTarget &&
      event.currentTarget.files &&
      event.currentTarget.files.length &&
      event.currentTarget.files[0].size &&
      event.currentTarget.files[0].size > 1000000
    ) {
      setHasSizeError(true);
      setErrorMessage('Logo image too large (max 1mb)');
    } else {
      setHasSizeError(false);
    }
  }

  async function onSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsProcessingInput(true);
    try {
      const input = transformFormToInputData(event.currentTarget);

      await handleSubmit(input);
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.message);
      setIsProcessingInput(false);
    }
  }

  function handleSnackbarClose() {
    setErrorMessage('');
  }

  const initialEin = initialState?.ein ? (initialState.ein === initialState.parentEin ? '' : initialState.ein) : '';

  return (
    <form onSubmit={onSubmit} id="test-form">
      <Grid container spacing={3}>
        {initialState?.id && <input type="hidden" name="id" defaultValue={initialState.id} />}

        <Grid item xs={12} md={4}>
          <Stack>
            <TextField
              className={classNames.input}
              name="ein"
              label="EIN"
              defaultValue={initialEin}
              required={isEinRequired}
              onChange={onEinInputChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack>
            <TextField
              className={classNames.input}
              name="parentEin"
              label="Parent EIN"
              defaultValue={initialState?.parentEin || ''}
              required={isParentEinRequired}
              onChange={onParentEinChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack>
            <TextField
              className={classNames.input}
              required
              name="name"
              defaultValue={initialState?.name || ''}
              label="Name"
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack gap={2}>
            <FormLabel>Image</FormLabel>
            <ImageInput
              defaultValue={initialState?.imageName || ''}
              name="imageName"
              s3Directory="organizations"
              onChange={onImageChange}
              crop={true}
              aspectRatio={1.5}
            />
            <Typography variant="caption" display="flex" color="GrayText" mb={1}>
              (For best results, upload a wide image with a 3:2 aspect ratio)
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            {addressFields.map(({ name, label }) => (
              <Grid item xs={12} md={3} key={`address-field-${name}`}>
                <Stack>
                  <TextField
                    className={classNames.input}
                    name={name}
                    defaultValue={(initialState || {})[name] || ''}
                    label={label}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            {textareaFields.map(({ name, label }) => (
              <Grid key={`textarea-field-${name}`} item xs={12} md={6}>
                <Stack>
                  <TextField
                    className={classNames.input}
                    name={name}
                    defaultValue={(initialState || {})[name] || ''}
                    label={label}
                    multiline
                    rows={5}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            {urlFields.map(({ name, label }) => (
              <Grid key={`url-field-${name}`} item xs={12} md={6}>
                <Stack>
                  <TextField
                    className={classNames.input}
                    name={name}
                    defaultValue={(initialState || {})[name] || ''}
                    label={label}
                    type="url"
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={<Checkbox name="deactivatedAt" defaultChecked={!!initialState?.deactivatedAt} />}
                label="Deactivated"
              />
            </Grid>

            {checkboxFields.map(({ name, label }) => (
              <Grid item xs={12} md={4} key={`checkbox-field-${name}`}>
                <FormControlLabel
                  control={<Checkbox name={name} defaultChecked={!!(initialState || {})[name]} />}
                  label={label}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {textFields.map(({ name, label }) => (
          <Grid key={`text-field-${name}`} item xs={12} md={6}>
            <Stack>
              <TextField
                className={classNames.input}
                key={`text-field-${name}`}
                name={name}
                defaultValue={(initialState || {})[name]}
                label={label}
              />
            </Stack>
          </Grid>
        ))}

        {numberFields.map(({ name, label }) => (
          <Grid key={`number-field-${name}`} item xs={12} md={6}>
            <Stack>
              <TextField
                className={classNames.input}
                name={name}
                defaultValue={(initialState || {})[name] || ''}
                type="number"
                label={label}
              />
            </Stack>
          </Grid>
        ))}

        <Grid item xs={12} md={6}>
          <Button variant="contained" type="submit" disabled={isFormDisabled}>
            {isSaving || isProcessingInput ? 'Saving...' : 'Submit'}
          </Button>
        </Grid>
      </Grid>

      <Snackbar open={!!errorMessage} autoHideDuration={2500} onClose={handleSnackbarClose}>
        <Alert elevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}
