import { useState, useEffect } from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Dialog, DialogTitle, DialogActions, Button, Paper, Alert } from '@mui/material';

import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import { convertCentsToDollars } from 'helpers/currencyHelper';
import { ContributionsWithDetailForDonor_Gql } from 'gql/contributionsWithDetailForDonor';
import { ContributionsWithDetail, DonationForContributionsWithDetail } from 'model/ContributionsWithDetail.model';
import ViewListIcon from '@mui/icons-material/ViewList';
import { convertUTCDateToLocalDate } from 'helpers/dateHelper';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    iconLink: {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
  })
);

interface TransactionHistoryDetailWithIconProps {
  donations: DonationForContributionsWithDetail[];
  amountCents: number;
  tipsCents: number;
  date: string;
  status: string;
}

const TransactionHistoryDetailWithIcon = ({
  donations,
  amountCents,
  tipsCents,
  date,
  status,
}: TransactionHistoryDetailWithIconProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {status === 'succeeded' && (
        <>
          <ViewListIcon className={classes.iconLink} onClick={() => setOpen(true)} />
          <Dialog maxWidth="md" fullWidth={true} onClose={handleClose} open={open}>
            <DialogTitle>Contribution Details for {convertUTCDateToLocalDate(date)}</DialogTitle>
            <TableContainer sx={{ width: '100%', pl: 1, pr: 1 }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#e4e4e4' }}>
                      <b>EIN</b>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#e4e4e4' }}>
                      <b>Organization</b>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#e4e4e4' }}>
                      <b>%</b>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#e4e4e4' }}>
                      <b>Amount</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {donations.map((donation: DonationForContributionsWithDetail) => (
                    <TableRow>
                      <TableCell>{donation.organization.ein}</TableCell>
                      <TableCell>{donation.organization.name}</TableCell>
                      <TableCell>{donation.allocation.percentage}%</TableCell>
                      <TableCell>{convertCentsToDollars(donation.amountCents)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2}>Tip</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{convertCentsToDollars(tipsCents)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <strong>{convertCentsToDollars(amountCents + tipsCents)}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

const TransactionHistory = () => {
  const [contributions, setContributions] = useState<ContributionsWithDetail[]>([]);

  const { loading, data } = useAuthenticatedHedadoQuery(ContributionsWithDetailForDonor_Gql);

  useEffect(() => {
    if (loading || !data) return;

    setContributions(data.contributionsWithDetailForDonor);
  }, [data, loading]);

  return (
    <>
      <Alert severity="success">
        Recently completed transactions may take several hours to show on this table as pending. Transactions scheduled
        in the future will not show here until processing has begun.
      </Alert>
      {contributions && contributions.length > 0 && (
        <TableContainer sx={{ marginTop: 2 }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#e4e4e4' }}>
                  <b>Date</b>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#e4e4e4' }}>
                  <b>Amount</b>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#e4e4e4' }}>
                  <b>Status</b>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#e4e4e4' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {contributions &&
                contributions.map((contribution) => (
                  <TableRow>
                    <TableCell>{convertUTCDateToLocalDate(contribution.createdAt)}</TableCell>
                    <TableCell>{convertCentsToDollars(contribution.amountCents + contribution.tipsCents)}</TableCell>
                    <TableCell>{contribution.paymentStatus}</TableCell>
                    <TableCell>
                      <TransactionHistoryDetailWithIcon
                        donations={contribution.donations}
                        amountCents={contribution.amountCents}
                        tipsCents={contribution.tipsCents}
                        date={contribution.createdAt}
                        status={contribution.paymentStatus}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TransactionHistory;
