import { gql } from '@apollo/client';
import { DonorPublicProfile } from 'model/Donor.model';

export interface GetPublicProfileQueryVariables {
  id?: string;
  slug?: string;
}

export interface Donor {
  id: string;
  __typename: 'Donor';
  email: string;
  firstName: string;
  lastName: string;
  publicProfile: DonorPublicProfile & {
    __typename: 'DonorPublicProfile';
  };
}

export interface GetPublicProfileQueryResponse {
  getDonor: {
    id: string;
    __typename: 'Donor';
    email: string;
    firstName: string;
    lastName: string;
    publicProfile: DonorPublicProfile & {
      __typename: 'DonorPublicProfile';
    };
  };
}

export const getPublicProfileGql = gql`
  query GetDonorPublicProfile($donorId: ID, $slug: String) {
    getDonor(id: $donorId, slug: $slug) {
      id
      email
      firstName
      lastName
      publicProfile {
        bannerImage
        bannerImageMobile
        city
        colorTheme
        description
        displayName
        instagramHandle
        isEnabled
        linkedInHandle
        profileImage
        slug
        state
        tikTokHandle
        twitterHandle
        organizations {
          id
          description
        }
      }
    }
  }
`;

export const checkPublicProfileExistsGql = gql`
  query CheckPublicProfileExists($slug: String!) {
    getDonor(slug: $slug) {
      id
      publicProfile {
        slug
      }
    }
  }
`;
