import { gql } from '@apollo/client';

export interface GetCampaignsQueryResponse {
  getCampaigns: {
    pageInfo: {
      hasNextPage: Boolean;
      endCursor: string;
    };
    edges: {
      cursor: string;
      node: {
        id: string;
        title?: string;
        slug?: string;
      };
    }[];
  };
}

export interface GetCampaignsArgs {
  first: number;
  after?: string;
  search?: string;
}

export const getCampaignsGql = gql`
  query GetCampaigns($first: Int!, $after: String, $search: String) {
    getCampaigns(first: $first, after: $after, search: $search) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          title
          slug
        }
      }
    }
  }
`;
