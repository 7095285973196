import type Organization from 'model/Organization.model';
import { memo, forwardRef } from 'react';
import { Alert, Checkbox, FormControlLabel, Link } from '@mui/material';
import { setStateFromInputBlur, setStateFromInputCheck } from 'helpers/formHelpers';
import {
  NonProfitsFormState,
  NonProfitsFormStateErrors,
  NonProfitsFormStateKey,
} from '../hooks/useNonProfitsFormState';
import OrganizationsAutocompleteInput from 'components/OrganizationsAutocompleteInput';
import FormSection from './FormSection';
import FormGridItem from './FormGridItem';
import { useCampaignPermissions } from '../../CampaignAdminPage/hooks/useCampaignPermissions';
import { CampaignTier } from 'model/CampaignTier.model';
import { env } from '../../../env';

interface NonProfitsSectionProps {
  errors: NonProfitsFormStateErrors;
  formState: NonProfitsFormState;
  selectedOrganizations: Organization[];
  setFormState: React.Dispatch<React.SetStateAction<NonProfitsFormState>>;
  setSelectedOrganizations: (organizations: Organization[]) => void;
  simplifiedView: boolean;
  tier: CampaignTier;
}

const NonProfitsSection = forwardRef<HTMLDivElement, NonProfitsSectionProps>(
  ({ formState, selectedOrganizations, setFormState, setSelectedOrganizations, simplifiedView, tier }, ref) => {
    const { displayDonorOrganizations } = useCampaignPermissions();

    function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
      setStateFromInputBlur(event, setFormState);
    }

    function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
      setStateFromInputCheck(event, setFormState);
    }

    return (
      <FormSection heading="Nonprofits" ref={ref}>
        <FormGridItem>
          <OrganizationsAutocompleteInput initialState={selectedOrganizations} onChange={setSelectedOrganizations} />
        </FormGridItem>
        {!simplifiedView && (
          <FormGridItem>
            <FormControlLabel
              control={
                <Checkbox
                  name={NonProfitsFormStateKey.showCampaignOrganizations}
                  checked={formState.showCampaignOrganizations.value}
                  onChange={handleCheckboxChange}
                  inputProps={{ onBlur: handleInputBlur }}
                />
              }
              label="Display highlighted nonprofits?"
            />
          </FormGridItem>
        )}
        <FormGridItem>
          <FormControlLabel
            control={
              <Checkbox
                name={NonProfitsFormStateKey.limitOrganizations}
                checked={formState.limitOrganizations.value}
                onChange={handleCheckboxChange}
                inputProps={{ onBlur: handleInputBlur }}
              />
            }
            label="Limit campaign donations to highlighted nonprofits?"
          />
        </FormGridItem>
        {!simplifiedView && (
          <FormGridItem>
            <FormControlLabel
              control={
                <Checkbox
                  name={NonProfitsFormStateKey.showDonorOrganizations}
                  checked={formState.showDonorOrganizations.value}
                  onChange={handleCheckboxChange}
                  inputProps={{ onBlur: handleInputBlur }}
                  disabled={!displayDonorOrganizations(tier)}
                />
              }
              label="Show all nonprofits that campaign participants have supported?"
            />
            {!displayDonorOrganizations(tier) && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                To show nonprofits from campaign participants, please{' '}
                <Link
                  href={`${env.REACT_APP_NEXTJS_URL}/pricing/match`}
                  sx={{ color: '#8784E6', fontWeight: 'bold' }}
                  target="_blank"
                >
                  upgrade your account
                </Link>
                .
              </Alert>
            )}
          </FormGridItem>
        )}
      </FormSection>
    );
  }
);

export default memo(NonProfitsSection);
