import React from 'react';
import { Typography, Snackbar, Box } from '@mui/material';
import AuthenticateButton from './AuthenticateButton';

interface UnauthCTABannerProps {
  open: boolean;
  onClose?: () => void;
}

const UnauthCTABanner = ({ open, onClose }: UnauthCTABannerProps) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{
        width: '100%',
        bottom: '0 !important',
        py: 1,
        px: 4,
        backgroundColor: 'secondary.main',
        left: { xs: '0 !important', sm: '50% !important' },
        right: { xs: '0 !important', sm: 'auto !important' },
      }}
    >
      <Box
        sx={{
          height: (theme) => ({
            md: theme.spacing(9),
            sm: theme.spacing(15),
            xs: theme.spacing(17),
          }),
          backgroundColor: 'secondary.main',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: 5,
          px: {
            sm: 4,
            xs: 2,
          },
          py: {
            md: 0,
            xs: 1,
          },
          display: 'flex',
          flexDirection: {
            md: 'row',
            xs: 'column',
          },
          justifyContent: {
            md: 'space-between',
            xs: 'space-around',
          },
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h6"
          component="p"
          sx={{ fontSize: { md: '1.25em', sm: '1em', xs: '0.875em' }, textAlign: { md: 'left', sm: 'center' } }}
        >
          Log in to create a personal portfolio and support multiple nonprofits in a single donation.
        </Typography>
        <Box sx={{ color: '#FFFFFF', padding: 1, display: 'flex', gap: 2, minWidth: '300px', flexShrink: 0 }}>
          <AuthenticateButton
            color="primary"
            icon={null}
            size="large"
            sx={{ textTransform: 'uppercase' }}
            variant="light"
          />
          <AuthenticateButton
            color="primary"
            disableElevation
            icon={null}
            isSignUp={true}
            size="large"
            variant="contained"
          />
        </Box>
      </Box>
    </Snackbar>
  );
};

export default UnauthCTABanner;
