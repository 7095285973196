import { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Box, Typography, Snackbar, Alert } from '@mui/material';
import Head from 'components/Head/Head';
import PageContainer from 'components/global/ColorPageContainer';

interface Props {
  title: string;
  children: any;
}

export default function AdminPage({ title, children }: Props) {
  const { state } = useLocation();
  const { snackbarMessage } = (state || {}) as { snackbarMessage?: string };
  const [snackbarOpen, setSnackbarOpen] = useState(!!snackbarMessage);

  function handleSnackbarClose() {
    setSnackbarOpen(false);
  }

  const pages = [
    {
      pathname: '/admin/organizations',
      name: 'Organizations',
    },
    {
      pathname: '/admin/campaigns',
      name: 'Campaigns',
    },
    {
      pathname: '/admin/public-profiles',
      name: 'Public Profiles',
    },
  ];

  return (
    <>
      <Head title={`Admin | Hedado`} />
      <Grid container spacing={4} sx={{ marginY: 1 }}>
        <Grid item md={2} xs={12}>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Admin Menu
          </Typography>
          <Box>
            {pages.map(({ pathname, name }) => (
              <Fragment key={pathname}>
                <Link to={{ pathname }}>{name}</Link>
                <br />
              </Fragment>
            ))}
          </Box>
        </Grid>
        <Grid item md={10} xs={12}>
          <PageContainer bgColor="#fff">
            <Typography variant="h5" sx={{ marginBottom: 4 }}>
              {title}
            </Typography>
            {children}
          </PageContainer>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={2500} onClose={handleSnackbarClose}>
        <Alert elevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
