import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Fade, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useDonorProfile from 'hooks/useDonorProfile';
import useOnboarding from 'hooks/useOnboarding';
import { useDonorDonationStatisticsQuery } from 'hooks/useDonorDonationStatisticsQuery';
import Head from 'components/Head/Head';
import PageHeader from 'components/global/PageHeader';
import PageContainer from 'components/global/ColorPageContainer';
import DonationSummary from 'components/summary/DonationSummary';
import GettingStartedChecklist from 'components/global/onboarding/GettingStartedChecklist';
import DonorNameOrAccountLink from 'components/global/DonorNameOrAccountLink';
import OrganizationList from 'components/organization/OrganizationList';
import CampaignList from 'components/campaign/CampaignList/CampaignList';

import { APP_TITLE, PAGE_TITLE_MY_SUMMARY } from 'utils/constants';
import { convertCentsToDollars } from 'helpers/currencyHelper';
import Organization from 'model/Organization.model';
import { getDistinctOrganizationsDonorSupported_Gql } from 'gql/getDistinctOrganizationsDonorSupported';
import { getCampaignsForDonor_Gql } from 'gql/campaigns/getCampaignsForDonor';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    reviewLink: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  })
);

const pageResults = () => {
  return;
};

const MySummary = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [onboarding, setOnboarding] = useOnboarding();
  const [donorProfile, { loading }] = useDonorProfile();

  const [profileLoading, setProfileLoading] = useState(loading);
  const { statistics } = useDonorDonationStatisticsQuery();

  useEffect(() => {
    setProfileLoading(loading);
  }, [loading]);

  const {
    data: donorSupportedOrganizationsQuery,
    waiting: donorSupportedOrganizationsQueryWaiting,
    loading: donorSupportedOrganizationsQueryLoading,
  } = useAuthenticatedHedadoQuery(getDistinctOrganizationsDonorSupported_Gql);

  const { data: campaignData } = useAuthenticatedHedadoQuery(getCampaignsForDonor_Gql);

  const breakpoints = {
    mdUp: useMediaQuery(theme.breakpoints.up('md')),
    mdDown: useMediaQuery(theme.breakpoints.down('md')),
  };

  const dismissOnboarding = () => {
    setOnboarding({
      checklist: { dismissed: true },
    });
  };

  const donorSupportedOrganizations: Organization[] =
    donorSupportedOrganizationsQuery?.getDistinctOrganizationsDonorSupported || [];

  const showDonorSupportedOrganizations =
    !donorSupportedOrganizationsQueryLoading &&
    !donorSupportedOrganizationsQueryWaiting &&
    !!donorSupportedOrganizationsQuery;

  return (
    <>
      <Head title={`${PAGE_TITLE_MY_SUMMARY} | ${APP_TITLE}`} />

      <PageHeader
        icon={<AssignmentIndIcon />}
        title={<DonorNameOrAccountLink defaultText="Complete Your Profile" />}
        subTitle="Your philanthropy at a glance."
      />

      <Grid container gap={4} sx={{ display: 'flex', flexWrap: 'nowrap' }} direction="row">
        <Grid item sx={{ flexGrow: 1 }}>
          <PageContainer>
            <Typography variant="h5" sx={{ paddingX: 2 }} gutterBottom>
              My Summary
            </Typography>

            {!profileLoading && (
              <DonationSummary
                totalDonations={convertCentsToDollars(statistics?.donationsTotalCents || 0)}
                nextDonationDate={donorProfile?.nextDonationDate || ''}
                nextDonationAmount={convertCentsToDollars(donorProfile?.nextDonationAmount || 0)}
              />
            )}

            {!onboarding.checklist?.dismissed && breakpoints.mdDown && (
              <Fade in={!onboarding.checklist?.dismissed}>
                <Grid item sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
                  <Typography variant="h5" sx={{ paddingX: 2, pt: 4 }}>
                    Getting Started
                  </Typography>
                  <GettingStartedChecklist onDismiss={dismissOnboarding} />
                </Grid>
              </Fade>
            )}

            {!!campaignData && <CampaignList title="My Campaigns" campaigns={campaignData.getCampaignsForDonor} />}

            {showDonorSupportedOrganizations && (
              <>
                <Grid container sx={{ pt: 4 }} spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h5" sx={{ paddingX: 2 }}>
                      Organizations I've Supported
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <RouterLink className={classes.reviewLink} to="/portfolio">
                      Take me to my portfolio &gt;&gt;
                    </RouterLink>
                  </Grid>
                </Grid>
                <OrganizationList
                  organizations={donorSupportedOrganizations}
                  onPageChange={pageResults}
                  title=""
                  subTitle=""
                  noOrgsTitle=" "
                  noOrgsText="After your first donation is processed or first receipt is forwarded, your organizations will appear here."
                />
              </>
            )}
          </PageContainer>
        </Grid>
        {!onboarding.checklist?.dismissed && breakpoints.mdUp && (
          <Fade in={!onboarding.checklist?.dismissed}>
            <Grid item sx={{ display: 'flex', minWidth: '250px', flexDirection: 'column' }} gap={3}>
              <Typography variant="h5" sx={{ paddingX: 2 }}>
                Getting Started
              </Typography>
              <GettingStartedChecklist onDismiss={dismissOnboarding} />
            </Grid>
          </Fade>
        )}
      </Grid>
    </>
  );
};

export default MySummary;
