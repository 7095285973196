import RouteItem from '../model/RouteItem.model';

import About from '../pages/About';
import BrowseNonprofits from '../pages/BrowseNonprofits';
import Faq from '../pages/Faq';
import Home from '../pages/Home';
import LoggedOut from '../pages/LoggedOut';
import LoginRequired from '../pages/LoginRequired';
import MyPortfolio from '../pages/donor/MyPortfolio';
import MySummary from '../pages/donor/MySummary';
import MyPublicProfile from 'pages/donor/MyPublicProfile';
import ManageGiving from '../pages/donor/ManageGiving';
import MyHistory from '../pages/donor/MyHistory';
import MyAccount from '../pages/donor/MyAccount';

// pages
import AuthRedirect from 'pages/AuthRedirect';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import ReceiptTracking from '../pages/ReceiptTracking';
import TermsOfUse from '../pages/TermsOfUse';
import PublicProfile from '../pages/PublicProfile';
import BainLaCampaign from '../pages/PublicCampaignBainLa';
import KraveCampaign from '../pages/PublicCampaignKrave';
import Authenticate from '../pages/Authenticate';
import CampaignAdmin from '../pages/CampaignAdmin';
import EditOrganization from '../pages/admin/organization/EditOrganization';
import OrganizationList from '../pages/admin/organization/OrganizationList';
import CreateOrganization from 'pages/admin/organization/CreateOrganization';
import EditCampaign from '../pages/admin/campaign/EditCampaign';
import CampaignList from '../pages/admin/campaign/CampaignList';
import AddCampaign from '../pages/admin/campaign/AddCampaign';
import PublicProfileList from '../pages/admin/donor/PublicProfileList';
import PublicCampaign from '../pages/PublicCampaign';

// define app routes
export const routes: Array<RouteItem> = [
  {
    key: 'router-auth',
    path: '/auth',
    enabled: true,
    component: Authenticate,
    protected: false,
  },
  {
    key: 'router-bainLa',
    tooltip: 'BainLA',
    path: '/BainLA',
    enabled: true,
    component: BainLaCampaign,
    protected: false,
  },
  {
    key: 'router-krave',
    tooltip: 'KraveBeauty',
    path: '/KraveBeauty',
    enabled: true,
    component: KraveCampaign,
    protected: false,
  },
  {
    key: 'router-campaigns',
    title: 'Campaign',
    path: '/c/:slug',
    enabled: true,
    component: PublicCampaign,
    protected: false,
  },
  {
    key: 'router-profile',
    title: 'Profile',
    path: '/d/:slug',
    // FIXME: compile error when trying to use strong env
    enabled: process.env.REACT_APP_FEATURE_FLAG_PUBLIC_PROFILES === 'true',
    component: PublicProfile,
    protected: false,
  },
  {
    key: 'router-history',
    tooltip: 'My History',
    path: '/history',
    enabled: true,
    component: MyHistory,
    protected: true,
  },
  {
    key: 'router-about',
    title: 'About',
    tooltip: 'About',
    path: '/about',
    enabled: true,
    component: About,
    protected: false,
  },
  {
    key: 'router-app',
    title: 'App',
    tooltip: 'App',
    path: '/',
    enabled: true,
    component: Home,
    protected: false,
  },
  {
    key: 'router-campaign',
    title: 'Campaign',
    tooltip: 'Campaign',
    path: '/campaign/:id',
    enabled: true,
    component: CampaignAdmin,
    protected: true,
  },
  {
    key: 'route-manage-giving',
    tooltip: 'ManageGiving',
    path: '/manage-giving',
    enabled: true,
    component: ManageGiving,
    protected: true,
  },
  {
    key: 'router-browse-nonprofits',
    title: 'BrowseNonprofits',
    tooltip: 'BrowseNonprofits',
    path: '/browse-nonprofits',
    enabled: true,
    component: BrowseNonprofits,
    protected: false,
  },
  {
    key: 'router-faq',
    title: 'FAQ',
    tooltip: 'FAQ',
    path: '/faq',
    enabled: true,
    component: Faq,
    protected: false,
  },
  {
    key: 'router-logged-out',
    title: 'Logged Out',
    tooltip: 'Logged Out',
    path: '/logged-out',
    enabled: true,
    component: LoggedOut,
    protected: false,
  },
  {
    key: 'router-login-required',
    title: '',
    tooltip: '',
    path: '/login-required',
    enabled: true,
    component: LoginRequired,
    protected: false,
  },
  {
    key: 'router-portfolio',
    title: 'MyPortfolio',
    tooltip: 'MyPortfolio',
    path: '/portfolio',
    enabled: true,
    component: MyPortfolio,
    protected: true,
  },
  {
    key: 'router-my-summary',
    title: 'MySummary',
    tooltip: 'MySummary',
    path: '/summary',
    enabled: true,
    component: MySummary,
    protected: true,
  },
  {
    key: 'router-privacy-policy',
    title: 'Privacy Policy',
    path: '/privacy-policy',
    enabled: true,
    component: PrivacyPolicy,
    protected: false,
  },
  {
    key: 'router-terms',
    title: 'Terms of Use',
    path: '/terms',
    enabled: true,
    component: TermsOfUse,
    protected: false,
  },
  {
    key: 'router-track-receipts',
    title: 'Track Receipts',
    path: '/receipt-tracking',
    enabled: true,
    component: ReceiptTracking,
    protected: false,
  },
  {
    key: 'router-donate',
    title: 'Donate',
    path: '/donate/manage-payments',
    enabled: true,
    protected: true,
  },
  {
    key: 'router-my-Account',
    title: 'My Account',
    path: '/my-account',
    enabled: true,
    component: MyAccount,
    protected: true,
  },
  {
    key: 'router-my-public-profile',
    title: 'My Public Profile',
    path: '/my-account/public-profile',
    enabled: true,
    component: MyPublicProfile,
    protected: true,
  },
  {
    key: 'router-admin-organizations',
    title: 'Organizations',
    path: '/admin/organizations',
    enabled: true,
    component: OrganizationList,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'router-admin-new-organization',
    title: 'New Organization',
    path: '/admin/organizations/new',
    enabled: true,
    component: CreateOrganization,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'router-admin-edit-organization',
    title: 'Edit Organization',
    path: '/admin/organizations/:id/edit',
    enabled: true,
    component: EditOrganization,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'router-admin-campaigns',
    title: 'Organizations',
    path: '/admin/campaigns',
    enabled: true,
    component: CampaignList,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'router-admin-edit-campaign',
    title: 'Edit Campaign',
    path: '/admin/campaigns/:id/edit',
    enabled: true,
    component: EditCampaign,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'router-admin-add-campaign',
    title: 'Add Campaign',
    path: '/admin/campaigns/new',
    enabled: true,
    component: AddCampaign,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'router-admin-public-profiles',
    title: 'Public Profiles',
    path: '/admin/public-profiles',
    enabled: true,
    component: PublicProfileList,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'router-auth-redirect',
    title: 'Hedado',
    path: '/auth-redirect',
    enabled: true,
    component: AuthRedirect,
    protected: false,
    adminOnly: false,
  },
];
