import { env } from '../../env';
import { useHistory } from 'react-router';
import { useEffect, useLayoutEffect } from 'react';
import { Theme, createStyles, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Container, Grid, Button, Typography, Link, Fade, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticateButton from './AuthenticateButton';
import IntroModal from './onboarding/IntroModal';
import OnboardingGuideCard from './onboarding/OnboardingGuideCard';
import { useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import useOnboarding, { emptyOnboarding } from '../../hooks/useOnboarding';
import useDonorProfile from '../../hooks/useDonorProfile';

// Constants
import { HEDADO_EMAIL } from '../../utils/constants';

// Icons
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SpeedIcon from '@mui/icons-material/Speed';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: '3rem !important',
      marginBottom: theme.spacing(2),
    },
  })
);

const images = {
  heroXs: require('../../assets/img/hedado-hero-image-xs.svg').default,
  hero: require('../../assets/img/hedado-hero-image.svg').default,
};

const WelcomeContainer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  const [donorProfile, { loading }] = useDonorProfile();
  const [profileLoading, setProfileLoading] = useState(loading);
  const [onboarding, setOnboarding] = useOnboarding();
  const [openModal, setOpenModal] = useState(false);
  const [shiftHeld, setShiftHeld] = useState(false);

  useEffect(() => {
    setProfileLoading(loading);
  }, [loading]);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      setShiftHeld(true);
    }
  };
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      setShiftHeld(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  useLayoutEffect(() => {
    handleOpenModal();
  });

  const shouldOpenIntroModal = () => {
    return (
      env.REACT_APP_FEATURE_FLAG_WELCOME_MODAL === true &&
      isAuthenticated &&
      !profileLoading &&
      !loading &&
      openModal &&
      onboarding.introModal?.dismissed !== true
    );
  };

  const shouldShowTrackReceiptsCard = () => {
    return (
      (onboarding.introModal?.receiptTracking !== false || onboarding.introModal?.exploreOnMyOwn === true) &&
      onboarding.receiptTrackingTodo?.dismissed !== true
    );
  };

  const shouldShowChooseAllocationsCard = () => {
    return (
      (onboarding.introModal?.portfolio !== false || onboarding.introModal?.exploreOnMyOwn === true) &&
      onboarding.portfolioTodo?.dismissed !== true
    );
  };

  const shouldShowAddPaymentMethodCard = () => {
    return (
      (onboarding.introModal?.portfolio !== false || onboarding.introModal?.exploreOnMyOwn === true) &&
      onboarding.accountTodo?.dismissed !== true &&
      onboarding.accountTodo?.hasPaymentMethod !== true
    );
  };

  const shouldShowAddRecurringDonationCard = () => {
    return (
      (onboarding.introModal?.portfolio !== false || onboarding.introModal?.exploreOnMyOwn === true) &&
      onboarding.accountTodo?.dismissed !== true &&
      onboarding.accountTodo?.hasPaymentMethod === true
    );
  };

  const shouldShowPublicProfileCard = () => {
    return !onboarding.profileTodo.dismissed;
  };

  const CARDS = [
    {
      name: 'TrackReceiptsCard',
      visible: shouldShowTrackReceiptsCard(),
      component: (
        <OnboardingGuideCard
          title="Track donation receipts"
          label={
            <Typography>
              Forward a receipt to{' '}
              <Link target="_blank" href={`mailto:${HEDADO_EMAIL}`}>
                {HEDADO_EMAIL}
              </Link>
              .
            </Typography>
          }
          checkboxName="receiptTracking"
          icon={<ReceiptLongIcon sx={{ fontSize: '2rem' }} />}
          backgroundColor="#a6cfe4"
          textColor="primary.main"
          checkboxStyle="visual"
          actionButton={{
            label: 'My receipts',
            onClick: () => {
              history.push('/history');
            },
          }}
          doneButton={{
            label: 'My history',
            onClick: () => {
              history.push('/history');
            },
          }}
          dismissable={onboarding.introModal?.receiptTracking !== true}
          onDismiss={() => dismissFeature('receiptTrackingTodo')}
          completed={onboarding.receiptTrackingTodo?.hasForwardedDonation}
        />
      ),
    },
    {
      name: 'BuildPortfolioCard',
      visible: true,
      component: (
        <OnboardingGuideCard
          title="Build your portfolio"
          label="Choose from over 1.5M nonprofits to support and manage them in one place."
          checkboxName="portfolio"
          icon={<VolunteerActivismIcon sx={{ fontSize: '2rem' }} />}
          backgroundColor="#afe69c"
          textColor="primary.main"
          checkboxStyle="visual"
          actionButton={{
            label: 'Browse nonprofits',
            onClick: () => {
              history.push('/browse-nonprofits');
            },
          }}
          doneButton={{
            label: 'Find more nonprofits',
            onClick: () => {
              history.push('/browse-nonprofits');
            },
          }}
          dismissable={false}
          completed={(onboarding.portfolioTodo?.allocations ?? 0) > 0}
        />
      ),
    },
    {
      name: 'ChooseYourAllocationsCard',
      visible: shouldShowChooseAllocationsCard(),
      component: (
        <OnboardingGuideCard
          title="Choose your allocations"
          label="Decide how much of your contribution to allocate to each nonprofit in your portfolio."
          checkboxName="portfolio"
          icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
          backgroundColor="#5da38e"
          textColor="#ffffff"
          checkboxStyle="visual"
          actionButton={{
            label: 'Get started',
            onClick: () => {
              history.push('/portfolio');
            },
          }}
          doneButton={{
            label: 'My portfolio',
            onClick: () => {
              history.push('/portfolio');
            },
          }}
          dismissable={true}
          onDismiss={() => dismissFeature('accountTodo')}
          completed={(onboarding.portfolioTodo?.allocations ?? 0) > 0}
        />
      ),
    },
    {
      name: 'AddPaymentMethodCard',
      visible: shouldShowAddPaymentMethodCard(),
      component: (
        <OnboardingGuideCard
          title="Add a payment method"
          label="Donate to all the nonprofits in your portfolio with one easy payment."
          checkboxName="portfolio"
          icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
          backgroundColor="#395884"
          textColor="#ffffff"
          checkboxStyle="visual"
          actionButton={{
            label: 'Add payment method',
            onClick: () => {
              history.push('/manage-giving');
            },
          }}
          doneButton={{
            label: 'Manage giving',
            onClick: () => {
              history.push('/manage-giving');
            },
          }}
          dismissable={true}
          onDismiss={() => dismissFeature('accountTodo')}
          completed={onboarding.accountTodo?.hasPaymentMethod}
        />
      ),
    },
    {
      name: 'AddRecurringDonationCard',
      visible: shouldShowAddRecurringDonationCard() && env.REACT_APP_FEATURE_FLAG_ENABLE_MATCH === false,
      component: (
        <OnboardingGuideCard
          title="Set up recurring donations"
          label="Sustained and predictable giving allows nonprofits to focus on their mission."
          checkboxName="portfolio"
          icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
          backgroundColor="#395884"
          textColor="#ffffff"
          checkboxStyle="visual"
          actionButton={{
            label: 'Add donation plan',
            onClick: () => {
              history.push('/manage-giving');
            },
          }}
          doneButton={{
            label: 'Manage giving',
            onClick: () => {
              history.push('/manage-giving');
            },
          }}
          dismissable={true}
          onDismiss={() => dismissFeature('accountTodo')}
          completed={onboarding.accountTodo?.hasRecurringDonation}
        />
      ),
    },
    {
      name: 'AddRecurringDonationCardWithMatching',
      visible: shouldShowAddRecurringDonationCard() && env.REACT_APP_FEATURE_FLAG_ENABLE_MATCH === true,
      component: (
        <OnboardingGuideCard
          title="Get matched when you give monthly"
          label="Give monthly to not only support nonprofits with predictable giving, but get up to $60 in matching funds to those organizations."
          checkboxName="portfolio"
          icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
          backgroundColor="#395884"
          textColor="#ffffff"
          checkboxStyle="visual"
          actionButton={{
            label: 'Add donation plan',
            onClick: () => {
              history.push('/manage-giving');
            },
          }}
          doneButton={{
            label: 'Manage giving',
            onClick: () => {
              history.push('/manage-giving');
            },
          }}
          dismissable={true}
          onDismiss={() => dismissFeature('accountTodo')}
          completed={donorProfile?.recurringFrequency === 'monthly'}
        />
      ),
    },
    {
      name: 'CreatePublicProfile',
      visible: shouldShowPublicProfileCard(),
      component: (
        <OnboardingGuideCard
          title="Create your public profile"
          label="Sharing more about the nonprofits you give to and why signals to others that philanthropy is important."
          checkboxName="publicProfile"
          icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
          backgroundColor="#eb9288"
          textColor="#ffffff"
          checkboxStyle="visual"
          actionButton={{
            label: 'Create a public profile',
            onClick: () => {
              history.push('/my-account/public-profile');
            },
          }}
          doneButton={{
            label: 'Edit Profile',
            onClick: () => {
              history.push('/my-account/public-profile');
            },
          }}
          dismissable={true}
          onDismiss={() => dismissFeature('profileTodo')}
          completed={onboarding.profileTodo.didCreatePublicProfile}
        />
      ),
    },
  ];

  const visibleCards = CARDS.filter((card) => card.visible);

  const heroString = isAuthenticated
    ? 'Welcome' + (donorProfile?.firstName ? `, ${donorProfile?.firstName}!` : '!')
    : 'Donate like a boss';

  const handleOpenModal = () => {
    if (openModal || loading || profileLoading || !isAuthenticated) {
      return;
    }
    setOpenModal(isAuthenticated && !loading && !profileLoading && onboarding.introModal?.dismissed !== true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const dismissFeature = (name: string) => {
    onboarding[name] = onboarding[name] || {};
    onboarding[name].dismissed = true;
    setOnboarding(onboarding);
  };

  return (
    <>
      <section>
        <Container
          maxWidth={false}
          sx={{
            minHeight: '60vh',
            overflowX: 'visible',
            overflowY: 'clip',
            display: 'flex',
            justifyContent: 'center',
            paddingX: { xs: theme.spacing(8) + ' !important', sm: theme.spacing(8) + ' !important' },
            alignItems: 'center',
            borderRadius: 6,
            flexDirection: 'column',
            marginTop: theme.spacing(3),
            marginLeft: { xs: theme.spacing(-2), sm: theme.spacing(0) },
            width: { xs: `calc(100% + ${theme.spacing(2)})`, sm: '100%' },
            // End hack
            backgroundColor: theme.palette.secondary.light,
            position: 'relative',
            '&::before': {
              overflowX: 'hidden',
              content: {
                xs: `url(${images.heroXs})`,
                sm: `url(${images.hero})`,
              },
              position: 'absolute',
              width: {
                xs: '160vw',
                sm: 'min(60vw, 60vh)',
                // md: 'min(80vw, 100vh)',
              },
              bottom: {
                xs: 'inherit',
                sm: '-1%',
              },
              right: {
                xs: 'inherit',
                // sm: 'max(-40vw, -40vh)',
                sm: 'max(-24vw, -24vh)',
                // lg: 'max(-40vh, max(-36vw, -36rem))',
              },
              zIndex: '0',
            },
          }}
        >
          <Grid
            container
            maxWidth="md"
            spacing={{ xs: 2, sm: 3, md: 6 }}
            className={classes.paddingXMore}
            sx={{
              mt: '0 !important',
              justifyContent: 'left',
              flexDirection: 'column',
              flexBasis: 'auto',
              zIndex: 1000,
              alignItems: 'flex-start',
            }}
          >
            <Grid item xs={12}>
              {/* TODO: Temp style overrides; re-style this at the theme level */}
              <Typography variant="h2" component="h2" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                {heroString}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                display: 'flex',
                gap: theme.spacing(2),
                flexWrap: 'wrap',
                flexDirection: { xs: 'column-reverse', sm: 'row' },
              }}
            ></Grid>
          </Grid>
          {!isAuthenticated && !loading ? (
            <Grid
              container
              maxWidth="md"
              spacing={2}
              sx={{
                zIndex: '1000',
                pb: 4,
              }}
            >
              <Grid item sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <AuthenticateButton color="primary" returnAfterLogin={false} variant="contained" />
                <AuthenticateButton
                  color="primary"
                  icon={<PersonAddIcon />}
                  isSignUp={true}
                  returnAfterLogin={false}
                  variant="contained"
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="text" size="large" href="https://hedado.com" target="_blank">
                  Learn more
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              <TransitionGroup
                component={Grid}
                container
                maxWidth="md"
                spacing={4}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  zIndex: '1000',
                  pb: 4,
                  flexWrap: 'wrap',
                }}
              >
                {visibleCards.map((card) => (
                  // TODO: Fix this transition, it's glitching in certain cases.
                  <Fade key={card.name}>
                    <Grid
                      key={card.name}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={visibleCards.length < 2 ? 6 : 4}
                      sx={{
                        display: 'flex',
                        transition: 'flex-grow .3s',
                      }}
                    >
                      {card.component}
                    </Grid>
                  </Fade>
                ))}
              </TransitionGroup>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end' }}>
                <Link
                  sx={{
                    display: 'block',
                    mt: 2,
                    p: 8,
                    fontSize: '0.8rem',
                    opacity: 0.5,
                    color: 'primary.main',
                    textDecorationColor: 'primary.main',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOnboarding(
                      shiftHeld
                        ? emptyOnboarding
                        : { ...emptyOnboarding, ...({ introModal: { dismissed: true } } as any) }
                    );
                  }}
                >
                  Restore getting started steps.
                </Link>
              </Box>
            </>
          )}
        </Container>
        <IntroModal open={shouldOpenIntroModal()} handleClose={handleCloseModal} />
      </section>
    </>
  );
};

export default WelcomeContainer;
