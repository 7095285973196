import { useState } from 'react';
import { IconButton, Tooltip, ClickAwayListener } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

interface ShareButtonProps {
  color?: string;
  onShared: () => void;
  shareData: {
    url: string;
    title: string;
  };
}

export default function ShareButton({ color = 'text.primary', shareData, onShared }: ShareButtonProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  function handleTooltipOpen() {
    setIsTooltipOpen(true);
  }

  function handleTooltipClose() {
    setIsTooltipOpen(false);
  }

  async function handleShareClick() {
    if (canShare(shareData)) {
      navigator
        .share(shareData)
        .then(() => onShared())
        .catch((_) => {});
    } else {
      const copiedToClipboard = await copyTextToClipboard(shareData.url);
      if (copiedToClipboard) {
        handleTooltipOpen();
        onShared();
      }
    }
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={isTooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="bottom-end"
        title="URL Copied to clipboard!"
      >
        <IconButton onClick={handleShareClick}>
          <ShareIcon sx={{ color }} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

function canShare(data: ShareData) {
  if ('canShare' in window.navigator) {
    return (window.navigator as any).canShare(data);
  } else {
    return false;
  }
}

async function copyTextToClipboard(text: string): Promise<boolean> {
  if (!navigator.clipboard) return fallbackCopyTextToClipboard(text);

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

function fallbackCopyTextToClipboard(text: string): boolean {
  // Because navigator.clipboard has limited availability in modern browsers, this deprecated
  // method of copying text is provided as a fallback
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch (error) {
    console.log(error);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}
