import { useRef, useState } from 'react';
import { DialogTitle, Dialog, DialogContent, Button, Box, TextField } from '@mui/material';

interface SimpleDialogProps {
  open: boolean;
  title: string;
  helpText?: string;
  text: string;
  allowedChars?: RegExp;
  maxChars: number | false;
  onClose: (shouldSave: boolean, value: string) => void;
}

const TextInputDialog = ({ open, title, helpText, text, allowedChars, maxChars, onClose }: SimpleDialogProps) => {
  const textInputRef = useRef<HTMLInputElement>();

  const [value, setValue] = useState<string>(text);

  const handleClose = (shouldSave: boolean, value: string = '') => {
    onClose(shouldSave, value);
  };

  const validateInput = (value: string): boolean => {
    if (value.length === 0) return false;
    if (!allowedChars) return false;
    if (maxChars && value.length > maxChars) return true;
    return allowedChars.test(value) === false;
  };

  const helperText = (): string => {
    if (validateInput(value)) return 'Your message cannot contain special characters or emoji.';
    return `${helpText}${maxChars ? ` (${value.length}/${maxChars})` : ''}`;
  };

  return (
    <>
      <Dialog onClose={() => handleClose(false)} aria-labelledby="confirmation-dialog" open={open}>
        <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            error={(() => validateInput(value))()}
            inputProps={{
              maxlength: maxChars,
              pattern: allowedChars,
            }}
            label={false}
            id="textInput"
            inputRef={textInputRef}
            onChange={(event) => {
              const { value } = event.target;
              setValue(value);
            }}
            helperText={(() => helperText())()}
            defaultValue={text}
            sx={{
              '> input:invalid': {
                border: '2px solid red',
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <Button sx={{ width: 125 }} variant="light" onClick={() => handleClose(false)}>
              Cancel
            </Button>
            <Button
              sx={{ width: 125 }}
              variant="contained"
              color="primary"
              onClick={() => handleClose(true, textInputRef.current?.value ?? '')}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TextInputDialog;
