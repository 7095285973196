import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Card, CardContent, CardMedia } from '@mui/material';
import { env } from 'env';
import isDarkColor from 'helpers/isDarkColor';
import Organization from 'model/Organization.model';
import useAddOrganizationToPortfolio from 'hooks/useAddOrganizationToPortfolio';
import { useOrganizationAddedAfterRedirect } from 'components/global/RedirectProvider';
import useDonorProfile from 'hooks/useDonorProfile';
import CauseAreaChip from 'components/organization/CauseAreaChip';
import LearnMoreButtonWithDrawer from 'components/organization/LearnMoreButtonWithDrawer';
import OrganizationAddedSnackbar from 'components/organization/OrganizationAddedSnackbar';
import AddMenu from 'components/organization/OrganizationCard/components/AddMenu';
import InPortfolioFlag from 'components/organization/OrganizationCard/components/InPortfolioFlag';
import ClampedText from 'components/ClampedText';

interface PublicProfileOrganizationCardProps {
  organization: Organization;
  hexColor?: string;
  currentlyInPortfolio?: boolean;
  donorDescription?: string;
}

export default function PublicProfileOrganizationCard({
  organization,
  hexColor = '#fff',
  donorDescription,
}: PublicProfileOrganizationCardProps) {
  const [donorProfile] = useDonorProfile();
  const { addOrganizationToPortfolio } = useAddOrganizationToPortfolio();
  const { added: addedAfterRedirect } = useOrganizationAddedAfterRedirect(organization.id);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const imageUrl = !organization.imageName
    ? env.REACT_APP_DEFAULT_ORG_IMAGE_URL
    : `${env.REACT_APP_ORGANIZATION_IMAGE_PATH}${organization.imageName}`;
  const isDark = isDarkColor(hexColor);

  const currentlyInPortfolio = (donorProfile?.allocations || []).some(
    (allocation) => allocation.organization.id === organization.id
  );

  function addOrganization() {
    if (isAuthenticated) {
      addOrganizationToPortfolio({ variables: { organizationId: organization.id } });
      setShowConfirmation(true);
    } else {
      loginWithStateToAddOrganization({ organizationId: organization.id });
    }
  }

  function handleAddToPortfolioClick() {
    addOrganization();
  }

  async function loginWithStateToAddOrganization({ organizationId }: { organizationId: string }) {
    loginWithRedirect({
      appState: {
        organizationIdToAdd: organizationId,
        returnTo: window.location.pathname,
      },
    });
  }

  useEffect(() => {
    setShowConfirmation(addedAfterRedirect);
  }, [addedAfterRedirect]);

  return (
    <Box sx={{ height: '100%', pb: { lg: 0, xs: 3 } }}>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          borderRadius: 2,
          flexDirection: { xs: 'column', lg: 'row' },
          bgcolor: isDark ? alpha('#FFF', 0.05) : '#FFF',
          borderWidth: '1px',
          borderColor: isDark ? alpha('#FFF', 0.1) : '#FFF',
          position: 'relative',
          maxWidth: { lg: 1000, md: '350px', xs: '325px' },
          mx: 'auto',
          height: '100%;',
        }}
      >
        <CardMedia
          component="img"
          image={imageUrl}
          sx={{
            width: { xs: '100%', lg: '345px' },
            height: { xs: 'auto', lg: '230px' },
            mx: 'auto',
            padding: { xs: 0, lg: 1 },
            borderRadius: { xs: 0, lg: 4 },
            flexShrink: 0,
          }}
        />
        {currentlyInPortfolio && <InPortfolioFlag />}
        <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingBottom: '16px !important' }}>
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={12} gap={1} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <Typography
                  component="div"
                  variant="h5"
                  color={isDark ? '#FFF' : 'text.primary'}
                  sx={{ pr: currentlyInPortfolio ? 5 : 0 }}
                >
                  {organization.name}
                </Typography>
                <Typography variant="subtitle1" color={isDark ? '#FFF' : 'text.secondary'}>
                  EIN: {organization.ein}
                </Typography>
              </Box>

              <Grid container gap={1}>
                {organization.causeArea && <CauseAreaChip causeArea={organization.causeArea} size="small" />}
              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', flexGrow: 1 }}>
                <Grid container sx={{ mb: 3 }}>
                  {donorDescription ? (
                    <Typography
                      variant="body1"
                      color={isDark ? '#FFF' : 'text.secondary'}
                      sx={{ whiteSpace: 'pre-wrap' }}
                    >
                      <em>
                        <ClampedText
                          text={donorDescription}
                          canExpand={true}
                          maxLineCount={3}
                          linkProps={
                            isDark
                              ? {
                                  sx: {
                                    color: '#fff',
                                  },
                                }
                              : undefined
                          }
                        />
                      </em>
                    </Typography>
                  ) : (
                    organization.mission && (
                      <Typography variant="body1" color={isDark ? '#FFF' : 'text.secondary'}>
                        <ClampedText
                          text={organization.mission}
                          canExpand={true}
                          maxLineCount={3}
                          linkProps={
                            isDark
                              ? {
                                  sx: {
                                    color: '#fff',
                                  },
                                }
                              : undefined
                          }
                        />
                      </Typography>
                    )
                  )}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', flexGrow: 0 }} gap={1}>
                  <LearnMoreButtonWithDrawer
                    organization={organization}
                    currentlyInPortfolio={currentlyInPortfolio}
                    color={isDark ? '#FFF' : 'text.secondary'}
                    buttonText={'About'}
                    bgColor={isDark ? 'secondary' : 'primary'}
                  />

                  <AddMenu
                    id={`AddMenu-${organization.ein}`}
                    organization={organization}
                    isInPortfolio={currentlyInPortfolio}
                    onAddToPortfolioClick={handleAddToPortfolioClick}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <OrganizationAddedSnackbar organization={organization} showSnackbar={showConfirmation} />
    </Box>
  );
}
