import type { PublicProfileFormProps } from '../PublicProfileForm';
import type { PublicProfileFormState } from './usePublicProfileFormState';
import { env } from 'env';
import { useMemo } from 'react';

interface UsePublicProfileHasUnsavedChangesArgs {
  publicProfile: PublicProfileFormProps['publicProfile'];
  formState: PublicProfileFormState;
  bannerSrc: string;
  bannerMobileSrc: string;
  profileSrc: string;
}

export function usePublicProfileHasUnsavedChanges({
  publicProfile,
  formState,
  bannerSrc,
  bannerMobileSrc,
  profileSrc,
}: UsePublicProfileHasUnsavedChangesArgs) {
  const hasUnsavedChanges = useMemo(
    () =>
      computeHasUnsavedChanges({
        publicProfile,
        formState,
        bannerSrc,
        bannerMobileSrc,
        profileSrc,
      }),
    [bannerMobileSrc, bannerSrc, formState, profileSrc, publicProfile]
  );

  return { hasUnsavedChanges };
}

function computeHasUnsavedChanges({
  publicProfile,
  formState,
  bannerSrc,
  bannerMobileSrc,
  profileSrc,
}: UsePublicProfileHasUnsavedChangesArgs) {
  return (
    formState.city.value !== publicProfile.city ||
    formState.colorTheme.value !== publicProfile.colorTheme ||
    formState.description.value !== publicProfile.description ||
    formState.displayName.value !== publicProfile.displayName ||
    formState.slug.value !== publicProfile.slug ||
    formState.state.value !== publicProfile.state ||
    formState.isEnabled.value !== publicProfile.isEnabled ||
    formState.instagramHandle.value !== publicProfile.instagramHandle ||
    formState.twitterHandle.value !== publicProfile.twitterHandle ||
    formState.linkedInHandle.value !== publicProfile.linkedInHandle ||
    formState.tikTokHandle.value !== publicProfile.tikTokHandle ||
    (publicProfile.organizations || []).some(
      (org) =>
        !(formState.organizations || []).find(
          (formStateOrg) => org.id === formStateOrg.id && formStateOrg.value === org.description
        )
    ) ||
    !imagesMatch(bannerSrc, publicProfile.bannerImage) ||
    !imagesMatch(bannerMobileSrc, publicProfile.bannerImageMobile) ||
    !imagesMatch(profileSrc, publicProfile.profileImage)
  );
}

function imagesMatch(formImage: string, imgPath: string) {
  return (!formImage && !imgPath) || formImage === `${env.REACT_APP_S3_PATH}/donors/${imgPath}`;
}
