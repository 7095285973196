import Stripe from '@stripe/stripe-js';
import { useState } from 'react';
import { Grid } from '@mui/material';
import ConfirmBankAccount from './components/ConfirmBankAccount';
import ConnectBankAccount from './components/ConnectBankAccount';

interface AddBankFormProps {
  campaignId?: string;
  clientSecret?: string | null;
  onCancel: () => void;
  onComplete: () => void;
}

enum FORM_STEPS {
  confirming = 'confirming',
  adding = 'adding',
}

export default function AddBankForm({ campaignId, clientSecret, onCancel, onComplete }: AddBankFormProps) {
  const [formStep, setFormStep] = useState<FORM_STEPS>(FORM_STEPS.adding);
  const [paymentMethod, setPaymentMethod] = useState<Stripe.PaymentMethod | { id: string } | null>(null);
  if (!clientSecret) return null;

  function handlePaymentMethodConnected(paymentMethod: Stripe.PaymentMethod | { id: string } | null) {
    setPaymentMethod(paymentMethod);
    setFormStep(FORM_STEPS.confirming);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {formStep === FORM_STEPS.adding ? (
            <ConnectBankAccount
              onCancel={onCancel}
              onPaymentMethodConnected={handlePaymentMethodConnected}
              clientSecret={clientSecret}
            />
          ) : (
            <ConfirmBankAccount
              paymentMethod={paymentMethod}
              clientSecret={clientSecret}
              onCancel={onCancel}
              onPaymentMethodAdded={onComplete}
              campaignId={campaignId}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
