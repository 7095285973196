import { useCallback } from 'react';
import { gtagEvent, gtagIdentify } from 'utils/gtag';

export enum events {
  addOrganizationToPortfolio = 'addOrganizationToPortfolio',
  addOrganizationToPortfolioFromSearch = 'addOrganizationToPortfolioFromSearch',
  giveNow = 'giveNow',
  addPaymentMethod = 'addPaymentMethod',
  cancelGivingPlan = 'cancelGivingPlan',
  login = 'login',
  removePaymentMethod = 'removePaymentMethod',
  search = 'search',
  shareCampaign = 'shareCampaign',
  updateGivingPlan = 'updateGivingPlan',
  shareProfile = 'shareProfile',
}

type TrackEvent = {
  (eventName: events): void;
  (eventName: events.addOrganizationToPortfolio, eventParams: { organizationId: string }): void;
  (eventName: events.addOrganizationToPortfolioFromSearch, eventParams: { ein: string }): void;
  (eventName: events.login, eventParams: { method: string }): void;
  (eventName: events.search, eventParams: { search_term: string }): void;
  (eventName: events.giveNow, eventParams: { ein: string; amountCents: number; tipsCents: number }): void;
  (eventName: events.shareCampaign, eventParams: { campaignId: string }): void;
  (eventName: events.shareProfile, eventParams: { donorId: string }): void;
  (
    eventName: events.updateGivingPlan,
    eventParams: {
      donationFrequency: string;
      amountCents: number;
      startAt: Date;
      tipsCents: number;
    }
  ): void;
};

export default function useAnalytics() {
  const trackEvent = useCallback<TrackEvent>((eventName: string, eventParams?: { [key: string]: any }) => {
    gtagEvent(eventName, eventParams);
  }, []);

  const identify = useCallback((donorId: string) => {
    gtagIdentify(donorId);
  }, []);

  return {
    trackEvent,
    identify,
  };
}
