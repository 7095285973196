import { useMemo } from 'react';
import { concat, createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { env } from 'env';
import { getAccessTokenFromStorage } from 'utils/storage';

export default function useLinkedApolloClient() {
  return useMemo(() => {
    const httpLinkNode = createHttpLink({
      uri: env.REACT_APP_NODE_GRAPHQL_URL,
    });

    const authMiddleware = setContext(async (_, { headers }) => {
      const token = getAccessTokenFromStorage();
      const authorizationHeader = token ? `Bearer ${token}` : '';

      return {
        headers: {
          ...headers,
          Authorization: authorizationHeader,
        },
      };
    });

    const link = concat(authMiddleware, httpLinkNode);

    return new ApolloClient({
      link,
      connectToDevTools: Boolean(env.REACT_APP_APOLLO_DEV_TOOLS) || false,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              getOrganizations: relayStylePagination(['search', 'missingImagesOnly', 'sortedByPopularity']),
              getCampaigns: relayStylePagination(['search']),
              getDonors: relayStylePagination(['search', 'publicOnly']),
            },
          },
        },
      }),
    });
  }, []);
}
