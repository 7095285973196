import { Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Organization from '../../model/Organization.model';
import Allocation from '../../model/Allocation';
import OrganizationCard from '../organization/OrganizationCard/OrganizationCard';

const useStyles: any = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly !important',
      paddingBottom: 35,
    },
    displayOrgs: {
      display: 'inline-flex',
      flexBasis: 'max-content !important',
      justifyContent: 'space-evenly',
    },
  })
);

interface OrganizationGridProps {
  organizationList: Organization[];
  currentOrganizations?: Allocation[];
  showAdd: boolean;
}

const OrganizationGrid = ({ organizationList, currentOrganizations, showAdd }: OrganizationGridProps) => {
  const orgIsInPortfolio = (organizationId: string): boolean => {
    if (!currentOrganizations) return false;

    const org = currentOrganizations.find((x) => x.organizationId === organizationId);
    return org !== undefined;
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.container} spacing={2}>
      {organizationList.length > 0 &&
        organizationList.map((organization: Organization) => (
          <Grid item xs={12} className={classes.displayOrgs} key={organization.ein}>
            <OrganizationCard
              key={organization.ein}
              organization={organization}
              showAdd={showAdd}
              currentlyInPortfolio={currentOrganizations && orgIsInPortfolio(organization.id)}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default OrganizationGrid;
