import Box from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';

interface PageContainerProps extends ContainerProps {
  children?: React.ReactNode;
  bgColor?: string;
}

const PageContainer = (props: PageContainerProps) => {
  return (
    <Container {...props } disableGutters maxWidth={false}>
      <Box sx={{ width: '100%', backgroundColor: props.bgColor || 'secondary.light', padding: 2, borderRadius: 4 }}>{props.children}</Box>
    </Container>
  );
};

export default PageContainer;
