import type { SelectChangeEvent } from '@mui/material';
import type { DonorPublicProfile } from 'model/Donor.model';
import type DonorProfile from 'model/DonorProfile.model';
import { useState } from 'react';
import {
  setStateFromInputBlur,
  setStateFromInputChange,
  setStateFromInputCheck,
  setStateFromSelectChange,
} from 'helpers/formHelpers';
import { useIsDonorProfileSlugTaken } from './useIsDonorProfileSlugTaken';

export enum PublicProfileFormStateKey {
  city = 'city',
  colorTheme = 'colorTheme',
  description = 'description',
  displayName = 'displayName',
  instagramHandle = 'instagramHandle',
  isEnabled = 'isEnabled',
  linkedInHandle = 'linkedInHandle',
  organizations = 'organizations',
  slug = 'slug',
  state = 'state',
  tikTokHandle = 'tikTokHandle',
  twitterHandle = 'twitterHandle',
}

export function usePublicProfileFormState(donor: DonorProfile, publicProfile: DonorPublicProfile) {
  const { isSlugTaken, checkIfSlugIsTaken } = useIsDonorProfileSlugTaken(donor.id);
  const [formState, setFormState] = useState(getStateFromDonor(donor, publicProfile));

  function handleSlugInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.value) checkIfSlugIsTaken(event.currentTarget.value as string);
    setStateFromInputChange(event, setFormState);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStateFromInputChange(event, setFormState);
  }

  function handleIsEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.currentTarget.checked) {
      setFormState((prevState) => ({ ...prevState, slug: { ...prevState.slug, value: '' } }));
    } else {
      if (publicProfile.slug) {
        setFormState((prevState) => ({ ...prevState, slug: { ...prevState.slug, value: publicProfile.slug } }));
        checkIfSlugIsTaken(publicProfile.slug);
      }
    }
    setStateFromInputCheck(event, setFormState);
  }

  function handleSelectChange(event: SelectChangeEvent<string>) {
    setStateFromSelectChange(event, setFormState);
  }

  function handleOrganizationDescriptionInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name: id, value } = event.currentTarget;
    setFormState((prevState) => ({
      ...prevState,
      organizations: [
        ...prevState.organizations.filter((org) => org.id !== id),
        { id, value, hasBlurredAtLeastOnce: true },
      ],
    }));
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    setStateFromInputBlur(event, setFormState);
  }

  function blurFormState() {
    setFormState((prev) => ({
      city: {
        hasBlurredAtLeastOnce: true,
        value: prev.city.value,
      },
      colorTheme: {
        hasBlurredAtLeastOnce: true,
        value: prev.colorTheme.value,
      },
      description: {
        hasBlurredAtLeastOnce: true,
        value: prev.description.value,
      },
      displayName: {
        hasBlurredAtLeastOnce: true,
        value: prev.displayName.value,
      },
      organizations: prev.organizations.map((organization) => ({
        id: organization.id,
        value: organization.value,
        hasBlurredAtLeastOnce: true,
      })),
      slug: {
        hasBlurredAtLeastOnce: true,
        value: prev.slug.value,
      },
      state: {
        hasBlurredAtLeastOnce: true,
        value: prev.state.value,
      },
      isEnabled: {
        hasBlurredAtLeastOnce: true,
        value: prev.isEnabled.value,
      },
      instagramHandle: {
        hasBlurredAtLeastOnce: true,
        value: prev.instagramHandle.value,
      },
      twitterHandle: {
        hasBlurredAtLeastOnce: true,
        value: prev.twitterHandle.value,
      },
      linkedInHandle: {
        hasBlurredAtLeastOnce: true,
        value: prev.linkedInHandle.value,
      },
      tikTokHandle: {
        hasBlurredAtLeastOnce: true,
        value: prev.tikTokHandle.value,
      },
    }));
  }

  function resetFormState(publicProfile: DonorPublicProfile) {
    setFormState(getStateFromDonor(donor, publicProfile));
  }

  return {
    formState,
    resetFormState,
    blurFormState,
    isSlugTaken,
    handleSlugInputChange,
    handleInputChange,
    handleIsEnabledChange,
    handleSelectChange,
    handleOrganizationDescriptionInputChange,
    handleInputBlur,
  };
}

export type PublicProfileFormState = {
  [key in Exclude<
    PublicProfileFormStateKey,
    PublicProfileFormStateKey.organizations | PublicProfileFormStateKey.isEnabled
  >]: {
    value: string;
    hasBlurredAtLeastOnce: boolean;
  };
} & {
  organizations: { id: string; value: string; hasBlurredAtLeastOnce: boolean }[];
  isEnabled: {
    value: boolean;
    hasBlurredAtLeastOnce: boolean;
  };
};

function getStateFromDonor(donorProfile: DonorProfile, publicProfile: DonorPublicProfile): PublicProfileFormState {
  return {
    city: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.city || '',
    },
    colorTheme: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.colorTheme || '',
    },
    description: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.description || '',
    },
    displayName: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.displayName === 'undefined undefined' ? '' : publicProfile.displayName,
    },
    organizations: (donorProfile.allocations || []).map((allocation) => ({
      id: allocation.organization.id,
      value:
        (publicProfile.organizations || []).find((org) => org.id === allocation.organization.id)?.description || '',
      hasBlurredAtLeastOnce: false,
    })),
    slug: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.slug || '',
    },
    state: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.state || '',
    },
    isEnabled: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.isEnabled,
    },
    instagramHandle: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.instagramHandle,
    },
    twitterHandle: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.twitterHandle,
    },
    linkedInHandle: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.linkedInHandle,
    },
    tikTokHandle: {
      hasBlurredAtLeastOnce: false,
      value: publicProfile.tikTokHandle,
    },
  };
}
