import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography, Card, CardContent, Theme, createStyles, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { env } from 'env';
import { CampaignCardProps } from '../CampaignList.interfaces';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    centerContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    fillContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    logo: {
      width: 64,
      height: 64,
    },
  })
);

const CampaignCard = ({ campaign }: CampaignCardProps) => {
  const classes = useStyles();
  const history = useHistory();

  const logoImage = `${env.REACT_APP_S3_PATH}/campaigns/${campaign.displayData?.logoImage}`;

  return (
    <>
      <Grid item xs={12} md={6} className={classes.fillContainer}>
        <Card className={classes.fillContainer}>
          <CardContent className={classes.fillContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Grid container>
                    <Grid item sx={{ width: 60}}>
                      <img className={classes.logo} src={logoImage} alt="" />
                    </Grid>
                    <Grid item xs>
                      <Typography sx={{ ml: 2, textAlign: 'left' }} variant="h5">
                        {campaign.title}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography variant="body1" className="campaignTextLimit">
                    {campaign.description}
                  </Typography>
                  <Button onClick={() => history.push(`/c/${campaign.slug}`)} variant="contained" color="primary">
                    View Campaign
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CampaignCard;
