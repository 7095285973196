import { createTheme, Theme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    light: true;
  }
}

const globalTheme: Theme = createTheme({
  palette: {
    primary: {
      main: '#072736',
      light: '#FFFFFF',
    },
    secondary: {
      main: '#86D966',
      light: '#EDF9ED',
    },
    info: {
      main: '#FFFFFF',
    },
    warning: {
      main: '#395884',
    },
  },
});

export const lightTheme: Theme = createTheme({
  palette: globalTheme.palette,
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'light', color: 'primary' },
          style: {
            textTransform: 'none',
            border: 'none',
            backgroundColor: globalTheme.palette.primary.light,
            color: '#072736',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: alpha(globalTheme.palette.primary.light, 0.74),
              '&:active': {
                backgroundColor: alpha(globalTheme.palette.primary.light, 0.62),
              },
            },
          },
        },
      ],
    },
  },
});
