import { Typography, Grid } from '@mui/material';
import { env } from 'env';
import Organization from 'model/Organization.model';
import CauseAreaChip from 'components/organization/CauseAreaChip';

export default function OrganizationPreview({ organization }: OrganizationPreviewProps) {
  const imageUrl = !organization.imageName
    ? env.REACT_APP_DEFAULT_ORG_IMAGE_URL
    : `${env.REACT_APP_ORGANIZATION_IMAGE_PATH}${organization.imageName}`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <img
          style={{ width: 150, maxWidth: '100%', height: 'auto' }}
          src={imageUrl}
          alt={`${organization.name} logo`}
        />
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant="h6">{organization.name}</Typography>
        {organization.causeArea && <CauseAreaChip causeArea={organization.causeArea} colorSet={0} />}
        {(organization.campaignSections || []).map((section, index) => (
          <CauseAreaChip key={section} causeArea={section} colorSet={Math.min(2, index + 1)} />
        ))}
        EIN: {organization.ein}
      </Grid>
    </Grid>
  );
}

interface OrganizationPreviewProps {
  organization: Organization;
}
