import { Grid, TextField, Box, FormControlLabel, Checkbox } from '@mui/material';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';
import DonorProfile from 'model/DonorProfile.model';
import { setStateFromInputChange, setStateFromInputCheck, setStateFromInputBlur } from 'helpers/formHelpers';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import useDonorProfile from 'hooks/useDonorProfile';

export default function PaymentInfo({ formState, setFormState, errors }: PaymentInfoProps) {
  const { isAuthenticatedAndReady } = useStoredAuth0Values();
  const [donorProfile] = useDonorProfile();

  useEffect(() => {
    if (!isAuthenticatedAndReady) return;
    if (!donorProfile) return;

    setFormState(getFormStateFromDonorProfile(donorProfile));
  }, [isAuthenticatedAndReady, donorProfile, setFormState]);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStateFromInputChange(event, setFormState);
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStateFromInputCheck(event, setFormState);
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    setStateFromInputBlur(event, setFormState);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          error={formState.firstName.hasBlurredAtLeastOnce && !!errors.firstName}
          fullWidth={true}
          helperText={formState.firstName.hasBlurredAtLeastOnce && errors.firstName}
          label="First Name"
          name="firstName"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          required
          value={formState.firstName.value}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          error={formState.lastName.hasBlurredAtLeastOnce && !!errors.lastName}
          fullWidth={true}
          helperText={formState.lastName.hasBlurredAtLeastOnce && errors.lastName}
          label="Last Name"
          name="lastName"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          required
          value={formState.lastName.value}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={formState.email.hasBlurredAtLeastOnce && !!errors.email}
          fullWidth={true}
          helperText={formState.email.hasBlurredAtLeastOnce && errors.email}
          label="Email"
          name="email"
          disabled={isAuthenticatedAndReady && !!donorProfile?.email}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          required
          type="email"
          value={formState.email.value}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth={true}
          label="Designation"
          multiline
          name="designation"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          placeholder="Add instructions for this nonprofit"
          rows={3}
          type="text"
          value={formState.designation.value}
        />
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            borderRadius: '3.84px',
            border: '0.5px solid',
            borderColor: errors.paymentMethod ? '#d32f2f' : '#818181',
            margin: 'auto',
            px: 1,
            py: 2,
          }}
        >
          <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
        </Box>
        {errors.paymentMethod && (
          <Typography variant="caption" sx={{ color: '#d32f2f', mx: 1.75 }}>
            {errors.paymentMethod}
          </Typography>
        )}
      </Grid>

      <Grid item xs={8}>
        <Typography variant="caption">
          We use smart, safe payment technology so donations are secure and minimize fees.
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <a href="https://stripe.com/" target="_blank" rel="noreferrer">
          <img
            alt="powered by stripe logo"
            src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"
          />
        </a>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormControlLabel
          control={<Checkbox name="anonymous" checked={!!formState.anonymous.value} onChange={handleCheckboxChange} />}
          label="Donate Anonymously"
          labelPlacement="start"
        />
      </Grid>
    </Grid>
  );
}

function getFormStateFromDonorProfile(donorProfile: DonorProfile): PaymentInfoFormState {
  return {
    firstName: {
      value: donorProfile.firstName,
      hasBlurredAtLeastOnce: false,
    },
    lastName: {
      value: donorProfile.lastName,
      hasBlurredAtLeastOnce: false,
    },
    email: {
      value: donorProfile.email,
      hasBlurredAtLeastOnce: false,
    },
    anonymous: {
      value: undefined,
      hasBlurredAtLeastOnce: false,
    },
    designation: {
      value: undefined,
      hasBlurredAtLeastOnce: false,
    },
    paymentMethod: {
      value: undefined,
      hasBlurredAtLeastOnce: false,
    },
  };
}

enum PaymentInfoFormStateKey {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  anonymous = 'anonymous',
  designation = 'designation',
  paymentMethod = 'paymentMethod',
}

type PaymentInfoErrors = {
  [key in PaymentInfoFormStateKey]?: string | null;
};

interface PaymentInfoProps {
  formState: PaymentInfoFormState;
  setFormState: React.Dispatch<React.SetStateAction<PaymentInfoFormState>>;
  errors: PaymentInfoErrors;
}

export type PaymentInfoFormState = {
  [key in Exclude<PaymentInfoFormStateKey, PaymentInfoFormStateKey.anonymous>]: {
    value?: string;
    hasBlurredAtLeastOnce: boolean;
  };
} & {
  anonymous: {
    value?: boolean;
    hasBlurredAtLeastOnce: boolean;
  };
};

export const defaultPaymentInfoFormState: PaymentInfoFormState = {
  firstName: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
  lastName: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
  email: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
  anonymous: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
  designation: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
  paymentMethod: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
};
