import { Snackbar, Alert, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface StatusSnackbarProps {
  hasErrored: boolean;
  hasUpdated: boolean;
  slug: string;
  onClose: () => void;
}

export default function StatusSnackbar({ slug, hasErrored, hasUpdated, onClose }: StatusSnackbarProps) {
  if (!hasErrored && !hasUpdated) return null;

  if (hasErrored)
    return (
      <Snackbar open={true} autoHideDuration={3500} onClose={onClose}>
        <Alert elevation={6} severity="error" variant="filled" sx={{ width: '100%' }}>
          Oops, it looks like an error occurred
        </Alert>
      </Snackbar>
    );

  if (!slug)
    return (
      <Snackbar open={true} autoHideDuration={10000} onClose={onClose}>
        <Alert elevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>
          Public Profile Disabled
        </Alert>
      </Snackbar>
    );

  return (
    <Snackbar open={true} autoHideDuration={10000} onClose={onClose}>
      <Alert elevation={6} severity="success" variant="filled" sx={{ width: '100%' }}>
        Public profile saved!{' '}
        <Link component={RouterLink} to={`/d/${slug}`} target="_blank">
          View Profile
        </Link>
      </Alert>
    </Snackbar>
  );
}
