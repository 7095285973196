import type { Donation } from 'components/DonorHistoryPage/hooks/useDonorDonationsQuery';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { convertCentsToDollars } from 'helpers/currencyHelper';

type DonationMatch = Donation['donationMatches'][number];

export default function PendingMatchListItem({ donationMatch }: { donationMatch: DonationMatch }) {
  const { slug, title } = donationMatch.campaign;
  const matchedAmount = convertCentsToDollars(donationMatch.matchedAmountInCents);

  return (
    <>
      {matchedAmount} is pending a match by{' '}
      <Link component={RouterLink} to={`/c/${slug}`}>
        {title}
      </Link>
    </>
  );
}
