import { Box, Button, Typography } from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface DonorHistoryHeaderProps {
  onExportDataClick: () => void;
  onManagePaymentClick: () => void;
}

export default function DonorHistoryHeader({ onExportDataClick, onManagePaymentClick }: DonorHistoryHeaderProps) {
  return (
    <>
      <Box
        sx={{
          alignItems: { md: 'center', xs: 'flex-start' },
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          flexWrap: 'wrap',
          justifyContent: { md: 'space-between', xs: 'flex-start' },
          mt: 2,
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FactCheckIcon />
          <Box>
            <Typography variant="h5">My History</Typography>
            <Typography variant="subtitle2">
              Review all your charitable giving. To include donations made outside Hedado, forward donation receipts to{' '}
              <a href="mailto:donations@hedado.com" target="_blank" rel="noreferrer">
                donations@hedado.com
              </a>
              .
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Button variant="outlined" size="large" onClick={onManagePaymentClick} startIcon={<CreditCardIcon />}>
            Manage Payment
          </Button>
          <Button
            sx={{ display: { xs: 'none', sm: 'flex' } }}
            variant="outlined"
            size="large"
            onClick={onExportDataClick}
            startIcon={<FileDownloadIcon />}
          >
            Export Data
          </Button>
        </Box>
      </Box>
    </>
  );
}
