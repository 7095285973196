import { gql } from '@apollo/client';
import Organization from '../../model/Organization.model';

export interface GetOrganizationByIdQueryVariables {
  id: string;
}

export interface GetOrganizationByIdQueryResponse {
  getOrganizationById: Organization & { __typename: 'Organization' };
}

export const getOrganizationByIdGql = gql`
  query GetOrganizationsById($id: String!) {
    getOrganizationById(id: $id) {
      affiliation
      annualReportUrl
      assetAmt
      assetCd
      assets
      causeArea
      charityNavigatorUrl
      city
      classification
      context
      deductibility
      deactivatedAt
      designation
      ein
      financialsUrl
      form990Url
      foundation
      globalImpact
      group
      guidestarUrl
      ico
      id
      imageName
      immediateImpact
      impact
      incomeAmt
      incomeCd
      localImpact
      longTermImpact
      mission
      name
      nteeCd
      orgSize
      parentEin
      programRestriction
      revenue
      revenueAmt
      routingOrganizationName
      ruling
      sortName
      state
      street
      subsection
      taxPeriod
      websiteUrl
      whyYouShouldCare
      zip
    }
  }
`;
