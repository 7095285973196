import { gql } from '@apollo/client';
import { CampaignTier } from 'model/CampaignTier.model';

export interface GetCampaignsWhereDonorIsAdminQueryResponse {
  getCampaignsWhereDonorIsAdmin: {
    id: string;
    title: string;
    slug?: string;
    nameOnReceipt: string;
    tier: CampaignTier;
    displayData: {
      logoImage?: string;
    };
  }[];
}

export const getCampaignsWhereDonorIsAdmin_Name = 'GetCampaignsWhereDonorIsAdmin';
export const getCampaignsWhereDonorIsAdmin_Gql = gql`
  query GetCampaignsWhereDonorIsAdmin {
    getCampaignsWhereDonorIsAdmin {
      id
      title
      slug
      nameOnReceipt
      tier
      displayData {
        logoImage
      }
    }
  }
`;
