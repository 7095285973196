import { gql } from '@apollo/client';

export interface GetDonorDonationStatisticsQueryResponse {
  getDonorDonationStatistics: {
    donationsTotalCents: number;
    settledMatchesTotalCents: number;
    donationsTotal: number;
    organizationsTotal: number;
  };
}

export const getDonorDonationStatisticsGql = gql`
  query GetDonorDonationStatistics {
    getDonorDonationStatistics {
      donationsTotalCents
      settledMatchesTotalCents
      donationsTotal
      organizationsTotal
    }
  }
`;
