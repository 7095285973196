import { useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography, Tooltip } from '@mui/material';
import useDonorProfile from 'hooks/useDonorProfile';
import { useSetupIntentSecret } from 'components/PaymentMethod/hooks/useSetupIntentSecret';
import AddBankForm from './components/AddBankForm/AddBankForm';
import AddCreditCardForm from './components/AddCreditCardForm';

interface ChangePaymentMethodProps {
  campaignId?: string;
  onCancel: () => void;
  onSubmitted: () => void;
}

enum PaymentMethodType {
  bank = 'bank',
  creditCard = 'creditCard',
}

export default function ChangePaymentMethod({ campaignId, onCancel, onSubmitted }: ChangePaymentMethodProps) {
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(PaymentMethodType.creditCard);
  const { clientSecret } = useSetupIntentSecret();
  const [donorProfile] = useDonorProfile();

  function handleTabChange(event: React.SyntheticEvent<Element, Event>, value: any) {
    setPaymentMethodType(value);
  }

  const showBankOption = !!donorProfile;
  const isBankOptionDisabled = !!(donorProfile && (!donorProfile.firstName || !donorProfile.lastName));

  return (
    <>
      <Tabs value={paymentMethodType} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Credit Card" value={PaymentMethodType.creditCard} />
        {showBankOption &&
          (isBankOptionDisabled ? (
            <Tooltip title="Please provide your first and last name in your account settings to use a bank payment method">
              <span>
                <Tab label="Bank" value={PaymentMethodType.bank} disabled={isBankOptionDisabled} />
              </span>
            </Tooltip>
          ) : (
            <Tab label="Bank" value={PaymentMethodType.bank} />
          ))}
      </Tabs>

      {paymentMethodType === PaymentMethodType.creditCard ? (
        <AddCreditCardForm onCancel={onCancel} onComplete={onSubmitted} campaignId={campaignId} />
      ) : paymentMethodType === PaymentMethodType.bank ? (
        <AddBankForm onCancel={onCancel} onComplete={onSubmitted} campaignId={campaignId} clientSecret={clientSecret} />
      ) : null}

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} sm={7} md={9}>
          <Typography variant="caption">
            We use smart and secure online payments to ensure that your donations are secure and minimize fees.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={3} sx={{ textAlign: 'right', verticalAlign: 'middle', paddingLeft: 1 }}>
          <a href="https://stripe.com/" target="_blank" rel="noreferrer">
            <Box
              alt="powered by stripe logo"
              src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"
              component="img"
              sx={{ width: '100%', maxWidth: '150px' }}
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
}
