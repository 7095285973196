import type { DonationsOrderByAttribute } from 'gql/donations/getDonorDonations';
import type { Donation } from 'components/DonorHistoryPage/hooks/useDonorDonationsQuery';
import dayjs from 'dayjs';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { convertCentsToDollars } from 'helpers/currencyHelper';
import { tableColumns } from 'components/DonorHistoryPage/hooks/useDonationsTableState';
import { TableCell } from '@mui/material';

interface DonationTableCellProps {
  tableColumn: typeof tableColumns[number];
  donation: Donation;
  onDonationMatchesClick: (donationMatches: Donation['donationMatches']) => void;
}

export default function DonationTableCell({ tableColumn, donation, onDonationMatchesClick }: DonationTableCellProps) {
  function handleCellClick() {
    if (tableColumn.id !== 'donationMatches' || donation.donationMatches.length === 0) return;

    onDonationMatchesClick(donation.donationMatches);
  }

  const doesDonationHaveAnyMatches = donation.donationMatches.length > 0;

  return (
    <TableCell
      align={tableColumn.numeric ? 'right' : 'left'}
      width={tableColumn.width}
      sx={{
        maxWidth: `${tableColumn.width}px`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: doesDonationHaveAnyMatches && tableColumn.id === 'donationMatches' ? 'pointer' : 'default',
        textDecoration: doesDonationHaveAnyMatches && tableColumn.id === 'donationMatches' ? 'underline' : 'default',
      }}
      onClick={handleCellClick}
    >
      {getCellValue(donation, tableColumn.id)}
    </TableCell>
  );
}

function getCellValue(donation: Donation, key: DonationsOrderByAttribute | 'donationMatches'): string | number {
  switch (key) {
    case 'createdAt':
      return dayjs(donation.createdAt).format('MM-DD-YYYY');
    case 'organizationEin':
      return donation.organization?.ein || '';
    case 'organizationName':
      return donation.organization?.name || '';
    case 'organizationTaxDeductibility':
      return donation.organization?.deductibility === 1 ? 'True' : 'False';
    case 'amountCents':
      return convertCentsToDollars(donation.amountCents || 0);
    case 'donationMatches':
      if (donation.donationMatches.length) {
        const totalMatched = donation.donationMatches
          .filter((donationMatch) => donationMatch.status !== DonationMatchStatus.excluded)
          .reduce((acc, curr) => acc + curr.matchedAmountInCents, 0);

        return convertCentsToDollars(totalMatched);
      } else {
        return '';
      }
  }
}
