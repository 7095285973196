import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import {
  getDonorDonationStatisticsGql,
  GetDonorDonationStatisticsQueryResponse,
} from 'gql/donations/getDonorDonationStatistics';

export function useDonorDonationStatisticsQuery() {
  const { data, loading, waiting } =
    useAuthenticatedHedadoQuery<GetDonorDonationStatisticsQueryResponse>(getDonorDonationStatisticsGql);

  return { statistics: data?.getDonorDonationStatistics || null, loading, waiting };
}
