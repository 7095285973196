import { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  blurState,
  setStateFromInputChange,
  setStateFromInputBlur,
  hasAnyError,
  hasAnyVisibleError,
} from 'helpers/formHelpers';
import useAnalytics, { events } from 'hooks/useAnalytics';
import { useUpdatePaymentMethod } from 'components/PaymentMethod/hooks/useUpdatePaymentMethod';
import LoadingButton from 'components/LoadingButton';

export default function AddCreditCardForm({ campaignId, onCancel, onComplete }: AddCreditCardFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [formState, setFormState] = useState(defaultCreditCardFormState);
  const [stripeError, setStripeError] = useState<string | null>(null);
  const [stripeUpdateCardLoading, setStripeUpdateCardLoading] = useState(false);
  const [updatePaymentMethodErrored, setUpdatePaymentMethodErrored] = useState(false);
  const { trackEvent } = useAnalytics();

  const { updatePaymentMethod, loading: updatePaymentMethodLoading } = useUpdatePaymentMethod({
    onCompleted: onComplete,
    onError: () => {
      setStripeUpdateCardLoading(false);
      setUpdatePaymentMethodErrored(true);
    },
  });

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    setStateFromInputBlur(event, setFormState);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStateFromInputChange(event, setFormState);
  }

  useEffect(() => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    cardElement.on('change', function (event) {
      if (event.error) {
        setStripeError(event.error.message);
      } else {
        setStripeError(null);
      }
    });
  }, [stripe, elements]);

  async function createPaymentMethod() {
    if (!stripe || !elements) return;
    const element = elements.getElement(CardElement);
    if (!element) return;

    const { nameOnCard } = formState;

    return await stripe.createPaymentMethod({
      type: 'card',
      card: element,
      billing_details: {
        name: nameOnCard.value,
      },
    });
  }

  async function handleSubmitClick() {
    const errors = computeFormStateErrors(formState, stripeError);
    if (hasAnyError(errors)) {
      blurState(setFormState);
      return;
    }
    setStripeUpdateCardLoading(true);

    const createPaymentMethodResult = await createPaymentMethod();

    if (!createPaymentMethodResult || createPaymentMethodResult.error || !createPaymentMethodResult.paymentMethod) {
      setStripeError(
        createPaymentMethodResult?.error?.message || 'Oops!  It looks like there was an error adding your card'
      );
      setStripeUpdateCardLoading(false);
      return;
    }

    trackEvent(events.addPaymentMethod);
    await updatePaymentMethod({
      variables: { paymentMethodId: createPaymentMethodResult.paymentMethod.id, campaignId },
    });
  }

  const errors = computeFormStateErrors(formState, stripeError);
  const isFormDisabled = hasAnyVisibleError(errors, formState);
  const isFormLoading = updatePaymentMethodLoading || stripeUpdateCardLoading;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          error={formState.nameOnCard.hasBlurredAtLeastOnce && !!errors.nameOnCard}
          fullWidth={true}
          helperText={formState.nameOnCard.hasBlurredAtLeastOnce && errors.nameOnCard}
          label="Name on card"
          name="nameOnCard"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          required
          value={formState.nameOnCard.value || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            borderRadius: '3.84px',
            border: '0.5px solid',
            borderColor: errors.paymentMethod ? '#d32f2f' : '#818181',
            margin: 'auto',
            px: 1,
            py: 2.25,
          }}
        >
          <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
        </Box>
        {errors.paymentMethod && (
          <Typography variant="caption" sx={{ color: '#d32f2f', mx: 1.75 }}>
            {errors.paymentMethod}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box>
          <LoadingButton
            onClick={handleSubmitClick}
            variant="contained"
            loading={isFormLoading}
            disabled={isFormDisabled}
            size="small"
          >
            Submit
          </LoadingButton>
          <Button onClick={onCancel} size="small" variant="outlined" sx={{ ml: 2 }}>
            Cancel
          </Button>
        </Box>
        {updatePaymentMethodErrored && (
          <Typography variant="caption" sx={{ color: '#d32f2f', mt: 2 }}>
            Oops, it looks like an error occurred.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

interface AddCreditCardFormProps {
  campaignId?: string;
  onCancel: () => void;
  onComplete: () => void;
}

const defaultCreditCardFormState: CreditCardFormState = {
  nameOnCard: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
  paymentMethod: {
    value: undefined,
    hasBlurredAtLeastOnce: false,
  },
};

enum CreditCardFormStateKey {
  nameOnCard = 'nameOnCard',
  paymentMethod = 'paymentMethod',
}

type CreditCardErrors = {
  [key in CreditCardFormStateKey]?: string | null;
};

type CreditCardFormState = {
  [key in CreditCardFormStateKey]: {
    value?: string;
    hasBlurredAtLeastOnce: boolean;
  };
};

function computeFormStateErrors(formState: CreditCardFormState, stripeError: string | null): CreditCardErrors {
  return {
    nameOnCard: !formState.nameOnCard.value ? 'This field is required' : '',
    paymentMethod: stripeError || '',
  };
}
