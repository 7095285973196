import React from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexWrap: 'nowrap !important',
      marginBottom: theme.spacing(2),
    },
    icon: {
      display: 'flex',
      flexGrow: 0,
    },
    header: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    title: {},
  })
);

interface PageHeaderProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  subTitle: string;
  sx?: any;
}

const PageHeader = ({ icon, title, subTitle, sx }: PageHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} className={classes.container} sx={{ ...sx, marginTop: { xs: 0 } }}>
        <Grid item className={classes.icon}>
          {icon}
        </Grid>
        <Grid item className={classes.header}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{subTitle}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PageHeader;
