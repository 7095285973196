import { gql } from '@apollo/client';

export interface GetCampaignAdminStatisticsQueryVariables {
  id: string;
}

export interface GetCampaignAdminStatisticsQueryResponse {
  getCampaignAdminStatistics: {
    __typename: 'CampaignAdminStatistics';
    id: string;
    donationsTotalCents: number;
    donorsTotal: number;
    organizationsTotal: number;
    pendingMatchesTotalCents: number;
    settledMatchesTotalCents: number;
  };
}

export const getCampaignAdminStatisticsGql = gql`
  query GetCampaignAdminStatistics($id: ID!) {
    getCampaignAdminStatistics(id: $id) {
      id
      donationsTotalCents
      donorsTotal
      organizationsTotal
      pendingMatchesTotalCents
      settledMatchesTotalCents
    }
  }
`;
