import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconButton, MenuItem, Menu, Divider, Badge, Tooltip } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { env } from 'env';
import useDonorProfile from 'hooks/useDonorProfile';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useDonorPublicProfile from 'hooks/useDonorPublicProfile';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import DonorNameOrAccountLink from 'components/global/DonorNameOrAccountLink';
import { getCampaignsWhereDonorIsAdmin_Gql } from 'gql/campaigns/getCampaignsWhereDonorIsAdmin';
import Campaign from 'model/Campaign.model';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    accountButtons: {
      paddingRight: 10,
    },
  })
);

const AccountProfileButton = () => {
  const { isAdmin } = useStoredAuth0Values();
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();

  const { logout, isAuthenticated } = useAuth0();
  const [donorProfile] = useDonorProfile();
  const { isEnabled: isPublicProfileEnabled } = useDonorPublicProfile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { data } = useAuthenticatedHedadoQuery(getCampaignsWhereDonorIsAdmin_Gql);

  const adminCampaigns = data?.getCampaignsWhereDonorIsAdmin ?? [];

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logUserOut = async () => {
    try {
      client.clearStore();
      logout({ returnTo: `${env.REACT_APP_HOST}/logged-out` });
    } catch (e) {
      console.error(e);
    }
  };

  const isProfileComplete = !!(donorProfile?.firstName && donorProfile?.lastName);

  return (
    <>
      {isAuthenticated && (
        <>
          <IconButton
            size="large"
            aria-label="Account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            className={classes.accountButtons}
            color="info"
          >
            <Badge badgeContent={donorProfile && !isProfileComplete ? 1 : 0} color="error">
              <Tooltip
                enterTouchDelay={0}
                title={!isProfileComplete ? 'Please provide your first & last name for your donation receipt.' : ''}
              >
                <AccountCircle />
              </Tooltip>
            </Badge>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled={isProfileComplete}>
              <DonorNameOrAccountLink defaultText="Update Your Profile" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                history.push('/my-account');
              }}
            >
              My Account
            </MenuItem>
            {env.REACT_APP_FEATURE_FLAG_PUBLIC_PROFILES === true && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push('/my-account/public-profile');
                }}
              >
                {isPublicProfileEnabled ? 'Edit' : 'Create'} Public Profile
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleClose();
                history.push('/history');
              }}
            >
              Transactions
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                history.push('/manage-giving');
              }}
            >
              Payment
            </MenuItem>

            {adminCampaigns.map((c: Campaign) => (
              <MenuItem
                key={c.id}
                onClick={() => {
                  handleClose();
                  history.push(`/campaign/${c.id}`);
                }}
              >
                {c.title}
              </MenuItem>
            ))}

            {isAdmin && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push('/admin/organizations');
                }}
              >
                Admin
              </MenuItem>
            )}

            <Divider />
            <MenuItem
              onClick={async () => {
                await logUserOut();
              }}
            >
              Sign out
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default AccountProfileButton;
