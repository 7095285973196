import { gql } from '@apollo/client';

export const ContributionsWithDetailForDonor_Name = 'ContributionsWithDetailForDonor';
export const ContributionsWithDetailForDonor_Gql = gql`
  query ContributionsWithDetailForDonor {
    contributionsWithDetailForDonor {
      createdAt
      paymentStatus
      amountCents
      tipsCents
      externalTransaction
      donations {
        amountCents
        organization {
          ein
          name
        }
        allocation {
          percentage
        }
      }
    }
  }
`;
