import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';
import Organization from 'model/Organization.model';
import GiveNowDialog from 'components/GiveNowDialog/GiveNowDialog';

interface AddMenuProps {
  id: string;
  organization: Organization;
  isInPortfolio: boolean;
  onAddToPortfolioClick: () => void;
}

export default function AddMenu({ id, organization, isInPortfolio, onAddToPortfolioClick }: AddMenuProps) {
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [isGiveNowDialogOpen, setIsGiveNowDialogOpen] = useState(false);
  const isMenuOpen = Boolean(menuAnchor);

  function openMenu(event: React.SyntheticEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  const closeGiveNow = () => {
    setIsGiveNowDialogOpen(false);
    closeMenu();
  };

  function handleGiveNowClick() {
    setIsGiveNowDialogOpen(true);
  }

  function handleAddToPortfolioClick() {
    onAddToPortfolioClick();
    closeMenu();
  }

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color={isInPortfolio ? 'secondary' : 'primary'}
        onClick={openMenu}
      >
        {' '}
        Support
      </Button>

      <Menu
        id={id}
        anchorEl={menuAnchor}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={handleGiveNowClick}>
          <AttachMoneyIcon sx={{ mr: 1 }} />
          Give Now
        </MenuItem>
        {isInPortfolio ? (
          <MenuItem sx={{ backgroundColor: 'secondary' }} onClick={() => history.push('/portfolio')}>
            <CheckIcon sx={{ mr: 1 }} />
            My Portfolio
          </MenuItem>
        ) : (
          <MenuItem onClick={handleAddToPortfolioClick}>
            <AddIcon sx={{ mr: 1 }} />
            Add to Portfolio
          </MenuItem>
        )}
      </Menu>
      <GiveNowDialog isOpen={isGiveNowDialogOpen} onClose={closeGiveNow} organization={organization} />
    </>
  );
}
