import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { env } from 'env';
import { getAccessTokenFromStorage } from 'utils/storage';

export function useDownloadDonorHistory() {
  const [hasErrored, setHasErrored] = useState(false);

  const downloadDonorHistoryExport = useCallback(async () => {
    try {
      const exportData = await getDonorHistoryExport();
      const fileName = `Hedado Donation History [${dayjs().format('YY-MM-DD')}].xlsx`;
      fileDownload(exportData, fileName);
    } catch (error) {
      setHasErrored(true);
    }
  }, []);

  return { downloadDonorHistoryExport, hasErrored, setHasErrored };
}

type ExportResponseData = string | ArrayBuffer | ArrayBufferView | Blob;

async function getDonorHistoryExport() {
  const token = getAccessTokenFromStorage();
  const authorizationHeader = token ? `Bearer ${token}` : '';
  const response = await axios.get<ExportResponseData>(`${env.REACT_APP_NODE_URL}/donorHistoryExport`, {
    responseType: 'blob',
    headers: {
      Authorization: authorizationHeader,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

  return response.data;
}
