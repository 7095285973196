import { gql } from '@apollo/client';

export const ProcessOneTimeOrganizationContributionGql = gql`
  mutation ProcessOneTimeOrganizationContribution(
    $amountCents: Int!
    $tipsCents: Int
    $firstName: String!
    $lastName: String!
    $email: String!
    $paymentMethodId: String!
    $organizationId: String!
    $anonymous: Boolean
    $designation: String
  ) {
    processOneTimeOrganizationContribution(
      input: {
        amountCents: $amountCents
        tipsCents: $tipsCents
        firstName: $firstName
        lastName: $lastName
        email: $email
        paymentMethodId: $paymentMethodId
        organizationId: $organizationId
        anonymous: $anonymous
        designation: $designation
      }
    ) {
      id
    }
  }
`;
