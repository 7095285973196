import WelcomeContainer from '../components/global/WelcomeContainer'

const LoggedOut = () => {

  return (
    <>
      <WelcomeContainer />
    </>
  );
};

export default LoggedOut;
