import type { CampaignFormProps } from '../CampaignForm';
import { useState } from 'react';

interface UseMembersFormStateArgs {
  qualification: CampaignFormProps['qualification'];
}

export enum EligibilityType {
  openToAll = 'openToAll',
  limitToDomains = 'limitToDomains',
  limitToPeople = 'limitToPeople',
  limitToPeopleAndDomains = 'limitToPeopleAndDomains',
  universal = 'universal',
}

export function useMembersFormState({ qualification }: UseMembersFormStateArgs) {
  const [membersFormState, setMembersFormState] = useState(getMembersFormState(qualification));

  return {
    membersFormState,
    setMembersFormState,
  };
}

function getMembersFormState(qualification: CampaignFormProps['qualification']) {
  return {
    memberEligibility: {
      value: getMemberEligibilityValue(qualification),
      hasBlurredAtLeastOnce: false,
    },
  };
}

export type MembersFormState = ReturnType<typeof getMembersFormState>;

export enum MemberFormStateKey {
  memberEligibility = 'memberEligibility',
}

function getMemberEligibilityValue(qualification: CampaignFormProps['qualification']): EligibilityType {
  if (!qualification || qualification.openToAll) return EligibilityType.openToAll;
  if (qualification.limitToPeople && qualification.limitToDomains) return EligibilityType.limitToPeopleAndDomains;
  if (qualification.limitToPeople) return EligibilityType.limitToPeople;
  if (qualification.limitToDomains) return EligibilityType.limitToDomains;
  if (qualification.universal) return EligibilityType.universal;

  return EligibilityType.openToAll;
}

export function computeMembersFormStateErrors(_: MembersFormState) {
  return {};
}

export type MembersFormStateErrors = ReturnType<typeof computeMembersFormStateErrors>;
