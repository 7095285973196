import { ChangeEvent, useState } from 'react';
import { Grid, Stack, Box, Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  GetAllPublicProfilesQueryArgs,
  GetAllPublicProfilesQueryResponse,
  getAllPublicProfilesGql,
} from 'gql/donor/getAllPublicProfiles';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useDebounce from 'hooks/useDebounce';
import AdminPage from 'components/admin/AdminPage';

const PER_PAGE = 25;

export default function PublicProfileList() {
  const debounce = useDebounce();
  const [search, setSearch] = useState('');

  const { data, fetchMore } = useAuthenticatedHedadoQuery<
    GetAllPublicProfilesQueryResponse,
    GetAllPublicProfilesQueryArgs
  >(getAllPublicProfilesGql, {
    variables: { first: PER_PAGE, search },
  });

  const pageInfo = data?.getDonors.pageInfo;
  const edges = data?.getDonors.edges || [];

  function handleFetchMore() {
    if (!pageInfo) return;

    fetchMore({
      variables: {
        first: PER_PAGE,
        after: pageInfo.endCursor,
        search,
      },
    });
  }

  function handleSearchChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = event.currentTarget.value;
    debounce(() => setSearch(value), 350);
  }

  return (
    <AdminPage title="Public Campaigns">
      <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 2, mb: 3 }}>
        <Grid item lg={8} md={6} xs={12}>
          <Stack>
            <TextField
              type="text"
              variant="standard"
              onChange={handleSearchChange}
              placeholder="Search by first name, last name, displayName or slug"
            />
          </Stack>
        </Grid>
      </Grid>
      {edges.map(
        ({
          node: {
            publicProfile: { slug, displayName },
          },
        }) => (
          <Box key={`publicProfile-${slug}`} sx={{ mb: 1 }}>
            <Link to={{ pathname: `/d/${slug}` }} style={{ display: 'inline-flex' }} target="_blank">
              {displayName || slug}
            </Link>
          </Box>
        )
      )}

      {pageInfo?.hasNextPage && (
        <Button sx={{ mt: 4 }} variant="outlined" onClick={handleFetchMore}>
          Load More
        </Button>
      )}
    </AdminPage>
  );
}
