import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

export default function useHedadoMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  gql: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext> & {
    suppressErrors?: boolean;
    throwErrors?: boolean;
  }
): MutationTuple<TData, TVariables, TContext, TCache> {
  const [mutationFunc, result] = useMutation<TData, TVariables, TContext, TCache>(gql, options);

  if (result.error && !options?.suppressErrors) {
    if (options?.throwErrors) throw new Error(result.error.message);

    const operationName = getOperationName(gql);
    const mutationName = `Mutation: ${operationName}`;

    console.error(mutationName);
    console.error(result.error);
  }

  return [mutationFunc, result];
}
