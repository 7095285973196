import { DialogTitle, Dialog, DialogContent, Button, Box } from '@mui/material';

interface SimpleDialogProps {
  open: boolean;
  title: string;
  text: string;
  declineText: string;
  confirmText: string;
  onClose: (value: boolean) => void;
}

const YesNoDialog = ({ open, title, text, onClose, declineText, confirmText }: SimpleDialogProps) => {
  const handleClose = (value: boolean) => {
    onClose(value);
  };

  return (
    <>
      <Dialog onClose={() => handleClose(false)} aria-labelledby="confirmation-dialog" open={open}>
        <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>{text}</Box>

          <Box sx={{ textAlign: 'right' }}>
            <Button sx={{ mr: 3 }} variant="outlined" color="warning" onClick={() => handleClose(false)}>
              {declineText}
            </Button>

            <Button variant="contained" color="primary" onClick={() => handleClose(true)}>
              {confirmText}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default YesNoDialog;
