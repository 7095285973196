import { Typography, Grid } from '@mui/material';
import { CampaignListProps } from './CampaignList.interfaces';
import CampaignCard from './components/CampaignCard';
import Campaign from '../../../model/Campaign.model';

const CampaignList = ({ title, campaigns }: CampaignListProps) => {
  if (!campaigns || campaigns.length === 0) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h5" sx={{ paddingX: 2, mt: 2 }} gutterBottom>
        {title}
      </Typography>

      <Grid container spacing={2}>
        {campaigns.map((campaign: Campaign) => (
          <CampaignCard campaign={campaign} />
        ))}
      </Grid>
    </>
  );
};

export default CampaignList;
