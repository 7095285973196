import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import useStoredAuth0Values from './useStoredAuth0Values';

export default function useHedadoQuery<TData = any, TVariables = OperationVariables>(
  gql: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    waitForAuthentication?: boolean;
    suppressErrors?: boolean;
    throwErrors?: boolean;
  }
): QueryResult<TData, TVariables> & { waiting: boolean } {
  const query = useQuery<TData, TVariables>(gql, options);

  const { error } = query;

  if (error && !options?.suppressErrors) {
    if (options?.throwErrors) throw new Error(error.message);

    const operationName = getOperationName(gql);
    const queryName = `Query: ${operationName}`;

    console.error(queryName);
    console.error(error);
  }

  return { ...query, waiting: !!options?.skip };
}

export function useAuthenticatedHedadoQuery<TData = any, TVariables = OperationVariables>(
  gql: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    throwErrors?: boolean;
  }
) {
  const { isAuthenticatedAndReady } = useStoredAuth0Values();
  const shouldQuerySkip = !!options?.skip || !!!isAuthenticatedAndReady;

  return useHedadoQuery(gql, { ...options, skip: shouldQuerySkip });
}
