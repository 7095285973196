import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';

export function setStateFromInputChange<T>(
  event: React.ChangeEvent<HTMLInputElement>,
  setState: React.Dispatch<React.SetStateAction<T>>
) {
  const { name, value } = event.currentTarget;

  return setState((prevState) => {
    if (!(name in prevState)) return prevState;

    return {
      ...prevState,
      [name]: {
        ...prevState[name as keyof T],
        value,
      },
    };
  });
}

export function setStateFromSelectChange<T>(
  event: SelectChangeEvent,
  setState: React.Dispatch<React.SetStateAction<T>>
) {
  const { name, value } = event.target;

  return setState((prevState) => {
    if (!(name in prevState)) return prevState;

    return {
      ...prevState,
      [name]: {
        ...prevState[name as keyof T],
        value,
      },
    };
  });
}

export function setStateFromInputCheck<T>(
  event: React.ChangeEvent<HTMLInputElement>,
  setState: React.Dispatch<React.SetStateAction<T>>
) {
  const { name, checked } = event.currentTarget;

  return setState((prevState) => {
    if (!(name in prevState)) return prevState;

    return {
      ...prevState,
      [name]: {
        ...prevState[name as keyof T],
        value: checked,
      },
    };
  });
}

export function setStateFromInputBlur<T>(
  event: React.ChangeEvent<HTMLInputElement>,
  setState: React.Dispatch<React.SetStateAction<T>>
) {
  const { name } = event.currentTarget;

  return setState((prevState) => {
    if (!(name in prevState)) return prevState;

    return {
      ...prevState,
      [name]: {
        ...prevState[name as keyof T],
        hasBlurredAtLeastOnce: true,
      },
    };
  });
}

export function blurState<T>(setState: React.Dispatch<React.SetStateAction<T>>) {
  return setState((prevState) => {
    return Object.keys(prevState).reduce((acc: T, curr) => {
      acc[curr as keyof T] = {
        ...prevState[curr as keyof T],
        hasBlurredAtLeastOnce: true,
      };

      return acc;
    }, {} as unknown as T);
  });
}

export function hasAnyError(errors: { [key: string]: any }): boolean {
  return Object.values(errors).some(Boolean);
}

export function hasAnyVisibleError(
  errors: { [key: string]: any },
  state: { [key: string]: { hasBlurredAtLeastOnce: boolean } | { hasBlurredAtLeastOnce: boolean }[] }
): boolean {
  return Object.keys(errors)
    .filter((key) => errors[key])
    .some((key) => {
      let val = state[key];

      if (Array.isArray(val)) {
        return val.some((el) => el.hasBlurredAtLeastOnce);
      } else {
        return val.hasBlurredAtLeastOnce;
      }
    });
}

export function getRequiredFieldError(value: number | string) {
  if (!!value) return '';

  return 'This field is required';
}

export function getMinimumIntegerError(
  value: number | string,
  { min, inclusive }: { min: number; inclusive: boolean }
) {
  if (value === '') return '';
  if (typeof value === 'string') value = parseFloat(value);

  if (isNaN(value)) return 'Please enter a valid number';

  const parsed = parseInt(value.toString());
  if (parsed !== value) return 'Please enter a whole number (no decimals)';

  return getGreaterThanError(value, min, inclusive);
}

export function getMinimumNumberError(value: number | string, { min, inclusive }: { min: number; inclusive: boolean }) {
  if (value === '') return '';
  if (typeof value === 'string') value = parseFloat(value);

  if (isNaN(value)) return 'Please enter a valid number';

  return getGreaterThanError(value, min, inclusive);
}

function getGreaterThanError(value: number, min: number, inclusive: boolean) {
  if (inclusive) {
    if (min > value) return `Please enter a number no less than ${min}`;
  } else if (min >= value) {
    return `Please enter a number greater than ${min}`;
  }

  return '';
}

export function getValidDateError(value: string) {
  if (!value) return '';

  return dayjs(value, 'YYYY-MM-DD').isValid() ? '' : 'Please enter a valid date';
}

export function getVisibleError(field: { hasBlurredAtLeastOnce: boolean }, error?: string | null) {
  if (!field.hasBlurredAtLeastOnce) return '';

  return error || '';
}

export function hasBlurredAnyInput(formState: { [key: string]: { hasBlurredAtLeastOnce: boolean } }) {
  return Object.values(formState).some(({ hasBlurredAtLeastOnce }) => hasBlurredAtLeastOnce);
}
