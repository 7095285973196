import { useParams } from 'react-router-dom';
import {
  getCampaignForModificationGql,
  GetCampaignForModificationQueryVariables,
  GetCampaignForModificationQueryResponse,
} from 'gql/campaigns/getCampaign';
import useHedadoQuery from 'hooks/useHedadoQuery';
import AdminPage from 'components/admin/AdminPage';
import CampaignForm from 'components/CampaignForm/CampaignForm';

export default function EditCampaign() {
  const { id: rawId } = useParams<{ id: string }>();
  const id = decodeURIComponent(rawId);

  const { loading: isCampaignLoading, data: campaignData } = useHedadoQuery<
    GetCampaignForModificationQueryResponse,
    GetCampaignForModificationQueryVariables
  >(getCampaignForModificationGql, {
    variables: { id },
  });

  if (isCampaignLoading) return <></>;

  if (!campaignData || !campaignData.getCampaign) {
    throw new Error(`Campaign with ID: ${id} could not be found`);
  }

  const { donors, domains, admin, qualification, ...campaign } = campaignData?.getCampaign;

  const pageTitle = `Edit Campaign: ${campaign?.title}`;

  return (
    <AdminPage title={pageTitle}>
      <CampaignForm admin={admin} campaign={campaign} domains={domains} donors={donors} qualification={qualification} />
    </AdminPage>
  );
}
