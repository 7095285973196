import { toCurrencyString } from 'helpers/donationProcessHelper';

export function numberExists(value?: number): boolean {
  return typeof value === 'number';
}

export function getDollarString(value?: number, { hideCents }: { hideCents?: boolean } = { hideCents: true }): string {
  return toCurrencyString((value || 0) / 100, { hideCents });
}

export function getCommaSeparatedString(value?: number): string {
  return (value || 0).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}
