import { gql } from '@apollo/client';

export interface RequestOrganizationChangesMutationResult {
  id: string;
}

export interface RequestOrganizationChangesMutationVariables {
  organizationId: string;
  changeRequests?: string;
}

export const requestOrganizationChangesGql = gql`
  mutation RequestOrganizationChanges($organizationId: ID!, $changeRequests: String) {
    requestOrganizationChanges(organizationId: $organizationId, changeRequests: $changeRequests) {
      id
    }
  }
`;
