import React, { useState } from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import Organization from '../../model/Organization.model';
import MoreInfo from './MoreInfo/MoreInfo';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    iconAction: {
      cursor: 'pointer',
    },
  })
);

interface LearnMoreDrawerProps {
  bgColor?: 'primary' | 'secondary';
  buttonText?: string;
  color?: string;
  currentlyInPortfolio?: boolean;
  iconOnly?: boolean;
  organization: Organization;
}

const LearnMoreButtonWithDrawer = ({
  organization,
  currentlyInPortfolio = true,
  iconOnly = false,
  color = 'primary.text',
  buttonText = 'Learn More',
  bgColor = 'primary',
}: LearnMoreDrawerProps) => {
  const classes = useStyles();
  const [orgDrawerOpen, setOrgDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return <></>;
    }

    setOrgDrawerOpen(open);
  };

  return (
    <>
      {!iconOnly ? (
        <Typography component="div" variant="body1" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
          <Button variant="outlined" size="medium" onClick={toggleDrawer(true)} color={bgColor}>
            {buttonText}
          </Button>
        </Typography>
      ) : (
        <IconButton
          size="large"
          onClick={toggleDrawer(true)}
          sx={{ marginTop: -1, marginRight: -1, color: color || 'inherit' }}
          color={bgColor}
        >
          <InfoIcon />
        </IconButton>
      )}

      <Drawer
        variant="temporary"
        anchor="right"
        open={orgDrawerOpen}
        onClose={toggleDrawer(false)}
        hideBackdrop={false}
      >
        <Box
          sx={{
            width: 'min(500px, 100%)',
            maxWidth: '500px',
            height: '100%',
            paddingRight: 2,
            paddingLeft: 2,
            marginTop: 10,
          }}
        >
          <CloseIcon onClick={toggleDrawer(false)} className={classes.iconAction} />

          <MoreInfo organization={organization} currentlyInPortfolio={currentlyInPortfolio} showButton={false} />
        </Box>
      </Drawer>
    </>
  );
};

export default LearnMoreButtonWithDrawer;
