import React, { useState } from 'react';
import axios from 'axios';
import { Button, IconButton, AlertTitle, Alert, Link } from '@mui/material';
import { FormHelperText, FilledInput, InputLabel, InputAdornment, FormControl, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import Head from 'components/Head/Head';
import PageHeader from '../components/global/PageHeader';
import PageContainer from '../components/global/ColorPageContainer';
import OrganizationList from '../components/organization/OrganizationList';
import OrganizationSearchResults from '../components/organization/OrganizationSearchResults';

import Organization from '../model/Organization.model';
import { Nonprofit, OrganizationSearchResult } from '../model/OrganizationSearchResult';
import {
  getOrganizationsGql,
  GetOrganizationsArgs,
  GetOrganizationsQueryResponse,
} from 'gql/organizations/getOrganizations';
import useHedadoQuery from 'hooks/useHedadoQuery';
import { getOrganizationsByCategory_Gql } from '../gql/organizationQueries';
import { PAGE_TITLE_BROWSE, APP_TITLE } from '../utils/constants';
import { encodeStringForUrl } from '../helpers/httpUtilityHelper';
import useAnalytics, { events } from 'hooks/useAnalytics';

const hedadoCategoryId = '3d4ebaa1-6723-438b-8ea2-d6d6abfd7f37';

const pageResults = () => {
  return;
};

const BrowseNonprofits = () => {
  const { trackEvent } = useAnalytics();
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<OrganizationSearchResult[]>([]);
  const [searchWasRun, setSearchWasRun] = useState(false);

  const { data } = useHedadoQuery(getOrganizationsByCategory_Gql, {
    variables: {
      categoryId: hedadoCategoryId,
    },
  });

  const organizations: Organization[] = data?.getOrganizationsByCategory ?? [];

  const { data: popularOrganizations } = useHedadoQuery<GetOrganizationsQueryResponse, GetOrganizationsArgs>(
    getOrganizationsGql,
    {
      variables: { first: 8, sortedByPopularity: true },
    }
  );

  const runSearch = async () => {
    if (searchText.length > 0) {
      setSearchWasRun(true);
      trackEvent(events.search, {
        search_term: searchText,
      });

      const text = encodeStringForUrl(searchText);
      const key = 'pk_live_d124528ea7ed421fc9c06c7716f45f27';

      const url = `https://partners.every.org/v0.2/search/${text}?apiKey=${key}`;

      let results = (await axios.get(url)).data as Nonprofit;
      setSearchResults(results.nonprofits);
    }
  };

  const clearSearch = () => {
    setSearchResults([]);
    setSearchText('');
    setSearchWasRun(false);
  };

  const onSearchTextKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      runSearch();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <Head title={`${PAGE_TITLE_BROWSE} | ${APP_TITLE}`} />
      <PageHeader
        icon={<SearchIcon />}
        title="Browse Nonprofits"
        subTitle="Review nonprofits recommended by Hedado or search from over 1.5M tax-deductible nonprofits."
      />

      <PageContainer>
        <Grid
          container
          xs={12}
          sm={12}
          md={8}
          lg={5}
          xl={4}
          spacing={1}
          sx={{ alignItems: 'center', flexWrap: 'nowrap' }}
        >
          <Grid item sx={{ flexGrow: 1, mr: 1 }}>
            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
              <InputLabel htmlFor="filled-adornment-amount">Nonprofit Name or EIN</InputLabel>
              {/* TODO: This is not very performant, lots of state rerendering happening on keypress.
                  Probably a symptom of https://stackoverflow.com/questions/50819260/react-input-onchange-lag */}
              <FilledInput
                sx={{ backgroundColor: '#FFFFFF', width: '100%' }}
                id="filled-adornment-amount"
                value={searchText}
                autoFocus={true}
                onChange={handleChange}
                onKeyPress={onSearchTextKeyPress}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <FormHelperText id="standard-weight-helper-text">EIN format: 12-3456789</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={runSearch} sx={{ display: { xs: 'none', sm: 'inherit' } }}>
              Search
            </Button>
            <IconButton color="primary" onClick={runSearch} sx={{ display: { xs: 'inherit', sm: 'none' } }}>
              <SearchIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={clearSearch} sx={{ display: { xs: 'none', sm: 'inherit' } }}>
              Clear
            </Button>
            <IconButton color="primary" onClick={clearSearch} sx={{ display: { xs: 'inherit', sm: 'none' } }}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </PageContainer>
      <br />

      {searchWasRun && (!searchResults || searchResults?.length === 0) && (
        <Alert severity="warning">
          <AlertTitle>No organizations found</AlertTitle>
          Please refine your search and try again. If an organization is missing, please contact{' '}
          <Link href="mailto:support@hedado.com?subject=Missing Organization">support@hedado.com</Link>.
        </Alert>
      )}

      {searchResults?.length > 0 && <OrganizationSearchResults searchResults={searchResults} />}

      {searchResults?.length === 0 && (
        <>
          {popularOrganizations?.getOrganizations && (
            <OrganizationList
              organizations={popularOrganizations?.getOrganizations.edges.map((edge) => edge.node)}
              onPageChange={pageResults}
              title="Popular on Hedado"
              subTitle="These nonprofits have received the most donations on Hedado"
            />
          )}

          <OrganizationList
            organizations={organizations}
            onPageChange={pageResults}
            title="Curated Charities"
            subTitle="Choose nonprofits that have been vetted by Hedado or its partners"
          />
        </>
      )}
    </>
  );
};

export default BrowseNonprofits;
