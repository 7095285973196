import type { CampaignDonationMatchesOrderByAttribute } from 'gql/donationMatches/getCampaignDonationMatches';
import { TableCell, TableSortLabel, Box } from '@mui/material';
import { tableColumns, DEFAULT_SORT_ORDERS } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import { visuallyHidden } from '@mui/utils';

interface DonationMatchesTableHeaderCellProps {
  handleSortLabelClick: (key: CampaignDonationMatchesOrderByAttribute) => void;
  orderByAttribute: CampaignDonationMatchesOrderByAttribute;
  orderDirection: 'asc' | 'desc';
  tableColumn: typeof tableColumns[number];
}

export default function DonationMatchesTableHeaderCell({
  handleSortLabelClick,
  orderByAttribute,
  orderDirection,
  tableColumn,
}: DonationMatchesTableHeaderCellProps) {
  const isActiveSortColumn = orderByAttribute === tableColumn.id;
  const sortDirection = isActiveSortColumn ? orderDirection : DEFAULT_SORT_ORDERS[tableColumn.id];
  const sortDirectionHelperText = orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending';

  function onSortLabelClick() {
    handleSortLabelClick(tableColumn.id);
  }

  return (
    <TableCell
      sx={{
        flexDirection: 'row',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: `${tableColumn.width}px`,
        backgroundColor: '#e4e4e4',
      }}
      key={tableColumn.id}
      align={tableColumn.numeric ? 'right' : 'left'}
      sortDirection={sortDirection}
      width={tableColumn.width}
    >
      <TableSortLabel active={isActiveSortColumn} direction={sortDirection} onClick={onSortLabelClick}>
        <b>{tableColumn.label}</b>
        {isActiveSortColumn && (
          <Box component="span" style={visuallyHidden}>
            {sortDirectionHelperText}
          </Box>
        )}
      </TableSortLabel>
    </TableCell>
  );
}
