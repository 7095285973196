import type { CampaignFormProps } from '../CampaignForm';
import type { UploadedImagesResult } from './useCampaignFormImages';
import { useMemo, useCallback } from 'react';
import { blurState } from 'helpers/formHelpers';
import { useDetailsFormState, DetailsFormState } from './useDetailsFormState';
import { useDonationsFormState, DonationsFormState } from './useDonationsFormState';
import { useMatchingFormState, MatchingFormState } from './useMatchingFormState';
import { useMembersFormState, MembersFormState, EligibilityType } from './useMembersFormState';
import { useNonProfitsFormState, NonProfitsFormState } from './useNonProfitsFormState';

interface UseCampaignFormSectionStateArgs {
  campaign: CampaignFormProps['campaign'];
  qualification: CampaignFormProps['qualification'];
}

export function useCampaignFormSectionStates({ campaign, qualification }: UseCampaignFormSectionStateArgs) {
  const { detailsFormState, setDetailsFormState } = useDetailsFormState(campaign);
  const { donationsFormState, setDonationsFormState } = useDonationsFormState(campaign, qualification);
  const { matchingFormState, setMatchingFormState } = useMatchingFormState(qualification);
  const { membersFormState, setMembersFormState } = useMembersFormState({ qualification });
  const { nonProfitsFormState, setNonProfitsFormState } = useNonProfitsFormState(campaign, qualification);

  const blurAllSectionFormStates = useCallback(() => {
    blurState(setDetailsFormState);
    blurState(setDonationsFormState);
    blurState(setMatchingFormState);
    blurState(setMembersFormState);
    blurState(setNonProfitsFormState);
  }, [setDetailsFormState, setDonationsFormState, setMatchingFormState, setMembersFormState, setNonProfitsFormState]);

  const getMutationVariablesFromSectionFormStates = useCallback(
    (uploadedImages: UploadedImagesResult) => {
      return {
        campaign: {
          ...getDetailsMutationVariables({ detailsFormState, donationsFormState }),
          displayData: getDisplayDataMutationVariables({
            donationsFormState,
            nonProfitsFormState,
            uploadedImages,
          }),
        },
        qualification: getQualificationMutationVariables({
          donationsFormState,
          matchingFormState,
          membersFormState,
          nonProfitsFormState,
        }),
      };
    },
    [detailsFormState, donationsFormState, matchingFormState, membersFormState, nonProfitsFormState]
  );

  return useMemo(
    () => ({
      sectionFormStates: {
        details: { formState: detailsFormState, setFormState: setDetailsFormState },
        donations: { formState: donationsFormState, setFormState: setDonationsFormState },
        matching: { formState: matchingFormState, setFormState: setMatchingFormState },
        members: { formState: membersFormState, setFormState: setMembersFormState },
        nonProfits: { formState: nonProfitsFormState, setFormState: setNonProfitsFormState },
      },
      blurAllSectionFormStates,
      getMutationVariablesFromSectionFormStates,
    }),
    [
      blurAllSectionFormStates,
      detailsFormState,
      donationsFormState,
      getMutationVariablesFromSectionFormStates,
      matchingFormState,
      membersFormState,
      nonProfitsFormState,
      setDetailsFormState,
      setDonationsFormState,
      setMatchingFormState,
      setMembersFormState,
      setNonProfitsFormState,
    ]
  );
}

interface GetDisplayDataMutationVariablesArgs {
  donationsFormState: DonationsFormState;
  nonProfitsFormState: NonProfitsFormState;
  uploadedImages: UploadedImagesResult;
}

function getDisplayDataMutationVariables({
  donationsFormState,
  nonProfitsFormState,
  uploadedImages,
}: GetDisplayDataMutationVariablesArgs) {
  return {
    bannerImage: uploadedImages.bannerImagePath,
    bannerImageMobile: uploadedImages.bannerImageMobilePath,
    logoImage: uploadedImages.logoImagePath,
    showCampaignOrganizations: nonProfitsFormState.showCampaignOrganizations.value,
    showDonationGoal: donationsFormState.showDonationGoal.value,
    showDonationTotal: donationsFormState.showDonationTotal.value,
    showDonationTotalMatched: donationsFormState.showDonationTotalMatched.value,
    showDonorOrganizations: nonProfitsFormState.showDonorOrganizations.value,
  };
}

interface GetDetailsMutationVariablesArgs {
  detailsFormState: DetailsFormState;
  donationsFormState: DonationsFormState;
}

function getDetailsMutationVariables({ detailsFormState, donationsFormState }: GetDetailsMutationVariablesArgs) {
  return {
    description: detailsFormState.description.value,
    donationGoalCents: donationsFormState.donationGoalCents.value,
    nameOnReceipt: detailsFormState.nameOnReceipt.value,
    slug: detailsFormState.slug.value,
    tier: detailsFormState.tier.value,
    title: detailsFormState.title.value,
  };
}

interface GetQualificationMutationVariablesArgs {
  donationsFormState: DonationsFormState;
  matchingFormState: MatchingFormState;
  membersFormState: MembersFormState;
  nonProfitsFormState: NonProfitsFormState;
}

function getQualificationMutationVariables({
  donationsFormState,
  matchingFormState,
  membersFormState,
  nonProfitsFormState,
}: GetQualificationMutationVariablesArgs) {
  const memberEligibility = membersFormState.memberEligibility.value;
  const openToAll = [EligibilityType.openToAll].includes(memberEligibility);
  const limitToDomains = [EligibilityType.limitToDomains, EligibilityType.limitToPeopleAndDomains].includes(
    memberEligibility
  );
  const limitToPeople = [EligibilityType.limitToPeople, EligibilityType.limitToPeopleAndDomains].includes(
    memberEligibility
  );
  const universal = [EligibilityType.universal].includes(memberEligibility);

  return {
    endDate: donationsFormState.endDate.value,
    hasMatching: matchingFormState.hasMatching.value,
    includeDonationsExceedingMatchLimits: matchingFormState.includeDonationsExceedingMatchLimits.value,
    individualMatchLimitCents: matchingFormState.hasIndividualMatchLimit.value
      ? matchingFormState.individualMatchLimitCents.value
      : null,
    limitOrganizations: nonProfitsFormState.limitOrganizations.value,
    limitToDomains,
    limitToPeople,
    matchRatio: matchingFormState.hasMatching.value ? parseFloat(matchingFormState.matchRatio.value) / 100 || 0 : null,
    openToAll,
    universal,
    overallMatchLimitCents: matchingFormState.hasOverallMatchLimit.value
      ? matchingFormState.overallMatchLimitCents.value
      : null,
    startDate: donationsFormState.startDate.value,
  };
}
