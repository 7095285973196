import type { CampaignFormProps } from '../CampaignForm';
import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getRequiredFieldError, getMinimumNumberError, getMinimumIntegerError } from 'helpers/formHelpers';
dayjs.extend(utc);

export enum MatchingFormStateKey {
  hasIndividualMatchLimit = 'hasIndividualMatchLimit',
  hasMatching = 'hasMatching',
  hasOverallMatchLimit = 'hasOverallMatchLimit',
  includeDonationsExceedingMatchLimits = 'includeDonationsExceedingMatchLimits',
  individualMatchLimitCents = 'individualMatchLimitCents',
  matchRatio = 'matchRatio',
  overallMatchLimitCents = 'overallMatchLimitCents',
}

export function useMatchingFormState(qualification: CampaignFormProps['qualification']) {
  const [matchingFormState, setMatchingFormState] = useState(getMatchingFormState(qualification));

  return { matchingFormState, setMatchingFormState };
}

function getMatchingFormState(qualification: CampaignFormProps['qualification']) {
  return {
    hasIndividualMatchLimit: {
      value: typeof qualification?.individualMatchLimitCents === 'number',
      hasBlurredAtLeastOnce: false,
    },
    hasOverallMatchLimit: {
      value: typeof qualification?.overallMatchLimitCents === 'number',
      hasBlurredAtLeastOnce: false,
    },
    includeDonationsExceedingMatchLimits: {
      value: !!qualification?.includeDonationsExceedingMatchLimits,
      hasBlurredAtLeastOnce: false,
    },
    individualMatchLimitCents: {
      value: qualification?.individualMatchLimitCents || 0,
      hasBlurredAtLeastOnce: false,
    },
    hasMatching: {
      value: !!qualification?.hasMatching,
      hasBlurredAtLeastOnce: false,
    },
    matchRatio: {
      value: typeof qualification?.matchRatio === 'number' ? (qualification?.matchRatio * 100).toString() : '',
      hasBlurredAtLeastOnce: false,
    },
    overallMatchLimitCents: {
      value: qualification?.overallMatchLimitCents || 0,
      hasBlurredAtLeastOnce: false,
    },
  };
}

export type MatchingFormState = ReturnType<typeof getMatchingFormState>;

export function computeMatchingFormStateErrors(formState: MatchingFormState): {
  [key in MatchingFormStateKey]?: string | null;
} {
  if (!formState.hasMatching.value) return {};

  return {
    individualMatchLimitCents: getIndividualMatchLimitCentsError(formState),
    matchRatio:
      getRequiredFieldError(formState.matchRatio.value) ||
      getMinimumNumberError(formState.matchRatio.value, { min: 0, inclusive: false }),
    overallMatchLimitCents: getOverallMatchLimitCentsError(formState),
  };
}

function getIndividualMatchLimitCentsError({ hasIndividualMatchLimit, individualMatchLimitCents }: MatchingFormState) {
  return hasIndividualMatchLimit.value
    ? getMinimumIntegerError(individualMatchLimitCents.value, { min: 0, inclusive: false })
    : '';
}

function getOverallMatchLimitCentsError({ hasOverallMatchLimit, overallMatchLimitCents }: MatchingFormState) {
  return hasOverallMatchLimit.value
    ? getMinimumIntegerError(overallMatchLimitCents.value, { min: 0, inclusive: false })
    : '';
}

export type MatchingFormStateErrors = ReturnType<typeof computeMatchingFormStateErrors>;
