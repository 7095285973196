import { Box, Button, Typography } from '@mui/material';
import Organization from 'model/Organization.model';
import { useAuth0 } from '@auth0/auth0-react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Confirmation({ organization }: ConfirmationProps) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const isLoggedOut = !isLoading && !isAuthenticated;

  function handleLoginClick() {
    loginWithRedirect();
  }

  function handleLearnMoreClick() {
    window.open('https://hedado.com/features/portfolio');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: '450px',
      }}
    >
      <CheckCircleOutlineIcon
        fontSize="large"
        sx={{ width: '2em', height: '2em', fontSize: '72px', mb: 2 }}
        color="secondary"
      />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Thank you!
      </Typography>
      <Typography variant="body1">
        Your donation to {organization.name} has been processed, and you'll find your tax-deductible receipt in your
        inbox shortly.
      </Typography>
      {isLoggedOut && (
        <>
          <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
            Ready to level-up your giving?{' '}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={handleLoginClick}>
              Create your free account
            </Button>
            <Typography variant="body1" sx={{ mx: 1 }}>
              or
            </Typography>
            <Button variant="text" onClick={handleLearnMoreClick}>
              learn more
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

interface ConfirmationProps {
  organization: Organization;
}
