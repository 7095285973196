import { useState, useRef, useLayoutEffect } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

export default function LoadingButton({
  loading,
  children,
  loadingIcon,
  ...restButtonProps
}: ButtonProps & { loading?: boolean; loadingIcon?: JSX.Element }) {
  const buttonRef = useRef<any>();
  const [width, setWidth] = useState(undefined);

  useLayoutEffect(() => {
    if (!buttonRef.current) return;

    if (!loading) {
      setWidth(buttonRef.current.offsetWidth);
    }
  }, [loading, buttonRef, children]);

  if (loading) {
    return (
      <Button ref={buttonRef} {...restButtonProps} disabled={true} style={{ width }}>
        {loadingIcon || <CircularProgress size={25} color="secondary" />}
      </Button>
    );
  }

  return (
    <Button ref={buttonRef} {...restButtonProps}>
      {children}
    </Button>
  );
}
