export const formatDate = (date: string) => {
  if (date === undefined || date.length === 0) return '';

  try {
    const dateTimeSegments = date.split(' ');
    const dateSegments = dateTimeSegments[0].split('-');
    const formattedDate = new Date(Number(dateSegments[0]), Number(dateSegments[1]) - 1, Number(dateSegments[2]));

    return formattedDate.toLocaleDateString();
  } catch {
    return '';
  }
};

export const datesAreOnSameDay = (first: Date, second: Date) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

export const formatDateToText = (date: Date): string => {
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const convertUTCDateToLocalDate = (dateToFormat: string) => {
  var date = new Date(dateToFormat);
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate.toLocaleDateString();
};
