import Head from 'components/Head/Head';
import { PAGE_TITLE_HISTORY, APP_TITLE } from 'utils/constants';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import DonorHistoryPage from 'components/DonorHistoryPage/DonorHistoryPage';

export default function MyHistory() {
  const { isAuthenticatedAndReady } = useStoredAuth0Values();

  if (!isAuthenticatedAndReady) return <></>;

  return (
    <>
      <Head title={`${PAGE_TITLE_HISTORY} | ${APP_TITLE}`} />
      <DonorHistoryPage />
    </>
  );
}
