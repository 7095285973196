import { Auth0Provider, Auth0ProviderOptions, AppState } from '@auth0/auth0-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createTheme, Theme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { env } from 'env';
import useLinkedApolloClient from 'hooks/useLinkedApolloClient';
import useAnalytics, { events } from 'hooks/useAnalytics';
import { RedirectContextProvider } from './RedirectProvider';
import { lightTheme } from 'theme/appTheme';
import { CookiesProvider } from 'react-cookie';

export default function AppProviders({ children }: { children: React.ReactNode }) {
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const defaultAuth0ProviderProps: Auth0ProviderOptions = {
    domain: env.REACT_APP_AUTH0_DOMAIN || 'devo3bq6a3s.us.auth0.com',
    clientId: env.REACT_APP_AUTH0_CLIENT_ID || 'q1pMm1wHNGgZkQ1T6dPYrMnBxhChpgAS',
    redirectUri: `${env.REACT_APP_HOST}/`,
    audience: env.REACT_APP_AUTH0_AUDIENCE,
    scope: 'read:current_user update:current_user_metadata',
    cacheLocation: 'memory',
    onRedirectCallback: (appState: AppState) => handleAuth0Redirect(appState),
  };
  const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY ?? '');
  const theme: Theme = responsiveFontSizes(createTheme(lightTheme));
  const client = useLinkedApolloClient();

  function handleAuth0Redirect(appState: AppState) {
    trackEvent(events.login, { method: 'Auth0' });
    history.push({ pathname: '/auth-redirect', state: appState });
  }

  return (
    <Auth0Provider {...defaultAuth0ProviderProps}>
      <Elements stripe={stripePromise}>
        <ApolloProvider client={client}>
          <RedirectContextProvider>
            <CookiesProvider>
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </CookiesProvider>
          </RedirectContextProvider>
        </ApolloProvider>
      </Elements>
    </Auth0Provider>
  );
}
