import { useReducer, useEffect } from 'react';
import { createStyles, Theme, CssBaseline, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';

import clsx from 'clsx';

import { getAccessTokenFromStorage } from 'utils/storage';
import useDonorProfile from 'hooks/useDonorProfile';
import useAnalytics from 'hooks/useAnalytics';
import NavigationDrawer from './NavigationDrawer';
import FooterActionBlock from './FooterActionBlock';
import Footer from './Footer';
import { FOOTER_HEIGHT } from '../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'clip',
    },
    childContainer: {
      maxWidth: '1600px',
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
      background: theme.palette.background.paper,
      // marginLeft: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

const Layout: React.FC = ({ children }) => {
  const { identify } = useAnalytics();
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const { isReady } = useStoredAuth0Values();
  const [open] = useReducer((open) => !open, true);
  const isLikelyAuthenticated = !!getAccessTokenFromStorage();
  const forceOpen = !isReady && isLikelyAuthenticated;
  const [donorProfile] = useDonorProfile();
  const donorId = donorProfile?.id;

  useEffect(() => {
    if (!donorId) return;

    identify(donorId);
  }, [donorId, identify]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavigationDrawer forceOpen={forceOpen} />
      <Container
        component={'main'}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        maxWidth={false}
        disableGutters={true}
        sx={{
          marginLeft: {
            xs: 0,
            sm: isAuthenticated || forceOpen ? '73px' : 0,
          },
          width: {
            xs: '100%',
            sm: `calc(100% - ${isAuthenticated || forceOpen ? '73px' : 0})`,
          },
        }}
      >
        <div className={classes.toolbar} />
        <Container className={classes.childContainer} maxWidth={false}>
          {isReady ? children : <Skeleton height={400} sx={{ borderRadius: 5, bgcolor: 'secondary.light' }} />}
        </Container>
        <FooterActionBlock />
        <Footer />
      </Container>
      <footer>{/* <Footer /> */}</footer>
    </div>
  );
};

export default Layout;
