import { Link as RouterLink } from 'react-router-dom';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import useDonorProfile from '../../hooks/useDonorProfile';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    updateProfileLink: {
      color: theme.palette.text.primary,
    },
  })
);

interface DonorNameOrAccountLinkProps {
  defaultText: string;
}

const DonorNameOrAccountLink = ({ defaultText }: DonorNameOrAccountLinkProps) => {
  const classes = useStyles();

  const [donorProfile] = useDonorProfile();

  const hasFirstName = (): boolean => {
    if (!donorProfile) return false;
    return donorProfile.firstName !== null && donorProfile.firstName !== undefined;
  };

  const hasLastName = (): boolean => {
    if (!donorProfile) return false;
    return donorProfile.lastName !== null && donorProfile.lastName !== undefined;
  };

  return (
    <>
      {(hasFirstName() || hasLastName()) &&
        `${hasFirstName() ? donorProfile?.firstName : ''} ${hasLastName() ? donorProfile?.lastName : ''}`}

      {!hasFirstName() && !hasLastName() && (
        <RouterLink className={classes.updateProfileLink} to="/my-account">
          {defaultText}
        </RouterLink>
      )}
    </>
  );
};

export default DonorNameOrAccountLink;
