import { Box, Skeleton } from '@mui/material';

export default function StatisticBoxSkeleton() {
  return (
    <Box
      sx={{
        flexBasis: { xs: '100%', sm: '50%', md: '33.333%', lg: '25%', xl: '20%' },
        flexGrow: 0,
        flexShrink: 1,
        minHeight: { xs: '130px', sm: '114px' },
        p: { xs: 1, sm: 2 },
        transform: 'scale(1,1)',
      }}
    >
      <Skeleton
        sx={{
          border: '1px solid #999',
          borderRadius: 2,
          flexGrow: 0,
          flexShrink: 0,
          height: '100%',
          p: 2,
          transform: 'scale(1,1)',
          width: '100%',
        }}
        variant="text"
      />
    </Box>
  );
}
