import { useState, useCallback } from 'react';
import {
  updateCampaignGql,
  UpdateCampaignMutationResponse,
  UpdateCampaignMutationVariables,
} from 'gql/campaigns/updateCampaign';
import { getCampaignForModificationGql } from 'gql/campaigns/getCampaign';
import { addCampaignGql, AddCampaignMutationResponse, AddCampaignMutationVariables } from 'gql/campaigns/addCampaign';
import { getCampaignsGql } from 'gql/campaigns/getCampaigns';
import useHedadoMutation from 'hooks/useHedadoMutation';

export function useSaveCampaign() {
  const [hasErrored, setHasErrored] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);

  const [addCampaignMutation] = useHedadoMutation<AddCampaignMutationResponse, AddCampaignMutationVariables>(
    addCampaignGql,
    {
      onError: () => setHasErrored(true),
      refetchQueries: [
        {
          query: getCampaignsGql,
          variables: { first: 25, search: '' },
        },
      ],
    }
  );
  const [updateCampaignMutation] = useHedadoMutation<UpdateCampaignMutationResponse, UpdateCampaignMutationVariables>(
    updateCampaignGql,
    {
      onError: () => setHasErrored(true),
      onCompleted: () => {
        setHasUpdated(true);
      },
    }
  );

  async function createCampaign(variables: AddCampaignMutationVariables) {
    const addCampaignResult = await addCampaignMutation({ variables });

    if (!addCampaignResult.data?.addCampaign?.id) {
      setHasErrored(true);
      return;
    }

    return addCampaignResult.data.addCampaign;
  }

  async function updateCampaign(id: string, variables: Omit<UpdateCampaignMutationVariables, 'id'>) {
    const updateCampaignResult = await updateCampaignMutation({
      variables: {
        id,
        ...variables,
      },

      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getCampaignForModificationGql,
          variables: { id },
        },
      ],
    });

    if (!updateCampaignResult.data?.updateCampaign) {
      setHasErrored(true);
      return;
    }

    return updateCampaignResult.data.updateCampaign;
  }

  const clearStatus = useCallback(() => {
    setHasErrored(false);
    setHasUpdated(false);
  }, []);

  return { createCampaign, hasErrored, hasUpdated, updateCampaign, clearStatus };
}
