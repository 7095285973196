import { useMemo } from 'react';
import { hasAnyError, hasAnyVisibleError } from 'helpers/formHelpers';
import { computeDetailsFormStateErrors, DetailsFormState } from './useDetailsFormState';
import { computeMatchingFormStateErrors, MatchingFormState } from './useMatchingFormState';
import { computeNonProfitsFormStateErrors, NonProfitsFormState } from './useNonProfitsFormState';
import { computeDonationsFormStateErrors, DonationsFormState } from './useDonationsFormState';
import { computeMembersFormStateErrors, MembersFormState } from './useMembersFormState';

interface UseCampaignFormErrorsArgs {
  isSlugTaken: boolean;
  details: {
    formState: DetailsFormState;
    setFormState: React.Dispatch<React.SetStateAction<DetailsFormState>>;
  };
  matching: {
    formState: MatchingFormState;
    setFormState: React.Dispatch<React.SetStateAction<MatchingFormState>>;
  };
  nonProfits: {
    formState: NonProfitsFormState;
    setFormState: React.Dispatch<React.SetStateAction<NonProfitsFormState>>;
  };
  donations: {
    formState: DonationsFormState;
    setFormState: React.Dispatch<React.SetStateAction<DonationsFormState>>;
  };
  members: {
    formState: MembersFormState;
    setFormState: React.Dispatch<React.SetStateAction<MembersFormState>>;
  };
}

export function useCampaignFormErrors({
  isSlugTaken,
  details,
  matching,
  nonProfits,
  donations,
  members,
}: UseCampaignFormErrorsArgs) {
  const detailsErrors = useMemo(
    () => computeDetailsFormStateErrors(details.formState, isSlugTaken),
    [details.formState, isSlugTaken]
  );
  const matchingErrors = useMemo(() => computeMatchingFormStateErrors(matching.formState), [matching.formState]);
  const nonProfitsErrors = useMemo(
    () => computeNonProfitsFormStateErrors(nonProfits.formState),
    [nonProfits.formState]
  );
  const donationsErrors = useMemo(() => computeDonationsFormStateErrors(donations.formState), [donations.formState]);
  const membersErrors = useMemo(() => computeMembersFormStateErrors(members.formState), [members.formState]);

  const doesCampaignFormHaveAnyError = useMemo(
    () => [detailsErrors, matchingErrors, nonProfitsErrors, donationsErrors, membersErrors].some(hasAnyError),
    [detailsErrors, donationsErrors, matchingErrors, membersErrors, nonProfitsErrors]
  );

  const doesCampaignFormHaveAnyVisibleError = useMemo(
    () =>
      [
        { errors: detailsErrors, state: details.formState },
        { errors: matchingErrors, state: matching.formState },
        { errors: nonProfitsErrors, state: nonProfits.formState },
        { errors: donationsErrors, state: donations.formState },
        { errors: membersErrors, state: members.formState },
      ].some(({ errors, state }) => hasAnyVisibleError(errors, state)),
    [
      details.formState,
      detailsErrors,
      donations.formState,
      donationsErrors,
      matching.formState,
      matchingErrors,
      members.formState,
      membersErrors,
      nonProfits.formState,
      nonProfitsErrors,
    ]
  );

  const sectionWithFirstError = useMemo(
    () =>
      (
        [
          { errors: detailsErrors, name: 'details' },
          { errors: matchingErrors, name: 'matching' },
          { errors: nonProfitsErrors, name: 'nonProfits' },
          { errors: donationsErrors, name: 'donations' },
          { errors: membersErrors, name: 'members' },
        ] as {
          name: 'details' | 'matching' | 'nonProfits' | 'donations' | 'members';
          errors: { [key: string]: any };
        }[]
      ).find((item) => hasAnyError(item.errors))?.name || '',
    [detailsErrors, donationsErrors, matchingErrors, membersErrors, nonProfitsErrors]
  );

  return {
    details: detailsErrors,
    matching: matchingErrors,
    nonProfits: nonProfitsErrors,
    donations: donationsErrors,
    members: membersErrors,
    doesCampaignFormHaveAnyError,
    doesCampaignFormHaveAnyVisibleError,
    sectionWithFirstError,
  };
}
