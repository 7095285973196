import type { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { gql } from '@apollo/client';

export type CampaignDonationMatchesOrderByAttribute =
  | 'createdAt'
  | 'donorName'
  | 'donorEmail'
  | 'organizationEin'
  | 'organizationName'
  | 'organizationTaxDeductibility'
  | 'donationAmountInCents'
  | 'matchedAmountInCents';

export interface GetCampaignDonationMatchesQueryVariables {
  campaignId: string;
  endDate?: Date;
  orderByAttribute: CampaignDonationMatchesOrderByAttribute;
  orderDirection: 'asc' | 'desc';
  organizationId?: string;
  page: number;
  rowsPerPage: number;
  search?: string;
  startDate?: Date;
  statuses: DonationMatchStatus[];
}

export interface GetCampaignDonationMatchesQueryResponse {
  getCampaignDonationMatches: {
    total: number;
    donationMatches: {
      id: string;
      createdAt: Date;
      donationAmountInCents: number;
      donation?: {
        id: string;
        organization?: {
          id: string;
          ein: string;
          name: string;
          deductibility: number;
        };
      };
      donor?: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
      };
      matchedAmountInCents: number;
      status: DonationMatchStatus;
    }[];
  };
}

export const getCampaignDonationMatchesQuery = gql`
  query GetCampaignDonationMatches(
    $campaignId: String!
    $endDate: Date
    $orderByAttribute: DonationMatchOrderByAttribute!
    $orderDirection: DonationMatchOrderDirection!
    $organizationId: ID
    $page: Int!
    $rowsPerPage: Int!
    $search: String
    $startDate: Date
    $statuses: [DonationMatchStatus!]!
  ) {
    getCampaignDonationMatches(
      campaignId: $campaignId
      endDate: $endDate
      orderByAttribute: $orderByAttribute
      orderDirection: $orderDirection
      organizationId: $organizationId
      page: $page
      rowsPerPage: $rowsPerPage
      search: $search
      startDate: $startDate
      statuses: $statuses
    ) {
      total
      donationMatches {
        id
        createdAt
        donationAmountInCents
        donation {
          id
          organization {
            id
            ein
            name
            deductibility
          }
        }
        donor {
          id
          firstName
          lastName
          email
        }
        matchedAmountInCents
        status
      }
    }
  }
`;
