import { Box } from '@mui/material';
import TransactionHistory from './components/TransactionHistory';

export default function ContributionsTable() {
  return (
    <Box sx={{ mt: 5 }}>
      <TransactionHistory />
    </Box>
  );
}
