import type RangeKeyDict from 'react-date-range';
import { useState } from 'react';
import { Popover, Box, TextField } from '@mui/material';
import { DateRange } from 'react-date-range';
import dayjs from 'dayjs';

interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  onDateRangeChange: ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => void;
}

export default function DateRangePicker({ startDate, endDate, onDateRangeChange }: DateRangePickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDateRangeChange(ranges: RangeKeyDict.RangeKeyDict) {
    onDateRangeChange({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    });
  }

  const textFieldValue = getDateRangeText({ startDate, endDate });
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <TextField
        value={textFieldValue}
        placeholder="Choose a date range"
        label="Date Range"
        onClick={handleClick}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <Popover
        open={open}
        id={id}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          // NOTE: This css is a workaround to address a bug in the date range picker library:
          // https://github.com/hypeserver/react-date-range/issues/360
          sx={{
            '& .noSelection': {
              '.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge': {
                background: '#fff',
              },
              '.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
                {
                  color: '#000',
                },
            },
          }}
        >
          <DateRange
            className={!startDate || !endDate ? 'noSelection' : ''}
            ranges={[{ startDate, endDate }]}
            startDatePlaceholder="Start"
            endDatePlaceholder="End"
            onChange={handleDateRangeChange}
            retainEndDateOnFirstSelection={true}
            months={2}
            direction="horizontal"
            editableDateInputs={true}
          />
        </Box>
      </Popover>
    </>
  );
}

function getDateRangeText({ startDate, endDate }: { startDate?: Date; endDate?: Date }) {
  if (!startDate && !endDate) return '';

  if (startDate && !endDate) return `After ${dayjs(startDate).format('MMMM D, YYYY')}`;
  if (endDate && !startDate) return `Before ${dayjs(endDate).format('MMMM D, YYYY')}`;

  if (dayjs(startDate).isSame(dayjs(endDate), 'year'))
    return `${dayjs(startDate).format('MMMM D')} to ${dayjs(endDate).format('MMMM D, YYYY')}`;

  return `${dayjs(startDate).format('MMMM D, YYYY')} to ${dayjs(endDate).format('MMMM D, YYYY')}`;
}
