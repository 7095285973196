export const convertCentsToDollars = (amountInCents: number) => {
  const centsToDollars = amountInCents / 100;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(centsToDollars);
};

export const convertIntToCurrency = (amount: number, showCents: boolean = false) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: showCents ? 2 : 0,
  });

  return formatter.format(amount);
}