import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import PageTitle from '../components/global/PageTitle';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    noBullets: {
      listStyleType: 'none',
    },
  })
);

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <>
      <PageTitle title="Privacy Policy" />

      <p>
        <Typography variant="h6">Effective date: 09/01/2021</Typography>
      </p>

      <p>
        <Typography variant="body1">
          At Hedado, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal
          data.{' '}
          <strong>
            By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies
            outlined below, and you hereby consent that we will collect, use and share your information as described in
            this Privacy Policy.
          </strong>
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Remember that your use of Hedado's Services is at all times subject to our Terms of Use, which incorporates
          this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them
          in the Terms of Use.
        </Typography>
      </p>

      {/* <p>
        <Typography variant="body1">
          You  may  print  a  copy  of  this  Privacy  Policy  by  clicking  here  <INSERT  LINK  THAT  LAUNCHES  PRINTABLE VERSION>. 
        </Typography>
      </p> */}

      <p>
        <Typography variant="h5">Privacy Policy Table of Contents</Typography>

        <ul className={classes.noBullets}>
          <li>What this Privacy Policy Covers</li>
          <li>Personal Data</li>
          <ul className={classes.noBullets}>
            <li>Categories of Personal Data We Collect</li>
            <li>Categories of Sources of Personal Data</li>
            <li>Our Commercial or Business Purposes for Collecting Personal Data</li>
          </ul>
          <li>How We Share Your Personal Data</li>
          <li>Tracking Tools, Advertising and Opt-Out</li>
          <li>Data Security and Retention</li>
          <li>Personal Data of Children</li>
          <li>Other State Law Privacy Rights</li>
          <li>Changes to this Privacy Policy</li>
          <li>Contact Information</li>
        </ul>
      </p>

      <p>
        <Typography variant="h6">What this Privacy Policy Covers</Typography>
      </p>

      <p>
        <Typography variant="body1">
          This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services.
          “Personal Data” means any information that identifies or relates to a particular individual and also includes
          information referred to as “personally identifiable information” or “personal information” under applicable
          data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we
          don’t own or control or people we don’t manage.
        </Typography>
      </p>

      <p>
        <Typography variant="h5">Personal Data</Typography>
      </p>

      <p>
        <Typography variant="h6">Categories of Personal Data We Collect</Typography>
      </p>

      <p>
        <Typography variant="body1">
          This chart details the categories of Personal Data that we collect and have collected over the past 12 months:
        </Typography>
      </p>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Category of Personal Data</TableCell>
              <TableCell>Examples of Personal Data We Collect</TableCell>
              <TableCell>Categories of Third Parties With Whom We Share this Personal Data:</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Profile or Contact Data
              </TableCell>
              <TableCell>
                <ul>
                  <li>First and last name</li>
                  <li>Email</li>
                  <li>Unique identifiers such as passwords</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Service Providers</li>
                  <li>Analytics Partners</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Payment Data
              </TableCell>
              <TableCell>
                <ul>
                  <li>Payment card type</li>
                  <li>Last 4 digits of payment card</li>
                  <li>Email</li>
                  <li>Bank account information</li>
                  <li>Credit card expiration date</li>
                  <li>Zip code</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Service Providers (specifically our payment processing partner, currently Stripe, Inc.)</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Commercial Data
              </TableCell>
              <TableCell>
                <ul>
                  <li>Purchase history</li>
                  <li>Consumer profiles</li>
                </ul>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Web Analytics
              </TableCell>
              <TableCell>
                <ul>
                  <li>Referring webpage/source through which you accessed the Services</li>
                  <li>Statistics associated with the interaction between device or browser and the Services</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Analytics Partners</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Consumer Demographic Data
              </TableCell>
              <TableCell>
                <ul>
                  <li>Age / date of birth</li>
                  <li>Zip code</li>
                </ul>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Professional or Employment-Related Data
              </TableCell>
              <TableCell>
                <ul>
                  <li>Current work email address</li>
                </ul>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Non-Public Education Data
              </TableCell>
              <TableCell>
                <ul>
                  <li>Higher education alma maters</li>
                </ul>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Inferences Drawn From Other Personal Data Collected
              </TableCell>
              <TableCell>
                <ul>
                  <li>Profiles reflecting user attributes</li>
                  <li>Profiles reflecting user behavior</li>
                </ul>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Other Identifying Information that You Voluntarily Choose to Provide
              </TableCell>
              <TableCell>
                <ul>
                  <li> Identifying information in emails or letters you send us</li>
                </ul>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <p>
        <Typography variant="h6">Categories of Sources of Personal Data</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We collect Personal Data about you from the following categories of sources:
          <ul>
            <li>You</li>
            <ul>
              <li>When you provide such information directly to us.</li>
              <ul>
                <li>When you create an account or use our interactive tools and Services.</li>
                <li>When you voluntarily provide information in free-form text boxes through the </li>
                <li>Services or through responses to surveys or questionnaires.</li>
                <li>When you send us an email or otherwise contact us.</li>
              </ul>
            </ul>
            <li>When you use the Services and such information is collected automatically.</li>
            <ul>
              <li>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</li>
              <li>If you use a location-enabled browser, we may receive information about your location.</li>
              <li>
                If you download and install certain applications and software we make available, we may receive and
                collect information transmitted from your computing device for the purpose of providing you the relevant
                Services, such as information regarding when you are logged on and available to receive updates or alert
                notices.
              </li>
            </ul>
            <li>Third Parties</li>
            <ul>
              <li>Vendors</li>
              <ul>
                <li>
                  We may use analytics providers to analyze how you interact and engage with the Services, or third
                  parties may help us provide you with customer support.
                </li>
                <li>We may use vendors to obtain information to generate leads and create user profiles.</li>
              </ul>
              <li>Advertising Partners</li>
              <ul>
                <li>
                  We receive information about you from some of our vendors who assist us with marketing or promotional
                  services related to how you interact with our websites, applications, products, Services,
                  advertisements or communications.
                </li>
              </ul>
              <li>Social</li>
              <ul>
                {' '}
                Networks
                <li>
                  If you provide your social network account credentials to us or otherwise sign in to the Services
                  through a third-party site or service, some content and/or information in those accounts may be
                  transmitted into your account with us.
                </li>
              </ul>
            </ul>
          </ul>
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Our Commercial or Business Purposes for Collecting Personal Data</Typography>
      </p>

      <p>
        <Typography variant="body1">
          <ul>
            <li>Providing, Customizing and Improving the Services</li>
            <ul>
              <li>Creating and managing your account or other user profiles.</li>
              <li>Providing you with the products, services or information you request.</li>
              <li>Meeting or fulfilling the reason you provided the information to us.</li>
              <li>Providing support and assistance for the Services.</li>
              <li>Improving the Services, including testing, research, internal analytics and product development.</li>
              <li>Personalizing the Services, website content and communications based on your preferences.</li>
              <li>Doing fraud protection, security and debugging.</li>
              <li>
                Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth
                in applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).
              </li>
            </ul>
            <li>Marketing the Services</li>
            <ul>
              <li>Marketing and selling the Services.</li>
              <li>Showing you advertisements, including interest-based or online behavioral advertising.</li>
            </ul>
            <li>Corresponding with You</li>
            <ul>
              <li>
                Responding to correspondence that we receive from you, contacting you when necessary or requested, and
                sending you information about Hedado or the Services.
              </li>
              <li>
                Sending emails and other communications according to your preferences or that display content that we
                think will interest you.
              </li>
            </ul>
            <li>Meeting Legal Requirements and Enforcing Legal Terms</li>
            <ul>
              <li>
                Fulfilling our legal obligations under applicable law, regulation, court order or other legal process,
                such as preventing, detecting and investigating security incidents and potentially illegal or prohibited
                activities.
              </li>
              <li>Protecting the rights, property or safety of you, Hedado or another party.</li>
              <li>Enforcing any agreements with you.</li>
              <li>Responding to claims that any posting or other content violates third-party rights.</li>
              <li>Resolving disputes.</li>
            </ul>
          </ul>
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          We will not collect additional categories of Personal Data or use the Personal Data we collected for
          materially different, unrelated or incompatible purposes without providing you notice.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">How We Share Your Personal Data</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We disclose your Personal Data to the categories of service providers and other parties listed in this
          section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a
          “sale” of your Personal Data. For more information, please refer to the state-specific sections below.
          <ul>
            <li>
              Service Providers. These parties help us provide the Services or perform business functions on our behalf.
              They include:
            </li>
            <ul>
              <li>Hosting, technology and communication providers.</li>
              <li>Security and fraud prevention consultants.</li>
            </ul>
            <li>
              Advertising Partners. These parties help us market our services and provide you with other offers that may
              be of interest to you. They include:
            </li>
            <ul>
              <li>Marketing providers.</li>
            </ul>
            <li>Business Partners. These parties partner with us in offering various services. They include:</li>
            <ul>
              <li>Companies that we partner with to offer joint promotional offers or opportunities.</li>
            </ul>
            <li>Parties You Authorize, Access or Authenticate</li>
            <ul>
              <li>Third parties you access through the services.</li>
              <li>Social media services.</li>
            </ul>
          </ul>
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Legal Obligations</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We may share any Personal Data that we collect with third parties in conjunction with any of the activities
          set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business
          Purposes for Collecting Personal Data” section above.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Business Transfers</Typography>
      </p>

      <p>
        <Typography variant="body1">
          All of your Personal Data that we collect may be transferred to a third party if we undergo a merger,
          acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in
          whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your
          information becomes subject to different privacy and security policies and practices.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Data that is Not Personal Data</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by
          removing information that makes the data personally identifiable to a particular user. We may use such
          aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes,
          including to analyze, build and improve the Services and promote our business, provided that we will not share
          such data in a manner that could identify you.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Tracking Tools, Advertising and Opt-Out</Typography>
      </p>

      <p>
        <Typography variant="body1">
          The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript
          (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit
          and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies
          are small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when
          you use that device to access our Services. We may also supplement the information we collect from you with
          information received from third parties, including third parties that have placed their own Cookies on your
          device(s). Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests
          sent from a browser at this time.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          We use the following types of Cookies:
          <ul>
            <li>
              Essential Cookies. Essential Cookies are required for providing you with features or services that you
              have requested. For example, certain Cookies enable you to log into secure areas of our Services.
              Disabling these Cookies may make certain features and services unavailable.
            </li>
            <li>
              Functional Cookies. Functional Cookies are used to record your choices and settings regarding our
              Services, maintain your preferences over time and recognize you when you return to our Services. These
              Cookies help us to personalize our content for you, greet you by name and remember your preferences (for
              example, your choice of language or region).
            </li>
            <li>
              Performance/Analytical Cookies. Performance/Analytical Cookies allow us to understand how visitors use our
              Services. They do this by collecting information about the number of visitors to the Services, what pages
              visitors view on our Services and how long visitors are viewing pages on the Services.
              Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order
              to help us improve our campaigns and the Services’ content for those who engage with our advertising. For
              example, Google LLC (“Google”) uses cookies in connection with its Google Analytics services. Google’s
              ability to use and share information collected by Google Analytics about your visits to the Services is
              subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out
              of Google’s use of Cookies by visiting the Google advertising opt-out page at
              www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at
              https://tools.google.com/dlpage/gaoptout/.
            </li>
            <li>
              Retargeting/Advertising Cookies. Retargeting/Advertising Cookies collect data about your online activity
              and identify your interests so that we can provide advertising that we believe is relevant to you. For
              more information about this, please see the section below titled “Information about Interest- Based
              Advertisements.”
            </li>
          </ul>
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have
          an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as
          well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each
          new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do
          this, however, you may have to manually adjust some preferences every time you visit our website and some of
          the Services and functionalities may not work.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your
          browser’s menu. To find out more information about Cookies, including information about how to manage and
          delete Cookies, please visit http://www.allaboutcookies.org/.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Information about Interest-Based Advertisements:</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad
          agencies, ad technology vendors and research firms, to serve advertisements through the Services. These
          advertisements may be targeted to users who fit certain general profile categories or display certain
          preferences or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal Data)
          may be provided to us by you, or derived from the usage patterns of particular users on the Services and/or
          services of third parties. Such information may be gathered through tracking users’ activities across time and
          unaffiliated properties, including when you leave the Services. To accomplish this, we or our service
          providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to you through
          the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting
          for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when
          you visit other websites. Web beacons allow ad networks to view, edit or set their own Cookies on your
          browser, just as if you had requested a web page from their site.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral
          Advertising. Through the DAA and Network Advertising Initiative (“NAI”), several media and marketing
          associations have developed an industry self-regulatory program to give consumers a better understanding of,
          and greater control over, ads that are customized based a consumer’s online behavior across different websites
          and properties. To make choices about Interest-Based Ads from participating third parties, including to
          opt-out of receiving behaviorally targeted advertisements from participating organizations, please visit the
          DAA’s or NAI’s consumer opt-out pages, which are located at http://www.networkadvertising.org/choices/ or
          www.aboutads.info/choices.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Data Security and Retention</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical,
          technical, organizational and administrative security measures based on the type of Personal Data and how we
          are processing that data. You should also help protect your data by appropriately selecting and protecting
          your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and
          signing off after you have finished accessing your account. Although we work to protect the security of your
          account and other data that we hold in our records, please be aware that no method of transmitting data over
          the internet or storing data is completely secure.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to
          provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to
          comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or
          required by applicable law, rule or regulation. We may further retain information in an anonymous or
          aggregated form where that information would not identify you personally.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Personal Data of Children</Typography>
      </p>

      <p>
        <Typography variant="body1">
          As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 13
          years of age; if you are a child under the age of 13, please do not attempt to register for or otherwise use
          the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13
          years of age, we will delete that information as quickly as possible. If you believe that a child under 13
          years of age may have provided Personal Data to us, please contact us at support@hedado.com.
        </Typography>
      </p>

      <p>
        <Typography variant="h5">State Law Privacy Rights</Typography>
      </p>

      <p>
        <Typography variant="h6">California Resident Rights</Typography>
      </p>

      <p>
        <Typography variant="body1">
          Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to
          prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in
          order to submit such a request, please contact us at support@hedado.com.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Nevada Resident Rights</Typography>
      </p>

      <p>
        <Typography variant="body1">
          If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third
          parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at
          support@hedado.com with the subject line “Nevada Do Not Sell Request” and providing us with your name and the
          email address associated with your account.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Changes to this Privacy Policy</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to
          time, but we will alert you to any such changes by placing a notice on the Hedado website, by sending you an
          email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us
          (or you haven’t provided us with your email address), those legal notices will still govern your use of the
          Services, and you are still responsible for reading and understanding them. If you use the Services after any
          changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information
          we collect is subject to the Privacy Policy in effect at the time such information is collected.
        </Typography>
      </p>

      <p>
        <Typography variant="h5">Contact Information:</Typography>
      </p>

      <p>
        <Typography variant="body1">
          If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your
          Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact
          us at:
          <ul>
            <li>626-629-8721</li>
            <li>https://hedado.com/</li>
            <li>support@hedado.com</li>
          </ul>
        </Typography>
      </p>
    </>
  );
};

export default PrivacyPolicy;
