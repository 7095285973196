import { gql } from '@apollo/client';

export const UpdateDonorMetadataMutation_Name = 'UpdateDonorMetadata';
export const UpdateDonorMetadataMutation_Gql = gql`
  mutation ${UpdateDonorMetadataMutation_Name}($metadata: String!) {
    updateDonorMetadata(metadata: $metadata) {
      id
      firstName
      lastName
      email
    }
  }
`;
