import { gql } from '@apollo/client';

export const getMostRecentSubscriptionForDonor_Name = 'GetMostRecentSubscriptionForDonor';
export const getMostRecentSubscriptionForDonor_Gql = gql`
  query GetMostRecentSubscriptionForDonor {
    getMostRecentSubscriptionForDonor {
      startAt
      deactivatedAt
      frequency
      amountCents
      tipsCents
      createdAt
    }
  }
`;
