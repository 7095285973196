import type { CampaignDonationMatchesOrderByAttribute } from 'gql/donationMatches/getCampaignDonationMatches';
import { useState, useMemo, useCallback } from 'react';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';

export const DEFAULT_SORT_ORDERS: { [key in CampaignDonationMatchesOrderByAttribute]: 'asc' | 'desc' } = {
  createdAt: 'desc',
  donationAmountInCents: 'asc',
  donorEmail: 'asc',
  donorName: 'asc',
  matchedAmountInCents: 'asc',
  organizationEin: 'asc',
  organizationName: 'asc',
  organizationTaxDeductibility: 'asc',
};

export type PageSize = 25 | 50 | 100;

export function useDonationMatchesTableState(campaignId: string, status: DonationMatchStatus) {
  // When specific attributes of the table state are mutated, a few things must happen in order to present a predictable and intuitive table UI.
  // 1. When filter criteria change, we need to go back to the first page of the results
  // 2. When the page changes, we need to clear the any selected state
  // This is why most of the state setters in this hook are preceded by an underscore.  The exported setters include these necessary side effects in addition to setting the specific state

  const [endDate, _setEndDate] = useState<Date>();
  const [headerCheckSelected, setHeaderCheckSelected] = useState(false);
  const [orderByAttribute, setOrderByAttribute] = useState<CampaignDonationMatchesOrderByAttribute>('createdAt');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('desc');
  const [organizationId, _setOrganizationId] = useState('');
  const [page, _setPage] = useState(0);
  const [rowsPerPage, _setRowsPerPage] = useState<PageSize>(25);
  const [search, _setSearch] = useState('');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [startDate, _setStartDate] = useState<Date>();

  const setPage = useCallback((value: number) => {
    _setPage(value);
    setSelectedRows([]);
    setHeaderCheckSelected(false);
  }, []);

  const setSearch = useCallback(
    (value: string) => {
      _setSearch(value);
      setPage(0);
    },
    [setPage]
  );

  const setStartDate = useCallback(
    (value?: Date) => {
      _setStartDate(value);
      setPage(0);
    },
    [setPage]
  );

  const setEndDate = useCallback(
    (value?: Date) => {
      _setEndDate(value);
      setPage(0);
    },
    [setPage]
  );

  const setOrganizationId = useCallback(
    (value: string) => {
      _setOrganizationId(value);
      setPage(0);
    },
    [setPage]
  );

  const setRowsPerPage = useCallback(
    (value: PageSize) => {
      _setRowsPerPage(value);
      setPage(0);
    },
    [setPage]
  );

  const setOrderBy = useCallback(
    (key: CampaignDonationMatchesOrderByAttribute) => {
      if (key === orderByAttribute) {
        setOrderDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
      } else {
        setOrderByAttribute(key);
        setOrderDirection(DEFAULT_SORT_ORDERS[key]);
      }
      setPage(0);
    },
    [orderByAttribute, setPage]
  );

  const clearFilters = useCallback(() => {
    _setEndDate(undefined);
    _setOrganizationId('');
    _setSearch('');
    _setStartDate(undefined);
    _setPage(0);
  }, []);

  const filters = useMemo(
    () => ({
      clearFilters,
      endDate,
      setEndDate,
      setOrganizationId,
      setSearch,
      setStartDate,
      startDate,
    }),
    [clearFilters, endDate, setEndDate, setOrganizationId, setSearch, setStartDate, startDate]
  );

  const pagination = useMemo(
    () => ({
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
    }),
    [page, rowsPerPage, setPage, setRowsPerPage]
  );

  const ordering = useMemo(
    () => ({
      orderByAttribute,
      orderDirection,
      setOrderBy,
    }),
    [orderByAttribute, orderDirection, setOrderBy]
  );

  const queryVariables = useMemo(() => {
    return {
      campaignId,
      first: rowsPerPage,
      orderByAttribute,
      orderDirection,
      organizationId,
      page,
      rowsPerPage,
      statuses:
        status === DonationMatchStatus.settled
          ? [DonationMatchStatus.settled, DonationMatchStatus.processing]
          : [status],
      ...(search ? { search } : null),
      ...(startDate ? { startDate } : null),
      ...(endDate ? { endDate } : null),
    };
  }, [
    campaignId,
    endDate,
    orderByAttribute,
    orderDirection,
    organizationId,
    page,
    rowsPerPage,
    search,
    startDate,
    status,
  ]);

  return useMemo(
    () => ({
      filters,
      headerCheckSelected,
      ordering,
      pagination,
      queryVariables,
      selectedRows,
      setHeaderCheckSelected,
      setSelectedRows,
    }),
    [filters, headerCheckSelected, ordering, pagination, queryVariables, selectedRows]
  );
}

export type DonationMatchesTableState = ReturnType<typeof useDonationMatchesTableState>;

export const tableColumns: {
  disablePadding: boolean;
  label: string;
  id: CampaignDonationMatchesOrderByAttribute;
  numeric: boolean;
  width: number;
}[] = [
  {
    disablePadding: true,
    label: 'Date',
    id: 'createdAt',
    numeric: false,
    width: 110,
  },
  {
    disablePadding: false,
    label: 'Donor Name',
    id: 'donorName',
    numeric: false,
    width: 150,
  },
  {
    disablePadding: false,
    label: 'Donor Email',
    id: 'donorEmail',
    numeric: false,
    width: 170,
  },
  {
    disablePadding: false,
    label: 'EIN',
    id: 'organizationEin',
    numeric: false,
    width: 125,
  },
  {
    disablePadding: false,
    label: 'Organization',
    id: 'organizationName',
    numeric: false,
    width: 170,
  },
  { disablePadding: false, label: 'Tax Deductibility', id: 'organizationTaxDeductibility', numeric: false, width: 175 },
  { disablePadding: false, label: 'Donation Amount', id: 'donationAmountInCents', numeric: true, width: 185 },
  { disablePadding: false, label: 'Match Amount', id: 'matchedAmountInCents', numeric: true, width: 175 },
];
