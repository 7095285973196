import { memo, forwardRef } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import {
  DetailsFormState,
  DetailsFormStateErrors,
  DetailsFormStateKey,
} from 'components/CampaignForm/hooks/useDetailsFormState';
import {
  setStateFromInputChange,
  setStateFromSelectChange,
  setStateFromInputBlur,
  getVisibleError,
} from 'helpers/formHelpers';
import FormSection from './FormSection';
import FormGridItem from './FormGridItem';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CampaignTier } from 'model/CampaignTier.model';
import FormControl from '@mui/material/FormControl';
import useStoredAuth0Values from '../../../hooks/useStoredAuth0Values';

interface DetailsSectionProps {
  errors: DetailsFormStateErrors;
  formState: DetailsFormState;
  isSlugTaken: boolean;
  onSlugChange: (slug: string) => void;
  setFormState: React.Dispatch<React.SetStateAction<DetailsFormState>>;
  onPricingTierChange: (tier: CampaignTier) => void;
}

const DetailsSection = forwardRef<HTMLDivElement, DetailsSectionProps>(
  ({ errors, formState, onSlugChange, setFormState, onPricingTierChange }, ref) => {
    function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
      setStateFromInputBlur(event, setFormState);
    }

    function handleSelectInputChange(event: SelectChangeEvent) {
      setStateFromSelectChange(event, setFormState);

      if (event.target.name === DetailsFormStateKey.tier) {
        onPricingTierChange(event.target.value as CampaignTier);
      }
    }

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      setStateFromInputChange(event, setFormState);
    }

    function handleSlugChange(event: React.ChangeEvent<HTMLInputElement>) {
      handleInputChange(event);
      onSlugChange(event.currentTarget.value);
    }

    const visibleErrors = {
      description: getVisibleError(formState.description, errors.description),
      nameOnReceipt: getVisibleError(formState.nameOnReceipt, errors.nameOnReceipt),
      slug: getVisibleError(formState.slug, errors.slug),
      title: getVisibleError(formState.title, errors.title),
    };

    const { isAdmin } = useStoredAuth0Values();

    return (
      <FormSection heading="Details" ref={ref}>
        {isAdmin && (
          <FormGridItem>
            <FormControl fullWidth>
              <InputLabel id="tier-label" required>
                Pricing Tier
              </InputLabel>
              <Select
                labelId="tier-label"
                id="tier-select"
                name={DetailsFormStateKey.tier}
                value={formState.tier.value}
                label="Pricing Tier"
                onChange={handleSelectInputChange}
                onBlur={handleInputBlur}
                required
              >
                <MenuItem value={CampaignTier.basic}>{CampaignTier.basic}</MenuItem>
                <MenuItem value={CampaignTier.benefactor}>{CampaignTier.benefactor}</MenuItem>
                <MenuItem value={CampaignTier.business}>{CampaignTier.business}</MenuItem>
              </Select>
            </FormControl>
          </FormGridItem>
        )}
        <FormGridItem>
          <TextField
            error={!!visibleErrors.title}
            fullWidth
            helperText={visibleErrors.title}
            label="Title"
            name={DetailsFormStateKey.title}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            required
            value={formState.title.value}
          />
        </FormGridItem>
        <FormGridItem>
          <TextField
            error={!!visibleErrors.nameOnReceipt}
            fullWidth
            helperText={visibleErrors.nameOnReceipt}
            label="Owner (name on receipt)"
            name={DetailsFormStateKey.nameOnReceipt}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            required
            value={formState.nameOnReceipt.value}
          />
        </FormGridItem>

        <FormGridItem>
          <TextField
            error={!!visibleErrors.slug}
            fullWidth
            helperText={visibleErrors.slug}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ fontSize: '0.75em' }} disableTypography={true}>
                  https://hedado.com/c/
                </InputAdornment>
              ),
            }}
            label="URL"
            name={DetailsFormStateKey.slug}
            onBlur={handleInputBlur}
            onChange={handleSlugChange}
            value={formState.slug.value}
          />
        </FormGridItem>
        <FormGridItem>
          <TextField
            error={!!visibleErrors.description}
            fullWidth
            helperText={visibleErrors.description}
            label="Description"
            minRows={3}
            multiline
            name={DetailsFormStateKey.description}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            required
            value={formState.description.value}
          />
        </FormGridItem>
      </FormSection>
    );
  }
);

export default memo(DetailsSection);
