import { gql } from '@apollo/client';
import Campaign from 'model/Campaign.model';

export interface GetCampaignsForDonorQueryResponse {
  getCampaignsForDonor: Campaign[];
}

export const getCampaignsForDonor_Name = 'GetCampaignsForDonor';
export const getCampaignsForDonor_Gql = gql`
  query GetCampaignsForDonor {
    getCampaignsForDonor {
      displayData {
        logoImage
        bannerImage
        bannerImageMobile
      }
      description
      id
      slug
      title
    }
  }
`;
