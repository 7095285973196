import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { Button, Drawer, Box, Grid, Typography, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { env } from 'env';
import { convertCentsToDollars } from 'helpers/currencyHelper';
import {
  getCampaignForModificationGql,
  GetCampaignForModificationQueryVariables,
  GetCampaignForModificationQueryResponse,
} from 'gql/campaigns/getCampaign';
import useHedadoQuery from 'hooks/useHedadoQuery';
import CampaignForm from 'components/CampaignForm/CampaignForm';

interface CampaignDetailsDrawerProps {
  campaignId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function CampaignDetailsDrawer({ campaignId, isOpen, onClose }: CampaignDetailsDrawerProps) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { data: campaignData } = useHedadoQuery<
    GetCampaignForModificationQueryResponse,
    GetCampaignForModificationQueryVariables
  >(getCampaignForModificationGql, {
    variables: { id: campaignId },
  });

  function handleEditClick() {
    setIsEditing(true);
  }

  function handleCancelEditClick() {
    if (!hasUnsavedChanges || window.confirm('Discard unsaved changes?')) {
      setIsEditing(false);
    }
  }

  function handleCampaignSaved() {
    setIsEditing(false);
    setHasUnsavedChanges(false);
  }

  function handleClose() {
    if (!hasUnsavedChanges || window.confirm('Discard unsaved changes?')) {
      onClose();
    }
  }

  const handleUnsavedChangesChanged = useCallback((hasUnsavedChanges: boolean) => {
    setHasUnsavedChanges(hasUnsavedChanges);
  }, []);

  useEffect(() => {
    if (isOpen) return;
    const timeout = setTimeout(() => {
      setIsEditing(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, [isOpen]);

  if (!campaignData?.getCampaign) return null;

  const { qualification, donors, admin, domains, ...campaign } = campaignData.getCampaign;

  if (isEditing)
    return (
      <Drawer anchor="right" onClose={handleClose} open={isOpen} variant="temporary">
        <Box
          sx={{
            pb: 2,
            height: 'calc(100vh - 64px)',
            mt: '64px',
            overflow: 'scroll',
            position: 'relative',
            backgroundColor: 'secondary.light',
            width: {
              sm: '500px',
              xs: '100vw',
            },
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              background: '#fff',
              px: 2,
              pb: 1.5,
              zIndex: 10,
              boxShadow: '0px 0px 3px #000',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography variant="h5" sx={{ pt: 1 }}>
                  {campaign.title}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'right' }}>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" size="small" onClick={handleCancelEditClick}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 2 }}>
            <CampaignForm
              admin={admin}
              campaign={campaign}
              domains={domains}
              donors={donors}
              onSaved={handleCampaignSaved}
              onUnsavedChangesChanged={handleUnsavedChangesChanged}
              qualification={qualification}
              simplifiedView={true}
            />
          </Box>
        </Box>
      </Drawer>
    );

  return (
    <Drawer anchor="right" onClose={onClose} open={isOpen} variant="temporary">
      <Box
        sx={{
          pb: 2,
          height: 'calc(100vh - 64px)',
          mt: '64px',
          overflow: 'scroll',
          position: 'relative',
          width: {
            sm: '500px',
            xs: '100vw',
          },
        }}
      >
        <Box sx={{ position: 'sticky', top: 0, background: '#fff', px: 2, pb: 1.5, boxShadow: '0px 0px 3px #000' }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h5" sx={{ pt: 1 }}>
                {campaign.title}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" size="small" onClick={handleEditClick}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ px: 2 }}>
          <Divider sx={{ mt: 1, mb: 3 }} />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Owner (name on receipt)</Typography>
            <Typography>{campaign.nameOnReceipt}</Typography>

            {campaign.slug && (
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>URL</Typography>
                <Typography>
                  <a
                    href={`${env.REACT_APP_NEXTJS_URL}/c/${campaign.slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >{`${env.REACT_APP_NEXTJS_URL}/c/${campaign.slug}`}</a>
                </Typography>
              </Box>
            )}

            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>Description</Typography>
              <Typography>{campaign.description}</Typography>
            </Box>

            {qualification.startDate && (
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>Start Date</Typography>
                <Typography>{dayjs.utc(qualification.startDate).format('MMMM D YYYY')}</Typography>
              </Box>
            )}

            {qualification.endDate && (
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>End Date</Typography>
                <Typography>{dayjs.utc(qualification.endDate).format('MMMM D YYYY')}</Typography>
              </Box>
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {qualification.hasMatching ? (
              <>
                {typeof qualification.matchRatio === 'number' && (
                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>Match Percentage</Typography>
                    <Typography>
                      {qualification.matchRatio > 0 ? <>{qualification.matchRatio * 100}%</> : 'No match'}
                    </Typography>
                  </Box>
                )}

                {typeof qualification.overallMatchLimitCents === 'number' && (
                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>Matching up to</Typography>
                    <Typography>
                      {convertCentsToDollars(qualification.overallMatchLimitCents)} total donations
                    </Typography>
                  </Box>
                )}

                {typeof qualification.individualMatchLimitCents === 'number' && (
                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>Matching up to</Typography>
                    <Typography>{convertCentsToDollars(qualification.individualMatchLimitCents)} per user</Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>Match Percentage</Typography>
                <Typography>No match</Typography>
              </Box>
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Highlighted Nonprofits</Typography>

            <Typography>
              {campaign.categoryOrganizations.edges.map(({ node }) => (
                <Box>{node.organization.name}</Box>
              ))}
            </Typography>

            <Box component="ul" sx={{ mt: 1, mb: 0, pl: 2 }}>
              {campaign.displayData.showCampaignOrganizations && (
                <Typography variant="body2" component="li">
                  Showing highlighted nonprofits
                </Typography>
              )}
              {campaign.displayData.showDonorOrganizations && (
                <Typography variant="body2" component="li">
                  Showing all nonprofits that campaign participants have supported
                </Typography>
              )}
              {qualification.limitOrganizations && (
                <Typography variant="body2" component="li">
                  Matching limited to highlighted nonprofits
                </Typography>
              )}
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Donation Tracking</Typography>
            {!!campaign.donationGoalCents && (
              <Typography variant="body2">
                <b>Campaign Goal: </b>
                {convertCentsToDollars(campaign.donationGoalCents)}
              </Typography>
            )}
            <Box component="ul" sx={{ mt: 1, mb: 0, pl: 2 }}>
              {!!(campaign.donationGoalCents && campaign.displayData.showDonationGoal) && (
                <Typography variant="body2" component="li">
                  Displaying campaign goal
                </Typography>
              )}
              {campaign.displayData.showDonationTotal && (
                <Typography variant="body2" component="li">
                  Displaying total amount donated
                </Typography>
              )}
              {campaign.displayData.showDonationTotalMatched && (
                <Typography variant="body2" component="li">
                  Displaying total amount matched
                </Typography>
              )}
              {qualification.includeDonationsExceedingMatchLimits && (
                <Typography variant="body2" component="li">
                  Continuing to track donations after campaign limit has been met
                </Typography>
              )}
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Members</Typography>
            <Box component="ul" sx={{ mt: 1, mb: 0, pl: 2 }}>
              {qualification.limitToPeople && (
                <Typography variant="body2" component="li">
                  Limited to specific email addresses
                </Typography>
              )}
              {qualification.limitToDomains && (
                <Typography variant="body2" component="li">
                  Limited to specific email domains
                </Typography>
              )}
              {qualification.openToAll && (
                <Typography variant="body2" component="li">
                  Open to any donor
                </Typography>
              )}
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Admin</Typography>
            <Box component="ul" sx={{ mt: 1, mb: 0, pl: 2 }}>
              {admin.map((singleAdmin) => (
                <Typography variant="body2" component="li">
                  {singleAdmin.donor.email}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
