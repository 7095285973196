import { gql } from '@apollo/client';

export interface GetCampaignDonationMatchTotalsQueryVariables {
  campaignId: string;
}

export interface GetCampaignDonationMatchTotalsQueryResponse {
  getCampaignDonationMatchTotals: {
    pending: number;
    deferred: number;
    excluded: number;
    settled: number;
    processing: number;
  };
}

export const getCampaignDonationMatchTotalsQuery = gql`
  query GetCampaignDonationMatchTotals($campaignId: ID!) {
    getCampaignDonationMatchTotals(campaignId: $campaignId) {
      pending
      deferred
      excluded
      settled
      processing
    }
  }
`;
