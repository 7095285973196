import { useAuth0 } from '@auth0/auth0-react';

import { Backdrop, Box, Modal, Fade, Button, Typography, Grid, Link, useMediaQuery } from '@mui/material';
import useOnboarding from '../../../hooks/useOnboarding';
import OnboardingGuideCard from './OnboardingGuideCard';

// icons
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ChevronRight from '@mui/icons-material/ChevronRight';

const images = {
  getStarted: require('../../../assets/img/hedado-get-started.svg').default,
  getStartedXs: require('../../../assets/img/hedado-get-started-xs.svg').default,
};

interface IntroModalProps {
  open: boolean;
  handleClose: () => void;
}

export default function IntroModal(props: IntroModalProps) {
  const [onboarding, setOnboarding] = useOnboarding();
  const { user, isAuthenticated } = useAuth0();

  const isMinHeight = useMediaQuery('(min-height:900px)');

  const handleCheckboxChange = async (e: React.BaseSyntheticEvent) => {
    let { name, checked } = e.target;
    e.persist();
    onboarding.introModal[name] = checked;
    setOnboarding(onboarding);
  };

  const handleClose = () => {
    setOnboarding({ introModal: { dismissed: true } });
    props.handleClose();
  };

  const handleExploreOnMyOwn = () => {
    setOnboarding({ introModal: { exploreOnMyOwn: true, dismissed: true } });
    props.handleClose();
  };

  return (
    <div>
      {isAuthenticated && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleExploreOnMyOwn}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: { xs: 0, md: isMinHeight ? '50%' : 0 },
                left: { xs: 0, md: isMinHeight ? '50%' : 0 },
                transform: { md: isMinHeight ? 'translate(-50%, -50%)' : 'none' },
                backgroundColor: 'secondary.light',
                width: { xs: '100%', md: isMinHeight ? '90vw' : '100%', lg: isMinHeight ? '80vw' : '100%' },
                maxWidth: '1200px',
                borderRadius: { xs: 0, md: isMinHeight ? 8 : 0 },
                boxShadow: 24,
                height: { xs: '100%', md: isMinHeight ? 'auto' : '100%' },
                p: { xs: 4, md: 8 },
                outline: 'none !important',
                justifyContent: 'center',
                display: 'flex',
                overflow: isMinHeight ? 'auto' : 'scroll',
              }}
            >
              <Grid container spacing={3} sx={{ display: 'flex' }} maxWidth="90%">
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                      src={images.getStarted}
                      alt="Get Started"
                      style={{ maxWidth: '50%', maxHeight: '20vh', minWidth: '200px' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography id="transition-modal-title" variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                    Welcome{user?.given_name ? `, ${user?.given_name}` : ' to Hedado'}!
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <Typography id="transition-modal-description" variant="h6" component="h3">
                    Being a philanthropist has never been easier. To make it easy to get started, tell us how we can
                    help.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', flexBasis: 'auto', gap: 4 }}>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                      <OnboardingGuideCard
                        title="Donation Receipts"
                        label="I want to track my receipts in one place and be ready for tax time."
                        checkboxName="receiptTracking"
                        icon={<ReceiptLongIcon sx={{ fontSize: '2rem' }} />}
                        backgroundColor="#a6cfe4"
                        textColor="primary.main"
                        state={onboarding.introModal?.receiptTracking}
                        onChangeEvent={handleCheckboxChange}
                        checkboxStyle="interactive"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                      <OnboardingGuideCard
                        title="Portfolio Giving"
                        label="I want Hedado to help me manage my charitable giving."
                        checkboxName="portfolio"
                        icon={<VolunteerActivismIcon sx={{ fontSize: '2rem' }} />}
                        backgroundColor="#5da38e"
                        textColor="#ffffff"
                        state={onboarding.introModal?.portfolio}
                        onChangeEvent={handleCheckboxChange}
                        checkboxStyle="interactive"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 4, display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<ChevronRight />}
                    onClick={handleClose}
                    disabled={
                      onboarding.introModal?.receiptTracking === undefined &&
                      onboarding.introModal?.portfolio === undefined
                    }
                  >
                    Get started
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                  <Link sx={{ cursor: 'pointer' }} onClick={handleExploreOnMyOwn}>
                    Let me explore on my own
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
