import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import RouteItem from 'model/RouteItem.model';
import { routes } from 'config/routes';
import { ScrollToTop } from 'helpers/ScrollToTop';
import AppProviders from 'components/global/AppProviders';
import Head from 'components/Head/Head';
import Layout from 'components/global/Layout';
import ProtectedRoute from 'components/auth/ProtectedRoute';

const DefaultComponent = () => <div>No Component Defined.</div>;

export default function App() {
  return (
    <React.StrictMode>
      <Router>
        <AppProviders>
          <ScrollToTop />
          <Switch>
            <Layout>
              <Head />
              {/* for each route config, a react route is created */}
              {routes
                .filter((route) => route.enabled)
                .map((route: RouteItem) =>
                  route.subRoutes ? (
                    route.subRoutes
                      .filter((subRoute) => subRoute.enabled)
                      .map((item: RouteItem) => (
                        <Route
                          key={`${item.key}`}
                          path={`${item.path}`}
                          component={item.component || DefaultComponent}
                          exact
                        />
                      ))
                  ) : route.protected ? (
                    <ProtectedRoute
                      adminOnly={route.adminOnly}
                      key={`${route.key}`}
                      path={`${route.path}`}
                      component={route.component || DefaultComponent}
                      exact
                    />
                  ) : (
                    <Route
                      key={`${route.key}`}
                      path={`${route.path}`}
                      component={route.component || DefaultComponent}
                      exact
                    />
                  )
                )}
            </Layout>
          </Switch>
        </AppProviders>
      </Router>
    </React.StrictMode>
  );
}
