import { gql } from '@apollo/client';

export const getDistinctOrganizationsDonorSupported_Name = 'GetDistinctOrganizationsDonorSupported';
export const getDistinctOrganizationsDonorSupported_Gql = gql`
  query GetDistinctOrganizationsDonorSupported {
    getDistinctOrganizationsDonorSupported {
      id
      ein
      name
      causeArea
      mission
      context
      impact
      whyYouShouldCare
      websiteUrl
      annualReportUrl
      financialsUrl
      form990Url
      orgSize
      imageName
      revenue
      assets
      street
      city
      state
      zip
      classification
      deductibility
    }
  }
`;
