import { useMemo, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { env } from 'env';
import { getDistinctDonorOrganizationsForCampaign_Gql } from 'gql/campaigns/getDistinctDonorOrganizationsForCampaign';
import useAnalytics, { events } from 'hooks/useAnalytics';
import useHedadoQuery from 'hooks/useHedadoQuery';
import useHedadoMutation from 'hooks/useHedadoMutation';
import Head from 'components/Head/Head';
import ClampedText from 'components/ClampedText';
import OrganizationList from 'components/organization/OrganizationList';
import ShareButton from 'components/ShareButton';
import { computeEmptyStates } from './CampaignLandingPage.helpers';
import { CampaignLandingPageProps } from './CampaignLandingPage.interfaces';
import CampaignOrganizations from './components/CampaignOrganizations';
import AmountRaisedProgress from './components/AmountRaisedProgress';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import {
  AddCampaignSessionMutationVariables,
  AddCampaignSessionMutationResponse,
  addCampaignSessionGql,
} from 'gql/campaigns/addCampaignSession';
import { useCampaignPermissions } from 'components/CampaignAdminPage/hooks/useCampaignPermissions';

const CampaignLandingPage = ({ campaign }: CampaignLandingPageProps) => {
  const { trackEvent } = useAnalytics();
  const [cookies, setCookie, removeCookie] = useCookies(['campaignSession']);
  const { displayDonorOrganizations } = useCampaignPermissions();

  useEffect(() => {
    handleCampaignTrackingCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [saveCampaignSession] = useHedadoMutation<
    AddCampaignSessionMutationResponse,
    AddCampaignSessionMutationVariables
  >(addCampaignSessionGql);

  async function handleCampaignTrackingCookie() {
    if (!cookies['campaignSession']) {
      // If the cookie doesn't exist add it
      const campaignSessionId = uuidv4();
      const cookieContent = {
        campaignSessionId,
        campaigns: [campaign.id],
      };

      await createCampaignCookie(cookieContent, campaignSessionId);
    } else {
      // Determine if the campaign id is already in the cookie, if not, add it to the campaigns
      const cookieContent = cookies.campaignSession;
      const campaignAlreadyInCookie = cookieContent.campaigns.find((c: any) => c === campaign.id);

      if (!campaignAlreadyInCookie) {
        cookieContent.campaigns.push(campaign.id);

        removeCookie('campaignSession');
        await createCampaignCookie(cookieContent, cookieContent.campaignSessionId);
      }
    }
  }

  async function createCampaignCookie(cookieContent: any, campaignSessionId: string) {
    const cookieAccessPath = '/';

    setCookie('campaignSession', cookieContent, {
      path: cookieAccessPath,
      expires: getCookieExpirationDate(),
      domain: env.REACT_APP_COOKIE_ROOT,
      sameSite: 'lax',
    });

    await saveCampaignSession({
      variables: {
        campaignSessionId,
        campaignId: campaign.id,
      },
    });
  }

  function getCookieExpirationDate(): Date {
    const date = dayjs().add(90, 'day');
    return date.toDate();
  }

  const { description, displayData, donationGoalCents, donationTotalCents, donationTotalMatchedCents, id, title } =
    campaign;
  const {
    bannerImage,
    bannerImageMobile,
    logoImage,
    showCampaignOrganizations,
    showDonationGoal,
    showDonationTotal,
    showDonationTotalMatched,
    showDonorOrganizations,
  } = displayData;

  const bannerSrc = `${env.REACT_APP_S3_PATH}/campaigns/${bannerImage}`;
  const bannerSrcMobile = bannerImageMobile ? `${env.REACT_APP_S3_PATH}/campaigns/${bannerImageMobile}` : bannerSrc;

  const { loading, data: donorOrganizationData } = useHedadoQuery(getDistinctDonorOrganizationsForCampaign_Gql, {
    variables: {
      campaignId: id,
    },
  });

  const campaignOrganizations = campaign.categoryOrganizations.edges.map((edge) => edge.node.organization);
  const donorOrganizations = donorOrganizationData?.getDistinctDonorOrganizationsForCampaign;

  const { isEmptyState, hasBothTypesToDisplay } = useMemo(
    () =>
      computeEmptyStates({
        showDonorOrganizations,
        donorOrganizations,
        campaignOrganizations,
        showCampaignOrganizations,
      }),
    [showDonorOrganizations, showCampaignOrganizations, campaignOrganizations, donorOrganizations]
  );

  if (loading) return <></>;

  return (
    <>
      <Head
        title={`${title} on Hedado`}
        description={`${title} - make an impact today.`}
        imageAlt={`${title} Logo on Hedado`}
      />
      <Box
        sx={{
          '&::before': {
            content: '""',
            float: 'left',
            paddingTop: {
              md: '16.6666666%',
              xs: '33.333333%',
            },
          },
          '&::after': {
            content: '""',
            display: 'block',
            clear: 'both',
          },
          ml: -3,
          mr: -3,
          backgroundImage: {
            xs: `url(${bannerSrcMobile})`,
            md: `url(${bannerSrc})`,
          },
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          aspectRatio: {
            xs: '3 / 1',
            md: '6 / 1',
          },
          height: 'auto',
          position: 'relative',
          borderRadius: { xl: '0 0 0.5rem 0.5rem' },
        }}
      ></Box>
      <Box
        sx={{
          position: 'relative',
          marginTop: { md: '-100px', xs: '-30px' },
          px: { md: 5, xs: 0 },
          mb: { md: 0, xs: 3 },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            alignItems: { md: 'center', xs: 'flex-start' },
            gap: { md: 4, xs: 1 },
          }}
        >
          <Box
            sx={{
              background: 'white',
              border: '4px solid white',
              borderRadius: {
                md: '32px',
                xs: '16px',
              },
              maxHeight: {
                md: 180,
                xs: 90,
              },
              maxWidth: {
                md: 180,
                xs: 90,
              },
              overflow: 'hidden',
              display: 'flex',
            }}
          >
            <Box
              component="img"
              sx={{
                maxWidth: '100%',
                borderRadius: {
                  md: '28px',
                  xs: '14px',
                },
              }}
              src={`${env.REACT_APP_S3_PATH}/campaigns/${logoImage}`}
              alt={`${title} logo`}
            />
          </Box>
          {showDonationTotal && donationTotalCents !== undefined && (
            <AmountRaisedProgress
              total={donationTotalCents}
              goal={donationGoalCents}
              matched={donationTotalMatchedCents}
              showMatched={showDonationTotalMatched}
              showGoal={showDonationGoal}
            />
          )}
        </Box>

        <Box
          sx={{
            position: 'absolute',
            right: { md: 0, xs: 16 },
            top: { md: 100, xs: 40 },
            pt: { md: 2, xs: 0 },
          }}
        >
          <ShareButton
            shareData={{
              url: `${env.REACT_APP_NEXTJS_URL}/c/${campaign.slug}`,
              title: document.title,
            }}
            onShared={() => {
              trackEvent(events.shareCampaign, { campaignId: campaign.id });
            }}
          />
        </Box>
      </Box>

      <Typography variant="h5" sx={{ px: { md: 5, xs: 0 }, my: 2 }}>
        {title}
      </Typography>

      <Box sx={{ marginBottom: 1, maxWidth: '800px', px: { md: 5, xs: 0 } }}>
        <Typography variant="body1">
          <ClampedText text={description} maxLineCount={3} canExpand={true} />
        </Typography>
      </Box>

      {isEmptyState && (
        <Box sx={{ mx: { sx: 0, sm: 4 } }}>
          <OrganizationList organizations={[]} title="" subTitle="" />
        </Box>
      )}

      {showCampaignOrganizations && (
        <CampaignOrganizations
          title={`${title} Supported`}
          organizations={campaignOrganizations}
          inAccordion={hasBothTypesToDisplay}
        />
      )}

      {hasBothTypesToDisplay && <Divider />}

      {showDonorOrganizations && displayDonorOrganizations(campaign.tier) && (
        <CampaignOrganizations
          title={`${title} Employee Picks`}
          organizations={donorOrganizations}
          inAccordion={hasBothTypesToDisplay}
        />
      )}
    </>
  );
};

export default CampaignLandingPage;
