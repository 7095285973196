import { useLocation } from 'react-router-dom';
import { APP_TITLE } from 'utils/constants';

interface MetaDataOverrides {
  title?: string;
  image?: string;
  imageAlt?: string;
  url?: string;
  description?: string;
}

interface MetaData {
  title: string;
  image: string;
  imageAlt: string;
  url: string;
  description: string;
}

const DEFAULT_TITLE = APP_TITLE;
const DEFAULT_IMAGE = `${window.location.origin}/icon.png`;
const DEFAULT_IMAGE_ALT = 'Hedado Logo';
const DEFAULT_DESCRIPTION = 'Donate like a boss.';

export function useMetaTagData(overrides?: MetaDataOverrides): MetaData {
  const { pathname } = useLocation();

  return {
    title: DEFAULT_TITLE,
    image: DEFAULT_IMAGE,
    imageAlt: DEFAULT_IMAGE_ALT,
    description: DEFAULT_DESCRIPTION,
    url: `${window.location.origin}${pathname}`,
    ...overrides,
  };
}
