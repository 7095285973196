import { addOrUpdatePaymentMethod_Gql } from 'gql/paymentMethod/addOrUpdatePaymentMethod';
import { getPaymentMethodQuery_Name } from 'gql/profileQueries';
import { getLoggedInDonorProfileQuery_Name } from 'gql/donorProfileQuery';
import { getCampaignPaymentMethod_Name } from 'gql/paymentMethod/getCampaignPaymentMethod';
import useHedadoMutation from 'hooks/useHedadoMutation';

export function useUpdatePaymentMethod({ onError, onCompleted }: { onError: () => void; onCompleted: () => void }) {
  const [updatePaymentMethod, { loading }] = useHedadoMutation(addOrUpdatePaymentMethod_Gql, {
    awaitRefetchQueries: true,
    refetchQueries: [getPaymentMethodQuery_Name, getLoggedInDonorProfileQuery_Name, getCampaignPaymentMethod_Name],
    onError,
    onCompleted,
  });

  return { updatePaymentMethod, loading };
}
