import type { Donation } from 'components/DonorHistoryPage/hooks/useDonorDonationsQuery';
import { useState, useEffect } from 'react';
import { Box, Paper, Skeleton, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useDonorDonationsQuery } from 'components/DonorHistoryPage/hooks/useDonorDonationsQuery';
import { useDonationsTableState, tableColumns } from 'components/DonorHistoryPage/hooks/useDonationsTableState';
import DonationFilters from './components/DonationFilters/DonationFilters';
import DonationsTableFooter from './components/DonationsTableFooter';
import DonationsTableHeaderCell from './components/DonationsTableHeaderCell';
import DonationTableCell from './components/DonationTableCell';
import DonationMatchesDialog from './components/DonationMatchesDialog/DonationMatchesDialog';
import DonationsTableEmptyState from './components/DonationsTableEmptyState';

export default function Donations() {
  const { filters, ordering, pagination, queryVariables } = useDonationsTableState();
  const { donations, isLoading } = useDonorDonationsQuery(queryVariables);
  const [viewingDonationMatches, setViewingDonationMatches] = useState<Donation['donationMatches']>([]);
  const [isDonationMatchesDialogOpen, setIsDonationMatchesDialogOpen] = useState(false);

  const showEmptyState = !isLoading && (donations?.donations || []).length === 0;

  function handleViewingDonationMatchesClick(donationMatches: Donation['donationMatches']) {
    setViewingDonationMatches(donationMatches);
    setIsDonationMatchesDialogOpen(true);
  }

  function handleCloseDonationMatchesDialog() {
    setIsDonationMatchesDialogOpen(false);
  }

  useEffect(() => {
    if (isDonationMatchesDialogOpen) return;

    const timeout = setTimeout(() => {
      setViewingDonationMatches([]);
    }, 250);

    return () => clearTimeout(timeout);
  }, [isDonationMatchesDialogOpen]);

  return (
    <>
      <DonationFilters {...filters} />
      <Paper elevation={2} sx={{ mt: 5, position: 'relative' }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableColumns.map((tableColumn) => (
                  <DonationsTableHeaderCell
                    handleSortLabelClick={ordering.setOrderBy}
                    orderByAttribute={ordering.orderByAttribute}
                    orderDirection={ordering.orderDirection}
                    tableColumn={tableColumn}
                    key={tableColumn.id}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& tr:nth-of-type(2n)': { backgroundColor: '#f9f9f9' },
                '& tr:hover': { backgroundColor: '#f2f2f2' },
              }}
            >
              {(donations?.donations || []).map((donation) => (
                <TableRow key={`donation_table_${donation.id}`}>
                  {tableColumns.map((tableColumn) => (
                    <DonationTableCell
                      key={`donation_${donation.id}_${tableColumn.id}`}
                      tableColumn={tableColumn}
                      donation={donation}
                      onDonationMatchesClick={handleViewingDonationMatchesClick}
                    />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading ? (
          <Box sx={{ p: 3 }}>
            <Skeleton width={'100%'} height={220} sx={{ borderRadius: 4, transform: 'scale(1, 1)' }} />
          </Box>
        ) : showEmptyState ? (
          <DonationsTableEmptyState />
        ) : (
          <DonationsTableFooter total={donations?.total || 0} {...pagination} />
        )}
      </Paper>

      <DonationMatchesDialog
        isOpen={isDonationMatchesDialogOpen}
        onClose={handleCloseDonationMatchesDialog}
        donationMatches={viewingDonationMatches}
      />
    </>
  );
}
