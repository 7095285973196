import { ReactNode, useEffect } from 'react';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import useHedadoMutation from 'hooks/useHedadoMutation';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import { UpdateLastLoginMutation_Gql } from '../../gql/updateLastLogin';

const useStyles: any = makeStyles(() =>
  createStyles({
    accountButtons: {
      paddingRight: 10,
    },
  })
);

interface AuthenticateButtonProps extends ButtonProps {
  hideButton?: boolean;
  icon?: ReactNode;
  isSignUp?: boolean;
  returnAfterLogin?: boolean;
}

const AuthenticateButton = ({
  color = 'info',
  hideButton = false,
  icon = <LoginIcon />,
  isSignUp = false,
  returnAfterLogin = true,
  variant = 'outlined',
  ...other
}: AuthenticateButtonProps) => {
  const text = isSignUp ? 'Create an account' : 'Sign in';
  const classes = useStyles();
  const { isAuthenticatedAndReady } = useStoredAuth0Values();
  const [cookies] = useCookies(['campaignSession']);

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [updateLastLogin] = useHedadoMutation(UpdateLastLoginMutation_Gql);

  useEffect(() => {
    if (isAuthenticatedAndReady) {
      let campaignSessionId: string | undefined = undefined;

      if (cookies['campaignSession']) {
        const cookieContent = cookies.campaignSession;
        if (cookieContent.campaignSessionId && cookieContent.campaignSessionId.length > 0) {
          campaignSessionId = cookieContent.campaignSessionId;
        }
      }

      updateLastLogin({
        variables: {
          campaignSessionId,
        },
      });
    }
  }, [cookies, isAuthenticatedAndReady, updateLastLogin]);

  function handleLoginClick() {
    const args: RedirectLoginOptions = {};
    if (returnAfterLogin) args.appState = { returnTo: window.location.pathname };
    if (isSignUp) args.screen_hint = 'signup';

    loginWithRedirect(args);
  }

  return (
    <>
      {!isAuthenticated && !hideButton && (
        <Button
          className={classes.accountButtons}
          color={color}
          onClick={handleLoginClick}
          size="large"
          startIcon={icon}
          variant={variant}
          {...other}
        >
          {text}
        </Button>
      )}
    </>
  );
};

export default AuthenticateButton;
