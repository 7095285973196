import { EnvType, load } from 'ts-dotenv';

export type Env = EnvType<typeof schema>;

export const schema = {
  NODE_ENV: String,
  REACT_APP_APOLLO_DEV_TOOLS: Boolean,
  REACT_APP_AUTH0_AUDIENCE: String,
  REACT_APP_AUTH0_CLIENT_ID: String,
  REACT_APP_AUTH0_DOMAIN: String,
  REACT_APP_CHARITY_NAVIGATOR_URL: String,
  REACT_APP_COOKIE_ROOT: String,
  REACT_APP_DEFAULT_ORG_IMAGE_URL: String,
  REACT_APP_DEFAULT_TIP: Number,
  REACT_APP_EVERY_API_KEY: String,
  REACT_APP_EVERY_SEARCH_URL: String,
  REACT_APP_FEATURE_FLAG_CAMPAIGN: Boolean,
  REACT_APP_FEATURE_FLAG_ENABLE_MATCH: Boolean,
  REACT_APP_FEATURE_FLAG_PUBLIC_PROFILES: Boolean,
  REACT_APP_FEATURE_FLAG_WELCOME_MODAL: Boolean,
  REACT_APP_GRAPHQL_URL: String,
  REACT_APP_GUIDE_STAR_URL: String,
  REACT_APP_HOST: String,
  REACT_APP_MAX_TIP_PERCENT: Number,
  REACT_APP_NEXTJS_URL: String,
  REACT_APP_NODE_GRAPHQL_URL: String,
  REACT_APP_NODE_URL: String,
  REACT_APP_ORGANIZATION_DEFAULT_IMAGE_PATH: String,
  REACT_APP_ORGANIZATION_IMAGE_PATH: String,
  REACT_APP_S3_PATH: String,
  REACT_APP_STORAGE_KEY: String,
  REACT_APP_STRIPE_PUBLIC_KEY: String,
};

export let env: Env;

export function loadEnv(): void {
  env = load(schema);
}
