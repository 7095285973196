import type Campaign from 'model/Campaign.model';
import { gql } from '@apollo/client';
import { CampaignTier } from 'model/CampaignTier.model';

export interface UpdateCampaignMutationVariables {
  id: string;
  relationships: {
    adminEmailsToAdd: string[];
    adminEmailsToRemove: string[];
    domainsToAdd: string[];
    domainsToRemove: string[];
    donorEmailsToAdd: string[];
    donorEmailsToRemove: string[];
    organizationIdsToAdd: string[];
    organizationIdsToRemove: string[];
  };
  campaign: {
    description: string;
    donationGoalCents: number;
    nameOnReceipt: string;
    slug: string;
    tier: CampaignTier;
    title: string;
    displayData: {
      bannerImage: string;
      bannerImageMobile: string;
      logoImage: string;
      showCampaignOrganizations: boolean;
      showDonationGoal: boolean;
      showDonationTotal: boolean;
      showDonationTotalMatched: boolean;
      showDonorOrganizations: boolean;
    };
  };
  qualification: {
    endDate: string;
    hasMatching: boolean;
    includeDonationsExceedingMatchLimits: boolean;
    individualMatchLimitCents: number | null;
    limitOrganizations: boolean;
    limitToDomains: boolean;
    limitToPeople: boolean;
    matchRatio: number | null;
    openToAll: boolean;
    overallMatchLimitCents: number | null;
    startDate: string;
    universal: boolean;
  };
}

export interface UpdateCampaignMutationResponse {
  updateCampaign: Campaign & {
    __typename: 'Campaign';
    categoryOrganizations: {
      pageInfo: {
        hasNextPage: boolean;
        endCursor?: string;
      };
      edges: {
        cursor: string;
        node: {
          organization: {
            id: string;
            ein: string;
            name: string;
          };
        };
      }[];
    };
    domains: {
      id: string;
      domainName: string;
    }[];
    donors: {
      id: string;
      donor: {
        id: string;
        email: string;
      };
    }[];
    admin: {
      id: string;
      donor: {
        id: string;
        email: string;
      };
    }[];
    qualification: {
      campaignId: string;
      endDate: Date | null;
      hasMatching: boolean | null;
      id: string;
      includeDonationsExceedingMatchLimits: boolean | null;
      individualMatchLimitCents: number | null;
      limitOrganizations: boolean;
      limitToDomains: boolean;
      limitToPeople: boolean;
      matchRatio: number | null;
      openToAll: boolean;
      overallMatchLimitCents: number | null;
      startDate: Date | null;
      universal: boolean | null;
    };
  };
}

export const updateCampaignGql = gql`
  mutation UpdateCampaign(
    $id: ID!
    $campaign: CampaignInput!
    $qualification: CampaignQualificationInput!
    $relationships: RelationshipsInput!
  ) {
    updateCampaign(id: $id, campaign: $campaign, qualification: $qualification, relationships: $relationships) {
      id
      displayData {
        bannerImage
        bannerImageMobile
        logoImage
        showCampaignOrganizations
        showDonationGoal
        showDonationTotal
        showDonationTotalMatched
        showDonorOrganizations
      }
      qualification {
        campaignId
        endDate
        id
        includeDonationsExceedingMatchLimits
        individualMatchLimitCents
        limitOrganizations
        limitToDomains
        limitToPeople
        matchRatio
        openToAll
        overallMatchLimitCents
        startDate
        hasMatching
      }
      admin {
        id
        donor {
          id
          email
        }
      }
      donors {
        id
        donor {
          id
          email
        }
      }
      domains {
        id
        domainName
      }
      categoryOrganizations(first: 100) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            organization {
              ein
              id
              name
            }
          }
        }
      }
    }
  }
`;
