import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';
import { Alert, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function TipDisclaimer({ sx }: { sx: SxProps<Theme> | undefined }) {
  return (
    <Alert severity="success" icon={false} sx={sx}>
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        Add a tip to support Hedado
        <Tooltip
          enterTouchDelay={0}
          title={`Hedado believes in maximizing your donation to nonprofits, so we don't charge fees. As a Public Benefit Corporation, our purpose is to 
help nonprofits and charities through sustained, predictable, and increased financial giving by creating donor-focused products and 
innovations. We appreciate any support you're willing to give.`}
        >
          <InfoOutlinedIcon sx={{ marginLeft: 1, mb: '2px', fontSize: '16px', color: 'primary.main' }} />
        </Tooltip>
      </Typography>
      Hedado does not charge any platform fees, and relies on the generosity of donors like you to support our service.
      💚
    </Alert>
  );
}
