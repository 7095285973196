import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Card, CardContent, CardMedia, IconButton, useMediaQuery } from '@mui/material';
import { Typography, Divider, Grid } from '@mui/material';
import { env } from 'env';

import Allocation from 'model/Allocation';
import CauseAreaChip from 'components/organization/CauseAreaChip';
import LearnMoreButtonWithDrawer from 'components/organization/LearnMoreButtonWithDrawer';
import AllocationDesignation from '../allocation/AllocationDesignation';
import AnonymousAllocationToggle from '../allocation/AnonymousAllocationToggle';
import GiveNowDialog from 'components/GiveNowDialog/GiveNowDialog';

import DeleteIcon from '@mui/icons-material/DeleteOutline';

interface OrgListItemProps {
  allocation: Allocation;
  onDeleteClicked: () => void;
}

const OrgListItem = ({ allocation, onDeleteClicked }: OrgListItemProps) => {
  const [isGiveNowDialogOpen, setIsGiveNowDialogOpen] = useState(false);
  let image = allocation.organization?.imageName
    ? `${env.REACT_APP_ORGANIZATION_IMAGE_PATH}${allocation.organization.imageName}`
    : env.REACT_APP_DEFAULT_ORG_IMAGE_URL;

  const organization = allocation.organization;
  const theme = useTheme();

  const breakpointMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  function handleGiveNowButtonClick() {
    setIsGiveNowDialogOpen(true);
  }

  function closeGiveNowDialog() {
    setIsGiveNowDialogOpen(false);
  }

  return (
    <>
      <Card variant="outlined" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <CardMedia
          component="img"
          sx={{
            width: { xs: '100%', sm: 'max(80px, 30vw)', md: 'min-content' },
            height: { xs: 'max(160px, 30vw)', sm: 'auto' },
            maxWidth: { md: '300px' },
            padding: { xs: 1, md: 1 },
            borderRadius: { xs: 4, md: 4 },
            objectFit: 'cover',
            flexGrow: { xs: 1, sm: 0 },
          }}
          image={image}
          alt={organization.name}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingBottom: '16px !important' }}>
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={12} md={9} gap={1}>
              <Grid
                container
                gap={1}
                sx={{ flexWrap: 'nowrap', alignItems: 'flex-start', justifyContent: 'space-between' }}
              >
                <Typography component="div" variant="h5">
                  {organization.name}
                </Typography>
                {!breakpointMdUp && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <LearnMoreButtonWithDrawer
                      organization={organization}
                      currentlyInPortfolio={true}
                      iconOnly={true}
                    />
                    <IconButton
                      onClick={onDeleteClicked}
                      color="primary"
                      size="large"
                      sx={{ marginTop: -1, marginRight: -1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Grid>

              <Grid container gap={1}>
                {organization.causeArea && <CauseAreaChip causeArea={organization.causeArea} size="small" />}
                <Typography variant="subtitle1" color="text.secondary">
                  EIN: {organization.ein}
                </Typography>
              </Grid>
            </Grid>

            {!breakpointMdUp && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <AllocationDesignation
                  compact={true}
                  allocationId={allocation.id}
                  organizationName={organization.name}
                  designation={allocation.designation}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: breakpointMdUp ? 0 : 2,
              }}
            >
              {!breakpointMdUp && (
                <Grid
                  item
                  xs={9}
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <AnonymousAllocationToggle
                    compact={true}
                    allocationId={allocation.id}
                    anonymous={allocation.anonymous}
                  />
                </Grid>
              )}
              <Grid
                container
                xs="auto"
                md={12}
                sx={{ textAlign: 'right', alignItems: 'flex-end', flexDirection: 'column' }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: '0.9rem', md: 'inherit' } }}>
                  Allocation
                </Typography>
                <Typography variant={breakpointMdUp ? 'h5' : 'h6'}>{allocation.percentage} %</Typography>
              </Grid>
            </Grid>

            {breakpointMdUp && (
              <Grid item xs={12}>
                <Divider sx={{ marginY: 2 }} />
              </Grid>
            )}

            {breakpointMdUp && (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <AllocationDesignation
                  compact={true}
                  allocationId={allocation.id}
                  organizationName={organization.name}
                  designation={allocation.designation}
                />
              </Grid>
            )}

            {breakpointMdUp ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <AnonymousAllocationToggle
                  compact={true}
                  allocationId={allocation.id}
                  anonymous={allocation.anonymous}
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleGiveNowButtonClick}>
                    Give Once
                  </Button>
                  <LearnMoreButtonWithDrawer organization={organization} currentlyInPortfolio={true} />
                  <IconButton onClick={onDeleteClicked} color="primary">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth={breakpointSmDown}
                size={breakpointSmDown ? 'large' : 'medium'}
                onClick={handleGiveNowButtonClick}
                sx={{ mt: 2 }}
              >
                Give Now
              </Button>
            )}
          </Grid>
        </CardContent>
      </Card>
      <GiveNowDialog isOpen={isGiveNowDialogOpen} onClose={closeGiveNowDialog} organization={organization} />
    </>
  );
};

export default OrgListItem;
