import { useState, useEffect } from 'react';
import { getAccessTokenFromStorage } from 'utils/storage';
import { env } from 'env';

export function useSetupIntentSecret() {
  const [clientSecret, setClientSecret] = useState<string | null | undefined>('');

  useEffect(() => {
    async function initialize() {
      const { setupIntentSecret } = await fetchSetupIntentSecret();
      setClientSecret(setupIntentSecret);
    }

    initialize();
  }, []);

  return { clientSecret };
}

async function fetchSetupIntentSecret(): Promise<{ setupIntentSecret?: string | null }> {
  const token = getAccessTokenFromStorage();

  const response = await fetch(`${env.REACT_APP_NODE_URL}/stripeSetupIntent`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}
