import { gql } from '@apollo/client';

export const deactivatePaymentMethod_Name = 'DeactivatePaymentMethod';
export const deactivatePaymentMethod_Gql = gql`
  mutation DeactivatePaymentMethod($campaignId: String) {
    deactivatePaymentMethod(campaignId: $campaignId) {
      id
      donorId
      deactivatedAt
    }
  }
`;
