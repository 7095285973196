import { Box, Typography, Button } from '@mui/material';
import { DonationMatchStatus } from 'model/DonationMatchStatus.model';

interface DonationMatchActionsProps {
  totalSelected: number;
  status: DonationMatchStatus;
  onSelectedStatusChange: (status: DonationMatchStatus) => void;
}

export default function DonationMatchActions({
  onSelectedStatusChange,
  status,
  totalSelected,
}: DonationMatchActionsProps) {
  if (status === DonationMatchStatus.settled) return null;

  const disableSelectedActions = totalSelected === 0;

  function onMoveToExcludedClick() {
    onSelectedStatusChange(DonationMatchStatus.excluded);
  }

  function onMoveToDeferredClick() {
    onSelectedStatusChange(DonationMatchStatus.deferred);
  }

  function onMoveToPendingClick() {
    onSelectedStatusChange(DonationMatchStatus.pending);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <Typography component="span">
          {totalSelected} row{totalSelected > 1 ? 's' : ''} selected
        </Typography>
        {status !== DonationMatchStatus.pending && (
          <Button variant="outlined" onClick={onMoveToPendingClick} disabled={disableSelectedActions}>
            Move To Inbox
          </Button>
        )}
        {status !== DonationMatchStatus.deferred && (
          <Button variant="outlined" onClick={onMoveToDeferredClick} disabled={disableSelectedActions}>
            Move To Deferred
          </Button>
        )}
        {status !== DonationMatchStatus.excluded && (
          <Button variant="outlined" onClick={onMoveToExcludedClick} disabled={disableSelectedActions}>
            Move To Excluded
          </Button>
        )}
      </Box>
    </Box>
  );
}
