import { Link as RouterLink } from 'react-router-dom';
import { createStyles, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Grid, Box } from '@mui/material';
import { Card, CardContent, Alert } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Head from 'components/Head/Head';
import PageHeader from 'components/global/PageHeader';
import PageContainer from 'components/global/ColorPageContainer';
import PaymentMethod from 'components/PaymentMethod/PaymentMethod';
import DonationPlan from 'components/donationPlan/DonationPlan';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useDonorProfile from 'hooks/useDonorProfile';
import PaymentMethodType from 'model/PaymentMethod.model';
import CurrentSubscription from 'model/CurrentSubscription.model';
import { getPaymentMethodQuery_Gql } from 'gql/profileQueries';
import { getLoggedInDonorActiveSubscription_Gql } from 'gql/giving/getLoggedInDonorActiveSubscription';

import { PAGE_TITLE_MANAGE_GIVING, APP_TITLE } from 'utils/constants';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    portfolioLink: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  })
);

const ManageGiving = () => {
  const classes = useStyles();
  const [donorProfile] = useDonorProfile();

  const { data: paymentData } = useAuthenticatedHedadoQuery(getPaymentMethodQuery_Gql);

  const { data: subscriptionData } = useAuthenticatedHedadoQuery(getLoggedInDonorActiveSubscription_Gql);

  const paymentMethod: PaymentMethodType | null = paymentData?.getDonorPaymentMethod ?? null;
  const currentSubscription: CurrentSubscription | null = subscriptionData?.getLoggedInDonorActiveSubscription ?? null;

  const hasExistingDonation: boolean = currentSubscription !== null;
  const donorHasOrgInPortfolio: boolean = !!donorProfile?.allocations?.length;
  const displayPledgeAmount: boolean =
    !!donorProfile?.pledgeAmount &&
    donorProfile?.nextDonationDate !== undefined &&
    donorProfile.nextDonationDate.length === 0;

  return (
    <Grid container sx={{ display: 'flex' }}>
      <Head title={`${PAGE_TITLE_MANAGE_GIVING} | ${APP_TITLE}`} />
      <Grid item sx={{ display: 'flex', flexDirection: 'column', maxWidth: 540 }}>
        <PageHeader
          icon={<MonetizationOnIcon />}
          title="Manage Giving"
          subTitle="Add a credit card and setup a donation plan to give to your portfolio."
        />

        {/* {!hasExistingDonation && displayPledgeAmount && (
          <Alert severity="error" sx={{ marginBottom: 3 }} icon={false}>
            <strong>
              Thank you for your pledge related to this year’s Bain LA Holiday Auction! You pledged to give{' '}
              {`$${donorProfile?.pledgeAmount}`}. <br />
              <br />
              <RouterLink className={classes.portfolioLink} to="/BainLA">
                View the Bain LA highlighted charities
              </RouterLink>
              .
            </strong>
          </Alert>
        )} */}

        <Box sx={{ marginBottom: 2 }}>
          <PageContainer>
            <Grid container>
              <Grid item sx={{ verticalAlign: 'middle', marginLeft: 2 }}>
                <Typography variant="h6">Manage Payment Method</Typography>
              </Grid>
            </Grid>
          </PageContainer>
        </Box>

        <Card sx={{ maxWidth: 650, marginBottom: 3 }}>
          <CardContent>
            <PaymentMethod
              paymentMethod={paymentMethod}
              isRemovingCardBlockedByCurrentDonationPlan={currentSubscription !== null}
            />
          </CardContent>
        </Card>

        <Box sx={{ marginBottom: 2 }}>
          <PageContainer>
            <Grid container>
              <Grid item sx={{ verticalAlign: 'middle', marginLeft: 2 }}>
                <Typography variant="h6">Your Donation Plan</Typography>
              </Grid>
            </Grid>
          </PageContainer>
        </Box>

        {!paymentMethod && (
          <Alert severity="warning">
            <Typography variant="body1">
              To set up your donation amount and frequency, please add a payment method above.
            </Typography>
          </Alert>
        )}

        {paymentMethod && !donorHasOrgInPortfolio && (
          <Alert severity="warning">
            <Typography variant="body1">
              To set up your donation amount and frequency, please{' '}
              <RouterLink className={classes.portfolioLink} to="/browse-nonprofits">
                add an organization to your portfolio
              </RouterLink>
              .
            </Typography>
          </Alert>
        )}

        {paymentMethod && donorHasOrgInPortfolio && (
          <>
            <Card sx={{ maxWidth: 650, marginBottom: 3 }}>
              <CardContent>
                <DonationPlan
                  currentSubscription={currentSubscription}
                  hasDonationPlan={hasExistingDonation}
                  displayPledgeAmount={displayPledgeAmount}
                  defaultTip={paymentMethod.type === 'PaymentMethods::BankAccount' ? 0 : 15}
                  donor={donorProfile ?? undefined}
                />
              </CardContent>
            </Card>

            <Alert severity="info">
              <Typography variant="body1">
                After completing a transaction you will receive a email confirmation. On the day of your scheduled
                contribution, you will receive a tax-deductible receipt referencing Every.org. We partner with{' '}
                <a href="https://every.org" className={classes.portfolioLink} target="_blank" rel="noreferrer">
                  Every.org
                </a>
                , a nonprofit who disburses funds to the organizations in your portfolio.
              </Typography>
            </Alert>
          </>
        )}
      </Grid>
      <Grid item xs="auto"></Grid>
    </Grid>
  );
};

export default ManageGiving;
