import type { DonationMatchStatus } from 'model/DonationMatchStatus.model';
import { gql } from '@apollo/client';

export interface UpdateDonationMatchesMutationVariables {
  campaignId: string;
  donationMatchIds: string[];
  input: {
    status: DonationMatchStatus;
  };
}

export interface UpdateDonationMatchesMutationResponse {
  updateDonationMatches: {
    count: number;
  };
}

export const updateDonationMatchesGql = gql`
  mutation UpdateDonationMatches($campaignId: ID!, $donationMatchIds: [ID!]!, $input: DonationMatchInput!) {
    updateDonationMatches(campaignId: $campaignId, donationMatchIds: $donationMatchIds, input: $input) {
      count
    }
  }
`;
