import { env } from 'env';
import useStoredAuth0Values from './useStoredAuth0Values';
import useHedadoQuery from './useHedadoQuery';
import {
  getPublicProfileGql,
  GetPublicProfileQueryResponse,
  GetPublicProfileQueryVariables,
} from 'gql/donor/getPublicProfile';

export default function useDonorPublicProfile() {
  const { isAuthenticatedAndReady } = useStoredAuth0Values();
  const { data, loading, error } = useHedadoQuery<GetPublicProfileQueryResponse, GetPublicProfileQueryVariables>(
    getPublicProfileGql,
    {
      skip: !isAuthenticatedAndReady,
    }
  );

  const publicProfilesActive = env.REACT_APP_FEATURE_FLAG_PUBLIC_PROFILES;
  const publicProfile = data?.getDonor?.publicProfile;

  return {
    data: publicProfile || null,
    loading,
    error,
    isEnabled: !!(publicProfilesActive && publicProfile?.isEnabled && publicProfile?.slug),
  };
}
