import { useRef, useEffect } from 'react';

export default function useDebounce() {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    []
  );

  function debounce(callback: () => void, time: number) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(callback, time);
  }

  return debounce;
}
