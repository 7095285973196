import type { DonationMatchesTableState } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { toCurrencyString } from 'helpers/donationProcessHelper';
import { useCampaignPaymentMethod } from 'components/CampaignAdminPage/hooks/useCampaignPaymentMethod';
import { useCampaignStatistics } from 'components/CampaignAdminPage/hooks/useCampaignStatistics';
import { useProcessCampaignMatch } from 'components/CampaignAdminPage/hooks/useProcessCampaignMatch';
import PaymentMethod from 'components/PaymentMethod/PaymentMethod';
import LoadingButton from 'components/LoadingButton';

interface CampaignDonationMatchDialogProps {
  campaignId: string;
  onClose: () => void;
  isOpen: boolean;
  currentQueryVariables: DonationMatchesTableState['queryVariables'];
}

export default function CampaignDonationMatchDialog({
  campaignId,
  isOpen,
  onClose,
  currentQueryVariables,
}: CampaignDonationMatchDialogProps) {
  const { processCampaignMatch, hasMatchedSuccessfully, hasErrored, clearStatus } = useProcessCampaignMatch(campaignId);
  const [isLoading, setIsLoading] = useState(false);
  const { statistics } = useCampaignStatistics(campaignId);
  const { paymentMethod } = useCampaignPaymentMethod(campaignId);
  const pendingMatchesTotalDollars = statistics?.pendingMatchesTotalCents
    ? toCurrencyString(statistics.pendingMatchesTotalCents / 100, { hideCents: false })
    : '';

  async function handleProcessCampaignMatchClick() {
    clearStatus();
    setIsLoading(true);

    await processCampaignMatch(currentQueryVariables);

    setIsLoading(false);
  }

  useEffect(() => {
    if (isOpen) return;

    const timeout = setTimeout(() => {
      clearStatus();
    }, 250);

    return () => clearTimeout(timeout);
  }, [clearStatus, isOpen]);

  if (hasMatchedSuccessfully) {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogContent sx={{ width: '460px', maxWidth: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              p: 4,
            }}
          >
            <CheckCircleOutlineIcon
              fontSize="large"
              sx={{ width: '2em', height: '2em', fontSize: '72px', mb: 2 }}
              color="secondary"
            />
            <Typography variant="h4" sx={{ mb: 2 }}>
              Thank you!
            </Typography>
            <Typography variant="body1">
              Your campaign donation match is processing. Once complete, you will find your tax-deductible receipt in
              your inbox shortly.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">Match Donations</Typography>
      </DialogTitle>
      <DialogContent sx={{ width: '460px', maxWidth: '100%' }}>
        <PaymentMethod campaignId={campaignId} paymentMethod={paymentMethod} />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          size="large"
          disabled={!paymentMethod}
          loading={isLoading}
          onClick={handleProcessCampaignMatchClick}
        >
          Process {pendingMatchesTotalDollars} Match
        </LoadingButton>
        <Button variant="outlined" size="large" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>

      {hasErrored && (
        <Box sx={{ p: 2, pt: 1, textAlign: 'right' }}>
          <Typography variant="caption" sx={{ color: '#d32f2f' }}>
            Oops, it looks like an error occurred.
          </Typography>
        </Box>
      )}
    </Dialog>
  );
}
