import type { CampaignFormProps } from '../CampaignForm';
import { useState } from 'react';
import { getRequiredFieldError } from 'helpers/formHelpers';
import { CampaignTier } from 'model/CampaignTier.model';

export enum DetailsFormStateKey {
  description = 'description',
  nameOnReceipt = 'nameOnReceipt',
  slug = 'slug',
  title = 'title',
  tier = 'tier',
}

export function useDetailsFormState(campaign: CampaignFormProps['campaign']) {
  const [detailsFormState, setDetailsFormState] = useState(computeDetailsFormState(campaign));

  return { detailsFormState, setDetailsFormState };
}

function computeDetailsFormState(campaign: CampaignFormProps['campaign']) {
  return {
    description: {
      value: campaign?.description || '',
      hasBlurredAtLeastOnce: false,
    },
    nameOnReceipt: {
      value: campaign?.nameOnReceipt || '',
      hasBlurredAtLeastOnce: false,
    },
    slug: {
      value: campaign?.slug || '',
      hasBlurredAtLeastOnce: false,
    },
    title: {
      value: campaign?.title || '',
      hasBlurredAtLeastOnce: false,
    },
    tier: {
      value: campaign?.tier || CampaignTier.basic,
      hasBlurredAtLeastOnce: false,
    },
  };
}

export type DetailsFormState = ReturnType<typeof computeDetailsFormState>;

export function computeDetailsFormStateErrors(
  { description, nameOnReceipt, title, slug, tier }: DetailsFormState,
  isSlugTaken: boolean
): {
  [key in DetailsFormStateKey]?: string | null;
} {
  return {
    description: getRequiredFieldError(description.value),
    nameOnReceipt: getRequiredFieldError(nameOnReceipt.value),
    tier: getRequiredFieldError(tier.value),
    title: getRequiredFieldError(title.value),
    slug: slug.hasBlurredAtLeastOnce && !!slug && isSlugTaken ? 'This url is already taken' : null,
  };
}

export type DetailsFormStateErrors = ReturnType<typeof computeDetailsFormStateErrors>;
