import { getLoggedInDonorProfileQuery_Name } from 'gql/donorProfileQuery';
import { getMostRecentSubscriptionForDonor_Name } from 'gql/giving/getMostRecentSubscriptionForDonor';
import { ProcessOneTimeOrganizationContributionGql } from 'gql/contributions/processOneTimeOrganizationContribution';
import useHedadoMutation from 'hooks/useHedadoMutation';

interface UseProcessOneTimeOrganizationContributionArgs {
  onCompleted: () => void;
  onError: () => void;
}

export function useProcessOneTimeOrganizationContribution({
  onCompleted,
  onError,
}: UseProcessOneTimeOrganizationContributionArgs) {
  const [processOneTimeOrganizationContribution, { loading }] = useHedadoMutation(
    ProcessOneTimeOrganizationContributionGql,
    {
      refetchQueries: [
        'GetLoggedInDonorActiveSubscription',
        'GetDonorPortfolioSummary',
        getLoggedInDonorProfileQuery_Name,
        getMostRecentSubscriptionForDonor_Name,
      ],
      onError,
      onCompleted,
    }
  );

  return { processOneTimeOrganizationContribution, loading };
}
