import { gql } from '@apollo/client';

export interface GetAllPublicProfilesQueryResponse {
  getDonors: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: {
      cursor: string;
      node: {
        publicProfile: {
          slug: string;
          displayName: string;
          profileImage: string;
        };
      };
    }[];
  };
}

export interface GetAllPublicProfilesQueryArgs {
  first: number;
  after?: string;
  search?: string;
}

export const getAllPublicProfilesGql = gql`
  query GetAllPublicProfilesGql($first: Int!, $after: String, $search: String) {
    getDonors(first: $first, search: $search, after: $after, publicOnly: true) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          publicProfile {
            slug
            displayName
            profileImage
          }
        }
      }
    }
  }
`;
