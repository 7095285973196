import type { LinkProps } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
  },
  default: {
    whiteSpace: 'pre-wrap',
  },
  multiLineEllipsis: ({ maxLineCount }: { maxLineCount: number }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    '-webkit-line-clamp': maxLineCount,
    '-webkit-box-orient': 'vertical',
  }),
});

interface ClampedTextProps {
  text: string;
  maxLineCount: number;
  canExpand: boolean;
  linkProps?: LinkProps;
}

export default function ClampedText({ text, maxLineCount, canExpand, linkProps }: ClampedTextProps) {
  const [clamped, setClamped] = useState(true);
  const containerRef = useRef<HTMLSpanElement>(null);
  const classes = useStyles({ maxLineCount });

  useEffect(() => {
    if (!containerRef.current) return;

    const { clientHeight, scrollHeight } = containerRef.current;
    setClamped(clientHeight !== scrollHeight);
  }, [containerRef]);

  function handleClick() {
    setClamped(false);
  }

  return (
    <>
      <span ref={containerRef} className={clamped ? classes.multiLineEllipsis : classes.default}>
        {text}
      </span>
      {clamped && canExpand && (
        <Link className={classes.link} onClick={handleClick} {...linkProps}>
          read more
        </Link>
      )}
    </>
  );
}
