import { gql } from '@apollo/client';

export interface GetOrganizationsQueryResponse {
  getOrganizations: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: {
      cursor: string;
      node: {
        annualReportUrl?: string;
        assets?: number;
        causeArea?: string;
        city?: string;
        classification?: number;
        context?: string;
        deductibility?: number;
        ein: string;
        financialsUrl?: string;
        form990Url?: string;
        id: string;
        imageName?: string;
        impact?: string;
        mission?: string;
        name: string;
        orgSize?: string;
        revenue?: number;
        state?: string;
        street?: string;
        websiteUrl?: string;
        whyYouShouldCare?: string;
        zip?: string;
      };
    }[];
  };
}

export interface GetOrganizationsArgs {
  first: number;
  after?: string;
  search?: string;
  missingImagesOnly?: boolean;
  sortedByPopularity?: boolean;
}

export const getOrganizationsGql = gql`
  query GetOrganizations(
    $first: Int!
    $after: String
    $search: String
    $missingImagesOnly: Boolean
    $sortedByPopularity: Boolean
  ) {
    getOrganizations(
      first: $first
      after: $after
      search: $search
      missingImagesOnly: $missingImagesOnly
      sortedByPopularity: $sortedByPopularity
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          annualReportUrl
          assets
          causeArea
          city
          classification
          context
          deductibility
          id
          ein
          financialsUrl
          form990Url
          imageName
          impact
          mission
          name
          orgSize
          revenue
          state
          street
          websiteUrl
          whyYouShouldCare
          zip
        }
      }
    }
  }
`;

export interface GetOrganizationsWithCampaignDonationMatchesArgs {
  first: number;
  after?: string;
  search?: string;
  campaignId: string;
}

export type GetOrganizationsWithCampaignDonationMatchesResponse = {
  getOrganizationsWithCampaignDonationMatches: GetOrganizationsQueryResponse['getOrganizations'];
};

export const getOrganizationsWithCampaignDonationMatchesGql = gql`
  query GetOrganizationsWithCampaignDonationMatches($first: Int!, $after: String, $search: String, $campaignId: ID!) {
    getOrganizationsWithCampaignDonationMatches(
      first: $first
      after: $after
      search: $search
      campaignId: $campaignId
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          annualReportUrl
          assets
          causeArea
          city
          classification
          context
          deductibility
          id
          ein
          financialsUrl
          form990Url
          imageName
          impact
          mission
          name
          orgSize
          revenue
          state
          street
          websiteUrl
          whyYouShouldCare
          zip
        }
      }
    }
  }
`;

export interface GetOrganizationsWithDonationsByDonorArgs {
  first: number;
  after?: string;
  search?: string;
}

export type GetOrganizationsWithDonationsByDonorResponse = {
  getOrganizationsWithDonationsByDonor: GetOrganizationsQueryResponse['getOrganizations'];
};

export const getOrganizationsWithDonationsByDonorGql = gql`
  query GetOrganizationsWithDonationsByDonor($first: Int!, $after: String, $search: String) {
    getOrganizationsWithDonationsByDonor(first: $first, after: $after, search: $search) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          annualReportUrl
          assets
          causeArea
          city
          classification
          context
          deductibility
          id
          ein
          financialsUrl
          form990Url
          imageName
          impact
          mission
          name
          orgSize
          revenue
          state
          street
          websiteUrl
          whyYouShouldCare
          zip
        }
      }
    }
  }
`;
