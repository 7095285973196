import type Organization from 'model/Organization.model';
import type { DonorPublicProfile } from 'model/Donor.model';
import type { PublicProfileFormState } from '../hooks/usePublicProfileFormState';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import PublicProfilePage from 'components/donor/PublicProfilePage/PublicProfilePage';
import CloseIcon from '@mui/icons-material/Close';

interface PreviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  formState: PublicProfileFormState;
  organizations: Organization[];
  images: {
    profileSrc?: string;
    bannerSrc?: string;
    bannerMobileSrc?: string;
  };
}

export default function PreviewDialog({ isOpen, organizations, onClose, formState, images }: PreviewDialogProps) {
  const publicProfile: Omit<DonorPublicProfile, 'profileImage' | 'bannerImage' | 'bannerImageMobile'> = {
    isEnabled: true,
    city: formState.city.value,
    state: formState.state.value,
    slug: formState.slug.value,
    displayName: formState.displayName.value,
    description: formState.description.value,
    colorTheme: formState.colorTheme.value,
    organizations: formState.organizations.map((org) => ({ id: org.id, description: org.value })),
    linkedInHandle: formState.linkedInHandle.value,
    twitterHandle: formState.twitterHandle.value,
    tikTokHandle: formState.tikTokHandle.value,
    instagramHandle: formState.instagramHandle.value,
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <DialogTitle
        sx={{
          position: 'fixed',
          width: '100%',
          backgroundColor: 'rgba(255,255,255,0.95)',
          textAlign: 'center',
          zIndex: 5,
          boxShadow: '0px 1px 20px #686868',
        }}
      >
        Previewing Public Profile
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: '12px', top: '12px' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PublicProfilePage donorId="" publicProfile={publicProfile} organizations={organizations} images={images} />
      </DialogContent>
    </Dialog>
  );
}
