import type PaymentMethod from 'model/PaymentMethod.model';
import { gql } from '@apollo/client';

export interface GetPaymentMethodQueryResponse {
  getDonorPaymentMethod?: PaymentMethod;
}

export const getPaymentMethodQuery_Name = 'GetDonorPaymentMethod';
export const getPaymentMethodQuery_Gql = gql`
  query ${getPaymentMethodQuery_Name} {
    getDonorPaymentMethod {
      id
      donorId
      paymentProcessorCustomerId
      name
      last4
      deactivatedAt
      addressZipCode
      retryCount
      type
      institution
      paymentProcessorSourceId
    }
  }
`;
