import type { SelectChangeEvent } from '@mui/material';
import { memo, forwardRef } from 'react';
import {
  Alert,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from '@mui/material';
import { setStateFromSelectChange, setStateFromInputBlur } from 'helpers/formHelpers';
import useDonorProfile from 'hooks/useDonorProfile';
import { useCampaignPermissions } from '../../../CampaignAdminPage/hooks/useCampaignPermissions';
import {
  EligibilityType,
  MembersFormState,
  MemberFormStateKey,
  MembersFormStateErrors,
} from 'components/CampaignForm/hooks/useMembersFormState';
import FormGridItem from '../FormGridItem';
import FormSection from '../FormSection';
import StringsTable from './components/StringsTable';
import { CampaignTier } from 'model/CampaignTier.model';
import { env } from '../../../../env';

interface MembersSectionProps {
  tier: CampaignTier;
  formState: MembersFormState;
  errors: MembersFormStateErrors;
  selectedAdminEmails: string[];
  selectedDomains: string[];
  selectedDonorEmails: string[];
  setFormState: React.Dispatch<React.SetStateAction<MembersFormState>>;
  setSelectedAdminEmails: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedDomains: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedDonorEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

const MembersSection = forwardRef<HTMLDivElement, MembersSectionProps>(
  (
    {
      tier,
      selectedAdminEmails,
      selectedDonorEmails,
      selectedDomains,
      formState,
      setFormState,
      setSelectedDomains,
      setSelectedAdminEmails,
      setSelectedDonorEmails,
    },
    ref
  ) => {
    const [donorProfile] = useDonorProfile();
    const { getTrimmedMemberOptions, canAddAdminUsers } = useCampaignPermissions();
    const selfDonorEmail = donorProfile?.email;

    const memberOptionList = getTrimmedMemberOptions(tier);
    const limitEligibleDonorList = memberOptionList.length === 1;

    function handleInputChange(event: SelectChangeEvent) {
      setStateFromSelectChange(event, setFormState);
    }

    function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
      setStateFromInputBlur(event, setFormState);
    }

    function handleAddDonor(emailToAdd: string) {
      setSelectedDonorEmails((prev) => (prev.includes(emailToAdd) ? prev : prev.concat([emailToAdd])));
    }

    function handleRemoveDonor(emailToRemove: string) {
      setSelectedDonorEmails((prev) => prev.filter((email) => email !== emailToRemove));
    }

    function handleAddDomain(domainNameToAdd: string) {
      setSelectedDomains((prev) => (prev.includes(domainNameToAdd) ? prev : prev.concat([domainNameToAdd])));
    }

    function handleRemoveDomain(domainNameToRemove: string) {
      setSelectedDomains((prev) => prev.filter((domainName) => domainName !== domainNameToRemove));
    }

    function handleAddAdmin(emailToAdd: string) {
      setSelectedAdminEmails((prev) => (prev.includes(emailToAdd) ? prev : prev.concat([emailToAdd])));
    }

    function handleRemoveAdmin(emailToRemove: string) {
      if (emailToRemove === selfDonorEmail) return;

      setSelectedAdminEmails((prev) => prev.filter((email) => email !== emailToRemove));
    }

    const showDonorEmailsTable = [EligibilityType.limitToPeople, EligibilityType.limitToPeopleAndDomains].includes(
      formState.memberEligibility.value
    );

    const showDomainsTable = [EligibilityType.limitToDomains, EligibilityType.limitToPeopleAndDomains].includes(
      formState.memberEligibility.value
    );

    return (
      <>
        <FormSection heading="Donors" ref={ref}>
          <FormGridItem>
            <FormControl fullWidth>
              <InputLabel id="eligible-members-select-label">Eligible Donors</InputLabel>
              <Select
                labelId="eligible-members-select-label"
                id="eligible-members-select"
                value={formState.memberEligibility.value}
                label="Eligible Members"
                name={MemberFormStateKey.memberEligibility}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                disabled={limitEligibleDonorList}
              >
                {memberOptionList &&
                  memberOptionList.map((option) => (
                    <MenuItem key={option.text} sx={{ fontSize: { xs: '0.75em', sm: '1rem' } }} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {limitEligibleDonorList && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                To add more donor eligibility options, please{' '}
                <Link
                  href={`${env.REACT_APP_NEXTJS_URL}/pricing/match`}
                  sx={{ color: '#8784E6', fontWeight: 'bold' }}
                  target="_blank"
                >
                  upgrade your account
                </Link>
                .
              </Alert>
            )}
          </FormGridItem>
          {showDonorEmailsTable && (
            <StringsTable
              values={selectedDonorEmails}
              onAdd={handleAddDonor}
              onRemove={handleRemoveDonor}
              tableHeading="Email Addresses"
              tableCellLabel="Email Address"
              showAddRow={true}
              textFieldProps={{
                placeholder: 'Email',
                label: 'Add a new email address',
              }}
            />
          )}
          {showDonorEmailsTable && showDomainsTable && (
            <FormGridItem>
              <Divider variant="middle" sx={{ my: 1 }} />
            </FormGridItem>
          )}
          {showDomainsTable && (
            <FormGridItem>
              <StringsTable
                values={selectedDomains}
                onAdd={handleAddDomain}
                onRemove={handleRemoveDomain}
                tableHeading="Domain Names"
                tableCellLabel="Domain Name"
                showAddRow={true}
                textFieldProps={{
                  placeholder: 'Add a new domain name',
                  label: 'Add a new domain name',
                  InputProps: {
                    startAdornment: <InputAdornment position="start">@</InputAdornment>,
                  },
                }}
              />
            </FormGridItem>
          )}
        </FormSection>
        <FormSection heading="Admin">
          <StringsTable
            values={selectedAdminEmails}
            onAdd={handleAddAdmin}
            onRemove={handleRemoveAdmin}
            tableHeading="Email Addresses"
            tableCellLabel="Email Address"
            showAddRow={canAddAdminUsers(tier, selectedAdminEmails.length)}
            textFieldProps={{
              placeholder: 'Email',
              label: 'Add a new email address',
            }}
            Row={({ value, onRemove }) => (
              <TableRow key={`row_${value}`}>
                <TableCell>
                  {value}
                  {value === selfDonorEmail ? ' (you)' : null}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {value !== selfDonorEmail && (
                    <Button color="error" variant="outlined" onClick={() => onRemove(value)}>
                      Remove
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )}
          />

          {!canAddAdminUsers(tier, selectedAdminEmails.length) && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              To add more campaign administrators, please{' '}
              <Link
                href={`${env.REACT_APP_NEXTJS_URL}/pricing/match`}
                sx={{ color: '#8784E6', fontWeight: 'bold' }}
                target="_blank"
              >
                upgrade your account
              </Link>
              .
            </Alert>
          )}
        </FormSection>
      </>
    );
  }
);

export default memo(MembersSection);
