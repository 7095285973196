import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import {
  getCampaignPaymentMethod_Gql,
  GetCampaignPaymentMethodQueryVariables,
  GetCampaignPaymentMethodQueryResult,
} from 'gql/paymentMethod/getCampaignPaymentMethod';

export function useCampaignPaymentMethod(campaignId: string) {
  const { data, loading, error } = useAuthenticatedHedadoQuery<
    GetCampaignPaymentMethodQueryResult,
    GetCampaignPaymentMethodQueryVariables
  >(getCampaignPaymentMethod_Gql, { variables: { campaignId } });

  return { paymentMethod: data?.getCampaignPaymentMethod || null, loading, error };
}
