import { Grid, InputLabel, Typography, Link } from '@mui/material';
import { toDollars, toCurrencyString } from 'helpers/donationProcessHelper';

export default function AmountAndTipSummary({
  amountCents,
  totalDollars,
  tipsCents,
  onChangeAmountClick,
}: AmountAndTipSummaryProps) {
  const amountDollars = toCurrencyString(toDollars(amountCents || 0));
  const tipsDollars = toCurrencyString(toDollars(tipsCents || 0));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel shrink={true}>Donation Amount</InputLabel>
        <Typography variant="h4">{totalDollars}</Typography>
        <Typography>
          {amountDollars}
          {!!tipsCents && <> + {tipsDollars} tip</>}
        </Typography>
        <Link onClick={onChangeAmountClick} sx={{ cursor: 'pointer', fontSize: '0.8em' }}>
          <Typography variant="caption">change</Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

interface AmountAndTipSummaryProps {
  amountCents: number;
  tipsCents?: number;
  totalDollars: string;
  onChangeAmountClick: () => void;
}
