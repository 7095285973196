import { useHistory } from 'react-router-dom';
import { uploadPresignedImage } from 'helpers/s3';
import { getOrganizationsGql } from 'gql/organizations/getOrganizations';
import { addOrganizationGql } from 'gql/organizations/addOrganization';
import useHedadoMutation from 'hooks/useHedadoMutation';
import AdminPage from 'components/admin/AdminPage';
import OrganizationForm from 'components/admin/OrganizationForm/OrganizationForm';
import { OrganizationInput } from 'components/admin/OrganizationForm/OrganizationForm.interfaces';

export default function CreateOrganization() {
  const pageTitle = 'New Organization';
  const history = useHistory();

  const [addOrganization, { loading: isSaving }] = useHedadoMutation(addOrganizationGql, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getOrganizationsGql,
        variables: { first: 25, search: '', missingImagesOnly: false, sortedByPopularity: true },
      },
    ],
  });

  async function handleSubmit({ imagesToUpload, input }: OrganizationInput) {
    await Promise.all(
      imagesToUpload.map(async ({ key, file, crop, aspectRatio }) => {
        const uploadResult = await uploadPresignedImage({
          file,
          fileName: `${input.ein}_${key}`,
          folderName: 'organizations',
          crop,
          aspectRatio,
        });

        input[key] = uploadResult;
      })
    );

    await addOrganization({
      variables: { input: input },
    });
    history.push({
      pathname: '/admin/organizations',
      state: { toast: 'Successfully Created Organization' },
    });
  }

  return (
    <AdminPage title={pageTitle}>
      <OrganizationForm handleSubmit={handleSubmit} isSaving={isSaving} />
    </AdminPage>
  );
}
