import { gql } from '@apollo/client';

export const UpdateLastLoginMutation_Name = 'UpdateLastLogin';
export const UpdateLastLoginMutation_Gql = gql`
  mutation UpdateLastLogin($campaignSessionId: ID) {
    updateLastLogin(campaignSessionId: $campaignSessionId) {
      id
      firstName
      lastName
      email
    }
  }
`;
