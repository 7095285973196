import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
  input: {
    backgroundColor: theme.palette.primary.light,
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
  },
}));
