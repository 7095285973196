import type { CampaignFormProps } from '../CampaignForm';
import { useState } from 'react';

export enum NonProfitsFormStateKey {
  limitOrganizations = 'limitOrganizations',
  showCampaignOrganizations = 'showCampaignOrganizations',
  showDonorOrganizations = 'showDonorOrganizations',
}

export function useNonProfitsFormState(
  campaign: CampaignFormProps['campaign'],
  qualification: CampaignFormProps['qualification']
) {
  const [nonProfitsFormState, setNonProfitsFormState] = useState(getNonProfitsFormState(campaign, qualification));

  return {
    nonProfitsFormState,
    setNonProfitsFormState,
  };
}

function getNonProfitsFormState(
  campaign: CampaignFormProps['campaign'],
  qualification: CampaignFormProps['qualification']
) {
  return {
    showCampaignOrganizations: {
      value:
        typeof campaign?.displayData?.showCampaignOrganizations === 'boolean'
          ? !!campaign?.displayData?.showCampaignOrganizations
          : true,
      hasBlurredAtLeastOnce: false,
    },
    showDonorOrganizations: {
      value: !!campaign?.displayData?.showDonorOrganizations,
      hasBlurredAtLeastOnce: false,
    },
    limitOrganizations: {
      value: !!qualification?.limitOrganizations,
      hasBlurredAtLeastOnce: false,
    },
  };
}

export type NonProfitsFormState = ReturnType<typeof getNonProfitsFormState>;

export function computeNonProfitsFormStateErrors(_: NonProfitsFormState) {
  return {};
}

export type NonProfitsFormStateErrors = ReturnType<typeof computeNonProfitsFormStateErrors>;
