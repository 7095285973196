import type PaymentMethodType from 'model/PaymentMethod.model';
import { Box, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { sentenceCase } from 'helpers/formatHelpers';

interface CreditCardProps {
  paymentMethod: PaymentMethodType;
}

export default function CreditCard({ paymentMethod }: CreditCardProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CreditCardIcon sx={{ mr: 1 }} />
      <Typography>
        <b>{sentenceCase(paymentMethod.institution ?? '')}</b> ending in <b>{paymentMethod.last4}</b>
      </Typography>
    </Box>
  );
}
