import { Box, Paper, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { linearProgressClasses } from '@mui/material';

import { toDollars, toCurrencyString } from 'helpers/donationProcessHelper';

interface AmountRaisedProgressProps {
  total: number;
  goal?: number | null;
  matched?: number;
  showGoal: boolean;
  showMatched: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    height: 20,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.secondary.light,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export default function AmountRaisedProgress({
  total,
  goal,
  matched,
  showMatched,
  showGoal,
}: AmountRaisedProgressProps) {
  const classes = useStyles();
  const hasTotal = total > 0;
  const hasGoal = !!goal;
  const hasMatched = !!matched;

  const totalDollars = hasTotal ? toCurrencyString(toDollars(total), { hideCents: true }) : '$0';
  const goalDollars = hasGoal ? toCurrencyString(toDollars(goal), { hideCents: true }) : '$0';
  // NOTE: this can be MUCH better, and evaluate matched about to eg: $2.5M, $1.75K, etc.
  // For now, just trying to get it done.
  const matchedDollars = hasMatched
    ? matched === 1_000_000_00
      ? '$1M'
      : toCurrencyString(toDollars(matched), { hideCents: true })
    : '$0';

  const totalWithMatchDollars =
    hasTotal || hasMatched ? toCurrencyString(toDollars(total + (matched || 0)), { hideCents: true }) : '$0';

  // We want to allow percentages over 100, however the linear progress behaves funny if the
  // value it receives is greater than 100.  That's why we've split them into two variables here.
  const percentage = hasGoal && hasTotal ? Math.round((total / goal) * 100) : 0;
  const linearProgressValue = Math.min(percentage, 100);

  if (showGoal) {
    return (
      <Box sx={{ width: { md: '325px', xs: '100%' } }}>
        <Box
          component={Paper}
          elevation={4}
          sx={{
            border: '4px solid white',
            px: 3,
            py: 2,
            display: { sm: 'block' },
            borderRadius: 8,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          {showMatched ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: showMatched ? 'flex-start' : 'flex-end',
              }}
            >
              <Box>
                <Typography fontSize="0.875em">
                  <em>
                    {totalDollars} + {matchedDollars} matched
                  </em>
                </Typography>
                <Typography component="span" fontSize="1.25em">
                  <b>{totalWithMatchDollars} donated</b>
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography>{percentage}%</Typography>
                <Typography variant="subtitle2" color="InfoText">
                  <em>of Goal</em>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Box>
                <Typography component="span" sx={{ fontSize: { sm: '1.25em', xs: '1em' } }}>
                  {totalDollars}
                </Typography>
                <Typography variant="subtitle2">
                  <em>of {goalDollars} donated</em>
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography>{percentage}%</Typography>
                <Typography variant="subtitle2" color="InfoText">
                  <em>of Goal</em>
                </Typography>
              </Box>
            </Box>
          )}
          <LinearProgress
            variant="determinate"
            value={linearProgressValue}
            className={classes.progress}
            sx={{ height: 10, mt: 2 }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: { md: '300px', xs: '100%' } }}>
      <Box
        component={Paper}
        elevation={4}
        sx={{
          border: '4px solid white',
          p: 3,
          borderRadius: 8,
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        {showMatched ? (
          <>
            <Typography fontSize="1em" sx={{ mb: 0.5 }}>
              <em>
                {totalDollars} + {matchedDollars} matched
              </em>
            </Typography>
            <Typography variant="h5" component="span" fontWeight={700}>
              <b>{totalWithMatchDollars} donated</b>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" component="span">
              Total Donated:{' '}
            </Typography>
            <Typography variant="h5" component="span" fontWeight={700}>
              {totalDollars}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
}
