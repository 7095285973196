import { gql } from '@apollo/client';
import { DonorPublicProfile } from 'model/Donor.model';

export interface UpdatePublicProfileMutationVariables {
  donorId: string;
  input: DonorPublicProfile;
}

export interface UpdatePublicProfileMutationResponse {
  updateDonorPublicProfile: {
    id: string;
    __typename: 'Donor';
    email: string;
    firstName: string;
    lastName: string;
    publicProfile: DonorPublicProfile & {
      __typename: 'DonorPublicProfile';
    };
  };
}

export const updatePublicProfileGql = gql`
  mutation UpdatePublicProfile($donorId: ID!, $input: UpdateDonorPublicProfileInput!) {
    updateDonorPublicProfile(donorId: $donorId, publicProfile: $input) {
      id
      email
      firstName
      lastName
      publicProfile {
        bannerImage
        bannerImageMobile
        city
        colorTheme
        description
        displayName
        instagramHandle
        isEnabled
        linkedInHandle
        profileImage
        slug
        state
        tikTokHandle
        twitterHandle
        organizations {
          id
          description
        }
      }
    }
  }
`;
