import { Box, Typography } from '@mui/material';

export default function DonationsTableEmptyState() {
  return (
    <Box
      sx={{
        px: 5,
        py: 3,
      }}
    >
      <Box
        sx={{
          height: 220,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" component="p">
          No results found.
        </Typography>
        <Typography>Try broadening your search or use different filter criteria.</Typography>
      </Box>
    </Box>
  );
}
