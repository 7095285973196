import { useHistory } from 'react-router-dom';
import {
  getCampaignWithOrganizationsGql,
  GetCampaignWithOrganizationsQueryVariables,
  GetCampaignWithOrganizationQueryResponse,
} from 'gql/campaigns/getCampaign';
import useHedadoQuery from 'hooks/useHedadoQuery';
import CampaignLandingPage from 'components/campaign/CampaignLandingPage/CampaignLandingPage';

const CAMPAIGN_ID = 'ad62e923-5f7d-4c75-bbb5-8802adb22584';

export default function KraveCampaign() {
  const history = useHistory();
  const { loading, data } = useHedadoQuery<
    GetCampaignWithOrganizationQueryResponse,
    GetCampaignWithOrganizationsQueryVariables
  >(getCampaignWithOrganizationsGql, {
    variables: {
      id: CAMPAIGN_ID,
      firstOrganizations: 50,
    },
  });

  if (loading) return <></>;

  if (!data || !data.getCampaign) {
    history.push('/');
    return <></>;
  }

  const campaign = data.getCampaign;

  return <CampaignLandingPage campaign={campaign} />;
}
