import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, IconButton, Tooltip, Box, Icon } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useHedadoMutation from 'hooks/useHedadoMutation';

// icons
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

import Allocation from '../../model/Allocation';
import AllocationInput from '../../model/AllocationInput.model';
import {
  getActiveAllocationsForDonorQuery_Gql,
  getActiveAllocationsForDonorQuery_Name,
} from '../../gql/allocation/getActiveAllocationsForDonorQuery';
import useOnboarding from '../../hooks/useOnboarding';
import { getDistinctOrganizationsDonorSupported_Name } from '../../gql/getDistinctOrganizationsDonorSupported';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface ManageContributionsProps {
  openState: [boolean, (open: boolean) => void];
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const updateAllocationsMutation = gql`
  mutation UpdatellocationPercentages($allocations: [AllocationInput!]!) {
    updateAllocationPercentages(input: { allocations: $allocations }) {
      errors
      success
    }
  }
`;

const ManageContributions = (props: ManageContributionsProps) => {
  const theme = useTheme();
  const { isAuthenticatedAndReady } = useStoredAuth0Values();
  // const [open, setOpen] = useState(false);
  const [onboarding, setOnboarding] = useOnboarding();
  const [originalAllocations, setOriginalAllocations] = useState<Allocation[]>([]);
  const [updatedAllocations, setUpdatedAllocations] = useState<Allocation[]>([]);

  const { loading, data } = useAuthenticatedHedadoQuery(getActiveAllocationsForDonorQuery_Gql);

  const [open, setOpen] = props.openState; // FIXME: should be a hook not passed state props

  const areAllocationsExhaustive =
    updatedAllocations.length === 0 ||
    updatedAllocations.map((x) => x.percentage).reduce((prev, next) => prev + next) === 100;

  useEffect(() => {
    if (loading || !data || !isAuthenticatedAndReady) return;

    setOriginalAllocations(data.getActiveAllocationsForDonor);
    setUpdatedAllocations([...data.getActiveAllocationsForDonor].sort((a, b) => b.percentage - a.percentage));
  }, [data, loading, isAuthenticatedAndReady]);

  const [updateAllocations, { error }] = useHedadoMutation(updateAllocationsMutation, {
    refetchQueries: [getActiveAllocationsForDonorQuery_Name, getDistinctOrganizationsDonorSupported_Name],
  });

  // Dialog handlers
  const handleClickOpen = () => {
    setOpen(true);
    setOnboarding({ portfolioTodo: { didSetAllocations: true } });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Allocation helpers and event handler
  const allocatedPercentage = () => {
    if (updatedAllocations.length === 0) return 0;

    return updatedAllocations.map((x) => x.percentage).reduce((prev, next) => prev + next);
  };

  const handleRemoveAllocation = (allocation: Allocation) => {
    const newAllocations = updatedAllocations.filter((x) => x.organizationId !== allocation.organizationId);
    setUpdatedAllocations(newAllocations);
  };

  const isSaveEnabled = () => {
    return allocatedPercentage() === 100 || updatedAllocations.length === 0;
  };

  const resetToOriginalValues = () => {
    setUpdatedAllocations(originalAllocations);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);

    setUpdatedAllocations((allocations) =>
      allocations.map((allocation: Allocation) => {
        if (allocation.organizationId === e.target.name) {
          return {
            ...allocation,
            percentage: value,
          };
        }
        return allocation;
      })
    );
  };

  function onGrossUpAllocationsClick() {
    if (!updatedAllocations.length) return;

    const currentTotal = updatedAllocations.map((allocation) => allocation.percentage).reduce(reduceIntoSum, 0);
    const amountToAdjust = Math.round(100 - currentTotal);
    if (amountToAdjust === 0) return;

    const rawAdjustments: number[] = Array(Math.abs(amountToAdjust)).fill(amountToAdjust > 0 ? 1 : -1);
    const groupedAdjustments = rawAdjustments.reduce(reduceIntoGroups(updatedAllocations.length), []);
    const adjustments = transpose(groupedAdjustments).map((group) => group.reduce(reduceIntoSum, 0));

    setUpdatedAllocations((prevAllocations) =>
      prevAllocations.map((prevAllocation, i) => ({
        ...prevAllocation,
        percentage: prevAllocation.percentage + adjustments[i] ?? 0,
      }))
    );
  }

  const handleSaveClick = () => {
    try {
      const allocationInputs = updatedAllocations.map(
        (allocation: Allocation) => new AllocationInput(allocation.percentage, allocation.organizationId)
      );

      updateAllocations({
        variables: {
          allocations: allocationInputs,
        },
      });

      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const breakpointMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const DeleteButton = ({ allocation, length }: { allocation: Allocation; length: number }) => {
    return (
      <Tooltip
        enterTouchDelay={0}
        title={
          length <= 1
            ? 'Your portfolio requires at least one nonprofit. To remove this one, please first add another.'
            : `Remove your allocation for '${allocation.organization.name}'.`
        }
        placement="top"
      >
        <div>
          <IconButton
            aria-label="delete"
            onClick={() => handleRemoveAllocation(allocation)}
            disabled={length <= 1}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {length > 1 ? <DeleteIcon /> : <DeleteIcon sx={{ opacity: 0.5 }} />}
          </IconButton>
        </div>
      </Tooltip>
    );
  };

  const AllocatedPercentageTotal = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
        <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1.0rem' }, pr: 0.5 }}>
          Allocated:
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              xs: '0.9rem',
              sm: '1.0rem',
              color: allocatedPercentage() > 100 ? theme.palette.error.main : 'inherit',
            },
          }}
        >
          {<strong>{allocatedPercentage()}%</strong>}
        </Typography>
        {allocatedPercentage() === 100 ? (
          <Icon color="secondary" sx={{ px: 0.25 }}>
            <CheckCircleIcon />
          </Icon>
        ) : null}
        {allocatedPercentage() > 100 ? (
          <Icon color="error" sx={{ px: 0.25 }}>
            <WarningOutlinedIcon />
          </Icon>
        ) : null}
        {allocatedPercentage() !== 100 ? (
          <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1.0rem' }, pl: 0.5, opacity: 0.6 }}>
            ({Math.abs(100 - allocatedPercentage())}% {allocatedPercentage() <= 100 ? 'remaining' : 'over'})
          </Typography>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      <Grid>
        <Button
          size="large"
          onClick={handleClickOpen}
          startIcon={<EditOutlinedIcon color="secondary" sx={{ whiteSpace: 'nowrap' }} />}
        >
          {(() => {
            const verb = onboarding.portfolioTodo?.didSetAllocations ?? false ? 'Edit' : 'Review';
            return breakpointMdUp ? `${verb} Allocation %` : `${verb} Allocation %`;
          })()}
        </Button>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Manage Allocations
          </BootstrapDialogTitle>
          <DialogContent dividers sx={{ width: '600px', maxWidth: '100%', px: { sm: 1, xs: 0 } }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #e0e0e0',
                mb: 2,
                pb: 2,
                px: { sm: 2, xs: 1 },
              }}
            >
              <Typography sx={{ fontWeight: 'bold', border: 0 }}>Organization</Typography>
              <Typography sx={{ fontWeight: 'bold', border: 0 }} align="center">
                Allocation
              </Typography>
            </Box>
            {updatedAllocations.map((allocation: Allocation) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #e0e0e0',
                  mb: 2,
                  pb: 2,
                  px: { sm: 2, xs: 1 },
                }}
                gap={2}
              >
                <Typography sx={{ textAlign: 'left' }}>{allocation.organization.name}</Typography>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      variant="outlined"
                      sx={{ width: '56px', borderRadius: '4px' }}
                      value={allocation.percentage}
                      name={allocation.organizationId}
                      onChange={handleTextChange}
                    />
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: 500,
                        mx: 1,
                      }}
                    >
                      %
                    </Typography>
                    <DeleteButton allocation={allocation} length={updatedAllocations.length} />
                  </Box>
                </Box>
              </Box>
            ))}
            {error && <div>{error}</div>}
          </DialogContent>
          <DialogActions>
            <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
              <Grid item>
                <AllocatedPercentageTotal />
              </Grid>
              <Grid item sx={{ gap: 1, display: 'flex' }}>
                <Button variant="outlined" color="primary" onClick={resetToOriginalValues}>
                  Reset
                </Button>
                {!areAllocationsExhaustive && (
                  <Tooltip title="Redistribute any balance evenly across your allocations">
                    <Button variant="outlined" color="primary" onClick={onGrossUpAllocationsClick}>
                      Auto
                    </Button>
                  </Tooltip>
                )}
                <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={!isSaveEnabled()}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default ManageContributions;

function reduceIntoGroups(allocationsLength: number) {
  return function (acc: number[][], curr: number, i: number) {
    const chunkIndex = Math.floor(i / allocationsLength);
    if (!acc[chunkIndex]) acc[chunkIndex] = [];
    acc[chunkIndex].push(curr);

    return acc;
  };
}

function reduceIntoSum(acc: number, curr: number) {
  return acc + curr;
}

function transpose(input: number[][]): number[][] {
  return input[0].map((x, i) => input.map((x) => x[i] || 0));
}
