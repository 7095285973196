import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticateButton from './AuthenticateButton';

// Material Imports
import { styled, useTheme, Theme, CSSObject, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import {
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItemText,
  ListItemButton,
  Box,
  Divider,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';

// Material Icons
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import useStoredAuth0Values from 'hooks/useStoredAuth0Values';
import AccountProfileButton from './AccountProfileButton';

const drawerWidth = 325;

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: '50px',
      width: '241px',
      [theme.breakpoints.only('xs')]: {
        zoom: '70%',
      },
    },
    drawer: {
      backgroundColor: 'red',
    },
    icon: {
      color: '#FFFFFF',
      [theme.breakpoints.only('xs')]: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
      },
    },
    logoutIcon: {
      color: '#FFFFFF',
      float: 'right',
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    iconText: {
      color: '#FFFFFF',
    },
    login: {
      marginLeft: 'auto',
    },
    accountButtons: {
      paddingRight: 10,
    },
  })
);

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: theme.palette.primary.main,
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  [theme.breakpoints.only('xs')]: {
    width: 0,
  },
  backgroundColor: theme.palette.primary.main,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.primary.main,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function NavigationDrawer({ forceOpen }: { forceOpen: boolean }) {
  const classes = useStyles();
  const theme = useTheme();
  const { isAuthenticatedAndReady } = useStoredAuth0Values();

  const [open, setOpen] = React.useState(false);
  const { isAuthenticated } = useAuth0();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [
    {
      name: 'My Overview',
      icon: <DashboardOutlinedIcon className={classes.icon} />,
      route: '/summary',
    },
    {
      name: 'Browse Nonprofits',
      icon: <SearchOutlinedIcon className={classes.icon} />,
      route: '/browse-nonprofits',
    },
    {
      name: 'My Portfolio',
      icon: <BusinessCenterIcon className={classes.icon} />,
      route: '/portfolio',
    },
    {
      name: 'Manage Giving',
      icon: <MonetizationOnOutlinedIcon className={classes.icon} />,
      route: '/manage-giving',
    },
    {
      name: 'My History',
      icon: <FactCheckOutlinedIcon className={classes.icon} />,
      route: '/history',
    },
    {
      name: 'Share your Giving Publicly',
      icon: <EmojiPeopleIcon className={classes.icon} />,
      route: `/my-account/public-profile`,
    },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ zIndex: 1300 }}>
        <Toolbar>
          {(isAuthenticated || forceOpen) && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ml: -1,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div">
            <RouterLink to={'/'} style={{ display: 'flex' }}>
              <img className={classes.logo} src="/full-header-dark.png" alt="Hedado logo" />
            </RouterLink>
          </Typography>
          {isAuthenticatedAndReady && (
            <div className={classes.login}>
              {/* TODO: this is a hack because without the login button loading at this point, logging in doesn't work; suppressed until it can be fixed */}
              <AuthenticateButton hideButton={true} />
              <AccountProfileButton />
            </div>
          )}
        </Toolbar>
      </AppBar>
      {(isAuthenticated || forceOpen) && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader onClick={handleDrawerClose}>
            <IconButton>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon className={classes.icon} />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <Tooltip key={index} title={item.name} placement="right" enterTouchDelay={0}>
                {/* This color: inherit is vital here to prevent the default link colors to overlay on the elements */}
                <Link to={item.route} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItem disableGutters>
                    <ListItemButton disableGutters style={{ color: 'none' }}>
                      <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        onClick={handleDrawerClose}
                        className={classes.iconText}
                        sx={{ opacity: open ? 1 : 0, transition: 'opacity 0.3s ease-in-out' }}
                      >
                        {item.name}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
              </Tooltip>
            ))}
          </List>
        </Drawer>
      )}
    </Box>
  );
}
