import { gql } from '@apollo/client';
import { ApolloCache, DefaultContext, OperationVariables, MutationFunctionOptions } from '@apollo/client';
import { getActiveAllocationsForDonorQuery_Gql } from 'gql/allocation/getActiveAllocationsForDonorQuery';
import { getLoggedInDonorProfileQuery_Gql } from 'gql/donorProfileQuery';
import useHedadoMutation from 'hooks/useHedadoMutation';
import useAnalytics, { events } from './useAnalytics';

const addToPortfolioMutation = gql`
  mutation AddOrgToPortfolio($organizationId: ID!) {
    addOrgToPortfolio(input: { organizationId: $organizationId }) {
      success
      errors
    }
  }
`;

export default function useAddOrganizationToPortfolio() {
  const { trackEvent } = useAnalytics();
  const [mutateFunction, { loading, error }] = useHedadoMutation(addToPortfolioMutation, {
    refetchQueries: [getActiveAllocationsForDonorQuery_Gql, getLoggedInDonorProfileQuery_Gql],
  });

  const addOrganizationToPortfolio = async (
    options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined
  ) => {
    trackEvent(events.addOrganizationToPortfolio, {
      organizationId: options?.variables?.organizationId ?? '',
    });
    return mutateFunction(options);
  };

  return {
    addOrganizationToPortfolio,
    loading,
    error,
  };
}
