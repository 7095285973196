import React from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import PageTitle from '../components/global/PageTitle';

import { HEDADO_EMAIL } from '../utils/constants';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    itemSpacing: {
      marginTop: 20,
    },
  })
);

interface FaqItemProps {
  title: string;
  children?: React.ReactNode;
}

const FaqItem = ({ title, children }: FaqItemProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.itemSpacing}>
        <Grid item xs={3}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">{children}</Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

const Faq = () => {
  return (
    <>
      <PageTitle title="Frequently Asked Questions" />

      <FaqItem title="What is Hedado?">
        Hedado is the future of personal philanthropy. We make it easy for individuals to plan, make, and manage their
        charitable giving.
      </FaqItem>

      <FaqItem title="Who is Hedado? ">
        Hedado is run by experienced leaders who have a passion for eliminating inequality in the world. To learn more
        about us, please visit our LinkedIn page:
        <a href="https://www.linkedin.com/company/hedado" target="_blank" rel="noreferrer">
          https://www.linkedin.com/company/hedado
        </a>
      </FaqItem>

      <FaqItem title="How does Hedado work?">
        <ol>
          <li>
            Find nonprofits to support or bring a list of ones you already support If you need help, you can browse the
            our recommendations by type of cause. If you're not finding what you like, let us know and we'll help you
          </li>
          <li>
            Add nonprofits to a portfolio and decide how much each should receive on a percentage basis We'll help you
            weight-allocate the portfolio to 100%
          </li>
          <li>
            See your giving in one place; and prepare for tax time. If you've made donations outside of Hedado, don't
            worry, you can forward receipts to {HEDADO_EMAIL} and we'll include those.
          </li>
        </ol>
      </FaqItem>

      <FaqItem title="How much does each charity in my portfolio get? ">
        You can split your overall donation across your selected charities as you see fit. If you have a $100 monthly
        donation and 4 charities in your portfolio, the allocation can be any combination of percentages that add to
        100% (e.g. 20%, 50%, 15%, 15%)
      </FaqItem>

      <FaqItem title="Can I add or remove charities to my portfolio? ">
        Yes! You can add (or remove) any 501(c)3 organization to your portfolio.
      </FaqItem>

      <FaqItem title="Can I edit the allocations in my portfolio?">
        Yes! You can change the donation splits across your portfolio at any time.
      </FaqItem>

      <FaqItem title="Is my portfolio private or public?">
        Your portfolio is private by default. You can share your portfolio page with others if you’d like.
      </FaqItem>

      <FaqItem title="What costs are associated with using Hedado?">
        You incur no costs to use Hedado and 100% of your donation is tax deductible. Hedado partners with stripe as a
        credit card processor and Every.org, a nonprofit, to get funds to the nonprofits you choose. To make that
        possible, Stripe charges processing fees and Every.org withholds 2.25% of funds. These partnerships make
        donating through Hedado more efficient than most platforms as the nonprofit does not have to incur any costs for
        technology.
      </FaqItem>

      <FaqItem title="How does Hedado make money?">
        We only make money if you choose to pay us - in the form of tips. This allows us to continue to help you — and
        others — make a greater impact.
      </FaqItem>

      <FaqItem title="Can I automate when and how much I give? ">
        Yes! We offer monthly, quarterly, and annual recurring giving.
      </FaqItem>

      <FaqItem title="Can I make a one-off donation too?">Yes!</FaqItem>

      <FaqItem title="Are my donations tax-deductible?">
        Yes! Your donation is made to Every.org, a US 501(c)(3) tax-exempt charity with EIN 61-1913297. Every.org then
        grants funds on your behalf to nonprofits you've chosen in Hedado. No goods or services are provided to you in
        exchange for your gift. Therefore, the full amount you paid qualifies as a charitable contribution for US tax
        purposes.
      </FaqItem>

      <FaqItem title="How do the charities receive my donation? ">
        Donations are directly distributed to charities and make use of smart features like donation pooling to ensure
        that charities spend more on making an impact, and less on processing donations. Standard credit card payment
        processing fees apply.
      </FaqItem>

      <FaqItem title="What receipts can I forward?">
        We'll accept all donations you've made, whether to nonprofits or otherwise. We'll do the work of identifying
        which are tax deductible and not.
      </FaqItem>

      <FaqItem title="Is my information private? ">
        Your name, personal information, and individual donation history will never be shared without your permission.
        Hedado does aggregate donation history to provide giving benchmarks, which are shared with users on this
        platform.
      </FaqItem>

      <FaqItem title="What if I don't have a receipt? ">
        That's OK. You can send an email with the amount, recipient, and date. While we may not be able to validate tax
        deductibility, we'll still add it to your profile.
      </FaqItem>

      <FaqItem title="Do you take year-end giving reports as a receipt? ">
        We'll take those too. If you rounded up retail purchases or made a donation through a donor advised fund - it's
        all trackable.
      </FaqItem>
    </>
  );
};

export default Faq;
