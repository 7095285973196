export const NODE_CLIENT_NAME = 'node';

// APP TEXT
export const APP_TITLE = 'Hedado';
export const FOOTER_TEXT = `${new Date().getFullYear()} Hedado`;

// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_BROWSE = 'Browse Nonprofits';
export const PAGE_TITLE_CAMPAIGNS = 'Browse Campaign';
export const PAGE_TITLE_DASHBOARD = 'Dashboard';
export const PAGE_TITLE_MY_SUMMARY = 'My Summary';
export const PAGE_TITLE_MANAGE_PAYMENTS = 'Manage Payments';
export const PAGE_TITLE_MANAGE_GIVING = 'Manage Giving';
export const PAGE_TITLE_HISTORY = 'My History';
export const PAGE_TITLE_PORTFOLIO = 'My Portfolio';

// PAGE ACTION BLOCKS
// TODO: These should be stored as a dictionary or JSON so we can use a key?
export const PAGE_ACTION_BLOCKS_CTA = 'Hedado empowers anyone to be a philanthropist.';
export const PAGE_ACTION_BLOCKS_BUTTONTEXT = 'Tell us what you think';
export const PAGE_ACTION_BLOCKS_ROUTE = 'https://airtable.com/shrbwSQ28V9HmHCuB';

// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 240;

// Hedado email
export const HEDADO_EMAIL = 'donations@hedado.com';

// Onboarding
export const WELCOME_MODAL_DELAY = 100; // Default should be ~3000

// Default images, 0 - 9
export const DEFAULT_ORG_IMAGES = [
  'photo-1608555855762-2b657eb1c348.jpg',
  'photo-1587571065775-0d2ae0c863e0.jpg',
  'photo-1578357078586-491adf1aa5ba.jpg',
  'photo-1579208570378-8c970854bc23.jpg',
  'photo-1494386346843-e12284507169.jpg',
  'photo-1599659593072-10de2e109486.jpg',
  'photo-1564045288780-5c11658fefa3.jpg',
  'photo-1523810192022-5a0fb9aa7ff8.jpg',
  'photo-1567098260588-16d4feba18b1.jpg',
  'photo-1474631245212-32dc3c8310c6.jpg',
];
