import { env } from 'env';
import { Redirect } from 'react-router-dom';
import { Alert, Box, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import useDonorProfile from 'hooks/useDonorProfile';
import {
  getPublicProfileGql,
  GetPublicProfileQueryResponse,
  GetPublicProfileQueryVariables,
} from 'gql/donor/getPublicProfile';
import PageHeader from 'components/global/PageHeader';
import PublicProfileForm from 'components/PublicProfileForm/PublicProfileForm';

export default function MyPublicProfile() {
  const [donor] = useDonorProfile();
  const arePublicProfilesEnabled = env.REACT_APP_FEATURE_FLAG_PUBLIC_PROFILES === true;
  const { waiting, loading, data } = useAuthenticatedHedadoQuery<
    GetPublicProfileQueryResponse,
    GetPublicProfileQueryVariables
  >(getPublicProfileGql);

  if (!arePublicProfilesEnabled) return <Redirect to="/my-account" />;
  if (!donor || loading || waiting) return <></>;
  if (!data || !data.getDonor) throw new Error('error fetching profile');
  const publicProfile = data.getDonor.publicProfile;
  const organizations = (donor?.allocations || []).map((allocation) => allocation.organization);

  return (
    <>
      <PageHeader icon={<AccountCircleIcon />} title="Show Your Support" subTitle="Settings > Public Profile" />
      {publicProfile.isEnabled && (
        <Button
          component={'a'}
          href={`${env.REACT_APP_NEXTJS_URL}/d/${publicProfile.slug}`}
          target="_blank"
          rel="noreferrer"
          variant="contained"
          color="secondary"
          endIcon={<OpenInNewIcon />}
          sx={{ mb: 3, mt: 1 }}
          size="large"
        >
          View your profile
        </Button>
      )}
      <Alert severity="warning" icon={false} sx={{ maxWidth: { sm: 540, xs: '100%' }, mb: 3 }}>
        Sharing more about the nonprofits you give to and why signals to others that philanthropy is important. It spurs
        donations to your favorite organizations, and also giving generally.
      </Alert>
      <Box sx={{ maxWidth: { sm: 540, xs: '100%' } }}>
        <PublicProfileForm donor={donor} publicProfile={publicProfile} organizations={organizations} />
      </Box>
    </>
  );
}
