import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import PageTitle from '../components/global/PageTitle';

interface PrivacyPolicyProps {
  text: string;
}

const PrivacyLink = ({ text }: PrivacyPolicyProps) => {
  return <Link to="/privacy-policy">{text}</Link>;
};

const TermsOfUse = () => {
  return (
    <>
      <PageTitle title="Terms of Use" />

      <p>
        <Typography variant="h6">Effective date: 09/01/2021</Typography>
      </p>

      <p>
        <Typography variant="body1">
          Welcome to Hedado. Please read on to learn the rules and restrictions that govern your use of our website(s),
          products, services and applications (the “Services”). If you have any questions, comments, or concerns
          regarding these terms or the Services, please contact us at: support@hedado.com.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          These Terms of Use (the “Terms”) are a binding contract between you and HEDADO, INC. (“Hedado,” “we” and
          “us”). Your use of the Services in any way means that you agree to all of these Terms, and these Terms will
          remain in effect while you use the Services. These Terms include the provisions in this document as well as
          those in the <PrivacyLink text="Privacy Policy" />. Your use of or participation in certain Services may also
          be subject to additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein
          by reference, and you understand and agree that by using or participating in any such Services, you agree to
          also comply with these Additional Terms.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Please read these Terms carefully. They cover important information about Services provided to you. These
          Terms include information about future changes to these Terms, limitations of liability, a class action waiver
          and resolution of disputes by arbitration instead of in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR
          SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR
          ACCESS THE SERVICES IN ANY MANNER.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION
          AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
          ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Will these Terms ever change?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We are constantly trying to improve our Services, so these Terms may need to change along with our Services.
          We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located
          at https://hedado.com/, send you an email, and/or notify you by some other means.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no
          longer be able to use the Services. If you use the Services in any way after a change to the Terms is
          effective, that means you agree to all of the changes.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Except for changes by us as described here, no other amendment or modification of these Terms will be
          effective unless in writing and signed by both you and us.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">What about my privacy?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          Hedado takes the privacy of its users very seriously. For the current Hedado Privacy Policy, please{' '}
          <PrivacyLink text="click here" />.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Children’s Online Privacy Protection Act</Typography>
      </p>

      <p>
        <Typography variant="body1">
          The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental
          consent before they knowingly collect personally identifiable information online from children who are under
          13 years of age. We do not knowingly collect or solicit personally identifiable information from children
          under 13 years of age; if you are a child under 13 years of age, please do not attempt to register for or
          otherwise use the Services or send us any personal information. If we learn we have collected personal
          information from a child under 13 years of age, we will delete that information as quickly as possible. If you
          believe that a child under 13 years of age may have provided us personal information, please contact us at
          support@hedado.com.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">What are the basics of using Hedado?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          You may be required to sign up for an account, select a password and user name (“Hedado User ID”), and provide
          us with certain information or data, such as your contact information. You promise to provide us with
          accurate, complete, and updated registration information about yourself. You may not select as your Hedado
          User ID a name that you do not have the right to use, or another person’s name with the intent to impersonate
          that person. You may not transfer your account to anyone else without our prior written permission.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Additionally, you may be able to access certain parts or features of the Services by using your account
          credentials from other services (each, a “Third Party Account”), such as those offered by Google. By using the
          Services through a Third Party Account, you permit us to access certain information from such account for use
          by the Services. You are ultimately in control of how much information is accessible to us and may exercise
          such control by adjusting your privacy settings on your Third Party Account.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          You represent and warrant that you are an individual of legal age to form a binding contract (or if not,
          you’ve received your parent’s or guardian’s permission to use the Services and have gotten your parent or
          guardian to agree to these Terms on your behalf).
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of or
          for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If
          your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the Services. We
          can’t and won’t be responsible for your using the Services in a way that breaks the law.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          You will not share your Hedado User ID, account or password with anyone, and you must protect the security of
          your Hedado User ID, account, password and any other access tools or credentials. You’re responsible for any
          activity associated with your Hedado User ID and account.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">What about messaging?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          As part of the Services, you may receive communications through the Services, including messages that Hedado
          sends you (for example, via email). When signing up for the Services, you will receive a welcome message and
          instructions on how to stop receiving messages.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Are there restrictions in how I can use the Services?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          You represent, warrant, and agree that you will not provide or contribute anything, including any Content (as
          that term is defined below), to the Services, or otherwise use or interact with the Services, in a manner
          that:
          <ul>
            <li>
              infringes or violates the intellectual property rights or any other rights of anyone else (including
              Hedado);
            </li>
            <li>
              violates any law or regulation, including, without limitation, any applicable export control laws, privacy
              laws or any other purpose not reasonably intended by Hedado;
            </li>
            <li>
              is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise
              objectionable;
            </li>
            <li>
              jeopardizes the security of your Hedado User ID, account or anyone else’s (such as allowing someone else
              to log in to the Services as you);
            </li>
            <li>
              attempts, in any manner, to obtain the password, account, or other security information from any other
              user;
            </li>
            <li>
              violates the security of any computer network, or cracks any passwords or security encryption codes;
            </li>
            <li>
              runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run
              or are activated while you are not logged into the Services, or that otherwise interfere with the proper
              working of the Services (including by placing an unreasonable load on the Services’ infrastructure);
            </li>
            <li>
              “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content
              (through use of manual or automated means);
            </li>
            <li>copies or stores any significant portion of the Content; or</li>
            <li>
              decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or
              information of or relating to the Services.
            </li>
          </ul>
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          A violation of any of the foregoing is grounds for termination of your right to use or access the Services.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">What are my rights in the Services?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          The materials displayed or performed or available on or through the Services, including, but not limited to,
          text, graphics, data, articles, photos, images, illustrations and so forth (all of the foregoing, the
          “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all
          copyright notices, trademark rules, information, and restrictions contained in any Content you access through
          the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute,
          perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not
          owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates
          someone else’s (including Hedado's) rights.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and
          non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using
          the Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other
          than using the Services is expressly prohibited without prior written permission from us. You understand that
          Hedado owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of,
          reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise
          exploit any of the Services. The Services may allow you to copy or download certain Content, but please
          remember that even where these functionalities exist, all the restrictions in this section still apply.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Who is responsible for what I see and do on the Services?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          Any information or Content publicly posted or privately transmitted through the Services is the sole
          responsibility of the person from whom such Content originated, and you access all such information and
          Content at your own risk, and we aren’t liable for any errors or omissions in that information or Content or
          for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any
          action regarding how you may interpret and use the Content or what actions you may take as a result of having
          been exposed to the Content, and you hereby release us from all liability for you having acquired or not
          acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in
          using the Services and are not responsible for which users gain access to the Services.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          You are responsible for all Content you contribute, in any manner, to the Services, and you represent and
          warrant you have all rights necessary to do so, in the manner in which you contribute it.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          The Services may contain links or connections to third-party websites or services that are not owned or
          controlled by Hedado. When you access third-party websites or use third-party services, you accept that there
          are risks in doing so, and that Hedado is not responsible for such risks.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Hedado has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or
          practices of or opinions expressed in any third-party websites or by any third party that you interact with
          through the Services. In addition, Hedado will not and cannot monitor, verify, censor or edit the content of
          any third-party site or service. We encourage you to be aware when you leave the Services and to read the
          terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By
          using the Services, you release and hold us harmless from any and all liability arising from your use of any
          third-party website or service.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Your interactions with organizations and/or individuals found on or through the Services, including payment
          and delivery of goods or services, and any other terms, conditions, warranties or representations associated
          with such dealings, are solely between you and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with any online or offline transaction with
          any of these third parties. You agree that Hedado shall not be responsible or liable for any loss or damage of
          any sort incurred as the result of any such dealings.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          If there is a dispute between participants on this site or Services, or between users and any third party, you
          agree that Hedado is under no obligation to become involved. In the event that you have a dispute with one or
          more other users, you release Hedado, its directors, officers, employees, agents, and successors from claims,
          demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or
          undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby
          do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance:
          “A general release does not extend to claims that the creditor or releasing party does not know or suspect to
          exist in his or her favor at the time of executing the release and that, if known by him or her, would have
          materially affected his or her settlement with the debtor or released party.”
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Will Hedado ever change the Services?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any
          part of the Services, or we may introduce new features or impose limits on certain features or restrict access
          to parts or all of the Services. We’ll try to give you notice when we make a material change to the Services
          that would adversely affect you, but this isn’t always practical. We reserve the right to remove any Content
          from the Services at any time, for any reason (including, but not limited to, if someone alleges you
          contributed that Content in violation of these Terms), in our sole discretion, and without notice.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">Do the Services cost anything?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          The Services are currently free, but we reserve the right to charge for certain or all Services in the future.
          We will notify you before any Services you are then using begin carrying a fee, and if you wish to continue
          using such Services, you must pay all applicable fees for such Services.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">What if I want to stop using the Services?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          You’re free to do that at any time by contacting us at support@hedado.com; please refer to our{' '}
          <PrivacyLink text="Privacy Policy" />, as well as the licenses above, to understand how we treat information
          you provide to us after you have stopped using our Services.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Hedado is also free to terminate (or suspend access to) your use of the Services or your account for any
          reason in our discretion, including your breach of these Terms. Hedado has the sole right to decide whether
          you are in violation of any of the restrictions set forth in these Terms.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Account termination may result in destruction of any Content associated with your account, so keep that in
          mind before you decide to terminate your account.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          If you have deleted your account by mistake, contact us immediately at support@hedado.com – we will try to
          help, but unfortunately, we can’t promise that we can recover or restore anything.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way
          of example, all of the following will survive termination: any obligation you have to indemnify us, any
          limitations on our liability, any terms regarding ownership or intellectual property rights, and terms
          regarding disputes between us, including without limitation the arbitration agreement.
        </Typography>
      </p>

      <p>
        <Typography variant="h6">What else do I need to know?</Typography>
      </p>

      <p>
        <Typography variant="body1">
          Warranty Disclaimer. Hedado and its licensors, suppliers, partners, parent, subsidiaries or affiliated
          entities, and each of their respective officers, directors, members, employees, consultants, contract
          employees, representatives and agents, and each of their respective successors and assigns (Hedado and all
          such parties together, the “Hedado Parties”) make no representations or warranties concerning the Services,
          including without limitation regarding any Content contained in or accessed through the Services, and the
          Hedado Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency
          of material contained in or accessed through the Services or any claims, actions, suits procedures, costs,
          expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the
          Services. The Hedado Parties make no representations or warranties regarding suggestions or recommendations of
          services or products offered or purchased through or in connection with the Services. THE SERVICES AND CONTENT
          ARE PROVIDED BY HEDADO (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND,
          EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME
          STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY
          TO YOU.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO
          LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE
          HEDADO PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR
          CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA,
          LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE
          GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR
          (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO
          YOU.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Indemnity. You agree to indemnify and hold the Hedado Parties harmless from and against any and all claims,
          liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from
          or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a
          third party using your account), and (b) your violation of these Terms. In the event of such a claim, suit, or
          action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your
          account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification
          obligations hereunder).
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Assignment. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or
          your Services account, in any way (by operation of law or otherwise) without Hedado's prior written consent.
          We may transfer, assign, or delegate these Terms and our rights and obligations without consent.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Choice of Law. These Terms are governed by and will be construed under the Federal Arbitration Act, applicable
          federal law, and the laws of the State of California, without regard to the conflicts of laws provisions
          thereof.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Arbitration Agreement. Please read the following ARBITRATION AGREEMENT carefully because it requires you to
          arbitrate certain disputes and claims with Hedado and limits the manner in which you can seek relief from
          Hedado. Both you and Hedado acknowledge and agree that for the purposes of any dispute arising out of or
          relating to the subject matter of these Terms, Hedado's officers, directors, employees and independent
          contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these
          Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms
          against you as the third-party beneficiary hereof.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (a) Arbitration Rules; Applicability of Arbitration Agreement. The parties shall use their best efforts to
          settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these
          Terms directly through good-faith negotiations, which shall be a precondition to either party initiating
          arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding
          arbitration in Los Angeles County, California. The arbitration will proceed in the English language, in
          accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one
          commercial arbitrator with substantial experience in resolving intellectual property and commercial contract
          disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with
          such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent
          jurisdiction.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (b) Costs of Arbitration. The Rules will govern payment of all arbitration fees. Hedado will pay all
          arbitration fees for claims less than seventy-five thousand ($75,000) dollars. Hedado will not seek its
          attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (c) Small Claims Court; Infringement. Either you or Hedado may assert claims, if they qualify, in small claims
          court in Los Angeles County, California or any United States county where you live or work. Furthermore,
          notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue
          injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the
          actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade
          secrets, patents or other intellectual property rights.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (d) Waiver of Jury Trial. YOU AND HEDADO WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE
          A TRIAL IN FRONT OF A JUDGE OR JURY. You and Hedado are instead choosing to have claims and disputes resolved
          by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules
          applicable in court and are subject to very limited review by a court. In any litigation between you and
          Hedado over whether to vacate or enforce an arbitration award, YOU AND HEDADO WAIVE ALL RIGHTS TO A JURY
          TRIAL, and elect instead to have the dispute be resolved by a judge.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (e) Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
          AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN
          ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER
          CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable,
          neither you nor Hedado is entitled to arbitration; instead all claims and disputes will be resolved in a court
          as set forth in (g) below.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (f) Opt-out. You have the right to opt out of the provisions of this Section by sending written notice of your
          decision to opt out to support@hedado.com within thirty (30) days of first accepting these Terms. You must
          include (i) your name and residence address, (ii) the email address and/or telephone number associated with
          your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (g) Exclusive Venue. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing
          arbitration agreement permits either you or Hedado to litigate any dispute arising out of or relating to the
          subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either
          party, and both you and Hedado agree that any judicial proceeding (other than small claims actions) will be
          brought in the state or federal courts located in, respectively, Los Angeles County, California, or the
          federal district in which that county falls.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          (h) Severability. If the prohibition against class actions and other claims brought on behalf of third parties
          contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement
          section will be null and void. This arbitration agreement will survive the termination of your relationship
          with Hedado.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Miscellaneous. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and
          other governmental assessments associated with your activity in connection with the Services, provided that
          the Hedado may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit.
          The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any
          further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that
          provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise
          remain in full force and effect and enforceable. You and Hedado agree that these Terms are the complete and
          exclusive statement of the mutual understanding between you and Hedado, and that these Terms supersede and
          cancel all previous written and oral agreements, communications and other understandings relating to the
          subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner,
          or joint venture of Hedado, and you do not have any authority of any kind to bind Hedado in any respect
          whatsoever.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          Except as expressly set forth in the section above regarding the arbitration agreement, you and Hedado agree
          there are no third-party beneficiaries intended under these Terms.
        </Typography>
      </p>
    </>
  );
};

export default TermsOfUse;
