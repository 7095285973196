type Color = {
  hex: string;
  name: string;
};

export const PROFILE_COLORS: Color[] = [
  { hex: '#ececec', name: 'Gray' },
  { hex: '#edf7ee', name: 'Light Green' },
  { hex: '#161b22', name: 'Charcoal' },
  { hex: '#a6cfe4', name: 'Hedado Blue' },
];

export const getProfileColor = (query: string): Color => {
  const defaultColor: Color = { hex: '#ececec', name: 'Gray' };
  const queryKey: 'hex' | 'name' = query.indexOf('#') === 0 ? 'hex' : 'name';

  return PROFILE_COLORS.find((color) => color[queryKey].toLowerCase() === query.toLowerCase()) ?? defaultColor;
};
