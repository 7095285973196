import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Snackbar, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Organization from '../../model/Organization.model';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    snackbarLink: {
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
  })
);

interface OrganizationAddedSnackbarProps {
  organization: Organization;
  showSnackbar: boolean;
}

const OrganizationAddedSnackbar = ({ organization, showSnackbar }: OrganizationAddedSnackbarProps) => {
  const classes = useStyles();
  const [showConfirmation, setShowConfirmation] = useState(showSnackbar);

  useEffect(() => {
    setShowConfirmation(showSnackbar);
  }, [showSnackbar]);

  return (
    <>
      <Snackbar
        open={showConfirmation}
        autoHideDuration={10000}
        onClose={() => setShowConfirmation(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          icon={<CheckCircleIcon sx={{ color: '#FFFFFF' }} />}
          onClose={() => setShowConfirmation(false)}
          sx={{ width: '100%', backgroundColor: '#072736', color: '#FFFFFF' }}
        >
          {organization.name} was added to your portfolio.{' '}
          <RouterLink className={classes.snackbarLink} to="/portfolio">
            Take me to my portfolio
          </RouterLink>
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrganizationAddedSnackbar;
