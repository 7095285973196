import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useAuthenticatedHedadoQuery } from 'hooks/useHedadoQuery';
import CurrentSubscription from '../../model/CurrentSubscription.model';
import AccountBalanceWalletOutlined from '@mui/icons-material/AccountBalanceWalletOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';

import SummaryCard from './SummaryCard';
import { getMostRecentSubscriptionForDonor_Gql } from '../../gql/giving/getMostRecentSubscriptionForDonor';
import { convertCentsToDollars } from '../../helpers/currencyHelper';
import { convertUTCDateToLocalDate } from '../../helpers/dateHelper';
import useOnboarding from 'hooks/useOnboarding';

interface DonationSummaryProps {
  totalDonations: string; // TODO: This should be a number and formatted with a string formatter
  nextDonationDate: string; // TODO: This should be a date and formatted with a string formatter
  nextDonationAmount: string; // TODO: This should be a number and formatted with a string formatter
}

const DonationSummary = ({ totalDonations, nextDonationDate, nextDonationAmount }: DonationSummaryProps) => {

  const [onboarding] = useOnboarding();

  const iconSize = onboarding ? ['24px', '4vw', '32px'] : ['32px', '6vw', '48px'];

  const [latestSubscription, setLatestSubscription] = useState<CurrentSubscription | null>(null);

  const { loading, data } = useAuthenticatedHedadoQuery(getMostRecentSubscriptionForDonor_Gql);

  useEffect(() => {
    if (loading || !data) return;

    setLatestSubscription(data.getMostRecentSubscriptionForDonor);
  }, [data, loading]);

  const latestTransactionDescription = () => {
    if (latestSubscription === null) return;
    const { createdAt, amountCents, tipsCents } = latestSubscription;

    const formattedAmount = convertCentsToDollars(amountCents as number);
    const formattedTip = convertCentsToDollars(tipsCents as number);
    const formattedDate = convertUTCDateToLocalDate(createdAt.toString());

    return `Your most recent donation was on ${formattedDate} for ${formattedAmount} with a ${formattedTip} tip.`;
  };

  const displayLatestDonation = (): boolean => {
    return latestSubscription !== null && latestSubscription.frequency === 'once';
  };

  return (
    <>
      <Grid container spacing={2}>
        <SummaryCard
          cardTitle="All-Time Donations"
          amount={totalDonations}
          icon={<AccountBalanceWalletOutlined sx={{ fontSize: iconSize }} color="secondary" />}
          buttonText="Donation History"
          route="/history"
          gridSize={displayLatestDonation() ? 4 : 6}
        />
        {/* TODO: To make the component work, it was forced to swap dates and amounts for this one, fix it */}
        <SummaryCard
          cardTitle={nextDonationDate.length > 0 ? 'Next scheduled donation' : 'No donation scheduled'}
          amount={nextDonationDate}
          // TODO: This should be a number and formatted with a string formatter
          date={
            nextDonationDate.length > 0 && nextDonationAmount && nextDonationAmount !== '$0.00'
              ? nextDonationAmount
              : undefined
          }
          icon={<EventNoteOutlinedIcon sx={{ fontSize: iconSize }} color="secondary" />}
          buttonText="Review Portfolio"
          route="/portfolio"
          gridSize={displayLatestDonation() ? 4 : 6}
        />

        {displayLatestDonation() && (
          <SummaryCard
            cardTitle="Latest Donation"
            text={latestTransactionDescription()}
            icon={<AccountBalanceWalletOutlined sx={{ fontSize: iconSize }} color="secondary" />}
            route="/manage-giving"
            buttonText="Manage Giving"
            gridSize={4}
          />
        )}
      </Grid>
    </>
  );
};

export default DonationSummary;
