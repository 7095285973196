import { v4 as uuidv4 } from 'uuid';
import { env } from '../env';
import cropImage from 'utils/crop';
import { getAccessTokenFromStorage } from 'utils/storage';

export async function uploadPresignedImage({
  file,
  fileName,
  folderName,
  crop,
  aspectRatio,
  extension,
}: {
  file: FormDataEntryValue;
  fileName: string;
  folderName: string;
  crop: boolean;
  aspectRatio?: number;
  extension?: string;
}): Promise<string> {
  if (!file || typeof file !== 'object' || file.size <= 0) {
    throw new Error('Image not provided');
  }
  let body;

  if (crop && aspectRatio) {
    const croppedImage = await cropImage(URL.createObjectURL(file), aspectRatio);
    body = croppedImage as File;
  } else {
    body = file;
  }

  const qualifiedFileName = `${fileName}_${uuidv4()}.${extension ? extension : file.name.split('.').pop()}`;
  const presignedUrl = await getS3PresignedUrl({ fileName: qualifiedFileName, fileType: file.type, folderName });

  const response = await fetch(presignedUrl, {
    method: 'put',
    headers: {
      'Content-Type': file.type,
    },
    mode: 'cors',
    body,
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const url = new URL(presignedUrl);

  return url.pathname.replace(`/${folderName}/`, '');
}

export async function getS3PresignedUrl({
  fileName,
  fileType,
  folderName,
}: {
  fileName: string;
  fileType: string;
  folderName: string;
}): Promise<string> {
  const token = getAccessTokenFromStorage();
  const response = await fetch(
    `${env.REACT_APP_NODE_URL}/s3/presignedUrl?imageName=${encodeURIComponent(fileName)}&imageType=${encodeURIComponent(
      fileType
    )}&folderName=${folderName}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}
