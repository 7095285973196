import type { CampaignFormProps } from '../CampaignForm';
import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getRequiredFieldError, getValidDateError, getMinimumIntegerError } from 'helpers/formHelpers';
dayjs.extend(utc);

export enum DonationsFormStateKey {
  endDate = 'endDate',
  showDonationGoal = 'showDonationGoal',
  showDonationTotal = 'showDonationTotal',
  showDonationTotalMatched = 'showDonationTotalMatched',
  donationGoalCents = 'donationGoalCents',
  startDate = 'startDate',
}

export function useDonationsFormState(
  campaign: CampaignFormProps['campaign'],
  qualification: CampaignFormProps['qualification']
) {
  const [donationsFormState, setDonationsFormState] = useState(getDonationsFormState(campaign, qualification));

  return { donationsFormState, setDonationsFormState };
}

function getDonationsFormState(
  campaign: CampaignFormProps['campaign'],
  qualification: CampaignFormProps['qualification']
) {
  return {
    endDate: {
      value: qualification?.endDate ? dayjs.utc(qualification?.endDate).format('YYYY-MM-DD') : '',
      hasBlurredAtLeastOnce: false,
    },
    showDonationGoal: {
      value: !!campaign?.displayData?.showDonationGoal,
      hasBlurredAtLeastOnce: false,
    },
    showDonationTotal: {
      value: !!campaign?.displayData?.showDonationTotal,
      hasBlurredAtLeastOnce: false,
    },
    showDonationTotalMatched: {
      value: !!campaign?.displayData?.showDonationTotalMatched,
      hasBlurredAtLeastOnce: false,
    },
    donationGoalCents: {
      value: campaign?.donationGoalCents || 0,
      hasBlurredAtLeastOnce: false,
    },
    startDate: {
      value: qualification?.startDate ? dayjs.utc(qualification?.startDate).format('YYYY-MM-DD') : '',
      hasBlurredAtLeastOnce: false,
    },
  };
}

export type DonationsFormState = ReturnType<typeof getDonationsFormState>;

export function computeDonationsFormStateErrors(formState: DonationsFormState): {
  [key in DonationsFormStateKey]?: string | null;
} {
  return {
    endDate: getRequiredFieldError(formState.endDate.value) || getEndDateError(formState),
    donationGoalCents: formState.showDonationGoal.value
      ? getMinimumIntegerError(formState.donationGoalCents.value, { min: 0, inclusive: false })
      : '',
    startDate: getRequiredFieldError(formState.startDate.value) || getValidDateError(formState.startDate.value),
  };
}

function getEndDateError({ endDate, startDate }: DonationsFormState) {
  return getValidDateError(endDate.value) ||
    (!!endDate.value &&
      !!startDate.value &&
      dayjs(startDate.value, 'YYYY-MM-DD').isValid() &&
      dayjs(endDate.value).isSame(startDate.value)) ||
    dayjs(endDate.value).isBefore(startDate.value)
    ? "End date can't be before start date"
    : '';
}

export type DonationsFormStateErrors = ReturnType<typeof computeDonationsFormStateErrors>;
