import { deepmerge } from '@mui/utils';
import OnboardingProgress from '../model/OnboardingProgress.model';
import useDonorProfile from '../hooks/useDonorProfile';
import useUpdateDonorMetadata from '../hooks/useUpdateDonorMetadata';
import useDonorPublicProfile from 'hooks/useDonorPublicProfile';
import { useEffect } from 'react';
import createPersistedState from 'use-persisted-state';

export const emptyOnboarding = {
  introModal: {},
  checklist: {},
  receiptTrackingTodo: {},
  portfolioTodo: {},
  accountTodo: {},
  profileTodo: {},
} as OnboardingProgress;

const useOnboardingState: any = createPersistedState('onboarding');

const useOnboarding = () => {
  const { isEnabled: isPublicProfileEnabled } = useDonorPublicProfile();
  const [donorProfile, { loading }] = useDonorProfile();
  const [onboarding, _setOnboarding] = useOnboardingState(emptyOnboarding);
  const [, { updateDonorMetadata }] = useUpdateDonorMetadata();

  useEffect(() => {
    if (loading || !donorProfile || !donorProfile?.donorMetadata) return;
    _setOnboarding({ ...onboarding, ...(JSON.parse(donorProfile?.donorMetadata) as OnboardingProgress) });
    // FIXME: no-unused-vars
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const setOnboarding = (updatedOnboarding: Partial<OnboardingProgress>) => {
    _setOnboarding({ ...onboarding, ...updatedOnboarding });
    updateDonorMetadata(JSON.stringify({ ...onboarding, ...updatedOnboarding }));
  };

  return [
    deepmerge(onboarding, {
      receiptTrackingTodo: {
        hasForwardedDonation: donorProfile?.hasForwardedDonation ?? false,
      },
      portfolioTodo: {
        didAddToPortfolio: (donorProfile?.allocations?.length ?? 0) > 0,
        allocations: donorProfile?.allocations?.length ?? 0,
      },
      accountTodo: {
        hasPaymentMethod: donorProfile?.hasPaymentMethod ?? false,
        hasRecurringDonation: donorProfile?.hasRecurringDonation ?? false,
      },
      profileTodo: {
        didCreatePublicProfile: isPublicProfileEnabled,
      },
    }) as OnboardingProgress,
    setOnboarding,
  ] as const;
};

export default useOnboarding;
