import { gql } from '@apollo/client';
import { CampaignTier } from 'model/CampaignTier.model';

export interface AddCampaignMutationVariables {
  relationships: {
    adminEmailsToAdd: string[];
    adminEmailsToRemove: string[];
    domainsToAdd: string[];
    domainsToRemove: string[];
    donorEmailsToAdd: string[];
    donorEmailsToRemove: string[];
    organizationIdsToAdd: string[];
    organizationIdsToRemove: string[];
  };
  campaign: {
    description: string;
    donationGoalCents: number;
    nameOnReceipt: string;
    slug: string;
    title: string;
    tier: CampaignTier;
    displayData: {
      bannerImage: string;
      bannerImageMobile: string;
      logoImage: string;
      showCampaignOrganizations: boolean;
      showDonationGoal: boolean;
      showDonationTotal: boolean;
      showDonationTotalMatched: boolean;
      showDonorOrganizations: boolean;
    };
  };
  qualification: {
    endDate: string;
    hasMatching: boolean;
    includeDonationsExceedingMatchLimits: boolean;
    individualMatchLimitCents: number | null;
    limitOrganizations: boolean;
    limitToDomains: boolean;
    limitToPeople: boolean;
    matchRatio: number | null;
    openToAll: boolean;
    overallMatchLimitCents: number | null;
    startDate: string;
    universal: boolean;
  };
}

export interface AddCampaignMutationResponse {
  addCampaign: { id: string };
}

export const addCampaignGql = gql`
  mutation AddCampaign(
    $campaign: CampaignInput!
    $qualification: CampaignQualificationInput!
    $relationships: RelationshipsInput!
  ) {
    addCampaign(campaign: $campaign, qualification: $qualification, relationships: $relationships) {
      id
    }
  }
`;
