import type { CampaignDonationMatchesOrderByAttribute } from 'gql/donationMatches/getCampaignDonationMatches';
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material';
import { tableColumns } from 'components/CampaignAdminPage/hooks/useDonationMatchesTableState';
import DonationMatchesTableHeaderCell from './components/DonationMatchesTableHeaderCell';

interface DonationMatchesTableHeaderProps {
  setOrderBy: (key: CampaignDonationMatchesOrderByAttribute) => void;
  orderDirection: 'asc' | 'desc';
  orderByAttribute: CampaignDonationMatchesOrderByAttribute;
  checkboxSelected: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSettled: boolean;
}

export default function DonationMatchesTableHeader({
  orderByAttribute,
  orderDirection,
  setOrderBy,
  checkboxSelected,
  onCheckboxChange,
  isSettled,
}: DonationMatchesTableHeaderProps) {
  return (
    <TableHead>
      <TableRow>
        {!isSettled && (
          <TableCell width={75} sx={{ backgroundColor: '#e4e4e4' }}>
            <Checkbox sx={{ p: 0 }} checked={checkboxSelected} onChange={onCheckboxChange} />
          </TableCell>
        )}
        {tableColumns.map((tableColumn) => (
          <DonationMatchesTableHeaderCell
            handleSortLabelClick={setOrderBy}
            orderByAttribute={orderByAttribute}
            orderDirection={orderDirection}
            tableColumn={tableColumn}
            key={tableColumn.id}
          />
        ))}
        {!isSettled && (
          <TableCell width={100} sx={{ backgroundColor: '#e4e4e4' }}>
            <b>Actions</b>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
